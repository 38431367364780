import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import dayjs from 'dayjs';
import { useRef } from 'react';

import Calendar from '../../Calendar';
import Drawer from '../../Drawer';
import '../../Schedule.css';
import { useDrawerState } from '../../useDrawerState';
import AvailabilityEvent, { AvailableEvent } from './AvailabilityEvent';

interface SelectTimeProps {
  onEventSelection: (event: AvailableEvent) => void;
  currentDate?: Date;
}

const SelectTime = ({
  // This is the current date set outside the add event flow
  currentDate: initialDate,
  onEventSelection,
}: SelectTimeProps) => {
  const { currentDate, setCurrentDate, expanded, expanding, toggleExpanded } =
    useDrawerState(initialDate);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonTitle>{dayjs(currentDate).format('MMMM YYYY')}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={() => setCurrentDate(new Date())}>
              Today
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="schedule-page" ref={contentRef}>
        <Calendar
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          expanded={expanded}
          expanding={expanding}
        />
        <Drawer
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          showAvailability
        >
          {({ eventGroup }) => (
            <>
              {eventGroup?.availabileEvents?.map((event) => (
                <AvailabilityEvent
                  key={event.start.toString()}
                  {...event}
                  onClick={() => {
                    contentRef.current
                      ?.closest('ion-nav')
                      ?.pop({}, () => onEventSelection(event));
                  }}
                />
              ))}
            </>
          )}
        </Drawer>
      </IonContent>
    </>
  );
};

export default SelectTime;
