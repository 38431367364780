import { IonRouterLink } from '@ionic/react';
import { Tooltip } from '@mui/joy';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import { ReactNode } from 'react';
import { useRouteMatch } from 'react-router';

import {
  TooltipHotkeyTitle,
  TooltipHotkeyTitleProps,
} from './TooltipHotkeyTitle';

export interface SidebarLinkProps extends TooltipHotkeyTitleProps {
  href: string;
  children: ReactNode;
  selected?: string[];
}

export const SidebarLink = ({
  href,
  title,
  first,
  second,
  children,
  selected: selectedPaths = [],
}: SidebarLinkProps) => {
  const match = useRouteMatch({
    path: [href, ...selectedPaths],
  });
  const selected = Boolean(match);

  return (
    <IonRouterLink routerLink={href} routerDirection="none">
      <Tooltip
        title={
          first ? (
            <TooltipHotkeyTitle title={title} first={first} second={second} />
          ) : undefined
        }
        variant="plain"
        placement="right"
        enterDelay={700}
      >
        <ListItem sx={{ margin: '2px 1em' }}>
          <ListItemButton
            selected={selected}
            color="neutral"
            variant={selected ? 'solid' : 'plain'}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'lightgray',
                color: 'black',
              },
            }}
          >
            {!children ? null : (
              <ListItemDecorator sx={{ color: 'inherit' }}>
                {children}
              </ListItemDecorator>
            )}
            <ListItemContent>{title}</ListItemContent>
          </ListItemButton>
        </ListItem>
      </Tooltip>
    </IonRouterLink>
  );
};
