export const getTargetIndex = (
  overIndex: number,
  activeIndex: number,
  isAfter: boolean | undefined
) => {
  if (activeIndex > overIndex) {
    return isAfter ? overIndex + 1 : overIndex;
  }

  if (activeIndex < overIndex) {
    return isAfter ? overIndex : overIndex - 1;
  }

  return overIndex;
};
