import Avatar from '@components/Avatar';
import { useOrgainzation } from '@features/Organization/organizationSlice';

import { useCustomAvatar } from './useCustomAvatar';

export interface UploadAvatarInput {
  extension: string;
  file: File;
}

interface UploadAvatarProps {
  avatar: string | undefined;
  avatarPreview: string | undefined;
  onAvatarUpload: (input: UploadAvatarInput) => void;
}

export const UploadAvatar = ({
  avatar,
  avatarPreview,
  onAvatarUpload,
}: UploadAvatarProps) => {
  const organization = useOrgainzation();
  const [src] = useCustomAvatar(avatar);

  return (
    <label>
      <Avatar
        src={avatarPreview ?? src}
        size="lg"
        sx={{ width: 60, height: 60, margin: '1em auto' }}
      />
      <input
        type="file"
        accept="image/*"
        hidden
        onChange={async (event) => {
          const file = event.target.files?.[0];

          if (!file || !organization) {
            return;
          }

          const extension = file.name.split('.').at(-1);
          const { readAndCompressImage } = require('browser-image-resizer');
          const resizedImage = await readAndCompressImage(file, {
            quality: 0.8,
            maxWidth: 256,
            maxHeight: 256,
          });

          if (!extension) {
            return;
          }

          onAvatarUpload({ extension, file: resizedImage });
        }}
      />
    </label>
  );
};
