import {
  CircularProgress,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';

interface AssignmentProps {
  selected?: boolean;
  progress?: number;
  title: string;
  onClick?: () => void;
}

export const Assignment = ({
  selected,
  title,
  progress,
  onClick,
}: AssignmentProps) => {
  return (
    <>
      <ListItem>
        <ListItemButton selected={selected} onClick={onClick}>
          <ListItemDecorator>
            <CircularProgress determinate value={progress} size="sm" />
          </ListItemDecorator>

          <ListItemContent>
            <Typography>{title}</Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>
      <ListDivider />
    </>
  );
};
