import dayjs from 'dayjs';

import { ParsedEvent } from '@models/events/model';

import { unfurlEventsBetween } from '../useUnfurlEventsBetween/unfurlEventsBetween';

export interface GetEventsProps {
  events?: ParsedEvent[];
  startDate: Date;
  endDate?: Date;
  limit?: number;
  startOf?: 'month' | 'day' | 'hour';
}

const maxIterations = 20;

export const unfurlEvents = ({
  events,
  startDate,
  endDate: rawEndDate,
  limit = 20,
  startOf = 'month',
}: GetEventsProps) => {
  if (!events) {
    return [];
  }

  const results: ParsedEvent[] = [];
  const endDate = rawEndDate || dayjs(startDate).add(2, 'years').toDate();
  let current = dayjs(startDate).startOf(startOf);
  let iteration = 1;

  while (
    results.length < limit &&
    current.toDate() < endDate &&
    iteration <= maxIterations
  ) {
    const batchResults = unfurlEventsBetween(
      events,
      current.toDate(),
      current.endOf('month').toDate()
    );

    const remaining = limit - results.length;

    results.push(...batchResults.slice(0, remaining));

    if (iteration > 3 && batchResults.length === 0) {
      break;
    }

    current = current.add(1, 'month').startOf('month');
    iteration++;
  }

  return results;
};
