import { Box } from '@mui/joy';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import 'swiper/css';

import { expandingTransitionSeconds, transitionTimingFunction } from '.';
import CalendarDay from './CalendarDay';
import { useCalendar } from './useCalendar';

interface CalendarDaysProps {
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  currentDate: Date;
  inputDate: Date;
  offset: number;
  expanded: boolean;
  expanding: boolean;
  transitioning: boolean;
}

export const calendarHeight = 250;

const CalendarDays = ({
  setCurrentDate,
  currentDate,
  inputDate,
  offset,
  expanded,
  expanding,
  transitioning,
}: CalendarDaysProps) => {
  const calendar = useCalendar({ inputDate, expanded, expanding, offset });

  const rowHeight = useMemo(
    () => (calendar.length / 7 === 6 ? 41.5 : 48.2),
    [calendar]
  );
  const wasTransitioning = usePrevious(expanding);
  const [next, setNext] = useState(!expanded);
  const wasNext = usePrevious(next);

  useEffect(() => {
    if (wasTransitioning !== expanding) {
      setNext(!expanded);
    }
  }, [expanding, wasTransitioning, expanded]);

  const row = useMemo(() => {
    const date = dayjs(inputDate).date();
    const startOfMonth = dayjs(inputDate).startOf('month').day();

    return Math.ceil((startOfMonth + date) / 7);
  }, [inputDate]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyItems: 'center',
        padding: 1,
        paddingTop: 0,
        marginTop: '-6px',
        height: calendarHeight,
        transition:
          expanding && wasNext !== next
            ? `${expandingTransitionSeconds}s transform ${transitionTimingFunction}`
            : undefined,

        transform: (next ? !expanded || expanding : !expanding)
          ? 'translateY(0)'
          : `translateY(-${(row - 1) * rowHeight}px)`,
      }}
    >
      {calendar?.map((day) => (
        <CalendarDay
          key={day.date}
          day={day}
          setCurrentDate={setCurrentDate}
          currentDate={currentDate}
          transitioning={transitioning}
        />
      ))}
    </Box>
  );
};

export default CalendarDays;
