import { Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { getEventSlug } from '@features/Schedule';
import { ParsedEvent, eventTypeSolidColorMap } from '@models/events/model';

import { RelatedEventContainer } from './RelatedEventContainer';

const timeFormat = 'h:mmA';

interface RelatedEventProps {
  event: ParsedEvent;
}

export const RelatedEvent = ({ event }: RelatedEventProps) => {
  const slug = getEventSlug(event);
  const organizationSlug = useOrganizationSlug();
  const readable = useMemo(() => {
    const day = dayjs(event.start);

    return {
      monthDay: day.format('D'),
      shortMonth: day.format('MMM'),
      startTime: day.format(timeFormat),
      endTime: day.add(event.duration, 'minutes').format(timeFormat),
    };
  }, [event.start, event.duration]);

  return (
    <RelatedEventContainer
      to={`/${organizationSlug}/schedule/${slug}`}
      eventType={event.type}
      left={
        <>
          <Typography
            fontSize={12}
            sx={{ color: eventTypeSolidColorMap[event.type], lineHeight: 1 }}
          >
            {readable.shortMonth}
          </Typography>
          <Typography
            fontSize={20}
            sx={{ color: eventTypeSolidColorMap[event.type], lineHeight: 1 }}
          >
            {readable.monthDay}
          </Typography>
        </>
      }
      primary={<>{event.title} Lesson</>}
      secondary={
        <>
          {readable.startTime} - {readable.endTime}
        </>
      }
    />
  );
};
