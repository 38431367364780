import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { useRef } from 'react';
import { useParams } from 'react-router';

import { EditInvoiceProvider } from './EditInvoice/EditInvoiceContext';
import { ViewInvoice } from './ViewInvoice';

export const UnauthenticatedInvoice = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const pageRef = useRef(null);

  return (
    <EditInvoiceProvider invoiceId={invoiceId}>
      <IonPage ref={pageRef}>
        <IonHeader translucent>
          <IonToolbar></IonToolbar>
        </IonHeader>
        <IonContent
          color="light"
          className="view-contact"
          style={{ paddingTop: 50 }}
          fullscreen
        >
          <ViewInvoice pageRef={pageRef} viewOnly />
        </IonContent>
      </IonPage>
    </EditInvoiceProvider>
  );
};
