import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { Avatar, Box, Button, Typography } from '@mui/joy';

import { ListHeader } from '@components/ListHeader';
import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { UploadAvatar, UploadAvatarInput } from '@components/UploadAvatar';

import { avatarCategories } from '../ViewContact/avatarCategories';

interface AvatarModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  selectedAvatar?: string;
  setSelectedAvatar: (avatar: string) => void;
  avatar: string | undefined;
  onAvatarUpload: (avatar: UploadAvatarInput) => void;
  avatarPreview: string | undefined;
}

export const AvatarModal = ({
  page,
  isOpen,
  onDone,
  selectedAvatar,
  setSelectedAvatar,
  avatar,
  onAvatarUpload,
  avatarPreview,
}: AvatarModalProps) => {
  if (!page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={page.current}
      isOpen={isOpen}
      onDidDismiss={() => onDone?.()}
    >
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <ModalListContainer>
          <Box>
            <Typography level="h4">Select Avatar</Typography>
            <Typography>Select the avatar for this contact.</Typography>
          </Box>

          <Box className="scrollable">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                margin: '0 !important',
              }}
            >
              <Box>
                <UploadAvatar
                  avatar={avatar}
                  onAvatarUpload={onAvatarUpload}
                  avatarPreview={avatarPreview}
                />
              </Box>
              {/* add custom profile image + history */}
              {Object.entries(avatarCategories).map(([category, artists]) => (
                <Box key={category}>
                  <ListHeader
                    v2
                    noSideSpacing
                    sx={{ textAlign: 'left', paddingLeft: '28px' }}
                  >
                    {category}
                  </ListHeader>
                  <Box
                    sx={{
                      padding: '5px 30px',
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'flex-start',
                      overflowX: 'auto',
                    }}
                  >
                    {artists.map(({ shortName, fileName }) => {
                      const resolvedFileName =
                        fileName ??
                        shortName.toLowerCase().split(' ').join('-');

                      return (
                        <Button
                          key={shortName}
                          variant="verticalPlain"
                          sx={{ fontSize: '11px', marginBottom: '5px' }}
                          slotProps={{
                            startDecorator: {
                              sx: (theme) => ({
                                border: `2px solid ${
                                  selectedAvatar === resolvedFileName
                                    ? `rgb(${theme.palette.primary.mainChannel})`
                                    : theme.palette.common.white
                                }`,
                              }),
                            },
                          }}
                          startDecorator={
                            <Avatar
                              src={`assets/avatars/${resolvedFileName}.png`}
                              style={{
                                borderRadius: '50%',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          }
                          onClick={() => setSelectedAvatar(resolvedFileName)}
                        >
                          {shortName}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ModalListContainer>
      </IonContent>
    </IonModal>
  );
};
