import { CssVarsProvider } from '@mui/joy/styles';
import { THEME_ID as JOY_THEME_ID } from '@mui/joy/styles';
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from '@mui/material/styles';

import theme from '@theme/theme';

import { FeatureFlagsProvider } from './FeatureFlags/FeatureFlagsProvider';
import { HotkeysProvider } from './Hotkeys/HotkeysProvider';
import { UserDataProvider } from './UserData';

export interface ProvidersProps {
  children: React.ReactNode;
}

const Providers = ({ children }: ProvidersProps) => (
  <CssVarsProvider
    disableTransitionOnChange
    defaultMode="system"
    theme={{ [JOY_THEME_ID]: theme }}
  >
    <MaterialCssVarsProvider defaultMode="system">
      <UserDataProvider>
        <FeatureFlagsProvider>
          <HotkeysProvider>{children}</HotkeysProvider>
        </FeatureFlagsProvider>
      </UserDataProvider>
    </MaterialCssVarsProvider>
  </CssVarsProvider>
);

export default Providers;
