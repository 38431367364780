import {
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

import { ListHelperText } from '@components/ListHelperText';
import { ToggleWrapper } from '@components/ionic-wrappers/ToggleWrapper';

import { useEditProductContext } from './ProductContext/useEditProductContext';
import { toCurrency } from './pricingUtils';

interface InvoiceDiscountsContentProps {
  readOnly?: boolean;
}

const InvoiceDiscountsContent = ({
  readOnly,
}: InvoiceDiscountsContentProps) => {
  const {
    basePricing,
    durationDiscount,
    setDurationDiscount,
    discountRate,
    setDiscountRate,
    hasBulkDiscount,
    setHasBulkDiscount,
    hasLateFees,
    setHasLateFees,
    hasFamilyDiscount,
    setHasFamilyDiscount,
  } = useEditProductContext();

  const lessonExampleMinutes = Number(durationDiscount) / 2;
  const priceForTwoLessons =
    basePricing / (basePricing / Number(durationDiscount));

  return (
    <>
      <IonList inset>
        <IonItem>
          <ToggleWrapper
            readOnly={readOnly}
            color="success"
            checked={hasFamilyDiscount}
            onIonChange={(event) => setHasFamilyDiscount(event.target.checked)}
          >
            Family Discount
          </ToggleWrapper>
        </IonItem>
        {hasFamilyDiscount ? (
          <IonItem>
            <IonInput
              style={{ textAlign: 'right', marginRight: 5 }}
              label="Discount Rate"
              type="number"
              value={discountRate}
              onIonChange={(event) =>
                setDiscountRate(Number(event.detail.value))
              }
            />
            %
          </IonItem>
        ) : null}
      </IonList>

      <ListHelperText inset>
        A family discount allows you to offer reduced lesson rates to families
        with multiple students, providing them savings while encouraging
        long-term commitment to your music lessons.
      </ListHelperText>

      <IonList inset>
        <IonItem>
          <ToggleWrapper
            readOnly={readOnly}
            color="success"
            checked={hasBulkDiscount}
            onIonChange={(event) => setHasBulkDiscount(event.detail.checked)}
          >
            Bulk Discount
          </ToggleWrapper>
        </IonItem>
        {hasBulkDiscount ? (
          <>
            <IonItem>
              <IonSelect
                label="Qualifying Durations"
                interface="popover"
                value={durationDiscount}
                onIonChange={(event) => setDurationDiscount(event.detail.value)}
              >
                {(
                  [
                    ['30', '30+ Minutes'],
                    ['60', '60+ Minutes'],
                  ] as const
                ).map(([value, label]) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonInput
                style={{ textAlign: 'right', marginRight: 5 }}
                label="Discount Rate"
                type="number"
                value={discountRate}
                onIonChange={(event) =>
                  setDiscountRate(Number(event.detail.value))
                }
              ></IonInput>
              %
            </IonItem>
          </>
        ) : null}
      </IonList>

      {!hasBulkDiscount ? (
        <ListHelperText inset>
          A bulk discount is a price reduction you offer to students who commit
          to a higher number of lessons per week.
        </ListHelperText>
      ) : (
        <ListHelperText inset>
          If two weekly {lessonExampleMinutes}-minute lessons have a base price
          total of ${toCurrency(priceForTwoLessons)}, then a {discountRate}%
          discount would result in a final price of $
          {toCurrency(
            priceForTwoLessons - (discountRate / 100) * priceForTwoLessons
          )}
          .
        </ListHelperText>
      )}

      <IonList inset>
        <IonItem>
          <ToggleWrapper
            readOnly={readOnly}
            checked={hasLateFees}
            onIonChange={(event) => setHasLateFees(event.detail.checked)}
            color="success"
          >
            Late Fees
          </ToggleWrapper>
        </IonItem>
        {hasLateFees ? (
          <>
            <IonItem>
              <IonInput
                style={{ textAlign: 'right', marginRight: 5 }}
                label="Late Fee"
                type="number"
                value={toCurrency(5)}
              ></IonInput>
              USD
            </IonItem>
            <IonItem>
              <IonInput
                style={{ textAlign: 'right', marginRight: 5 }}
                label="Grace Period"
                type="number"
                value="7"
              ></IonInput>
              days
            </IonItem>
          </>
        ) : null}
      </IonList>

      {hasLateFees ? (
        <ListHelperText inset>
          A grace period is the additional time allowed after the invoice due
          date, during which the student can make payment without incurring late
          fees. This provides flexibility for students and helps maintain a
          positive teacher-student relationship.
        </ListHelperText>
      ) : (
        <ListHelperText inset>
          Late fees are additional charges applied to an invoice when a
          student's payment is not received by the due date plus an optional
          grace period.
        </ListHelperText>
      )}
    </>
  );
};

export default InvoiceDiscountsContent;
