import {
  IonActionSheet,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonNav,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { useRef, useState } from 'react';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import AddEventOverview from '../AddEvent/AddEventOverview';
import { EditEventProvider } from '../AddEvent/EditEventContext';
import SelectStudent from '../AddEvent/SelectStudent';
import { EditProductEvent } from './EditProductEvent';
import { ViewEvent } from './ViewEvent';
import { useEventId } from './useEventId';

export const EventDetail = () => {
  const { eventId, eventInstanceDate } = useEventId();

  const organizationSlug = useOrganizationSlug();
  const router = useIonRouter();
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const page = useRef<HTMLElement | null>(null);

  const currentDate = eventInstanceDate || new Date();

  return (
    <EditEventProvider eventId={eventId} eventInstanceDate={eventInstanceDate}>
      {({
        reset,
        fieldProps: { eventType, selectStudentIsOpen },
        addStudentModalState,
        ongoingEvent,
        onUpdateEvent,
        recurranceChanged,
      }) => (
        <IonPage ref={page}>
          {isEditing ? (
            <>
              <IonNav
                root={() => (
                  <>
                    <IonHeader>
                      <IonToolbar>
                        <IonButtons slot="start">
                          <IonButton
                            color="dark"
                            onClick={() => {
                              // TODO: add action sheet for canceling unsaved changes
                              setIsEditing(false);
                              reset();
                            }}
                          >
                            Cancel
                          </IonButton>
                        </IonButtons>
                        <IonTitle>Event Details</IonTitle>
                        <IonButtons slot="end">
                          <IonButton
                            color="dark"
                            onClick={() => {
                              if (ongoingEvent) {
                                return setShowConfirmation(true);
                              }

                              onUpdateEvent();
                              setIsEditing(false);
                            }}
                          >
                            Done
                          </IonButton>
                        </IonButtons>
                      </IonToolbar>
                    </IonHeader>
                    <IonContent color="light" style={{ paddingTop: 50 }}>
                      {eventId ? (
                        <AddEventOverview
                          currentDate={currentDate}
                          onDone={() => setIsEditing(false)}
                          onDelete={() => {
                            setIsEditing(false);
                            router.goBack();
                          }}
                        />
                      ) : null}
                    </IonContent>
                  </>
                )}
              />
            </>
          ) : (
            <>
              <IonHeader>
                <IonToolbar className="view-contact-toolbar">
                  <IonButtons slot="start">
                    <IonBackButton
                      defaultHref={`/${organizationSlug}/schedule`}
                      color="dark"
                    />
                  </IonButtons>
                  {eventType === 'product' ? null : (
                    <IonButtons slot="end">
                      <IonButton
                        color="dark"
                        onClick={() => setIsEditing(true)}
                        routerDirection="none"
                      >
                        Edit
                      </IonButton>
                    </IonButtons>
                  )}
                </IonToolbar>
              </IonHeader>
              <IonContent color="light" style={{ paddingTop: 50 }}>
                <ViewEvent />
              </IonContent>
            </>
          )}

          <EditProductEvent page={page} />
          <SelectStudent
            modalState={{
              page,
              isOpen: selectStudentIsOpen,
              setIsOpen: addStudentModalState.setIsOpen,
            }}
          />
          <IonActionSheet
            isOpen={showConfirmation}
            header="This is a repeating event."
            buttons={[
              ...(recurranceChanged
                ? []
                : [
                    {
                      text: 'Save for this event only',
                      handler: () => {
                        onUpdateEvent(true);
                        setIsEditing(false);
                      },
                    },
                  ]),
              {
                text: 'Save for future events',
                handler: () => {
                  onUpdateEvent();
                  setIsEditing(false);
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
              },
            ]}
            onDidDismiss={() => setShowConfirmation(false)}
          />
        </IonPage>
      )}
    </EditEventProvider>
  );
};
