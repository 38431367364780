import { IonModal, IonNav } from '@ionic/react';
import { useMediaQuery } from '@mui/material';
import { ParsedContact } from '@shared/models/contacts';

import { UserAccessProvider } from './UserAccessContext';
import { UserAccessRouter } from './UserAccessRouter';

interface UserAccessModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  contact: ParsedContact;
}

export const UserAccessModal = (props: UserAccessModalProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!props.page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={props.page.current}
      isOpen={props.isOpen}
      onDidDismiss={props.onDone}
      style={
        isDesktop ? { '--height': '380px', '--max-width': '600px' } : undefined
      }
    >
      <UserAccessProvider {...props}>
        <IonNav root={() => <UserAccessRouter />} />
      </UserAccessProvider>
    </IonModal>
  );
};
