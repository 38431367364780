import { UniqueIdentifier } from '@dnd-kit/core';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import uuid4 from 'uuid4';

import { useContactQuery } from '@models/contacts/useContactQuery';
import { useLessonQuery } from '@models/lessons/useLessonQuery';

import { indentationWidth } from '../AssignmentTab/Dnd/SortableTree';
import { buildTree } from '../AssignmentTab/Dnd/buildTree';
import { getIsAfter } from '../AssignmentTab/Dnd/getIsAfter';

export interface Assignment {
  type: 'assignment';
  id: string;
  title: string;
  description: string;
  emoji: string;
  isPlaceholder?: boolean;
  children: Assignment[];
  collapsed?: boolean;
}

export interface AssignmentSection {
  type: 'section';
  id: string;
  title: string;
  children: Assignment[];
  collapsed?: boolean;
}

export const initAssignment = ({
  children,
  ...overrides
}: Partial<Assignment> = {}): Assignment => ({
  type: 'assignment',
  id: uuid4(),
  title: '',
  description: '',
  emoji: '',
  children: children ?? [],
  ...overrides,
});

export const initFlattenedAssignment = ({
  children,
  ...overrides
}: Partial<FlattenedAssignment> = {}): FlattenedAssignment => ({
  ...initAssignment({ children }),
  parentId: null,
  depth: 0,
  index: 0,
  sectionId: '',
  isSubtask: false,
  ...overrides,
});

export const initSection = ({
  children,
  ...overrides
}: Partial<AssignmentSection> = {}): AssignmentSection => ({
  type: 'section',
  id: uuid4(),
  title: '',
  children: children ?? [],
  ...overrides,
});

export const initFlattenedSection = ({
  children,
  ...overrides
}: Partial<FlattenedSection> = {}): FlattenedSection => ({
  ...initSection({ children }),
  parentId: null,
  depth: 0,
  index: 0,
  sectionId: '',
  isSubtask: false,
  ...overrides,
});

export type AssignmentListItem = Assignment | AssignmentSection;

interface FlattenedProps {
  parentId: string | null;
  depth: number;
  index: number;
  sectionId: string;
  isSubtask: boolean;
}

export type FlattenedAssignment = Assignment & FlattenedProps;
export type FlattenedSection = AssignmentSection & FlattenedProps;
export type FlattenedItem = FlattenedAssignment | FlattenedSection;

interface FlattenProps {
  items: AssignmentListItem[];
  parentId?: string | null;
  sectionId?: string;
  depth?: number;
}

export const flattenAssignmentItems = ({
  items,
  parentId = null,
  sectionId,
  depth = 0,
}: FlattenProps): FlattenedItem[] =>
  items.reduce<FlattenedItem[]>(
    (acc, item, index) => [
      ...acc,
      {
        ...item,
        parentId,
        depth,
        index,
        sectionId: sectionId ?? item.id,
        isSubtask: Boolean(sectionId && parentId && sectionId !== parentId),
      },
      ...flattenAssignmentItems({
        items: item.children,
        parentId: item.id,
        depth: depth + 1,
        sectionId: sectionId ?? item.id,
      }),
    ],
    []
  );

export const useLessonProviderValue = () => {
  const [stared, setStared] = useState(false);
  const [newAssignment, setNewAssignment] = useState<Assignment | undefined>();
  const page = useRef<HTMLElement | null>(null);
  const [title, setTitle] = useState('Jan 23');
  const [description, setDescription] = useState(
    'During the duration of this course, students will learn the fundamental concepts and principles of the subject matter as well as develop key skills.'
  );
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState<
    string | null
  >();

  const [previewOpen, setPreviewOpen] = useState(false);

  const completed = Math.round((1 / 12) * 100);

  const togglePreview = useCallback(() => {
    setPreviewOpen(!previewOpen);
  }, [previewOpen]);

  const [assignmentTree, setAssignmentTree] = useState<AssignmentSection[]>([
    initSection({
      title: 'In Progress',
      children: [
        initAssignment({
          title: 'Warm ups',
          description: 'Make sure to complete this assignment by Friday.',
        }),
        initAssignment({
          title: 'Eine Kleine Nachtmusik',
          description: 'Play both hands together.',
        }),
        initAssignment({
          title: '12 bar blues',
          description: 'Practice the first 8 bars of the piece.',
        }),
        initAssignment({ isPlaceholder: true }),
      ],
    }),
    initSection({
      title: 'Completed',
      children: [
        initAssignment({
          title: 'Test',
        }),
        initAssignment({ isPlaceholder: true }),
      ],
    }),
  ]);

  const flattenedAssignmentItems = useMemo(
    () => flattenAssignmentItems({ items: assignmentTree }),
    [assignmentTree]
  );

  const setFlattenedAssignmentItems = useCallback(
    (
      flattenedItems:
        | FlattenedItem[]
        | ((current: FlattenedItem[]) => FlattenedItem[])
    ) => {
      const value =
        typeof flattenedItems === 'function'
          ? flattenedItems(flattenedAssignmentItems)
          : flattenedItems;
      const newTree = buildTree(value) as AssignmentSection[];

      return setAssignmentTree(newTree);
    },
    [setAssignmentTree, flattenedAssignmentItems]
  );

  const assignmentHash = useMemo(
    () =>
      Object.fromEntries(
        flattenedAssignmentItems.flatMap((assignment) =>
          assignment.type === 'assignment' ? [[assignment.id, assignment]] : []
        )
      ),

    [flattenedAssignmentItems]
  );

  useHotkeys('P', () => togglePreview());

  const { lessonId } = useParams<{ lessonId: string }>();
  const [lesson] = useLessonQuery(lessonId);
  const [contact] = useContactQuery(lesson?.contactId);

  const setAssignment = useCallback(
    (assignment: FlattenedItem) => {
      setFlattenedAssignmentItems((current) =>
        current.map((assignmentItem) =>
          assignmentItem.id === assignment.id ? assignment : assignmentItem
        )
      );
    },
    [setFlattenedAssignmentItems]
  );

  const addNewAssignment = useCallback(
    (assignment?: Partial<Assignment>) => {
      setFlattenedAssignmentItems((current) => [
        ...current,
        initFlattenedAssignment({
          isPlaceholder: true,
          type: 'assignment',
          ...assignment,
        }),
      ]);
    },
    [setFlattenedAssignmentItems]
  );

  const assignmentListRef = useRef<HTMLDivElement>(null);

  const focusAssignmentItem = useCallback(
    (assignmentItemId: string) =>
      assignmentListRef.current
        ?.querySelector<HTMLInputElement>(
          `[data-assignment-id="${assignmentItemId}"] .assignment-title input, [data-section-id="${assignmentItemId}"] .section-title input`
        )
        ?.focus(),
    [assignmentListRef]
  );

  const insertPlaceholderAfter = useCallback(
    (assignmentId: string) => {
      const assignmentIndex = flattenedAssignmentItems.findIndex(
        (assignmentListItem) => assignmentListItem.id === assignmentId
      );

      const nextItem = flattenedAssignmentItems[assignmentIndex + 1];

      if (nextItem?.type === 'assignment' && nextItem.isPlaceholder) {
        return { id: nextItem.id, isNew: false };
      }

      const insertedAssignment = initFlattenedAssignment({
        isPlaceholder: true,
      });

      setFlattenedAssignmentItems(
        flattenedAssignmentItems.flatMap((assignmentListItem) =>
          assignmentListItem.id === assignmentId
            ? [assignmentListItem, insertedAssignment]
            : [assignmentListItem]
        )
      );

      return { id: insertedAssignment.id, isNew: true };
    },
    [setFlattenedAssignmentItems, flattenedAssignmentItems]
  );

  const insertAfterAndFocus = useCallback(
    (assignmentId: string) => {
      const { id: nextId, isNew } = insertPlaceholderAfter(assignmentId);

      if (nextId) {
        if (!isNew) {
          return focusAssignmentItem(nextId);
        }

        setTimeout(() => focusAssignmentItem(nextId));
      }
    },
    [insertPlaceholderAfter, focusAssignmentItem]
  );

  const ensurePlaceholderAfter = useCallback(
    (assignmentId: string) => {
      const assignmentIndex = flattenedAssignmentItems.findIndex(
        (assignmentListItem) => assignmentListItem.id === assignmentId
      );
      const matchingAssignment = flattenedAssignmentItems[assignmentIndex];

      if (
        matchingAssignment?.type === 'assignment' &&
        matchingAssignment.isPlaceholder
      ) {
        return { id: assignmentId, isNew: false };
      }

      const nextItem = flattenedAssignmentItems[assignmentIndex + 1];

      if (nextItem?.type === 'assignment') {
        return {
          id: nextItem.isPlaceholder ? nextItem.id : undefined,
          isNew: false,
        };
      }

      const insertedAssignment = initFlattenedAssignment({
        isPlaceholder: true,
      });
      const targetAssignmentId =
        assignmentId || flattenedAssignmentItems.at(-1)?.id;

      setFlattenedAssignmentItems(
        flattenedAssignmentItems.flatMap((assignmentListItem) =>
          assignmentListItem.id === targetAssignmentId
            ? [assignmentListItem, insertedAssignment]
            : [assignmentListItem]
        )
      );

      return { id: insertedAssignment.id, isNew: true };
    },
    [setFlattenedAssignmentItems, flattenedAssignmentItems]
  );

  const focusAfter = useCallback(
    (assignmentId?: string) => {
      const id = assignmentId ?? flattenedAssignmentItems.at(-1)?.id;

      if (!id) {
        return;
      }

      const { id: nextId, isNew } = ensurePlaceholderAfter(id);

      if (nextId) {
        if (!isNew) {
          return focusAssignmentItem(nextId);
        }

        setTimeout(() => focusAssignmentItem(nextId));
      }
    },
    [ensurePlaceholderAfter, focusAssignmentItem, flattenedAssignmentItems]
  );

  const [offsetLeft, setOffsetLeft] = useState(0);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeY, setActiveY] = useState(0);
  const [overDimensions, setOverDimensions] = useState<{
    top: number;
    bottom: number;
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const indicator = useMemo((): {
    id: string;
    position: 'before' | 'after';
    shouldIndent: boolean;
  } | null => {
    if (!isDragging) {
      return null;
    }

    const overItem = flattenedAssignmentItems.find(({ id }) => id === overId);
    const activeItem = flattenedAssignmentItems.find(
      ({ id }) => id === activeId
    );

    if (!overItem || !activeItem || !overDimensions) {
      return null;
    }

    if (activeItem.type === 'section') {
      const overSectionId = overItem.sectionId;

      const overSectionIndex = flattenedAssignmentItems.findIndex(
        ({ sectionId }) => sectionId === overSectionId
      );
      const overSection = flattenedAssignmentItems[overSectionIndex];

      if (!overSection) {
        return null;
      }

      const isAfter = getIsAfter(overDimensions, activeY);

      return isAfter
        ? {
            id: overSection.children.at(-1)?.id ?? overSection.id,
            position: 'after',
            shouldIndent: false,
          }
        : { id: overSection.id, position: 'before', shouldIndent: false };
    }

    const position = getIsAfter(overDimensions, activeY) ? 'after' : 'before';

    const indicatorIndex = flattenedAssignmentItems.findIndex(
      ({ id }) => id === overItem.id
    );

    const indicatorItem = flattenedAssignmentItems[indicatorIndex];

    if (
      indicatorItem?.type === 'assignment' &&
      indicatorItem.isPlaceholder &&
      position === 'after'
    ) {
      return null;
    }

    const previousItemIndex =
      position === 'after' ? indicatorIndex : indicatorIndex - 1;
    const previousItem = flattenedAssignmentItems[previousItemIndex];

    if (indicatorItem?.type === 'section' && position === 'before') {
      if (
        !previousItem ||
        (previousItem.type === 'assignment' && previousItem.isPlaceholder)
      ) {
        return null;
      }
    }

    const shouldIndent = (() => {
      if (!indicatorItem || !previousItem) {
        return false;
      }

      const nextItem =
        flattenedAssignmentItems[previousItemIndex + 1]?.id === activeId
          ? flattenedAssignmentItems[previousItemIndex + 2]
          : flattenedAssignmentItems[previousItemIndex + 1];

      if (
        activeItem.children.length > 0 ||
        (previousItem.id === activeItem.id && !activeItem.isSubtask) ||
        previousItem.type === 'section'
      ) {
        return false;
      }

      if (nextItem?.id !== activeItem.id && nextItem?.isSubtask) {
        return true;
      }

      const activeItemDepth = activeItem.depth;
      const adjustedDepth = Math.max(activeItemDepth - 1, 1);
      const adjustmentAmount = indentationWidth * adjustedDepth;
      const extra = activeItem.isSubtask ? 20 : -60;
      const adjustedOffsetLeft = offsetLeft + extra + adjustmentAmount;

      return adjustedOffsetLeft >= indentationWidth;
    })();

    return {
      id: overItem.id,
      position,
      shouldIndent,
    };
  }, [
    activeId,
    activeY,
    flattenedAssignmentItems,
    overDimensions,
    overId,
    offsetLeft,
    isDragging,
  ]);

  return {
    insertAfterAndFocus,
    focusAssignmentItem,
    ensurePlaceholderAfter,
    focusAfter,
    title,
    setTitle,
    description,
    setDescription,
    previewOpen,
    setPreviewOpen,
    togglePreview,
    completed,
    contact,
    lessonId,
    page,
    newAssignment,
    setNewAssignment,
    stared,
    setStared,
    setAssignment,
    assignmentModalOpen,
    setAssignmentModalOpen,
    addNewAssignment,
    assignmentListRef,
    selectedAssignmentId,
    setSelectedAssignmentId,
    flattenedAssignmentItems,
    setFlattenedAssignmentItems,
    assignmentHash,
    overId,
    setOverId,
    activeId,
    setActiveId,
    activeY,
    setActiveY,
    indicator,
    overDimensions,
    setOverDimensions,
    offsetLeft,
    setOffsetLeft,
    isDragging,
    setIsDragging,
  };
};

export type UseLessonProviderReturn = ReturnType<typeof useLessonProviderValue>;
