import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router';

import {
  useOrganizationId,
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import { useLessonsQuery } from '@models/lessons/useLessonsQuery';

const Lessons = () => {
  const page = useRef<HTMLElement | null>(null);
  const organizationSlug = useOrganizationSlug();
  const location = useLocation();
  const user = useUser();
  const organizationId = useOrganizationId();

  const contactId = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return params.get('c');
  }, [location.search]);

  const [lessonNotes] = useLessonsQuery({
    constraints: contactId ? [where('contactId', '==', contactId)] : [],
    skip:
      !contactId &&
      !(organizationId && user?.organizationAccounts[organizationId]),
  });

  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/${organizationSlug}/contacts/${contactId}`}
              color="dark"
            />
          </IonButtons>
          <IonTitle>All Lesson Notes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {lessonNotes?.map(({ id, createdAt, title }) => (
            <IonItem
              key={id}
              routerLink={`/${organizationSlug}/lesson-notes/${id}`}
            >
              <IonLabel>{title}</IonLabel>
              <IonNote slot="end">
                {dayjs(createdAt.toDate()).format('M/D/YY')}
              </IonNote>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Lessons;
