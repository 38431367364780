import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box, IconButton, Typography } from '@mui/joy';
import dayjs from 'dayjs';
import { startTransition, useEffect, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import '@models/events/model';
import { useEventsQuery } from '@models/events/useEventsQuery';

import { getHour } from './DurationEvent';
import { durationEventHeight } from './EventViewContainer';
import { EventGroup, useEventGroups } from './useEventGroups';
import { useInfiniteSlider } from './useInfiniteSlider';

interface ChildrenProps {
  eventGroup: EventGroup | undefined;
}

interface DrawerProps {
  expanded: boolean;
  toggleExpanded: () => void;
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  agendaView?: boolean;
  setAgendaView?: React.Dispatch<React.SetStateAction<boolean>>;
  showAvailability?: boolean;
  children?: React.ReactNode | ((props: ChildrenProps) => JSX.Element);
}

const Drawer = ({
  expanded,
  toggleExpanded,
  agendaView,
  setCurrentDate,
  currentDate,
  setAgendaView,
  showAvailability,
  children,
}: DrawerProps) => {
  const { previousDate, setPreviousDate, setSwiper, setTransitioning } =
    useInfiniteSlider();

  const fullDate = useMemo(
    () => dayjs(currentDate).format('dddd, MMMM D, YYYY'),
    [currentDate]
  );

  const [events] = useEventsQuery({ currentDate });
  const eventGroups = useEventGroups(events || [], previousDate || currentDate);

  // useScrollToEventContent?
  const match = useRouteMatch('/:organizationSlug/schedule');
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (showAvailability || expanded || agendaView) {
      return;
    }

    const [toadysFirstEvent] = eventGroups.get(0)?.events || [];
    const scrollTop = ref.current?.scrollTop ?? 0;

    if (scrollTop > 0) {
      return;
    }

    const interval = setInterval(() => {
      if (
        ref.current &&
        toadysFirstEvent &&
        ref.current.offsetHeight > 0 &&
        ref.current.scrollTop === 0
      ) {
        const { start } = toadysFirstEvent;
        ref.current.scrollTo({ top: durationEventHeight * getHour(start) });
        clearInterval(interval);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [agendaView, match, eventGroups, showAvailability, expanded]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        // TODO: calculate this based on the height of the modal
        paddingBottom: 80,
        background: 'white',
        borderRadius: '1em 1em 0 0',
        boxShadow: '1px 1px 1px lightgray',
      }}
    >
      <Box style={{ display: 'flex', padding: '0.2em', position: 'relative' }}>
        <Typography
          fontSize={14}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
            height: 'fit-content',
            textAlign: 'center',
            color: 'gray',
          }}
        >
          {fullDate}
        </Typography>

        <div style={{ marginLeft: 'auto' }}>
          {setAgendaView ? (
            <IconButton
              color="neutral"
              variant="plain"
              size="sm"
              onClick={() => setAgendaView?.((current) => !current)}
              style={{ padding: '0 1em', color: 'gray' }}
            >
              <FormatListBulletedIcon />
            </IconButton>
          ) : null}
          <IconButton
            color="neutral"
            variant="plain"
            size="sm"
            onClick={() => toggleExpanded()}
            style={{
              transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
              transformOrigin: 'center',
              transition: '0.4s transform',
              padding: '0 1em',
              color: 'gray',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </div>
      </Box>
      <div
        style={{ overflowY: 'auto', flex: 1 }}
        onTouchMoveCapture={(event) => event.stopPropagation()}
        ref={ref}
      >
        <SwiperReact
          onSwiper={setSwiper}
          onSlideChange={(event) => {
            if (event.activeIndex !== 1) {
              startTransition(() => {
                setPreviousDate(currentDate);
                setCurrentDate(
                  dayjs(currentDate)
                    .add(event.activeIndex - 1, 'days')
                    .toDate()
                );
              });
            }
          }}
          preventInteractionOnTransition
          onSlideChangeTransitionEnd={(event) => {
            if (event.activeIndex !== 1) {
              setTransitioning(event.activeIndex - 1);
            }
          }}
          initialSlide={1}
        >
          {([-1, 0, 1] as const).map((offset) => (
            <SwiperSlide key={offset}>
              {typeof children === 'function'
                ? children?.({ eventGroup: eventGroups.get(offset) })
                : null}
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>
    </Box>
  );
};

export default Drawer;
