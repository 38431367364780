import { Box, Typography, TypographySystem } from '@mui/joy';
import Input, { InputProps } from '@mui/joy/Input';
import { forwardRef } from 'react';

interface TypographyInputProps extends InputProps {
  level?: keyof TypographySystem;
}

export const TypographyInput = forwardRef<HTMLDivElement, TypographyInputProps>(
  ({ level = 'body-md', sx, ...props }, ref) => (
    <Box>
      <Input
        slotProps={{
          input: {
            size: 1,
          },
        }}
        variant="plain"
        ref={ref}
        sx={[
          (theme) => ({
            ...theme.typography[level],
            minHeight: 0,
            padding: 0,
            minWidth: 0,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      />
      <Typography
        level={level}
        sx={[
          {
            visibility: 'hidden',
            paddingRight: '4px',
            height: 0,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {props.value || props.placeholder}
      </Typography>
    </Box>
  )
);
