import {
  IonActionSheet,
  IonItem,
  IonList,
  IonText,
  useIonRouter,
} from '@ionic/react';
import { Add } from '@mui/icons-material';
import { Box, Button, Checkbox, Input } from '@mui/joy';
import { ParsedContact } from '@shared/models/contacts';
import { deleteDoc, doc } from 'firebase/firestore';
import { useState } from 'react';

import Avatar from '@components/Avatar';
import { ListHeader } from '@components/ListHeader';
import { useCustomAvatar } from '@components/useCustomAvatar';
import {
  useOrganizationPermissions,
  useOrganizationSlug,
} from '@features/Organization/organizationSlice';
import { contactsCollection } from '@models/contacts/model';

import { getContactInitials } from '../ViewContact';
import { InstrumentsModal } from '../ViewContact/InstrumentsModal';
import { EditContactFieldProps } from '../useEditContact';
import { AvatarModal } from './AvatarModal';
import { EditAddresses } from './EditAddresses';
import { EditContactItemContainer } from './EditContactItemContainer';
import { EditEmails } from './EditEmails';
import { EditPhones } from './EditPhones';

interface EditContactProps extends EditContactFieldProps {
  isNew?: boolean;
  contactId?: string;
  linkedContacts?: ParsedContact[] | undefined;
  onDone?: () => void;
  page: React.MutableRefObject<HTMLElement | null>;
  avatar?: string | undefined;
}

export const EditContact = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  emails,
  setEmails,
  phones,
  setPhones,
  addresses,
  setAddresses,
  isNew,
  contactId,
  linkedContacts,
  onDone,
  page,
  availableInstruments,
  selectedInstruments,
  setSelectedInstruments,
  selectedAvatar,
  setSelectedAvatar,
  notes,
  setNotes,
  avatar,
  onAvatarUpload,
  avatarPreview,
}: EditContactProps) => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [showInstrumentsModal, setShowInstrumentsModal] = useState(false);
  const [isAvatarModalOpen, setIsAvatarOpen] = useState(false);
  const router = useIonRouter();
  const organizationSlug = useOrganizationSlug();
  const permissions = useOrganizationPermissions();
  const [customAvatarSrc] = useCustomAvatar(avatar);
  const avatarSrc = (() => {
    if (avatarPreview) {
      return avatarPreview;
    }

    if (selectedAvatar === 'custom') {
      return customAvatarSrc;
    }

    return `assets/avatars/${selectedAvatar}.png`;
  })();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 10px 0 10px',
          '@media (min-width: 747px)': {
            paddingTop: '30px',
          },
        }}
      >
        <Avatar
          sx={{
            width: 120,
            height: 120,
            fontSize: 20,
            fontWeight: 400,
            marginRight: 1,
          }}
          src={avatarSrc}
          onClick={() => setIsAvatarOpen(true)}
        >
          {getContactInitials({ firstName, lastName })}
        </Avatar>
        <Button
          sx={{ color: 'black' }}
          variant="plain"
          onClick={() => setIsAvatarOpen(true)}
        >
          Edit
        </Button>
      </Box>
      <AvatarModal
        isOpen={isAvatarModalOpen}
        page={page}
        onDone={() => setIsAvatarOpen(false)}
        selectedAvatar={selectedAvatar}
        setSelectedAvatar={(nextAvatarSrc) => {
          setSelectedAvatar(nextAvatarSrc);
          setIsAvatarOpen(false);
        }}
        avatar={avatar}
        onAvatarUpload={onAvatarUpload}
        avatarPreview={avatarPreview}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          marginTop: '10px',
        }}
      >
        {/* Edit Name */}
        <EditContactItemContainer>
          <Input
            placeholder="First Name"
            name="firstName"
            variant="plain"
            value={firstName}
            onChange={(event) => setFirstName(String(event.target.value))}
            sx={{ borderBottom: '1px solid lightgray' }}
          />

          <Input
            placeholder="Last Name"
            name="lastName"
            variant="plain"
            value={lastName}
            onChange={(event) => setLastName(String(event.target.value))}
          />
        </EditContactItemContainer>

        {/* Edit Instruments */}
        <Box sx={{ marginBottom: '-10px' }}>
          <ListHeader v2 sx={{ paddingLeft: 0 }}>
            Instruments
          </ListHeader>

          <Box
            sx={{
              padding: '10px',
              paddingBottom: 0,
              marginLeft: '10px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '15px',
              alignItems: 'flex-start',
            }}
          >
            <Button
              size="sm"
              variant="verticalOutline"
              startDecorator={<Add />}
              onClick={() => setShowInstrumentsModal(true)}
              data-testid="add-instrument-button"
            >
              Add
            </Button>

            {availableInstruments.map((instrument) => {
              const checked = selectedInstruments.includes(instrument);

              return (
                <Button
                  key={instrument}
                  size="sm"
                  variant="instrument"
                  onClick={() =>
                    setSelectedInstruments((current) =>
                      current.includes(instrument)
                        ? current.filter((item) => item !== instrument)
                        : [...current, instrument]
                    )
                  }
                  startDecorator={
                    <>
                      <Checkbox overlay checked={checked} />
                      <img
                        alt="instrument icon"
                        src={`assets/instruments/${instrument.toLowerCase()}.svg`}
                      />
                    </>
                  }
                >
                  {instrument}
                </Button>
              );
            })}
          </Box>
        </Box>

        <EditPhones phones={phones} setPhones={setPhones} page={page} />
        <EditEmails emails={emails} setEmails={setEmails} page={page} />
        <EditAddresses
          addresses={addresses}
          setAddresses={setAddresses}
          page={page}
        />

        {/* Edit Notes */}
        <Box sx={{ marginLeft: '3px' }}>
          <textarea
            value={notes}
            placeholder="Notes"
            onChange={(event) => setNotes(event.target.value)}
            style={{
              padding: '0 15px 15px 15px',
              width: '100%',
              border: 'none',
              outline: 'none',
              minHeight: '150px',
              background: 'transparent',
            }}
          />
        </Box>

        <InstrumentsModal
          selectedInstruments={selectedInstruments}
          setSelectedInstruments={setSelectedInstruments}
          isOpen={showInstrumentsModal}
          page={page}
          onDone={() => setShowInstrumentsModal(false)}
        />

        {!isNew && contactId && permissions?.contacts.canDelete ? (
          <>
            <IonList className="hide-last-border">
              <IonItem
                style={{ alignText: 'center' }}
                onClick={() => setIsActionSheetOpen(true)}
              >
                <IonText
                  slot="start"
                  color="danger"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  Delete Contact
                </IonText>
              </IonItem>
            </IonList>
            <IonActionSheet
              isOpen={isActionSheetOpen}
              header="Are you sure you want to delete this contact?"
              buttons={[
                {
                  text: 'Delete',
                  role: 'destructive',
                  data: {
                    action: 'delete',
                  },
                  handler: async () => {
                    await deleteDoc(doc(contactsCollection, contactId));

                    const nextContactId = linkedContacts?.find(
                      ({ id }) => id !== contactId
                    )?.id;
                    const urlFragment = nextContactId
                      ? `/${nextContactId}`
                      : '';
                    onDone?.();

                    router.push(
                      `/${organizationSlug}/contacts${urlFragment}`,
                      'back'
                    );
                  },
                },
                {
                  text: 'Cancel',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ]}
              onDidDismiss={() => setIsActionSheetOpen(false)}
            />
          </>
        ) : null}
      </Box>
    </>
  );
};
