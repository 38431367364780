import {
  ArrowForwardIosOutlined,
  CloseFullscreenOutlined,
  CloseOutlined,
  HomeOutlined,
  IosShareOutlined,
  OpenInFullOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListDivider,
  Option,
  Select,
  Typography,
} from '@mui/joy';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { Fragment, useState } from 'react';

import { ListHeader } from '@components/ListHeader';
import { desktopNavHeightPixels } from '@features/Contacts/ContactGroup';

import { useLesson } from '../LessonProvider/useLesson';
import { Assignment } from './Assignment';

export const LessonPreview = () => {
  const {
    togglePreview,
    title,
    completed,
    flattenedAssignmentItems,
    description,
  } = useLesson();
  const [previewIndex, setPreviewIndex] = useState(0);

  const previewAssignment = flattenedAssignmentItems[previewIndex - 1];

  const [previewFullScreen, setPreviewFullScreen] = useState(false);
  useHotkeys('F', () => setPreviewFullScreen(!previewFullScreen));

  return (
    <Box
      sx={(theme) => ({
        flex: 1,
        borderLeft: `1px solid ${theme.palette.divider}`,
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',

        ...(previewFullScreen
          ? {
              position: 'fixed',
              top: 0,
              left: '-1px',
              right: 0,
              bottom: 0,
              zIndex: 9999999,
              backgroundColor: 'white',
            }
          : undefined),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 13px',
          height: `${desktopNavHeightPixels}px`,
          borderBottom: `1px solid ${theme.palette.divider}`,

          '@media (min-width: 747px)': {
            background: 'rgb(255 255 255 / 71%)',
            [theme.getColorSchemeSelector('dark')]: {
              backgroundColor: 'rgb(0 0 0 / 71%)',
            },
            backdropFilter: 'blur(30px)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            defaultValue="chris"
            variant="plain"
            sx={{ height: '28px', minHeight: 0 }}
          >
            <Option value="chris">Preview as Chris</Option>
            <Option value="teacher">Preview as Teacher</Option>
          </Select>
          <Typography
            sx={(theme) => ({
              color: theme.palette.neutral[400],
              marginLeft: '10px',
              fontSize: '14px',
            })}
          >
            Showing published and unpublished content.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '3px' }}>
          <IconButton>
            <IosShareOutlined />
          </IconButton>
          <IconButton
            sx={{ transform: 'rotate(90deg)' }}
            onClick={() => setPreviewFullScreen(!previewFullScreen)}
          >
            {previewFullScreen ? (
              <CloseFullscreenOutlined />
            ) : (
              <OpenInFullOutlined />
            )}
          </IconButton>
          <IconButton onClick={togglePreview}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
        }}
      >
        <Box sx={{ minWidth: '220px', maxWidth: '350px', flex: 1 }}>
          <Box
            sx={(theme) => ({
              padding: '10px 0',
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Button
              variant="plain"
              color="neutral"
              startDecorator={<HomeOutlined />}
              sx={{ color: 'black' }}
            >
              Home
            </Button>
          </Box>
          <Box sx={{ padding: '15px' }}>
            <Typography level="title-md">{title}</Typography>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                determinate
                value={completed}
                sx={{
                  width: '100%',
                  margin: '15px 0',
                  '--_inner-size': '120px',
                  height: '120px',
                }}
              />
              <Box
                sx={{
                  textAlign: 'center',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Typography level="h3" component="span">
                  {completed}%{' '}
                </Typography>
                <Typography
                  component="span"
                  level="title-sm"
                  sx={(theme) => ({
                    color: theme.palette.neutral[400],
                  })}
                >
                  complete
                </Typography>
              </Box>
            </Box>

            <Typography sx={{ textAlign: 'center' }}>
              You're in the groove!
            </Typography>
          </Box>

          <Box sx={{ padding: '10px 0 10px 0' }}>
            <Divider />
          </Box>

          <List>
            {flattenedAssignmentItems.map((assignment, index) =>
              assignment.type === 'section' ? (
                <Fragment key={assignment.id}>
                  <ListHeader v2 sx={{ marginLeft: '0 !important' }}>
                    {assignment.title}
                  </ListHeader>
                  <ListDivider />
                </Fragment>
              ) : (
                <Assignment
                  key={assignment.id}
                  title={assignment.title}
                  onClick={() => setPreviewIndex(index + 1)}
                  selected={previewIndex === index + 1}
                />
              )
            )}
          </List>
        </Box>
        <Box
          sx={(theme) => ({
            flex: 1,
            padding: '30px',
            paddingTop: '30px',
            borderLeft: `1px solid ${theme.palette.divider}`,
            position: 'relative',

            '& > *': {
              marginBottom: '1.5rem',
            },
            '& p': {
              lineHeight: '2',
            },
          })}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              padding: '50px',
            }}
          >
            <Button
              endDecorator={<ArrowForwardIosOutlined />}
              variant="outlined"
            >
              Complete and Continue
            </Button>
          </Box>
          {previewIndex === 0 ? (
            <>
              <Typography level="h3">Overview</Typography>
              <Typography>{description}</Typography>
            </>
          ) : null}
          {previewAssignment && previewAssignment.type === 'assignment' ? (
            <>
              <Typography level="h3">{previewAssignment.title}</Typography>
              <Typography>{previewAssignment.description}</Typography>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
