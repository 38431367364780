import { Box, Divider, TabPanel } from '@mui/joy';

import { useLesson } from '../LessonProvider/useLesson';
import { Activity } from './Activity';
import { AssignmentArea } from './AssignmentArea';
import { Comments } from './Comments';

export const AssignmentTab = () => {
  const { description, setDescription } = useLesson();

  return (
    <TabPanel value={0}>
      <Box
        component="textarea"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        placeholder="Start typing or type '/' for commands"
        sx={{
          width: '100%',
          marginTop: '5px',
          marginLeft: '-2px',
          border: 'none',
          outline: 'none',
          resize: 'none',
          background: 'transparent',
          lineHeight: '1.8',
          '&::placeholder': {
            paddingLeft: 0,
          },
        }}
      />

      <AssignmentArea />

      <Box sx={{ marginTop: '40px' }}>
        <Divider
          sx={(theme) => ({
            backgroundColor: theme.palette.neutral[100],
          })}
        />
      </Box>

      <Comments />
      <Activity />
    </TabPanel>
  );
};
