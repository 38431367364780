import { IonItem, IonList, IonSelect, IonSelectOption } from '@ionic/react';
import { Textarea } from '@mui/joy';
import { useEffect, useMemo } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHeader } from '@components/ListHeader';
import { ListHelperText } from '@components/ListHelperText';
import { useEditEventContext } from '@features/Schedule/AddEvent/EditEventContext/useEditEventContext';

export type CancelProductType =
  | 'cancelThisLesson'
  | 'cancelFutureLessons'
  | 'custom';

const cancelTypes: CancelProductType[] = [
  'cancelThisLesson',
  'cancelFutureLessons',
  'custom',
];

const cancelTypeLabels: Record<CancelProductType, string> = {
  cancelThisLesson: 'Cancel This Lesson',
  cancelFutureLessons: 'Cancel All Future Lessons',
  custom: 'Custom',
};

export type CancelationOption =
  | 'saveLesson'
  | 'refundLesson'
  | 'cancelWithoutRefund';

const cancelationOptions: CancelationOption[] = [
  'saveLesson',
  'refundLesson',
  'cancelWithoutRefund',
];

const cancelationOptionLabels: Record<CancelationOption, string> = {
  saveLesson: 'Save Lesson',
  refundLesson: 'Refund Lesson',
  cancelWithoutRefund: 'Cancel Without Refund',
};

export const CancelProductEvent = () => {
  const {
    editProductEventFields: {
      productEditType,
      cancelType,
      setCancelType,
      cancelationOption,
      setCancelationOption,
    },
    contract,
  } = useEditEventContext();
  const schedulingV1_3Enabled = useFeatureFlag('schedulingV1_3Enabled');
  const customCancelTypeEnabled = useFeatureFlag('customCancelTypeEnabled');

  const filteredCancelationOptions = useMemo(
    () =>
      cancelationOptions.filter(
        (key) =>
          ((key !== 'saveLesson' || schedulingV1_3Enabled) &&
            key !== 'refundLesson') ||
          contract?.allowRefunds
      ),
    [contract, schedulingV1_3Enabled]
  );

  useEffect(() => {
    const [firstCancellationOption] = filteredCancelationOptions;
    const invalidCancelationOption = !filteredCancelationOptions.some(
      (option) => option === cancelationOption
    );
    if (
      (!cancelationOption || invalidCancelationOption) &&
      firstCancellationOption
    ) {
      setCancelationOption(firstCancellationOption);
    }
  }, [cancelationOption, filteredCancelationOptions, setCancelationOption]);

  // TODO:
  // check if last minute cancellation
  // check if contract allows last minute cancellation refunds

  if (productEditType !== 'cancel') {
    return null;
  }

  return (
    <>
      <IonList inset>
        <IonItem>
          <IonSelect
            label="Cancel Type"
            interface="popover"
            value={cancelType}
            onIonChange={(event) => setCancelType(event.detail.value)}
          >
            {cancelTypes
              .filter(
                (option) => option !== 'custom' || customCancelTypeEnabled
              )
              .map((option) => (
                <IonSelectOption key={option} value={option}>
                  {cancelTypeLabels[option]}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
      </IonList>
      <ListHeader>Financial</ListHeader>
      <IonList inset>
        <IonItem>
          <IonSelect
            label="Cancelation Options"
            interface="popover"
            value={cancelationOption}
            onIonChange={(event) => setCancelationOption(event.detail.value)}
          >
            {filteredCancelationOptions.map((option) => (
              <IonSelectOption key={option} value={option}>
                {cancelationOptionLabels[option]}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonList>
      <ListHelperText>
        Allows you to decide the financial outcome when a lesson is canceled
      </ListHelperText>
      <IonList inset>
        <Textarea
          placeholder="Notes"
          color="neutral"
          variant="plain"
          sx={{
            padding: '15px',
            '--Textarea-focusedThickness': 0,
            minHeight: '150px',
          }}
        />
      </IonList>
    </>
  );
};
