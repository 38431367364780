import { query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { notEmpty } from '@models/events/useEventsQuery';
import { productsCollection } from '@models/products/model';
import { auth } from '@utils/firebase';

import { parseProduct } from './parseProduct';

export const useProductsQuery = () => {
  const [user] = useAuthState(auth);
  const organizationId = useOrganizationId();
  const [rawProducts, loading, error] = useCollection(
    !user || !organizationId
      ? null
      : query(productsCollection, where('organizationId', '==', organizationId))
  );

  const products = useMemo(() => {
    const allProducts = rawProducts?.docs.map(parseProduct).filter(notEmpty);

    if (!allProducts) {
      return;
    }

    const globalProduct = allProducts.find(
      (product) => product.type === 'global'
    );

    if (!globalProduct) {
      return;
    }

    return allProducts
      .flatMap((product) => {
        if (product.type === 'global') {
          return [];
        }

        if (product.type === 'advanced') {
          return [product];
        }

        return [
          {
            ...globalProduct,
            id: product.id,
            productName: product.productName,
            instrument: product.instrument,
          },
        ];
      })
      .sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return a.createdAt.toMillis() - b.createdAt.toMillis();
        }

        return 0;
      });
  }, [rawProducts]);

  return [products, loading, error] as const;
};
