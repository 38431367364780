import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from '@ionic/react';
import { matchPath } from 'react-router';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import './Tabs.css';
import { useTabs } from './tabs.routes';

interface TabsProps {
  children: React.ReactNode;
}

const Tabs = ({ children }: TabsProps) => {
  const tabs = useTabs();
  const slug = useOrganizationSlug();
  const { routeInfo } = useIonRouter();
  const { pathname } = routeInfo;

  return (
    <IonTabs>
      <IonRouterOutlet id="main-1">{children}</IonRouterOutlet>

      <IonTabBar slot="bottom" translucent>
        {tabs.map(({ url, iosIcon, mdIcon, title, selected = [] }) => (
          <IonTabButton
            key={url}
            tab={url.split('/').at(-1)}
            data-testid={`tab-${title}`}
            href={`/${slug}${url}`}
            selected={[...selected, `/:organizationId${url}`].some((path) =>
              matchPath(pathname, path)
            )}
          >
            <IonIcon ios={iosIcon} md={mdIcon} />
            <IonLabel>{title}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default Tabs;
