import dayjs from 'dayjs';
import { Timestamp, where } from 'firebase/firestore';
import { useMemo } from 'react';

import { useInvoicesQuery } from '@models/invoices/useInvoicesQuery';

export const useXMonthsRevenue = (monthOffset = 0) => {
  const queryMonth = useMemo(
    () => dayjs().tz('UTC').subtract(monthOffset, 'month'),
    [monthOffset]
  );

  const [invoices, loading] = useInvoicesQuery({
    constraints: [
      where(
        'start',
        '>=',
        Timestamp.fromDate(queryMonth.startOf('month').toDate())
      ),
      where(
        'start',
        '<=',
        Timestamp.fromDate(queryMonth.endOf('month').toDate())
      ),
    ],
  });

  return {
    amount: useMemo(
      () =>
        invoices?.reduce((previous, current) => previous + current.amount, 0),
      [invoices]
    ),
    currency: 'USD',
    loading,
  };
};
