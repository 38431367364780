import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonNav,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useRef } from 'react';

import { EditInvoice } from './EditInvoice/EditInvoice';
import { EditInvoiceProvider } from './EditInvoice/EditInvoiceContext';
import { ShareWithLink } from './ViewInvoice/ShareWithLink';

type ContactMethod = 'manual' | 'email' | 'sms';
const contactMethod: ContactMethod = 'manual';

export interface AddEventProps {
  isOpen: boolean;
  page: React.MutableRefObject<HTMLElement | null>;
  onCancel?: () => void;
  onDone?: () => void;
  accountId?: string;
}

const AddInvoice = ({
  isOpen,
  page,
  onCancel,
  accountId,
  onDone,
}: AddEventProps) => {
  const ionNavRef = useRef<HTMLIonNavElement>(null);
  if (!page.current) {
    return null;
  }

  return (
    <EditInvoiceProvider invoiceId="add" accountId={accountId}>
      {({ saveInvoice, reset }) => (
        <IonModal
          presentingElement={page.current || undefined}
          isOpen={isOpen}
          onDidDismiss={() => {
            reset();
            onCancel?.();
          }}
        >
          <IonNav
            ref={ionNavRef}
            root={() => (
              <>
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton
                        color="dark"
                        onClick={() => {
                          reset();
                          onCancel?.();
                        }}
                      >
                        Cancel
                      </IonButton>
                    </IonButtons>
                    <IonTitle>New Invoice</IonTitle>
                    <IonButtons slot="end">
                      <IonButton
                        color="dark"
                        onClick={() => {
                          (async () => {
                            const saveInvoicePromise = saveInvoice();

                            if (contactMethod === 'manual') {
                              const result = await saveInvoicePromise;
                              const invoiceId = result?.response?.id;

                              if (!invoiceId) {
                                throw new Error('Invoice ID not found');
                              }

                              ionNavRef.current?.push(() => (
                                <ShareWithLink
                                  onDone={onDone}
                                  invoiceId={invoiceId}
                                />
                              ));
                            } else {
                              onDone?.();
                            }
                          })();
                        }}
                      >
                        Add
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding" color="light">
                  <EditInvoice />
                </IonContent>
              </>
            )}
          />
        </IonModal>
      )}
    </EditInvoiceProvider>
  );
};

export default AddInvoice;
