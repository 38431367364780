import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { useMutation } from '@models/mutations/useMutation';

interface UseValidateBusinessNameProps {
  urlSlug: string;
  currentSlug?: string;
}
export const useValidateBusinessName = ({
  urlSlug,
  currentSlug,
}: UseValidateBusinessNameProps) => {
  const [validateBusinessNameMutation] = useMutation('validateBusinessName');
  const [nameIsTaken, setNameIsTaken] = useState<boolean | undefined>();
  const previousUrlSlug = usePrevious(urlSlug);

  const [typingPromise, setTypingPromise] = useState<
    Promise<void> | undefined
  >();
  const [mutationPromise, setMutationPromise] = useState<
    Promise<boolean> | undefined
  >();

  /**
   * Runs when urlSlug changes
   */
  useEffect(() => {
    if (urlSlug === previousUrlSlug || !urlSlug) {
      return;
    }

    if (currentSlug && urlSlug === currentSlug) {
      setNameIsTaken(false);
      return;
    }

    setNameIsTaken(undefined);
    setTypingPromise(new Promise((resolve) => setTimeout(resolve, 800)));
  }, [urlSlug, previousUrlSlug, currentSlug]);

  /**
   * Runs after typing is complete
   */
  useEffect(() => {
    let mounted = true;
    if (!typingPromise) {
      return;
    }

    (async () => {
      await typingPromise;

      if (!mounted) {
        return;
      }

      setMutationPromise(
        (async () => {
          const result = await validateBusinessNameMutation({ slug: urlSlug });

          return Boolean(result.response?.meta.slugExists);
        })()
      );
      setTypingPromise(undefined);
    })();

    return () => {
      mounted = false;
    };
  }, [typingPromise, validateBusinessNameMutation, urlSlug]);

  /**
   * Runs after mutation is complete
   */
  useEffect(() => {
    if (!mutationPromise) {
      return;
    }

    let mounted = true;

    (async () => {
      const slugExists = await mutationPromise;

      if (!mounted) {
        return;
      }

      setNameIsTaken(urlSlug !== currentSlug && slugExists);
      setMutationPromise(undefined);
    })();

    return () => {
      mounted = false;
    };
  }, [mutationPromise, urlSlug, currentSlug]);

  const valid = nameIsTaken !== true;
  const loading = Boolean(urlSlug && nameIsTaken === undefined);
  const error = false;

  return [valid, loading, error] as const;
};
