import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { Box, Button } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';

import {
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import AddEvent from '@features/Schedule/AddEvent';
import { useModalState } from '@hooks/useModalState';
import { contactsCollection } from '@models/contacts/model';
import { serverTimestamp } from '@utils/serverTimestamp';

import AddContact from '../AddContact';
import { bothBarHeights, desktopContactNavHeight } from '../ContactGroup';
import { ContactProvider } from './ContactProvider/ContactProvider';
import { EditContact } from './EditContact';
import SelectContact from './SelectContact';
import { ViewContact } from './ViewContact';

interface ContactDetailProps {
  contactId?: string;
  onContactSelection?: (contactId: string) => void;
}

export const ContactDetail = ({
  contactId: contactIdOverride,
  onContactSelection,
}: ContactDetailProps) => {
  const organizationSlug = useOrganizationSlug();
  const addEventModalState = useModalState();
  const router = useIonRouter();
  const user = useUser();

  const isDesktop = useMediaQuery('(min-width:747px)');

  return (
    <ContactProvider contactId={contactIdOverride}>
      {({
        // TODO: remove prop drilling here
        isEditing,
        page,
        setIsEditing,
        initialize,
        scheduleSave,
        fieldProps,
        contactId,
        handleAddContact,
        linkedContacts,
        isLinking,
        setIsLinking,
        contact,
        addingContact,
        setAddingContact,
        preset,
      }) => {
        const content = (
          <>
            {isEditing ? (
              <EditContact
                onDone={() => setIsEditing(false)}
                page={page}
                contactId={contactId}
                linkedContacts={linkedContacts}
                avatar={contact?.avatar}
                {...fieldProps}
              />
            ) : (
              <ViewContact
                contact={contact}
                onAddContact={handleAddContact}
                onAddEvent={() => addEventModalState.setIsOpen(true)}
                setIsLinking={setIsLinking}
                linkedContacts={linkedContacts}
                onContactSelection={onContactSelection}
                page={page}
                onEdit={() => {
                  initialize();
                  setIsEditing(true);
                }}
              />
            )}
          </>
        );

        const modals = (
          <>
            <AddContact
              addingContact={addingContact}
              preset={preset}
              setAddingContact={setAddingContact}
              onDone={(nextContact) => {
                if (nextContact) {
                  router.push(
                    `/${organizationSlug}/contacts/${nextContact.id}`,
                    isDesktop ? 'none' : 'forward'
                  );
                }
              }}
              page={page}
            />
            <AddEvent
              isOpen={addEventModalState.isOpen}
              onDone={() => addEventModalState.setIsOpen(false)}
              onCancel={() => addEventModalState.setIsOpen(false)}
              page={page}
              contactId={contactId}
              accountId={contact?.accountId}
            />
            {contact ? (
              <SelectContact
                isOpen={isLinking}
                page={page}
                onCancel={() => setIsLinking(false)}
                hiddenContactIds={linkedContacts?.map(({ id }) => id)}
                onContactSelected={(selectedContact) => {
                  if (!user) {
                    throw new Error('User not found');
                  }

                  setIsLinking(false);
                  updateDoc(doc(contactsCollection, selectedContact.id), {
                    updatedAt: serverTimestamp(),
                    updatedBy: user.userId,
                    accountId: contact.accountId,
                    tags: { ...selectedContact.tags, primaryContact: false },
                  });
                }}
                title="Link Contact"
              />
            ) : null}
          </>
        );

        if (isDesktop) {
          return (
            <Box
              ref={page}
              sx={{
                overflowY: 'auto',
                maxHeight: '100vh',
                paddingTop: desktopContactNavHeight,
                overflowX: 'hidden',
              }}
            >
              <Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: '10px',
                    top: `${bothBarHeights + 16}px`,
                    zIndex: 10,
                  }}
                >
                  {isEditing ? (
                    <>
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={() => setIsEditing(false)}
                        data-testid="edit-contact-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="plain"
                        color="neutral"
                        onClick={scheduleSave}
                        data-testid="edit-contact-button"
                      >
                        Done
                      </Button>
                    </>
                  ) : null}
                </Box>
                {content}
              </Box>
              {modals}
            </Box>
          );
        }

        return (
          <>
            <IonPage ref={page}>
              {isEditing ? (
                <IonHeader>
                  <IonToolbar className="no-background">
                    <IonButtons slot="start">
                      <IonButton
                        color="dark"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </IonButton>
                    </IonButtons>
                    <IonTitle>Contact Details</IonTitle>
                    <IonButtons slot="end">
                      <IonButton
                        color="dark"
                        onClick={scheduleSave}
                        data-testid="save-contact-button"
                      >
                        Done
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
              ) : (
                <IonHeader translucent>
                  <IonToolbar className="view-contact-toolbar">
                    <IonButtons slot="start">
                      <IonBackButton
                        defaultHref={`/${organizationSlug}/contacts`}
                        color="dark"
                      />
                    </IonButtons>
                    <IonButtons slot="end">
                      <IonButton
                        color="dark"
                        onClick={() => {
                          initialize();
                          setIsEditing(true);
                        }}
                        routerDirection="none"
                        data-testid="edit-contact-button"
                      >
                        Edit
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
              )}
              <IonContent
                className={isEditing ? 'padding-bottom' : 'view-contact'}
                style={{ paddingTop: 50 }}
                fullscreen
              >
                {content}
              </IonContent>
            </IonPage>
            {modals}
          </>
        );
      }}
    </ContactProvider>
  );
};
