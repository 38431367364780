import { RawUser } from '@shared/models/users';
import { query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { organizationsCollection } from '@utils/firebase';

interface UseOrganizationsQueryProps {
  user: RawUser | undefined;
}

export const useOrganizationsQuery = ({
  user: userData,
}: UseOrganizationsQueryProps) => {
  const [queryResult, loading] = useCollection(
    !userData?.organizations?.length
      ? null
      : query(
          organizationsCollection,
          where('__name__', 'in', userData.organizations)
        )
  );

  const organizationsData = useMemo(
    () =>
      queryResult?.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })),
    [queryResult]
  );

  return {
    data: organizationsData,
    loading,
  };
};
