import { Box, Button, Input, inputClasses } from '@mui/joy';
import { Address } from '@shared/models/contacts';
import React from 'react';

import { EditContactItemContainer } from './EditContactItemContainer';
import { EditContactItemType } from './EditContactItemType';

interface EditAddressesProps {
  addresses: Address[];
  setAddresses: React.Dispatch<React.SetStateAction<Address[]>>;
  page: React.MutableRefObject<HTMLElement | null>;
}

export const EditAddresses = ({
  addresses,
  setAddresses,
  page,
}: EditAddressesProps) => (
  <EditContactItemContainer
    addButton={
      <Button
        variant="plain"
        color="neutral"
        data-testid="add-address-button"
        onClick={() =>
          setAddresses([
            ...addresses,
            {
              city: '',
              state: '',
              street1: '',
              street2: '',
              zip: '',
              country: '',
              type: 'home',
            },
          ])
        }
      >
        + Add Address
      </Button>
    }
  >
    {addresses.map((address, index) => {
      const handleAddressUpdate = (key: string, nextValue: string) =>
        setAddresses((current) =>
          current.map((item, i) =>
            i === index ? { ...item, [key]: nextValue } : item
          )
        );

      const handleAddressChange =
        (key: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
          handleAddressUpdate(key, String(event.target.value));

      return (
        <Box
          key={index}
          sx={{
            [`& .contact-item-buttons`]: {
              height: '200px',
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              [`& > .${inputClasses.root}`]: {
                borderBottom: '1px solid lightgray',
              },
            }}
          >
            <Input
              variant="plain"
              color="neutral"
              type="text"
              placeholder="Street"
              autoComplete="street-address"
              name="street1"
              value={address.street1}
              onChange={handleAddressChange('street1')}
            />
            <Input
              variant="plain"
              color="neutral"
              type="text"
              placeholder="Street"
              name="street2"
              value={address.street2}
              onChange={handleAddressChange('street2')}
            />
            <Input
              variant="plain"
              color="neutral"
              type="text"
              placeholder="City"
              autoComplete="address-level2"
              name="city"
              value={address.city}
              onChange={handleAddressChange('city')}
            />
            <Box
              sx={{
                display: 'flex',
                [`& > .${inputClasses.root}`]: {
                  width: '80px',
                  flex: 1,
                },
              }}
            >
              <Input
                variant="plain"
                color="neutral"
                type="text"
                placeholder="State"
                autoComplete="address-level1"
                value={address.state}
                onChange={handleAddressChange('state')}
                sx={{ borderRight: '1px solid lightgray' }}
              />

              <Input
                variant="plain"
                color="neutral"
                className="left-padding"
                type="number"
                placeholder="ZIP"
                autoComplete="postal-code"
                name="zip"
                value={address.zip}
                onChange={handleAddressChange('zip')}
                sx={{ paddingLeft: '5px' }}
              />
            </Box>
            <Input
              variant="plain"
              color="neutral"
              type="text"
              placeholder="Country"
              autoComplete="country"
              name="country"
              value={address.country}
              onChange={handleAddressChange('country')}
              sx={{
                borderBottom: 'none !important',
                borderTop: '1px solid lightgray',
              }}
            />
          </Box>
          <EditContactItemType
            label={address.type}
            setLabel={(nextValue) => handleAddressUpdate('type', nextValue)}
            page={page}
            labelType="Address"
            onDeleteEntity={() =>
              setAddresses(
                addresses.filter((_, itemIndex) => itemIndex !== index)
              )
            }
          />
        </Box>
      );
    })}
  </EditContactItemContainer>
);
