import Konva from 'konva';
import { useEffect, useRef, useState } from 'react';
import { Group, Layer, Rect, Transformer } from 'react-konva';

import { MediaPage } from '../MediaProvider/useMediaProviderValue';
import MediaImage from './Image';

const sheetMusicMultiplier = 0.3;
const sheetMusicImageWidth = 1280 * sheetMusicMultiplier;
const sheetMusicImageHeight = 1780 * sheetMusicMultiplier;
export interface ShapeProps {
  x: number;
  y: number;
  width: number;
  height: number;
  id: string;
  name: string;
  url: string;
}

interface CanvasPageProps {
  visible: boolean;
  canvasContainerWidth: number;
  canvasContainerHeight: number;
  selectedNode: string;
  setSelectedNode: React.Dispatch<React.SetStateAction<string>>;
  page: MediaPage;
}

export const CanvasPage = ({
  visible,
  canvasContainerWidth,
  canvasContainerHeight,
  selectedNode,
  setSelectedNode,
  page,
}: CanvasPageProps) => {
  const groupRef = useRef<Konva.Group | null>(null);

  const multiplier = 2.4;
  const stageWidth = 210 * multiplier;
  const stageHeight = 297 * multiplier;
  const [stageCenterY, setStageCenterY] = useState(0);
  const [stageCenterX, setStageCenterX] = useState(0);

  const handleUnselect = () => {
    setSelectedNode('');
    transformerRef?.nodes([]);
  };

  useEffect(() => {
    setStageCenterX(canvasContainerWidth / 2 - stageWidth / 2);
    setStageCenterY(canvasContainerHeight / 2 - stageHeight / 2);
  }, [canvasContainerWidth, canvasContainerHeight, stageWidth, stageHeight]);

  const [transformerRef, setTransformerRef] =
    useState<Konva.Transformer | null>(null);

  return (
    <Layer visible={visible}>
      <Group
        ref={groupRef}
        clipFunc={(ctx) => {
          ctx.save();
          ctx.rect(0, 0, stageWidth, stageHeight);
          ctx.restore();
        }}
        x={stageCenterX}
        y={stageCenterY}
        height={stageHeight}
        width={stageWidth}
      >
        <Rect
          height={stageHeight}
          width={stageWidth}
          fill="white"
          onClick={handleUnselect}
        />
        {page.imageUrl ? (
          <MediaImage
            shapeProps={{
              x: 40,
              y: 40,
              // TODO: use actual image height/width
              width: sheetMusicImageWidth,
              height: sheetMusicImageHeight,
              id: 'img1',
              name: 'img1',
              url: page.imageUrl,
            }}
            onSelect={(e) => {
              setSelectedNode(e.target.attrs.name);
              transformerRef?.nodes([e.target]);
            }}
            // onChange={(next) => {
            //   images[index] = next;
            // }}
            selectedItem={selectedNode}
          />
        ) : null}
      </Group>
      <Transformer
        visible={Boolean(selectedNode)}
        ref={setTransformerRef}
        keepRatio
        enabledAnchors={[
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
        ]}
        flipEnabled={false}
      />
    </Layer>
  );
};
