import { IonContent, IonPage } from '@ionic/react';
import { Box } from '@mui/joy';

import { MediaBottomBar } from './MediaBottomBar/index.';
import { MediaCanvas } from './MediaCanvas';
import { MediaDocument } from './MediaDocument';
import { MediaNavbar } from './MediaNavbar';
import { MediaProvider } from './MediaProvider';
import { MediaSidebar } from './MediaSidebar';

export const MediaDetail = () => (
  <MediaProvider>
    {({ page, editorType }) => (
      <IonPage ref={page}>
        <IonContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <MediaNavbar />
            <Box sx={{ display: 'flex', flex: 1 }}>
              <MediaSidebar />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {editorType === 'canvas' ? <MediaCanvas /> : null}
                {editorType === 'document' ? <MediaDocument /> : null}
                {editorType === 'canvas' ? <MediaBottomBar /> : null}
              </Box>
            </Box>
          </Box>
        </IonContent>
      </IonPage>
    )}
  </MediaProvider>
);
