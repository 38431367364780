import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { productsCollection } from './model';
import { parseProduct } from './parseProduct';

export const useProductQuery = (productId: string | undefined) => {
  const [productSnapshot, loading, error] = useDocument(
    productId ? doc(productsCollection, productId) : null
  );

  const product = useMemo(
    () => (productSnapshot ? parseProduct(productSnapshot) : undefined),
    [productSnapshot]
  );

  return [product, loading, error] as const;
};
