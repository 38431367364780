import { useDndContext, useDndMonitor } from '@dnd-kit/core';

import { useLesson } from '../../LessonProvider/useLesson';

export const DndContextListener = () => {
  const { droppableRects } = useDndContext();
  const { setOverDimensions, flattenedAssignmentItems } = useLesson();

  useDndMonitor({
    onDragOver({ over, active }) {
      if (!over) {
        return;
      }

      if (active.data.current?.type === 'section') {
        const overItem = flattenedAssignmentItems.find(
          ({ id }) => id === over.id
        );

        const overSection =
          overItem?.sectionId === overItem?.id
            ? overItem
            : flattenedAssignmentItems.find(
                ({ id }) => id === overItem?.sectionId
              );

        if (!overSection) {
          return;
        }

        const firstId = overSection.id;
        const lastId = overSection.children.at(-1)?.id ?? firstId;

        const firstRect = droppableRects.get(firstId);
        const lastRect = droppableRects.get(lastId);

        if (!firstRect || !lastRect) {
          return;
        }

        return setOverDimensions({
          top: firstRect.top,
          bottom: lastRect.bottom,
        });
      }

      const rect = over?.rect;

      if (!rect) {
        return null;
      }

      setOverDimensions({ top: rect.top, bottom: rect.bottom });
    },
  });

  return <></>;
};
