import { createContext } from 'react';

import {
  UseMoveContactsProps,
  UseMoveContactsReturn,
  useMoveContactsValue,
} from './useMoveContactsValue';

export const MoveContactsContext = createContext<
  UseMoveContactsReturn | undefined
>(undefined);

interface MoveContactsProviderProps extends UseMoveContactsProps {
  children?: React.ReactNode;
}

export const MoveContactsProvider = ({
  children,
  ...moveContactProps
}: MoveContactsProviderProps) => {
  const result = useMoveContactsValue(moveContactProps);

  return (
    <MoveContactsContext.Provider value={result}>
      {children}
    </MoveContactsContext.Provider>
  );
};
