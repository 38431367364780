import { IonActionSheet } from '@ionic/react';
import { AddOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { Typography } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { RawContact } from '@shared/models/contacts';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import Avatar from '@components/Avatar';
import { useUserData } from '@components/UserData/useUserData';
import { InstrumentsModal } from '@features/Contacts/ContactDetail/ViewContact/InstrumentsModal';
import { contactsCollection } from '@models/contacts/model';
import { serverTimestamp } from '@utils/serverTimestamp';

import { AdminLinkBase } from '../AdminLink';
import { ensureSignular } from './ensureSignular';

interface AdminInstrumentsProps {
  page: React.MutableRefObject<HTMLElement | null>;
}

export const AdminInstruments = ({ page }: AdminInstrumentsProps) => {
  const { userContact } = useUserData();
  const [selectedInstruments, setSelectedInstruments] = useState<string[]>([]);
  const [showInstrumentsModal, setShowInstrumentsModal] = useState(false);
  const [instrumentToRemove, setInstrumentToRemove] = useState<
    string | undefined
  >();
  const [instrumentsInitialized, setInstrumentsInitialized] = useState(false);

  useEffect(() => {
    if (
      instrumentsInitialized ||
      showInstrumentsModal ||
      !userContact?.instruments?.length ||
      selectedInstruments.length > 0
    ) {
      return;
    }

    setInstrumentsInitialized(true);
    setSelectedInstruments(userContact?.instruments || []);
  }, [
    instrumentsInitialized,
    showInstrumentsModal,
    selectedInstruments,
    userContact,
  ]);

  const isDesktop = useMediaQuery('(min-width:747px)');

  return (
    <>
      {userContact?.instruments?.map((instrument) => (
        <AdminLinkBase
          key={instrument}
          text={`${ensureSignular(instrument)} Lessons`}
          icon={
            <Avatar
              sx={(theme) => ({
                width: 40,
                height: 40,
                fontWeight: 400,
                marginRight: '8px',
                backgroundColor: 'white',
                padding: '5px',
                [theme.getColorSchemeSelector('dark')]: {
                  filter: 'invert(1)',
                },
              })}
              src={`assets/instruments/${instrument.toLowerCase()}.svg`}
            />
          }
          onClick={() => setInstrumentToRemove(instrument)}
          iconRight={
            isDesktop ? (
              <Typography
                sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                Delete
                <RemoveCircleOutline sx={{ fontSize: '16px' }} />
              </Typography>
            ) : (
              <RemoveCircleOutline sx={{ fontSize: '16px' }} />
            )
          }
        />
      ))}

      <AdminLinkBase
        text="Add"
        icon={<AddOutlined sx={{ marginLeft: '9px' }} />}
        iconRight={null}
        onClick={() => setShowInstrumentsModal(true)}
      />

      <IonActionSheet
        isOpen={Boolean(instrumentToRemove)}
        header={`Are you sure you want to remove ${instrumentToRemove} from your instruments?`}
        buttons={[
          {
            text: 'Delete',
            role: 'destructive',
            data: {
              action: 'delete',
            },
            handler: async () => {
              if (!userContact) {
                throw new Error('User contact not found');
              }

              const instruments = (userContact.instruments || []).filter(
                (instrument) => instrument !== instrumentToRemove
              );

              setSelectedInstruments(instruments);

              const nextContact: Partial<RawContact> = {
                updatedAt: serverTimestamp(),
                updatedBy: userContact.userId,
                instruments,
              };

              updateDoc(doc(contactsCollection, userContact.id), nextContact);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
        onDidDismiss={() => setInstrumentToRemove(undefined)}
      />

      <InstrumentsModal
        selectedInstruments={selectedInstruments}
        setSelectedInstruments={setSelectedInstruments}
        isOpen={showInstrumentsModal}
        page={page}
        onDone={async () => {
          if (!userContact) {
            throw new Error('User contact not found');
          }

          setShowInstrumentsModal(false);

          const nextUserContact: Partial<RawContact> = {
            updatedAt: serverTimestamp(),
            updatedBy: userContact.userId,
            instruments: selectedInstruments,
          };

          await updateDoc(
            doc(contactsCollection, userContact.id),
            nextUserContact
          );

          setSelectedInstruments(userContact.instruments || []);
        }}
      />
    </>
  );
};
