import { IonModal, IonNav } from '@ionic/react';
import { useMediaQuery } from '@mui/material';
import { ParsedContact } from '@shared/models/contacts';

import { TransferPrimaryProvider } from './TransferPrimaryContext';
import { TransferPrimaryOverview } from './TransferPrimaryOverview';

interface TransferPrimaryModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  linkedContacts: ParsedContact[] | undefined;
  contactId: string;
}

export const TransferPrimaryModal = (props: TransferPrimaryModalProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!props.page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={props.page.current}
      isOpen={props.isOpen}
      onDidDismiss={props.onDone}
      style={
        isDesktop ? { '--height': '650px', '--max-width': '900px' } : undefined
      }
    >
      <TransferPrimaryProvider {...props}>
        <IonNav root={() => <TransferPrimaryOverview {...props} />} />
      </TransferPrimaryProvider>
    </IonModal>
  );
};
