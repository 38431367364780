import { useContext } from 'react';

import { ContactProviderContext } from './ContactProvider';

export const useContact = () => {
  const context = useContext(ContactProviderContext);

  if (!context) {
    throw new Error('useContact has to be used within a <ContactProvider />');
  }

  return context;
};
