import { Box, Button, Typography } from '@mui/joy';

import { useMedia } from '../MediaProvider/useMedia';
import { SidebarContentContainer } from './SidebarContentContainer';

export const Uploads = () => {
  const { fileInputRef, handleUploadFiles } = useMedia();

  return (
    <SidebarContentContainer title="Uploads">
      <Box
        sx={() => ({
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Button
            color="neutral"
            fullWidth
            onClick={() => fileInputRef.current?.click()}
          >
            Upload Files
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,.pdf"
            hidden
            multiple
            onChange={handleUploadFiles}
          />
          <Box
            sx={(theme) => ({
              border: `2px dashed ${theme.palette.neutral[500]}`,
              borderRadius: '5px',
            })}
          >
            <Box
              sx={{
                padding: '50px',
              }}
            >
              <Typography
                textColor="neutral.700"
                level="body-sm"
                sx={{ textAlign: 'center' }}
              >
                Drag media here to upload or connect an account…
              </Typography>
            </Box>
          </Box>
          <Typography
            textColor="neutral.500"
            sx={{ fontSize: '10px', textAlign: 'center' }}
          >
            You can upload PDFs and images.
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '350px',
          }}
        >
          <Typography
            level="body-xs"
            textColor="neutral.500"
            sx={{
              textAlign: 'center',
              padding: '0 5px',
              fontSize: '10px',
            }}
          >
            By uploading, you confirm that your content compiles with our{' '}
            <a href="http://google.com">Terms of Use</a> and does not infringe
            on any third party rights.
          </Typography>
        </Box>
      </Box>
    </SidebarContentContainer>
  );
};
