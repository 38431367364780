import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Checkbox, Typography } from '@mui/joy';
import { useEffect } from 'react';
import { useSet } from 'react-use';

import { ListHeader } from '@components/ListHeader';
import { ModalListContainer } from '@components/Modal/ModalListContainer';

import { useContact } from '../ContactProvider/useContact';

interface StartLessonModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  adding: boolean;
  onDone?: (instruments?: string[]) => void;
}

const StartLessonModal = ({ page, adding, onDone }: StartLessonModalProps) => {
  const [selectedInstruments, selectedInstrumentsActions] = useSet<string>();
  const { contact } = useContact();

  useEffect(() => {
    if (adding || selectedInstruments.size === 0) {
      return;
    }

    selectedInstrumentsActions.reset();
  }, [adding, selectedInstruments.size, selectedInstrumentsActions]);

  if (!page.current || !contact?.instruments) {
    return null;
  }

  const title = 'What instruments are you teaching in this lesson?';

  return (
    <>
      <IonModal
        presentingElement={page.current}
        isOpen={adding}
        onDidDismiss={() => onDone?.()}
        style={{ '--height': '500px' }}
      >
        <IonHeader>
          <IonToolbar class="no-background">
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => onDone?.()}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <ModalListContainer>
            <Box>
              <Typography level="h4">{title}</Typography>
              <Typography>
                You can edit this contact to add additional instruments.
              </Typography>
            </Box>

            <Box className="scrollable" sx={{ padding: '0 20px' }}>
              <ListHeader
                v2
                sx={{
                  textAlign: 'left',

                  '@media (min-width: 747px)': {
                    marginLeft: 0,
                    marginBottom: '10px',
                    paddingLeft: '4px',
                  },
                }}
              >
                Instruments
              </ListHeader>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                {contact?.instruments.map((instrument) => (
                  <Button
                    key={instrument}
                    size="sm"
                    variant="instrument"
                    onClick={() =>
                      selectedInstrumentsActions.toggle(instrument)
                    }
                    startDecorator={
                      <>
                        <Checkbox
                          overlay
                          checked={selectedInstrumentsActions.has(instrument)}
                        />
                        <img
                          alt="instrument icon"
                          src={`assets/instruments/${instrument.toLowerCase()}.svg`}
                        />
                      </>
                    }
                  >
                    {instrument}
                  </Button>
                ))}
              </Box>
            </Box>

            <Button
              variant="outlined"
              color="neutral"
              size="lg"
              endDecorator={<ArrowForwardIos />}
              onClick={() => onDone?.([...selectedInstruments])}
            >
              New Lesson Note
            </Button>
          </ModalListContainer>
        </IonContent>
      </IonModal>
    </>
  );
};

export default StartLessonModal;
