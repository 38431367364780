import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Button } from '@mui/joy';
import { InvoiceStatus } from '@shared/models/invoices';
import { useRef, useState } from 'react';

import { DatePickerItem } from '@components/DatePickerItem';
import { ListHeader } from '@components/ListHeader';

import { useEditInvoiceContext } from '../EditInvoice/useEditProductContext';
import './MarkAsPaidModal.css';

type SegmentValue = 'cash' | 'check' | 'other';
const isSegmentValue = (value: unknown): value is SegmentValue =>
  value === 'cash' || value === 'check' || value === 'other';

interface MarkAsPaidModalProps {
  invoiceStatus: InvoiceStatus;
  presentingElement: HTMLElement | undefined;
  isOpen?: boolean;
  onDone?: () => void;
  invoiceAmount?: number;
}

export const MarkAsPaidModal = ({
  invoiceStatus,
  presentingElement,
  isOpen,
  onDone,
  invoiceAmount,
}: MarkAsPaidModalProps) => {
  const modalRef = useRef<HTMLIonModalElement | null>(null);
  const {
    markAsPaid: {
      paymentType,
      setPaymentType,
      datePaid,
      setDatePaid,
      paymentNotes,
      setPaymentNotes,
    },
    saveStatus,
  } = useEditInvoiceContext();
  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);
  const actionText =
    invoiceStatus === 'paid' ? 'Mark as Unpaid' : 'Mark as Paid';

  return (
    <IonModal
      presentingElement={presentingElement}
      isOpen={isOpen}
      onDidDismiss={onDone}
      ref={modalRef}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={() => modalRef.current?.dismiss()}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{actionText}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding flex-column" color="light">
        {invoiceStatus !== 'paid' ? (
          <>
            <IonList inset>
              <IonSegment
                value={paymentType}
                onIonChange={(event) => {
                  if (isSegmentValue(event.detail.value)) {
                    setPaymentType(event.detail.value);
                  }
                }}
              >
                <IonSegmentButton value="cash">
                  <IonLabel>Cash</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="check">
                  <IonLabel>Check</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="other">
                  <IonLabel>Other</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonList>
            <IonList inset>
              <DatePickerItem
                utcDate={datePaid}
                onUtcDate={setDatePaid}
                index={0}
                openIndex={openIndex}
                onOpenIndexChange={setOpenIndex}
                hideBottomDivider
              >
                Issue Date
              </DatePickerItem>
            </IonList>
            {paymentType !== 'cash' ? (
              <>
                <ListHeader inset>Payment Notes</ListHeader>
                <IonList inset>
                  <IonItem>
                    <IonInput
                      clearInput
                      placeholder={`e.g., ${
                        paymentType === 'check' ? 'check #1234' : 'Visa'
                      }`}
                      value={paymentNotes}
                      onIonChange={(event) =>
                        setPaymentNotes(String(event.detail.value))
                      }
                    />
                  </IonItem>
                </IonList>
              </>
            ) : null}
          </>
        ) : null}
        <IonList inset style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            style={{ textTransform: 'uppercase' }}
            onClick={async () => {
              saveStatus(invoiceStatus === 'paid' ? 'sent' : 'paid');
              onDone?.();
            }}
          >
            {actionText}
            {invoiceAmount !== undefined && invoiceStatus !== 'paid' ? (
              <>
                : <b style={{ marginLeft: 5 }}>${invoiceAmount}</b>
              </>
            ) : (
              ''
            )}
          </Button>
        </IonList>
      </IonContent>
    </IonModal>
  );
};
