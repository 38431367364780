import { createContext } from 'react';

import {
  UseMoveContactProps,
  UseTransferPrimaryReturn,
  useTransferPrimaryValue,
} from './useTransferPrimaryValue';

export const TransferPrimaryContext = createContext<
  UseTransferPrimaryReturn | undefined
>(undefined);

interface TransferPrimaryProviderProps extends UseMoveContactProps {
  children?: React.ReactNode;
}

export const TransferPrimaryProvider = ({
  children,
  ...rest
}: TransferPrimaryProviderProps) => {
  const result = useTransferPrimaryValue(rest);

  return (
    <TransferPrimaryContext.Provider value={result}>
      {children}
    </TransferPrimaryContext.Provider>
  );
};
