import { IonContent, IonPage } from '@ionic/react';
import { useState } from 'react';

import { useOrgainzation } from '@features/Organization/organizationSlice';

import NoShowsContent from '../Products/AddProduct/NoShowsContent';
import { EditProductProvider } from '../Products/AddProduct/ProductContext';
import AdminEditHeader from './AdminEditHeader';

const GlobalNoShows = () => {
  const [isEditing, setIsEditing] = useState(false);
  const globalProductId = useOrgainzation()?.globalProductId;

  if (!globalProductId) {
    return null;
  }

  return (
    <EditProductProvider productId={globalProductId}>
      <IonPage>
        <AdminEditHeader isEditing={isEditing} setIsEditing={setIsEditing}>
          No Shows
        </AdminEditHeader>
        <IonContent className="ion-padding" color="light">
          {/* TODO: Add date picker here */}
          <NoShowsContent readOnly={!isEditing} />
        </IonContent>
      </IonPage>
    </EditProductProvider>
  );
};

export default GlobalNoShows;
