import { ParsedContact } from '@shared/models/contacts';
import { useMemo } from 'react';

import { useCustomAvatar } from './useCustomAvatar';

export const useContactAvatar = (contact: ParsedContact | undefined) => {
  const [customAvatarSrc, customAvatarLoading, error] = useCustomAvatar(
    contact?.avatar
  );

  const loading = useMemo(
    () => contact?.avatarSlug === 'custom' && customAvatarLoading,
    [contact, customAvatarLoading]
  );

  const src = (() => {
    if (!contact) {
      return null;
    }

    if (contact.avatarSlug === 'custom') {
      return customAvatarSrc;
    }

    return `assets/avatars/${contact.avatarSlug}.png`;
  })();

  return [src, loading, error] as const;
};
