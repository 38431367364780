import { createContext } from 'react';

import {
  UseUserAccessProps,
  UseUserAccessReturn,
  useUserAccessValue,
} from './useUserAccessValue';

export const UserAccessContext = createContext<UseUserAccessReturn | undefined>(
  undefined
);

interface UserAccessProviderProps extends UseUserAccessProps {
  children?:
    | React.ReactNode
    | ((props: UseUserAccessReturn) => React.ReactNode);
}

export const UserAccessProvider = ({
  children,
  ...rest
}: UserAccessProviderProps) => {
  const result = useUserAccessValue(rest);

  return (
    <UserAccessContext.Provider value={result}>
      {typeof children === 'function' ? children(result) : children}
    </UserAccessContext.Provider>
  );
};
