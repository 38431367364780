import { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import { Frequency, RRule, RRuleSet } from 'rrule';

import { ParsedEvent, RawEvent } from './model';

interface GetRrulePatternResult {
  freq: Frequency | undefined;
  interval: number | undefined;
}

export const getRrulePattern = (
  frequencyOption: FrequencyOption
): GetRrulePatternResult | undefined => {
  if (frequencyOption === 'Never') {
    return undefined;
  }

  if (frequencyOption === 'Every Day') {
    return {
      freq: Frequency.DAILY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every Week') {
    return {
      freq: Frequency.WEEKLY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every 2 Weeks') {
    return {
      freq: Frequency.WEEKLY,
      interval: 2,
    };
  }

  if (frequencyOption === 'Every Month') {
    return {
      freq: Frequency.MONTHLY,
      interval: 1,
    };
  }

  if (frequencyOption === 'Every Year') {
    return {
      freq: Frequency.YEARLY,
      interval: 1,
    };
  }

  throw new Error(`Invalid frequency option: ${frequencyOption}`);
};

export const frequencyOptions = [
  'Never',
  'Every Day',
  'Every Week',
  'Every 2 Weeks',
  'Every Month',
  'Every Year',
] as const;

export type FrequencyOption = (typeof frequencyOptions)[number];

const createRrule = (event: RawEvent): RRuleSet | undefined => {
  if (!event?.ongoing || !event.frequency) {
    return undefined;
  }

  const rruleSet = new RRuleSet();

  const frequencyOption = event.frequency;
  const rrule = new RRule({
    ...getRrulePattern(frequencyOption),
    dtstart: event.start.toDate(),
    until: event.end?.toDate(),
  });

  event.exceptions?.forEach((exception) => {
    rruleSet.exdate(exception.toDate());
  });

  rruleSet.rrule(rrule);

  return rruleSet;
};

export const parseEvent = (
  doc: QueryDocumentSnapshot<RawEvent> | DocumentSnapshot<RawEvent>
): ParsedEvent | undefined => {
  const base = doc.data();

  if (!base) {
    return undefined;
  }

  const { rrule, start, end, type, ...data } = base;
  const parsedRrule = createRrule(base);

  return {
    id: doc.id,
    start: start.toDate(),
    end: end?.toDate() || null,
    type,
    ...(parsedRrule && { rrule: parsedRrule }),
    ...data,
  };
};
