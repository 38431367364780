import {
  QueryFieldFilterConstraint,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { notEmpty } from '@models/events/useEventsQuery';
import { invoicesCollection } from '@models/invoices/model';
import { auth } from '@utils/firebase';

import { parseInvoice } from './parseInvoice';

interface UseInvoicesQueryProps {
  constraints?: QueryFieldFilterConstraint[];
  limit?: number;
  skip?: boolean;
}

export const useInvoicesQuery = ({
  skip,
  limit: limitBase,
  constraints,
}: UseInvoicesQueryProps = {}) => {
  const [user] = useAuthState(auth);
  const organizationId = useOrganizationId();
  const [rawInvoices, loading, error] = useCollection(
    !user || !organizationId || skip
      ? null
      : query(
          invoicesCollection,
          ...[
            where('organizationId', '==', organizationId),
            ...(constraints || []),
          ],
          orderBy('start', 'desc'),
          ...(limitBase ? [limit(limitBase)] : [])
        )
  );

  const invoices = useMemo(
    () =>
      rawInvoices?.docs
        ?.map(parseInvoice)
        .filter(notEmpty)
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt.toMillis() - b.createdAt.toMillis();
          }

          return 0;
        }),
    [rawInvoices]
  );

  return [invoices, loading, error] as const;
};
