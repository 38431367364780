import { useContext } from 'react';

import { AppIntroProviderContext } from './AppIntroProvider';

export const useAppIntro = () => {
  const context = useContext(AppIntroProviderContext);

  if (!context) {
    throw new Error(
      'useAppIntro has to be used within a <AppIntroProviderProvider />'
    );
  }

  return context;
};
