import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ParsedContact } from '@shared/models/contacts';
import { where } from 'firebase/firestore';
import { addOutline, addSharp } from 'ionicons/icons';
import { useMemo, useRef, useState } from 'react';

import AddContact from '@features/Contacts/AddContact';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';
import { ModalState } from '@hooks/useModalState';
import { useContactsQuery } from '@models/contacts/useContactsQuery';

import { useEditEventContext } from './EditEventContext/useEditEventContext';

export interface SelectStudentProps {
  modalState: ModalState;
  accountId?: string;
}

const SelectStudent = ({ modalState, accountId }: SelectStudentProps) => {
  const {
    fieldProps: { contactIds, setSelectStudentIsOpen, setContactIds },
  } = useEditEventContext();
  const [contacts] = useContactsQuery({
    constraints: accountId ? [where('accountId', '==', accountId)] : undefined,
  });
  const [addingContact, setAddingContact] = useState(false);
  const page = useRef<HTMLIonModalElement | null>(null);
  const organizationPermissions = useOrganizationPermissions();

  const availableContacts = useMemo(() => {
    const unavailableContactIds = new Set(contactIds);

    return contacts?.filter(({ id }) => !unavailableContactIds.has(id));
  }, [contacts, contactIds]);

  const onContactSelected = (nextContact: ParsedContact) => {
    setSelectStudentIsOpen(false);
    setContactIds((existing) => [...new Set([...existing, nextContact.id])]);
  };
  const onCancel = () => setSelectStudentIsOpen(false);

  if (!modalState.page.current) {
    return null;
  }

  return (
    <>
      <IonModal
        presentingElement={modalState.page.current}
        isOpen={modalState.isOpen}
        onDidDismiss={onCancel}
        ref={page}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={onCancel}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Add Student</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                disabled={!organizationPermissions?.contacts.canCreate}
                onClick={() => setAddingContact(true)}
              >
                <IonIcon ios={addOutline} md={addSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar />
          <IonList>
            {availableContacts?.map((contact) => {
              const { id, firstName, lastName } = contact;

              return (
                <IonItem
                  key={id}
                  button
                  routerDirection="none"
                  onClick={() => onContactSelected?.(contact)}
                >
                  <IonLabel>
                    {firstName} {lastName}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        </IonContent>
      </IonModal>
      <AddContact
        addingContact={addingContact}
        setAddingContact={setAddingContact}
        page={page}
        preset={accountId ? { accountId } : undefined}
        onDone={(newContact) => {
          if (newContact) {
            onContactSelected?.(newContact);
          }
        }}
      />
    </>
  );
};

export default SelectStudent;
