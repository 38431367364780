import { Button } from '@mui/joy';

import { useMedia } from '../MediaProvider/useMedia';
import { SidebarContentContainer } from './SidebarContentContainer';

export const Draw = () => {
  const { editorType, setEditorType } = useMedia();

  return (
    <SidebarContentContainer title="Draw">
      Draw
      <Button
        onClick={() => {
          setEditorType(editorType === 'canvas' ? 'document' : 'canvas');
        }}
      >
        Switch editor
      </Button>
    </SidebarContentContainer>
  );
};
