import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import {
  AddCircleOutline,
  AutoGraphOutlined,
  BoltOutlined,
  CloudDownloadOutlined,
  DrawOutlined,
  HourglassBottomOutlined,
  ListAltOutlined,
  MicOutlined,
  MusicNote,
  NoteAddOutlined,
  OndemandVideoOutlined,
  QuizOutlined,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { useEffect, useMemo, useRef, useState } from 'react';
import uuid4 from 'uuid4';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { Tag } from '@features/Contacts/ContactDetail/ViewContact/ContactTag';
import { hasGuidedPractice } from '@features/Media/MediaDetail/MediaSidebar';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { useLesson } from '../../LessonProvider/useLesson';
import { Assignment } from '../../LessonProvider/useLessonProviderValue';

const multiplier = 0.04;
const pagePreviewWidth = 1280 * multiplier;
const pagePreviewHeight = 1780 * multiplier;

const mediaOptions = [
  { id: uuid4(), title: 'Canon in D', tags: ['Sheet Music', 'Classical'] },
  {
    id: uuid4(),
    title: 'Eine Kleine Nachtmusik',
    tags: ['Sheet Music', 'Classical'],
    hasMetronome: true,
  },
  {
    id: uuid4(),
    title: '12 Bar Blues',
    tags: ['Sheet Music', 'Jazz'],
    hasBackgroundTrack: true,
    hasSegments: true,
  },
  {
    id: uuid4(),
    title: 'Moonlight Sonata',
    tags: ['Sheet Music', 'Classical'],
  },
  {
    id: uuid4(),
    title: 'The Entertainer',
    tags: ['Sheet Music', 'Jazz'],
    hasMetronome: true,
    hasSegments: true,
  },
  {
    id: uuid4(),
    title: 'Fur Elise',
    tags: ['Sheet Music', 'Classical'],
    hasBackgroundTrack: true,
  },
  {
    id: uuid4(),
    title: 'Take Five',
    tags: ['Sheet Music', 'Jazz'],
  },
  {
    id: uuid4(),
    title: 'Flight of the Bumblebee',
    tags: ['Sheet Music', 'Classical'],
  },
  {
    id: uuid4(),
    title: 'Autumn Leaves',
    tags: ['Sheet Music', 'Jazz'],
  },
  {
    id: uuid4(),
    title: 'Rhapsody in Blue',
    tags: ['Sheet Music', 'Classical'],
  },
];

interface NewAssignmentModalProps {
  isOpen: boolean;
  onDone?: (assignment?: Assignment) => void;
}

const NewAssignmentModal = ({
  isOpen,
  onDone: onDoneBase,
}: NewAssignmentModalProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { page, newAssignment } = useLesson();
  const titleRef = useRef<HTMLInputElement | null>(null);
  const [focusSet, setFocusSet] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const onDone = (assignment?: Assignment) => {
    setCurrentPage(1);
    onDoneBase?.(assignment);
  };

  useHotkeys('Meta + Enter', () => onDone?.(newAssignment), {
    ignoredElementWhitelist: ['INPUT'],
    enabled: isOpen,
  });

  useEffect(() => {
    if (!isOpen || focusSet || newAssignment?.title) {
      return;
    }

    const timeoutId = setTimeout(() => {
      titleRef.current?.querySelector('input')?.focus();
      setFocusSet(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [isOpen, newAssignment, focusSet]);

  const organizationSlug = useOrganizationSlug();
  const nextMediaId = useMemo(() => uuid4(), []);

  if (!page.current || !newAssignment) {
    return null;
  }

  const title = currentPage === 1 ? 'Choose a template' : 'Choose sheet music';

  return (
    <>
      <IonModal
        presentingElement={page.current}
        isOpen={isOpen}
        onDidDismiss={() => onDone?.()}
        style={{
          '--height': '750px',
          '--max-width': '1000px',
        }}
      >
        <IonHeader>
          <IonToolbar class="no-background">
            {currentPage > 1 ? (
              <IonButtons slot="start">
                <IonButton color="dark" onClick={() => setCurrentPage(1)}>
                  Back
                </IonButton>
              </IonButtons>
            ) : null}
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => onDone?.()}>
                Cancel
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {currentPage === 1 ? (
            <ModalListContainer>
              <Box>
                <Typography level="h4">{title}</Typography>
                <Typography>
                  What kind of step are you adding to the lesson?
                </Typography>
              </Box>

              <Box className="scrollable" sx={{ padding: '0 20px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                    textAlign: 'left',
                    margin: '0 auto',
                  }}
                >
                  {[
                    {
                      icon: <OndemandVideoOutlined />,
                      title: 'Video',
                      description:
                        "Deliver short, meaningful video messages to boost your students' learning.",
                    },
                    {
                      icon: <MusicNote />,
                      title: 'Audio',
                      description:
                        'Create and share engaging audio clips to bring your teaching to life.',
                    },

                    {
                      icon: <DrawOutlined />,
                      title: 'Sheet Music',
                      description:
                        'Arrange and annotate share sheet music to help your students practice.',
                    },

                    {
                      icon: <QuizOutlined />,
                      title: 'Quiz',
                      description:
                        "Create a quiz to test your students' knowledge and understanding.",
                    },
                    {
                      icon: <BoltOutlined />,
                      title: 'Flash Cards',
                      description:
                        'Create flash cards to help students memorize key concepts.',
                    },
                    ...(hasGuidedPractice
                      ? [
                          {
                            icon: <AutoGraphOutlined />,
                            title: 'Guided Practice',
                            description:
                              'Create step-by-step practice routines to guide your students.',
                          },
                        ]
                      : []),
                    {
                      icon: <MicOutlined />,
                      title: 'Student Recording',
                      description:
                        'Request recordings and provide in-app feedback.',
                    },
                    {
                      icon: <NoteAddOutlined />,
                      title: 'Document',
                      description:
                        'Add rich text, images, and links to your assignment.',
                    },
                    {
                      icon: <CloudDownloadOutlined />,
                      title: 'Downloads',
                      description:
                        'Add files that students can download and view offline.',
                    },
                  ].map((template, index) => (
                    <Box
                      key={index}
                      onClick={() => setCurrentPage(2)}
                      sx={(theme) => ({
                        display: 'flex',
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: '10px',
                        padding: '20px',
                        gap: '20px',
                        maxWidth: '390px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                      })}
                    >
                      <Box>{template.icon}</Box>
                      <Box>
                        <Typography level="title-md">
                          {template.title}
                        </Typography>
                        <Typography textColor="neutral.500">
                          {template.description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </ModalListContainer>
          ) : null}

          {currentPage === 2 ? (
            <ModalListContainer sx={{ gap: '15px' }}>
              <Box>
                <Typography level="h4">Select Sheet Music</Typography>
                <Typography>
                  Choose sheet music from your library to add to your lesson.
                </Typography>
              </Box>

              <Input
                variant="search"
                placeholder="Find sheet music…"
                startDecorator={<Search />}
                value={searchValue}
                onChange={(event) =>
                  setSearchValue(event.target.value.toLowerCase())
                }
              />

              <Box className="scrollable">
                <List variant="contactList">
                  <Box
                    component="a"
                    target="_blank"
                    href={`/${organizationSlug}/media/${nextMediaId}`}
                    sx={{ textDecoration: 'none' }}
                    onClick={() => {
                      // TODO: create assignment on backend and save to lesson/assignment
                      // (`nextMediaId` is the media id)
                      onDone?.();
                    }}
                  >
                    <ListItem>
                      <ListItemButton>
                        <ListItemDecorator
                          sx={{
                            width: pagePreviewWidth,
                            justifyContent: 'center',
                          }}
                        >
                          <AddCircleOutline sx={{ fontSize: '30px' }} />
                        </ListItemDecorator>

                        <ListItemContent>
                          <Typography level="title-md">
                            Create new sheet music
                          </Typography>
                          <Typography textColor="neutral.500">
                            Add PDFs, images, annotations, and more to create an
                            interactive experience.
                          </Typography>
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                  </Box>
                  {mediaOptions.map((mediaOption) => (
                    <Box
                      key={mediaOption.id}
                      component="a"
                      target="_blank"
                      href={`/${organizationSlug}/media/${mediaOption.id}`}
                      onClick={() => {
                        // TODO: assign assignment on backend and save to lesson/assignment
                        // (`nextMediaId` is the media id)
                        onDone?.();
                      }}
                      sx={{ textDecoration: 'none' }}
                    >
                      <ListItem
                        onClick={() => {
                          // select
                        }}
                      >
                        <ListItemButton>
                          <ListItemDecorator>
                            <img
                              src={`${window.location.origin}/assets/sheet-music-1.png`}
                              alt="sheet music"
                              style={{
                                width: pagePreviewWidth,
                                height: pagePreviewHeight,
                                borderRadius: '5px',
                              }}
                            />
                          </ListItemDecorator>

                          <ListItemContent
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                            }}
                          >
                            <Typography level="title-md">
                              {mediaOption.title}
                            </Typography>
                            {mediaOption.tags.length ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  flexWrap: 'wrap',
                                  overflowX: 'auto',
                                }}
                              >
                                {mediaOption.tags.map((tag, tagIndex) => (
                                  <Tag key={tagIndex}>{tag}</Tag>
                                ))}
                              </Box>
                            ) : null}
                          </ListItemContent>
                          <Box
                            sx={(theme) => ({
                              svg: {
                                color: theme.palette.neutral[500],
                              },
                            })}
                          >
                            {mediaOption.hasBackgroundTrack ? (
                              <MusicNote />
                            ) : null}
                            {mediaOption.hasMetronome ? (
                              <HourglassBottomOutlined />
                            ) : null}
                            {mediaOption.hasSegments ? (
                              <ListAltOutlined />
                            ) : null}
                          </Box>
                        </ListItemButton>
                      </ListItem>
                      <ListDivider />
                    </Box>
                  ))}
                </List>
              </Box>
            </ModalListContainer>
          ) : null}
        </IonContent>
      </IonModal>
    </>
  );
};

export default NewAssignmentModal;
