import Box, { BoxProps } from '@mui/joy/Box';

import './ListHeader.css';

export interface ListHeaderProps {
  children?: React.ReactNode;
  firstHeader?: boolean;
  border?: boolean;
  inset?: boolean;
  style?: React.CSSProperties;
  sx?: BoxProps['sx'];
  v2?: boolean;
  title?: string;
  noSideSpacing?: boolean;
}

export const ListHeader = ({
  children,
  firstHeader,
  inset,
  style,
  border,
  sx,
  title,
  v2,
  noSideSpacing,
}: ListHeaderProps) => (
  <Box
    sx={[
      {
        ...(border
          ? { borderBottom: '1px solid lightgray', paddingBottom: '5px' }
          : undefined),
        ...(v2
          ? {
              fontWeight: 600,
              letterSpacing: '1px',
              paddingTop: 0,
              ...(noSideSpacing && { paddingLeft: 0 }),
              '@media (min-width: 747px)': {
                // TODO: remove .css file and remove !important
                marginLeft: noSideSpacing ? '0 !important' : '20px',
              },
            }
          : undefined),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    style={style}
    className={[
      'list-header',
      inset ? 'inset' : undefined,
      firstHeader ? 'first-header' : undefined,
    ]
      .filter(Boolean)
      .join(' ')}
  >
    {title ? (
      <Box
        sx={{
          '& > span:not(:first-of-type)': {
            marginLeft: '3px',
          },
        }}
      >
        {title.split(' ').map((word, index) => (
          <span key={index} style={{ fontWeight: 600 }}>
            {word}
          </span>
        ))}
      </Box>
    ) : null}
    {children}
  </Box>
);
