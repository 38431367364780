import {
  IonActionSheet,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useEditProductContext } from './ProductContext/useEditProductContext';
import { toCurrency } from './pricingUtils';

const defaultPricingOptions = [
  [15, '15 minutes'],
  [30, '30 minutes'],
  [45, '45 minutes'],
  [60, '1 hour'],
  [90, '1 hour, 30 mintues'],
  [120, '2 hours'],
  [150, '2.5 hours'],
  [180, '3 hours'],
] as const;

export const lesssonPricingSlug = '-minute-lesson';

export interface LessonPricingItem {
  duration: number;
  price: number;
  isDefault?: boolean;
}

interface LessonPricingProps {
  pricingId: string;
}

/**
 * TODO: refactor to not use router vestiges
 */
const LessonPricing = ({ pricingId }: LessonPricingProps) => {
  const routerDuration = Number(pricingId.split(lesssonPricingSlug)[0]);
  const [nextRouterDuration, setNextRouterDuration] = useState<
    number | undefined
  >();

  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const { lessonPricing, setLessonPricing } = useEditProductContext();

  useEffect(() => {
    if (routerDuration === nextRouterDuration) {
      setNextRouterDuration(undefined);
    }
  }, [routerDuration, nextRouterDuration]);

  const lessonPricingItem = useMemo(
    () =>
      lessonPricing.find(
        ({ duration }) =>
          duration === routerDuration || duration === nextRouterDuration
      ),
    [lessonPricing, routerDuration, nextRouterDuration]
  );

  const invalidDurations = new Set(
    lessonPricing.map(({ duration }) => duration)
  );

  const [hasCustomPricing, setHasCustomPricing] = useState(
    !defaultPricingOptions.some(([value]) => value === routerDuration)
  );

  const setLessonPricingItem = useCallback(
    (input: LessonPricingItem) => {
      const newLessonPricing = lessonPricing.map((item) =>
        item.duration === routerDuration ? input : item
      );

      if (input.duration !== routerDuration) {
        setNextRouterDuration(input.duration);
      }

      setLessonPricing(newLessonPricing);
    },
    [lessonPricing, routerDuration, setLessonPricing]
  );

  const lowestDuration = useMemo(
    () => Math.min(...lessonPricing.map(({ duration }) => duration)),
    [lessonPricing]
  );

  const ref = useRef<HTMLIonContentElement | null>(null);

  if (!lessonPricingItem) {
    return null;
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonTitle>Lesson Pricing</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light" ref={ref}>
        <IonList inset>
          <IonItem>
            <IonSelect
              label="Duration"
              interface="popover"
              value={hasCustomPricing ? 'custom' : lessonPricingItem.duration}
              onIonChange={(event) => {
                const next = event.detail.value;

                if (next === 'custom') {
                  setHasCustomPricing(true);
                } else {
                  if (hasCustomPricing) {
                    setHasCustomPricing(false);
                  }

                  setLessonPricingItem({
                    ...lessonPricingItem,
                    duration: Number(next),
                  });
                }
              }}
            >
              {([...defaultPricingOptions, ['custom', 'Custom']] as const).map(
                ([value, label]) => (
                  <IonSelectOption
                    key={value}
                    value={value}
                    disabled={
                      value !== 'custom' &&
                      value !== lessonPricingItem.duration &&
                      invalidDurations.has(value)
                    }
                  >
                    {label}
                  </IonSelectOption>
                )
              )}
            </IonSelect>
          </IonItem>
          {hasCustomPricing ? (
            <IonItem>
              <IonInput
                label="Custom Duration"
                type="number"
                clearInput
                placeholder="e.g., 60"
                value={lessonPricingItem.duration}
                onIonChange={(event) =>
                  setLessonPricingItem({
                    ...lessonPricingItem,
                    duration: Number(event.detail.value),
                  })
                }
              />
            </IonItem>
          ) : null}
          <IonItem>
            <IonInput
              style={{ textAlign: 'right', marginRight: 5 }}
              label="Lesson Price"
              type="number"
              value={toCurrency(lessonPricingItem.price)}
              onIonChange={(event) =>
                setLessonPricingItem({
                  ...lessonPricingItem,
                  price: Number(event.detail.value),
                })
              }
            ></IonInput>
            USD
          </IonItem>
          <IonItem>
            <IonToggle
              checked={lessonPricingItem.isDefault}
              disabled={lessonPricing.length === 1}
              onIonChange={(event) => {
                if (event.detail.checked) {
                  setLessonPricing(
                    lessonPricing.map((item) => ({
                      ...item,
                      isDefault: item.duration === routerDuration,
                    }))
                  );
                } else {
                  if (lowestDuration === lessonPricingItem.duration) {
                    const [first, second, ...rest] = lessonPricing.sort(
                      (a, b) => a.duration - b.duration
                    );

                    if (!first || !second) {
                      return;
                    }

                    setLessonPricing([
                      { ...first, isDefault: false },
                      { ...second, isDefault: true },
                      ...rest,
                    ]);
                  } else {
                    setLessonPricing(
                      lessonPricing.map((item) => ({
                        ...item,
                        isDefault: item.duration === lowestDuration,
                      }))
                    );
                  }
                }
              }}
              color="success"
            >
              Default Lesson Duration
            </IonToggle>
          </IonItem>
        </IonList>
        <IonList inset>
          <IonItem
            style={{ alignText: 'center' }}
            onClick={() => setIsActionSheetOpen(true)}
          >
            <IonText
              slot="start"
              color="danger"
              style={{ width: '100%', textAlign: 'center' }}
            >
              Delete
            </IonText>
          </IonItem>
        </IonList>
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header="Are you sure you want to delete this lesson duration?"
          buttons={[
            {
              text: 'Delete',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: () => {
                setLessonPricing(
                  lessonPricing.filter(
                    (item) => item.duration !== lowestDuration
                  )
                );

                ref.current?.closest('ion-nav')?.pop();
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIsActionSheetOpen(false)}
        />
      </IonContent>
    </>
  );
};

export default LessonPricing;
