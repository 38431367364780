import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { contactsCollection } from './model';
import { parseContact } from './parseContact';

export const useContactQuery = (contactId: string | undefined) => {
  const [contactSnapshot, loading, error] = useDocument(
    contactId ? doc(contactsCollection, contactId) : null
  );

  const contact = useMemo(
    () => (contactSnapshot ? parseContact(contactSnapshot) : undefined),
    [contactSnapshot]
  );

  return [contact, loading, error] as const;
};
