import {
  IonActionSheet,
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import {
  ForwardToInboxOutlined,
  InfoOutlined,
  MailOutlineOutlined,
} from '@mui/icons-material';
import { Alert, Box, Button, Typography } from '@mui/joy';
import { useState } from 'react';

import { ModalListContainer } from '@components/Modal/ModalListContainer';

import { useUserAccess } from './useUserAccess';

const credentialTypeMap: Record<string, string> = {
  'google.com': 'Google',
  password: 'Password',
};

export const ManageAccessOverview = () => {
  const {
    onDone,
    page,
    removeInvite,
    createInvite,
    lastSentAgo,
    invite,
    inviteExpired,
    contact,
    lastLoginAgo,
  } = useUserAccess();
  const [isRemoveAccessOpen, setIsRemoveAccessOpen] = useState(false);
  const [isPasswordAlertOpen, setIsPasswordAlertOpen] = useState(false);

  if (!page.current) {
    return null;
  }

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton
              color="dark"
              onClick={onDone}
              data-testid="close-modal-button"
            >
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer sx={{ gap: '10px' }}>
          <Box>
            <Typography level="h4" data-testid="invite-status-value">
              {invite ? (
                <>Invite {inviteExpired ? 'Expired' : 'Sent'}</>
              ) : (
                'User Access'
              )}
            </Typography>
          </Box>

          <Alert
            sx={{ textAlign: 'left' }}
            color="neutral"
            startDecorator={<InfoOutlined />}
          >
            {invite ? (
              <>
                {inviteExpired ? (
                  <>
                    The invite has expired and the user has not accepted the
                    invite.
                  </>
                ) : (
                  <>
                    The user has been invited to the organization. They will
                    receive an email with a link to accept the invite.
                  </>
                )}
              </>
            ) : (
              <>
                The user has access to the organization. You can remove access
                or reset their password.
              </>
            )}
          </Alert>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              textAlign: 'left',
            }}
          >
            <Box
              sx={{
                margin: '0 16px',
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',

                '@media (min-width: 747px)': {
                  margin: 0,
                },
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={{
                    width: '40px',
                    display: 'flex',
                  }}
                >
                  {invite ? (
                    <ForwardToInboxOutlined />
                  ) : (
                    <MailOutlineOutlined />
                  )}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <Typography>
                        {(invite ? invite.inviteEmail : contact.userEmail) ||
                          'Unknown'}
                      </Typography>

                      {invite ? (
                        <>
                          <Typography>
                            {lastSentAgo ? `Sent ${lastSentAgo}` : undefined}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography>
                            {lastLoginAgo
                              ? `Last login ${lastLoginAgo}`
                              : 'Has not logged in'}
                          </Typography>

                          <Typography>
                            Credential Type:{' '}
                            {credentialTypeMap[
                              contact.userCredentialType || ''
                            ] || 'Unknown'}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ marginTop: '10px', marginLeft: '45px' }}>
                {invite ? (
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={createInvite}
                    sx={{
                      '&:hover': {
                        color: 'gray',
                      },
                    }}
                  >
                    Resend Invite
                  </Button>
                ) : (
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={() => setIsPasswordAlertOpen(true)}
                    sx={{
                      '&:hover': {
                        color: 'gray',
                      },
                    }}
                  >
                    Reset Password
                  </Button>
                )}
              </Box>
            </Box>

            {/* TODO: use an auto expanding height */}
            <Box
              sx={{
                padding: '0 30px 0 30px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                color="danger"
                variant="plain"
                onClick={() => setIsRemoveAccessOpen(true)}
              >
                Remove Access
              </Button>
            </Box>
          </Box>
        </ModalListContainer>
      </IonContent>

      <IonActionSheet
        isOpen={isRemoveAccessOpen}
        header="Do you want to remove access for this user?"
        buttons={[
          {
            text: 'Remove Access',
            role: 'destructive',
            data: {
              action: 'delete',
            },
            handler: async () => {
              await removeInvite();
              onDone?.();
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'cancel',
            },
          },
        ]}
        onDidDismiss={() => setIsRemoveAccessOpen(false)}
      />

      <IonAlert
        isOpen={isPasswordAlertOpen}
        header="Password Reset"
        subHeader="Users can complete the forgot password flow to reset their password."
        message=" You can also remove access and send another invite which allows you to change the email as well."
        buttons={['Close']}
        onDidDismiss={() => setIsPasswordAlertOpen(false)}
      />
    </>
  );
};
