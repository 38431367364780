import { Typography, TypographyProps } from '@mui/joy';

export const ListHelperText = ({
  children,
  sx,
  fontSize,
  color,
  inset,
  ...props
}: TypographyProps & { inset?: boolean }) => (
  <Typography
    sx={
      inset
        ? { padding: '0 36px', marginBottom: 4, marginTop: -1, ...sx }
        : { padding: '0 26px', marginBottom: 3, marginTop: 0, ...sx }
    }
    color={color ?? 'neutral'}
    fontSize={fontSize ?? 12}
    {...props}
  >
    {children}
  </Typography>
);
