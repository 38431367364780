import Box, { BoxProps } from '@mui/joy/Box';
import { forwardRef } from 'react';

export const HorizontalList = forwardRef(({ sx, ...props }: BoxProps, ref) => (
  <Box
    ref={ref}
    sx={{
      maxWidth: '100%',
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      display: 'grid',
      gridTemplateRows: 'auto auto auto',
      gridAutoFlow: 'column',
      gap: 1,
      ...sx,
    }}
    {...props}
  />
));
