import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import styled from '@emotion/styled';
import { IonContent, IonPage } from '@ionic/react';
import { Button, Typography } from '@mui/joy';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import ProfileAvatar from '@components/ProfileAvatar';
import { ContentContainer } from '@features/Contacts/ContentContainer';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';
import Settings from '@features/Settings';

import { ContactTagCountBlock } from './ContactTagCountBlock';
import { NextLessonBlock } from './NextLessonBlock';
import { ValidateEmailBlock } from './ValidateEmailBlock';
import { XMonthsRevenueBlock } from './XMonthsRevenueBlock';

const checkPermissions = async () => {
  const result = await FirebaseMessaging.checkPermissions();
  return result.receive;
};

const requestPermissions = async () => {
  const result = await FirebaseMessaging.requestPermissions();
  return result.receive;
};

const getToken = async () => {
  const result = await FirebaseMessaging.getToken();
  return result.token;
};

// const deleteToken = async () => {
//   await FirebaseMessaging.deleteToken();
// };

const web = true; // temp
const getDeliveredNotifications = async () => {
  if (web) {
    return;
  }
  const result = await FirebaseMessaging.getDeliveredNotifications();
  return result.notifications;
};

// const removeDeliveredNotifications = async () => {
//   await FirebaseMessaging.removeDeliveredNotifications({
//     notifications: [{ id: '798dfhliblqew89pzads' }],
//   });
// };

// const removeAllDeliveredNotifications = async () => {
//   await FirebaseMessaging.removeAllDeliveredNotifications();
// };

// const subscribeToTopic = async () => {
//   await FirebaseMessaging.subscribeToTopic({ topic: 'news' });
// };

// const unsubscribeFromTopic = async () => {
//   await FirebaseMessaging.unsubscribeFromTopic({ topic: 'news' });
// };

const addTokenReceivedListener = async () => {
  await FirebaseMessaging.addListener('tokenReceived', (event) => {
    console.log('tokenReceived', { event });
  });
};

const addNotificationReceivedListener = async () => {
  await FirebaseMessaging.addListener('notificationReceived', (event) => {
    console.log('notificationReceived', { event });
  });
};

const addNotificationActionPerformedListener = async () => {
  await FirebaseMessaging.addListener(
    'notificationActionPerformed',
    (event) => {
      console.log('notificationActionPerformed', { event });
    }
  );
};

// const removeAllListeners = async () => {
//   await FirebaseMessaging.removeAllListeners();
// };

const AvatarArea = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: 0,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const Home = () => {
  const permissions = useOrganizationPermissions();
  const schedulingV1_1Enabled = useFeatureFlag('schedulingV1_1Enabled');
  const crmFunnelsEnabled = useFeatureFlag('crmFunnelsEnabled');

  const tabContainerRef = useRef<HTMLElement | null>(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [initialized, setIinitialized] = useState(false);
  useEffect(() => {
    if (initialized) {
      return;
    }

    (async () => {
      const initialResult = await checkPermissions();
      console.log({ initialResult });

      if (initialResult === 'denied') {
        setIinitialized(true);

        return;
      }

      await addTokenReceivedListener();
      await addNotificationReceivedListener();
      await addNotificationActionPerformedListener();
      const deliveredNotifications = await getDeliveredNotifications();
      console.log({ deliveredNotifications });

      if (initialResult === 'granted') {
        const token = await getToken();

        console.log('The token 293842333-', { token });

        setIinitialized(true);

        return;
      }

      const result = await requestPermissions();
      console.log({ result });
      setIinitialized(true);
    })();
  }, [initialized]);

  return (
    <IonPage className="tab-container" ref={tabContainerRef}>
      <IonContent fullscreen>
        <ContentContainer>
          <Box
            sx={{
              '@media (min-width: 747px)': {
                padding: '0 40px',
              },
            }}
          >
            <AvatarArea>
              <Button
                variant="plain"
                data-testid="settings-button"
                onClick={() => setIsSettingsOpen(true)}
              >
                <ProfileAvatar size="sm" sx={{ width: 36, height: 36 }} />
              </Button>
            </AvatarArea>
            <Box
              sx={{
                padding: '140px',
                paddingTop: '40px',
                paddingLeft: '20px',
              }}
            >
              <Typography level="h1" sx={{ maxWidth: '350px' }}>
                &ldquo;Music is enough for a lifetime, but a lifetime is not
                enough for <span style={{ fontWeight: 800 }}>music</span>
                .&rdquo;
              </Typography>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: 13,
                  paddingTop: '5px',
                }}
              >
                — Sergei Rachmaninoff
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                bottom: '55px',
                left: 0,
                textTransform: 'uppercase',
                fontSize: 13,

                '@media (min-width: 747px)': {
                  left: '40px',
                },
              }}
            >
              <ValidateEmailBlock />
              {permissions?.isTeacher ? (
                <>
                  {schedulingV1_1Enabled ? (
                    <>
                      <NextLessonBlock />
                      <XMonthsRevenueBlock />
                    </>
                  ) : null}

                  <ContactTagCountBlock tag="student" title="Active Students" />
                  {crmFunnelsEnabled ? (
                    <ContactTagCountBlock
                      tag="potentialStudent"
                      title="Potential Students"
                      color="#ffd3ff"
                    />
                  ) : null}
                </>
              ) : null}
            </Box>
            <Settings
              presentingPage={tabContainerRef}
              isOpen={isSettingsOpen}
              setIsOpen={setIsSettingsOpen}
            />
          </Box>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
