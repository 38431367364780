import { useContext } from 'react';

import { MediaProviderContext } from '.';

export const useMedia = () => {
  const context = useContext(MediaProviderContext);

  if (!context) {
    throw new Error(
      'useMedia has to be used within a <MediaProviderProvider />'
    );
  }

  return context;
};
