import { createContext } from 'react';

import useContactProviderValue, {
  UseContactProviderQueryReturn,
} from './useContactProviderValue';

export const ContactProviderContext = createContext<
  UseContactProviderQueryReturn | undefined
>(undefined);

interface ContactProviderProps {
  children?:
    | React.ReactNode
    | ((value: UseContactProviderQueryReturn) => React.ReactNode);
  contactId?: string;
}

export const ContactProvider = ({
  children,
  contactId,
}: ContactProviderProps) => {
  const value = useContactProviderValue({ contactId });

  return (
    <ContactProviderContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ContactProviderContext.Provider>
  );
};
