import { ParsedContact, RawContact } from '@shared/models/contacts';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { contactsCollection } from '@models/contacts/model';
import { useFirebaseAuthState } from '@utils/firebase';
import { serverTimestamp } from '@utils/serverTimestamp';

import { getUserLoginFields } from './getUserLoginFields';

interface UseLoginEventProps {
  userContact: ParsedContact | undefined;
}

export const useLoginEvent = ({ userContact }: UseLoginEventProps) => {
  const [authUser] = useFirebaseAuthState();
  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(() => {
    if (userContact?.updatedBy === 'system') {
      return;
    }

    if (loggingIn && userContact) {
      const nextContact: Partial<RawContact> = {
        updatedAt: serverTimestamp(),
        updatedBy: userContact.userId,
        ...getUserLoginFields(authUser),
      };

      updateDoc(doc(contactsCollection, userContact.id), nextContact);
      setLoggingIn(false);
    }
  }, [loggingIn, userContact, authUser]);

  return {
    onLoginStart: () => setLoggingIn(true),
  };
};
