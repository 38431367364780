import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { useEditProductContext } from '../Products/AddProduct/ProductContext/useEditProductContext';

interface EditAreaProps {
  isEditing: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  children?: React.ReactNode;
}

const AdminEditHeader = ({
  isEditing,
  setIsEditing,
  children,
}: EditAreaProps) => {
  const organizationSlug = useOrganizationSlug();
  const { scheduleSave } = useEditProductContext();

  return isEditing ? (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            color="dark"
            onClick={() => {
              setIsEditing?.(false);
            }}
          >
            Cancel
          </IonButton>
        </IonButtons>
        <IonTitle>{children}</IonTitle>
        <IonButtons slot="end">
          <IonButton
            color="dark"
            onClick={() => {
              setIsEditing?.(false);
              scheduleSave?.();
            }}
          >
            Done
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  ) : (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            defaultHref={`/${organizationSlug}/admin`}
            text="Admin"
            color="dark"
          />
        </IonButtons>
        <IonTitle>{children}</IonTitle>
        <IonButtons slot="end">
          <IonButton
            color="dark"
            onClick={() => setIsEditing?.(true)}
            routerDirection="none"
          >
            Edit
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default AdminEditHeader;
