import { Box, Button } from '@mui/joy';
import { Typography } from '@mui/material';
import { useState } from 'react';

import { useUserData } from '@components/UserData/useUserData';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { sendEmailVerification } from '@utils/firebase';

import { HomePageBlock } from './HomePageBlock';

interface ValidateEmailBlockProps {
  featureName?: string;
}

export const ValidateEmailBlock = ({
  featureName,
}: ValidateEmailBlockProps) => {
  const [emailResent, setEmailResent] = useState(false);
  const organizationSlug = useOrganizationSlug();
  const { emailVerified, watchEmailVerificationClosely } = useUserData();

  if (emailVerified) {
    return null;
  }

  return (
    <HomePageBlock
      title={`Unlock ${featureName || 'all features'}`}
      color="#d1d3ff"
    >
      <Box>
        <Typography>
          Confirming your email will unlock{' '}
          {featureName || 'all features within the app'}.
        </Typography>

        {emailResent ? (
          <Typography>Email resent ✅</Typography>
        ) : (
          <Button
            data-testid="resend-email-verification"
            sx={{ marginTop: '10px' }}
            onClick={() => {
              sendEmailVerification({
                url: `${window.location.origin}/${organizationSlug}/home`,
              });
              setEmailResent(true);
              watchEmailVerificationClosely();
            }}
          >
            Resend email
          </Button>
        )}
      </Box>
    </HomePageBlock>
  );
};
