import { Box, Typography } from '@mui/joy';
import { EventType } from '@shared/models/events';

import { IonicLink } from '@components/IonicLink';
import {
  eventTypeLightColorMap,
  eventTypeSolidColorMap,
} from '@models/events/model';

import { sidePadding } from '.';
import { HorizontalListItem } from './HorizontalListItem';

interface RelatedEventProps {
  to?: string;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  left?: React.ReactNode;
  eventType?: EventType;
}

export const RelatedEventContainer = ({
  to,
  primary,
  secondary,
  left,
  eventType = 'product',
}: RelatedEventProps) => {
  return (
    <HorizontalListItem sx={{ paddingLeft: `${sidePadding}px` }}>
      <IonicLink unstyled to={to || ''}>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid lightgray',
            paddingBottom: '16px',
          }}
        >
          <Box
            sx={{
              color: eventTypeSolidColorMap[eventType],
              background: eventTypeLightColorMap[eventType],
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              width: 50,
              height: 50,
              borderRadius: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1px',
            }}
          >
            {left}
          </Box>

          <Box sx={{ flex: 1 }}>
            {primary ? (
              <Typography fontSize={14} sx={{ fontWeight: 600 }}>
                {primary}
              </Typography>
            ) : null}
            {secondary ? (
              <Typography fontSize={12}>{secondary}</Typography>
            ) : null}
          </Box>
        </Box>
      </IonicLink>
    </HorizontalListItem>
  );
};
