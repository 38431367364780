import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos, Search } from '@mui/icons-material';
import { Box, Button, Input, Typography } from '@mui/joy';
import { useState } from 'react';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';
import { InstrumentsSelection } from '@features/Contacts/ContactDetail/ViewContact/InstrumentsModal/InstrumentsSelection';

import { useAppIntro } from './useAppIntro';

export const Page3 = () => {
  const [searchValue, setSearchValue] = useState('');
  const { onSubmit, selectedInstruments, setSelectedInstruments } =
    useAppIntro();

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer sx={{ gap: '10px' }}>
          <ModalTypographyContainer>
            <Typography level="h4"> My Instruments</Typography>
            <Typography>
              Select the instruments that you teach at your studio.
            </Typography>
          </ModalTypographyContainer>

          <Box sx={{ paddingTop: '30px' }}>
            <Input
              variant="search"
              placeholder="Find an instrument…"
              startDecorator={<Search />}
              value={searchValue}
              onChange={(event) =>
                setSearchValue(event.target.value.toLowerCase())
              }
            />
          </Box>

          <Box
            className="scrollable"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <InstrumentsSelection
              selectedInstruments={selectedInstruments}
              setSelectedInstruments={setSelectedInstruments}
              searchValue={searchValue}
            />
          </Box>

          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            disabled={!selectedInstruments.length}
            onClick={onSubmit}
            data-testid="page-3-submit-button"
          >
            Done
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
