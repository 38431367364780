import { CheckRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import { SxProps, keyframes } from '@mui/material';
import { InvoiceStatus } from '@shared/models/invoices';

import { toCurrency } from '@features/Admin/Products/AddProduct/pricingUtils';
import { themeColors } from '@theme/theme';

const pulse1 = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
`;

const pulse2 = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  1% {
    transform: scale(2);
    opacity: 1;
  }
  100% {
    transform: scale(2.8);
    opacity: 0;
  }
`;

const invoiceStatusMap: Record<InvoiceStatus, string> = {
  draft: 'Draft',
  sent: 'Sent',
  viewed: 'Viewed',
  paid: 'Paid',
  void: 'Voided',
};

interface InvoiceHeaderProps {
  amount: number;
  invoiceStatus: InvoiceStatus;
  size?: 'small' | 'large';
  sx?: SxProps;
}

export const InvoiceHeader = ({
  amount,
  invoiceStatus,
  size = 'large',
  sx,
}: InvoiceHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: size === 'small' ? 1 : 1.5,
      alignItems: 'center',
      ...sx,
    }}
  >
    <Box
      sx={{
        borderRadius: '50%',
        height: size === 'small' ? 40 : 60,
        width: size === 'small' ? 40 : 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          background: themeColors.darkGreen,
          borderRadius: '50%',
          height: size === 'small' ? 25 : 30,
          width: size === 'small' ? 25 : 30,
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            animation: `${pulse1} 0.5s ease forwards`,
            animationDelay: '0.3s',
            background: themeColors.lightGreen,
            zIndex: -1,
          },

          '&:after': {
            content: '""',
            position: 'absolute',
            borderRadius: '50%',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            animation: `${pulse2} 1s ease`,
            animationDelay: '0.6s',
            background: themeColors.lightGreen,
            zIndex: -1,
          },
        }}
      >
        <CheckRounded sx={{ color: 'white' }} />
      </Box>
    </Box>
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: size === 'small' ? 0 : 1,
      }}
    >
      <Typography fontSize={size === 'small' ? 16 : undefined}>
        {invoiceStatusMap[invoiceStatus]}
      </Typography>
      <Typography
        level={size === 'small' ? 'body-md' : 'h3'}
        sx={size === 'small' ? { fontWeight: 'bold' } : undefined}
      >
        {toCurrency(amount)} USD
      </Typography>
    </Box>
  </Box>
);
