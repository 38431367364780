import {
  Actions,
  MutationPayload,
  RawMutationDiscriminator,
} from '@shared/models/mutations';
import {
  CollectionReference,
  collection,
  doc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import uuid4 from 'uuid4';

import { firestore } from '@utils/firebase';
import { serverTimestamp } from '@utils/serverTimestamp';

const orgIdNotRequired = new Set<Actions>([
  'createOrganization',
  'acceptInvite',
]);

interface GetRunMutationProps<T extends Actions> {
  action: T;
  organizationId?: string;
  userId?: string;
}

export const getRunMutation =
  <T extends Actions>({
    action,
    userId: baseUserId,
    organizationId,
  }: GetRunMutationProps<T>) =>
  async (
    payload: MutationPayload<T>,
    userIdOverride?: string
  ): Promise<RawMutationDiscriminator<T>> => {
    const userId = userIdOverride || baseUserId;

    if (!userId) {
      throw Error("Can't run mutation without a userId");
    }

    if (!organizationId && !orgIdNotRequired.has(action)) {
      throw Error("Can't run mutation without organizationId");
    }

    const mutationsCollection = collection(
      firestore,
      'mutations'
    ) as CollectionReference<RawMutationDiscriminator<T>>;

    const mutationId = uuid4();
    const mutationDocument = doc(mutationsCollection, mutationId);

    await (async () => {
      try {
        return await setDoc(mutationDocument, {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          createdBy: userId,
          updatedBy: userId,

          action,
          pending: true,
          payload,
          ...(organizationId && { organizationId }),
          userId,
          manualProcessing: false,
        });
      } catch (error) {
        console.log('error', { error, stringError: `${error}` });
      }
    })();

    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(mutationDocument, (document) => {
        const data = document.data();

        if (data?.pending === false) {
          unsubscribe();

          if (data.success) {
            resolve(data);
          } else {
            reject(data);
          }
        }
      });
    });
  };
