import { createContext } from 'react';

import {
  UseAppIntroProviderReturn,
  useAppIntroProviderValue,
} from './useAppIntroProviderValue';

export const AppIntroProviderContext = createContext<
  UseAppIntroProviderReturn | undefined
>(undefined);

interface AppIntroProviderProviderProps {
  children?: React.ReactNode;
  onDone: () => void;
}

export const AppIntroProvider = ({
  children,
  onDone,
}: AppIntroProviderProviderProps) => (
  <AppIntroProviderContext.Provider
    value={useAppIntroProviderValue({ onDone })}
  >
    {children}
  </AppIntroProviderContext.Provider>
);
