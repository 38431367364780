import { AvailableFeatureFlags } from '@shared/models/featureFlags';
import { FirestoreError } from 'firebase/firestore';
import { useMemo } from 'react';

import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { useFeatureFlagsQuery } from '@models/featureFlags/useFeatureFlagsQuery';

import { featureFlagsOff } from './featureFlagsOff';

export const useFeatureFlagsValue = (): [
  AvailableFeatureFlags,
  boolean,
  FirestoreError | undefined
] => {
  const organizationId = useOrganizationId();
  const user = useUser();
  const userId = user?.userId;

  const [globalFeatureFlags, globalLoading, globalError] = useFeatureFlagsQuery(
    {
      type: 'global',
    }
  );
  const [organizationFeatureFlags, organizationLoading, organizationError] =
    useFeatureFlagsQuery({
      type: 'organization',
      organizationId,
      skip: !organizationId,
    });
  const [userFeatureFlags, userLoading, userError] = useFeatureFlagsQuery({
    type: 'user',
    userId,
    organizationId,
    skip: !userId || !organizationId,
  });

  const loading = globalLoading || organizationLoading || userLoading;

  const featureFlags: AvailableFeatureFlags = useMemo(() => {
    if (!globalFeatureFlags?.[0]?.flags) {
      return featureFlagsOff;
    }

    const baseFlags = {
      ...globalFeatureFlags[0].flags,
    } as AvailableFeatureFlags;

    [organizationFeatureFlags, userFeatureFlags].forEach((featureFlagGroup) => {
      Object.entries(featureFlagGroup?.[0]?.flags ?? {}).forEach(
        ([key, value]) => {
          if (value !== undefined) {
            baseFlags[key as keyof AvailableFeatureFlags] = value;
          }
        }
      );
    });

    return baseFlags;
  }, [globalFeatureFlags, organizationFeatureFlags, userFeatureFlags]);

  return [featureFlags, loading, globalError || organizationError || userError];
};

export type UseFeatureFlagsReturn = ReturnType<typeof useFeatureFlagsValue>;
