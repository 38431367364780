import {
  AttachFileOutlined,
  DragIndicatorOutlined,
  LabelOutlined,
  LoopOutlined,
} from '@mui/icons-material';
import { Box, Checkbox, IconButton, Tooltip, checkboxClasses } from '@mui/joy';
import { ClickAwayListener } from '@mui/material';
import { forwardRef, useRef, useState } from 'react';

import { useLesson } from '../LessonProvider/useLesson';
import {
  FlattenedAssignment,
  initAssignment,
} from '../LessonProvider/useLessonProviderValue';
import { AssignmentMenu } from './AssignmentMenu';
import { TypographyInput } from './TypographyInput';
import { usePrioritizedInput } from './usePrioritizedInput';

const animationDuration = 0.3;

interface AssignmentItemProps {
  assignment: FlattenedAssignment;
  handleProps?: any;
  children?: React.ReactNode;
}

export const AssignmentItem = forwardRef<HTMLDivElement, AssignmentItemProps>(
  ({ assignment, handleProps, children }, ref) => {
    const {
      setAssignment,
      setAssignmentModalOpen,
      setNewAssignment,
      insertAfterAndFocus,
      ensurePlaceholderAfter,
    } = useLesson();

    const [title, setTitle] = usePrioritizedInput({
      assignmentId: assignment.id,
      assignmentKey: 'title',
    });
    const [description, setDescription] =
      usePrioritizedInput<FlattenedAssignment>({
        assignmentId: assignment.id,
        assignmentKey: 'description',
      });

    const inputRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [backupTitle, setBackupTitle] = useState('');

    const maxHeight = 111;
    const minHeight = description ? 54 : 38;

    return (
      <Box sx={{ width: '100%' }}>
        <ClickAwayListener
          onClickAway={() => {
            if (!isFocused) {
              return;
            }

            setIsFocused(false);
            ensurePlaceholderAfter(assignment.id);
          }}
        >
          <Box
            sx={(theme) => ({
              padding: '10px 0',
              position: 'relative',
              width: '100%',

              '& .drag-indicator': {
                cursor: 'grab',
                opacity: 0,
                color: theme.palette.neutral[400],
                transition: 'opacity 0.3s',
                position: 'absolute',
                top: '18px',
                left: '-28px',
                margin: 'auto',
                outline: 'none',
                '&:active': {
                  cursor: 'grabbing',
                },
              },
              '&:hover .drag-indicator': {
                opacity: assignment.isPlaceholder ? 0 : 1,
              },
            })}
            ref={ref}
          >
            {assignment.isPlaceholder ? null : (
              <DragIndicatorOutlined
                className="drag-indicator"
                {...handleProps}
              />
            )}
            <Box
              data-assignment-id={assignment.id}
              sx={{
                height: isFocused ? maxHeight : minHeight,
                overflow: 'hidden',
                transition: `${animationDuration}s height ease-in-out`,
              }}
            >
              <Box
                sx={{
                  padding: '10px 0',
                  display: 'flex',
                  gap: '15px',
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    disabled={assignment.isPlaceholder}
                    sx={
                      assignment.isPlaceholder
                        ? {
                            [`& .${checkboxClasses.checkbox}`]: {
                              borderStyle: 'dashed',
                            },
                          }
                        : undefined
                    }
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                    }}
                  >
                    {assignment.emoji ? (
                      <Box sx={{ fontSize: '28px' }}>{assignment.emoji}</Box>
                    ) : null}
                    <Box sx={{ flex: 1 }}>
                      <TypographyInput
                        level="title-md"
                        value={title}
                        className="assignment-title"
                        onKeyDown={(event) => {
                          if (event.code === 'Escape') {
                            event.preventDefault();
                            inputRef.current?.querySelector('input')?.blur();
                            setIsFocused(false);

                            return;
                          }

                          if (event.code === 'Enter' && assignment.title) {
                            event.preventDefault();
                            setIsFocused(false);
                            insertAfterAndFocus(assignment.id);
                          }
                        }}
                        onChange={(event) => setTitle(event.target.value)}
                        onFocus={() => {
                          setBackupTitle(assignment.title);
                          setIsFocused(true);
                        }}
                        onBlur={() => {
                          if (!assignment.title) {
                            setAssignment({
                              ...assignment,
                              title: backupTitle,
                            });
                          }
                        }}
                        ref={inputRef}
                      />
                      {isFocused || description ? (
                        <TypographyInput
                          level="body-sm"
                          value={description}
                          placeholder="Notes"
                          onChange={(event) =>
                            setDescription(event.target.value)
                          }
                          sx={{
                            color: 'neutral.600',
                            fontSize: '14px',
                            '&:hover': {
                              color: 'neutral.600',
                            },
                          }}
                          onFocus={() => setIsFocused(true)}
                        />
                      ) : null}
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          marginTop: '10px',
                        }}
                      >
                        <Tooltip variant="plain" title="Add Labels">
                          <IconButton variant="plain" color="neutral">
                            <LabelOutlined />
                          </IconButton>
                        </Tooltip>

                        <Tooltip variant="plain" title="Add Attachments">
                          <IconButton
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                              // TODO: rename this to createAttachment
                              setNewAssignment(initAssignment());
                              setAssignmentModalOpen(true);
                            }}
                          >
                            <AttachFileOutlined
                              sx={{ transform: 'rotate(30deg)' }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip variant="plain" title="Repeat">
                          <IconButton
                            variant="plain"
                            color="neutral"
                            onClick={() => {
                              // TODO
                            }}
                          >
                            <LoopOutlined />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {assignment.isPlaceholder && !isFocused ? null : (
                  <Box>
                    {/* <IconButton
                onClick={() => setPreviewOpen(true)}
                disabled={assignment.isPlaceholder}
              >
                <PlayCircleOutline />
              </IconButton> */}
                    <AssignmentMenu assignmentId={assignment.id} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
        {children}
      </Box>
    );
  }
);
