import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Button, Input } from '@mui/joy';
import { Typography } from '@mui/material';
import { RawUser } from '@shared/models/users';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSearchParam, useWindowSize } from 'react-use';

import { IonicLink } from '@components/IonicLink';
import { useUserData } from '@components/UserData/useUserData';
import {
  auth,
  firestore,
  sendPasswordResetEmail,
  signInWithEmail,
  signInWithGoogle,
  signUpWithEmail,
} from '@utils/firebase';

import { GoogleIcon } from './GoogleIcon';

const errorMapping = {
  'auth/email-already-in-use': 'Email already in use',
  'auth/wrong-password': 'Invalid login credentials',
  'auth/user-not-found': 'Invalid login credentials',
  'auth/invalid-login-credentials': 'Invalid login credentials',
  'auth/operation-not-allowed': 'Email/password accounts are not enabled',
  'auth/weak-password': 'Password is not strong enough',
  'auth/invalid-email': 'Invalid email',
  'auth/account-exists-with-different-credential': 'Account already exists',
  'auth/invalid-credential': 'Invalid credential',
  'auth/user-disabled': 'User disabled',
  'auth/invalid-verification-code': 'Invalid verification code',
  'auth/invalid-verification-id': 'Invalid verification ID',
} as const;

interface SignInOrUpProps {
  variant: 'login' | 'signUp';
}

const inputHeight = '55px';

const LoginOrSignUp = ({ variant }: SignInOrUpProps) => {
  const code = useSearchParam('invite');
  const userExists = useSearchParam('userExists') === 'true';
  const { setupUser, onLoginStart } = useUserData();
  const signingUp = variant === 'signUp' && !userExists;
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [user, loadingUser] = useAuthState(auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { width } = useWindowSize();
  const loginSignUpVerbiage = emailSent
    ? 'Email sent'
    : forgotPassword
    ? 'Forgot your password?'
    : userExists
    ? 'Almost Done'
    : signingUp
    ? 'Get started'
    : 'Welcome back';

  useEffect(() => {
    if (!userExists || !user || setupUser.loading) {
      return;
    }

    console.log('Automatically setting up user');
    setupUser.run({ userId: user.uid });
  }, [userExists, user, setupUser, setupUser.loading]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const handleErrors = (input: () => Promise<unknown>) => () => {
    (async () => {
      try {
        await input();
      } catch (error) {
        const mappedError = Object.entries(errorMapping).find(([key]) =>
          String(error).includes(key)
        )?.[1];

        if (!mappedError) {
          console.log({ error });
        }

        setErrorMessage(mappedError ?? 'Something went wrong');
      }
    })();
  };

  if (user && !code) {
    return (
      <Box sx={{ padding: 2 }}>
        <p>A user was found, but no code.</p>
        <p>App should redirect in this situation…</p>
      </Box>
    );
  }

  if (loadingUser) {
    return <div>loading…</div>;
  }

  return (
    <IonPage data-auth="signed-out">
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{loginSignUpVerbiage}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Box sx={{ padding: 2 }}>
          <IonHeader collapse="condense">
            <IonToolbar className="inline-toolbar">
              <IonTitle size="large">{loginSignUpVerbiage}</IonTitle>
            </IonToolbar>
          </IonHeader>
          {userExists ? (
            <Typography sx={{ padding: '0 18px', marginBottom: 2 }}>
              Please log in to accept your invite.
            </Typography>
          ) : null}
          {emailSent ? (
            <Typography sx={{ padding: '0 18px', marginBottom: 2 }}>
              Check your email for password reset instructions.
            </Typography>
          ) : null}
          <form
            onSubmit={(event) => {
              event.preventDefault();

              handleErrors(async () => {
                if (emailSent) {
                  setEmailSent(false);
                  setForgotPassword(false);
                  setErrorMessage('');

                  return;
                }

                if (forgotPassword) {
                  await sendPasswordResetEmail(email);
                  setEmailSent(true);

                  return;
                }

                onLoginStart();
                const authenticateWithEmail = signingUp
                  ? signUpWithEmail
                  : signInWithEmail;
                const result = await authenticateWithEmail(email, password);
                const userId = result.user.uid;

                if (signingUp || userExists) {
                  await setupUser.run({ userId, verifyEmail: true });
                }
              })();
            }}
          >
            <Box
              sx={{
                padding: '10px 20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: 500,
                margin: 'auto',
              }}
            >
              {forgotPassword || emailSent ? null : (
                <>
                  <Button
                    variant="outlined"
                    color="neutral"
                    startDecorator={
                      <GoogleIcon size={25} style={{ marginRight: '10px' }} />
                    }
                    onClick={handleErrors(async () => {
                      onLoginStart();

                      const userId = await signInWithGoogle();

                      if (!userId) {
                        throw new Error('No user id');
                      }

                      const userDoc = await getDoc(
                        doc(firestore, 'users', userId)
                      );

                      const existingUser = userDoc.data() as
                        | RawUser
                        | undefined;

                      if (existingUser?.organizations?.length) {
                        return;
                      }

                      await setupUser.run({ userId });
                    })}
                    sx={{ height: inputHeight }}
                  >
                    {signingUp ? 'Sign up' : 'Log in'} with Google
                  </Button>

                  <Box
                    sx={{
                      padding: '10px',
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      '::before': {
                        content: "''",
                        position: 'absolute',
                        top: '50%',
                        left: '0',
                        right: '0',
                        height: '1px',
                        background: '#e0e0e0',
                        zIndex: -1,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        background: 'white',
                        width: 'fit-content',
                        padding: '0 20px',
                      }}
                    >
                      {signingUp ? 'Or sign up with email' : 'or'}
                    </Typography>
                  </Box>
                </>
              )}

              {emailSent ? null : (
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="username email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  autoFocus
                  sx={{ height: inputHeight }}
                />
              )}

              {forgotPassword || emailSent ? null : (
                <Box sx={{ position: 'relative' }}>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Password"
                    autoComplete={
                      signingUp ? 'new-password' : 'current-password'
                    }
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    sx={{
                      height: inputHeight,
                      ...(signingUp
                        ? undefined
                        : {
                            '& input': {
                              paddingRight: `${width >= 400 ? 150 : 85}px`,
                            },
                          }),
                    }}
                  />

                  {signingUp || forgotPassword ? null : (
                    <Button
                      variant="plain"
                      sx={{
                        position: 'absolute',
                        right: '5px',
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                        height: 'fit-content',
                      }}
                      onClick={() => setForgotPassword(true)}
                    >
                      Forgot{width >= 400 ? ' Password' : ''}?
                    </Button>
                  )}
                </Box>
              )}

              <Button sx={{ height: inputHeight }} type="submit">
                {emailSent
                  ? 'Sign In'
                  : forgotPassword
                  ? 'Send me the link'
                  : signingUp
                  ? 'Create account'
                  : 'Log in'}
              </Button>

              {errorMessage ? (
                <Box
                  data-testid="error-message"
                  sx={(theme) => ({
                    color: theme.palette.warning[400],
                    marginBottom: 3,
                  })}
                >
                  {errorMessage}
                </Box>
              ) : null}

              {!userExists ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {forgotPassword && !emailSent ? (
                    <>
                      <Button
                        variant="plain"
                        onClick={() => setForgotPassword(false)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Box>
                        {signingUp
                          ? 'Already have an account?'
                          : 'New to Harmonize?'}
                      </Box>
                      <IonicLink
                        unstyled
                        to={signingUp ? '/login' : '/sign-up'}
                      >
                        {signingUp ? 'Log in' : 'Sign up for free'}
                      </IonicLink>
                    </>
                  )}
                </Box>
              ) : null}
            </Box>
          </form>
        </Box>
      </IonContent>
    </IonPage>
  );
};

export default LoginOrSignUp;
