import { ClientRect } from '@dnd-kit/core';

export const getIsAfter = (
  overRect: Pick<ClientRect, 'top' | 'bottom'> | undefined,
  activeY: number | undefined
) => {
  if (!overRect || activeY === undefined) {
    return undefined;
  }

  const height = overRect.bottom - overRect.top;
  const overCenter = overRect.top + height / 2;
  const activeCenter = 10 + activeY;

  return activeCenter > overCenter;
};
