import { createContext } from 'react';

import {
  UseEditEventProps,
  UseEditEventReturn,
  useEditEvent,
} from '../useEditEvent';

export const EditEventContext = createContext<UseEditEventReturn | undefined>(
  undefined
);

interface EditEventProviderProps extends UseEditEventProps {
  children?: React.ReactNode | ((props: UseEditEventReturn) => React.ReactNode);
}

export const EditEventProvider = ({
  children,
  ...editEventProps
}: EditEventProviderProps) => {
  const result = useEditEvent(editEventProps);

  return (
    <EditEventContext.Provider value={result}>
      {typeof children === 'function' ? children(result) : children}
    </EditEventContext.Provider>
  );
};
