import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonNav,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { EditInvoice } from './EditInvoice/EditInvoice';
import { EditInvoiceProvider } from './EditInvoice/EditInvoiceContext';
import { ViewInvoice } from './ViewInvoice';

export const InvoiceDetail = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const pageRef = useRef(null);
  const organizationSlug = useOrganizationSlug();
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return (
      <EditInvoiceProvider invoiceId={invoiceId}>
        {({ saveInvoice }) => (
          <IonPage>
            <IonNav
              root={() => (
                <>
                  <IonHeader>
                    <IonToolbar>
                      <IonButtons slot="start">
                        <IonButton
                          color="dark"
                          onClick={() => {
                            setIsEditing(false);
                          }}
                        >
                          Cancel
                        </IonButton>
                      </IonButtons>
                      <IonTitle>Invoice Details</IonTitle>
                      <IonButtons slot="end">
                        <IonButton
                          color="dark"
                          onClick={() => {
                            setIsEditing(false);
                            saveInvoice();
                          }}
                        >
                          Done
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent
                    color="light"
                    style={{ paddingTop: 50 }}
                    fullscreen
                  >
                    <EditInvoice />
                  </IonContent>
                </>
              )}
            />
          </IonPage>
        )}
      </EditInvoiceProvider>
    );
  }

  return (
    <EditInvoiceProvider invoiceId={invoiceId}>
      <IonPage ref={pageRef}>
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                defaultHref={`/${organizationSlug}/admin/invoices`}
                color="dark"
              />
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                color="dark"
                onClick={() => setIsEditing(true)}
                routerDirection="none"
              >
                Edit
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          color="light"
          className="view-contact"
          style={{ paddingTop: 50 }}
          fullscreen
        >
          <ViewInvoice pageRef={pageRef} />
        </IonContent>
      </IonPage>
    </EditInvoiceProvider>
  );
};
