import { IonModal, IonNav } from '@ionic/react';
import { memo, useRef } from 'react';

import './Settings.css';
import SettingsOverview from './SettingsOverview';

interface SettingsProps {
  presentingPage: React.MutableRefObject<HTMLElement | null> | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Settings = memo(
  ({ presentingPage, isOpen, setIsOpen }: SettingsProps) => {
    const modalRef = useRef<HTMLIonModalElement | null>(null);

    if (presentingPage !== null && !presentingPage.current) {
      return null;
    }

    return (
      <IonModal
        ref={modalRef}
        presentingElement={presentingPage?.current || undefined}
        isOpen={isOpen}
        onIonModalDidDismiss={() => setIsOpen(false)}
      >
        <IonNav root={() => <SettingsOverview modalRef={modalRef} />} />
      </IonModal>
    );
  }
);

export default Settings;
