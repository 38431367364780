import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { Box, Button, Typography, buttonClasses } from '@mui/joy';

import { useUserData } from '@components/UserData/useUserData';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';

export const Activity = () => {
  const { userContact } = useUserData();

  return (
    <>
      <Box sx={{ marginTop: '40px' }}>
        <Button
          endDecorator={
            <ArrowForwardIosOutlined
              sx={{
                transform: 'rotate(90deg) translateY(8px) !important',
              }}
            />
          }
          variant="plain"
          color="neutral"
          size="lg"
          sx={(theme) => ({
            paddingLeft: 0,
            color: theme.palette.text.primary,
            [`& .${buttonClasses.startDecorator}`]: {
              marginRight: 0,
            },
          })}
        >
          Activity
        </Button>
      </Box>

      <Box sx={{ paddingBottom: '40px' }}>
        <Typography
          component="div"
          sx={(theme) => ({
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            marginTop: '10px',
            color: theme.palette.neutral[500],
          })}
        >
          <ContactAvatar
            contact={userContact}
            sx={{
              height: '18px',
              width: '18px',
              marginRight: '10px',
            }}
          />
          <Box>
            <Box
              component="span"
              sx={(theme) => ({
                color: theme.palette.neutral[900],
              })}
            >
              andy
            </Box>{' '}
            created the note
          </Box>
          <Box>•</Box>
          <Box>3 days ago</Box>
        </Typography>
      </Box>
    </>
  );
};
