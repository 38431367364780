import { ref } from 'firebase/storage';
import { useDownloadURL } from 'react-firebase-hooks/storage';

import { useOrgainzation } from '@features/Organization/organizationSlice';
import { storage } from '@utils/firebase';

export const useCustomAvatar = (avatar: string | undefined) => {
  const organization = useOrgainzation();

  return useDownloadURL(
    organization && avatar
      ? ref(storage, `/organizations/${organization.id}/profiles/${avatar}`)
      : null
  );
};
