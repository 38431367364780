import { ParsedContact } from '@shared/models/contacts';
import {
  QueryFieldFilterConstraint,
  and,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { contactsCollection } from '@models/contacts/model';
import { notEmpty } from '@models/events/useEventsQuery';

interface UseContactsQueryProps {
  skip?: boolean;
  skipAccountFilter?: boolean;
  constraints?: QueryFieldFilterConstraint[];
}

export const useContactsQuery = ({
  skip,
  skipAccountFilter,
  constraints,
}: UseContactsQueryProps = {}) => {
  const user = useUser();
  const organizationId = useOrganizationId();

  const accountConstraints =
    (!skipAccountFilter &&
      organizationId &&
      user?.organizationAccounts[organizationId]?.map((accountId) =>
        where('accountId', '==', accountId)
      )) ||
    [];

  const [rawContacts, loading, error] = useCollection(
    skip || !user || !organizationId
      ? null
      : query(
          contactsCollection,
          and(
            ...[
              where('organizationId', '==', organizationId),
              ...accountConstraints,
              ...(constraints || []),
            ].filter(notEmpty)
          )
        )
  );

  const contacts = useMemo(
    () =>
      rawContacts?.docs
        ?.map((doc): ParsedContact => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => {
          if (a.tags?.primaryContact) {
            return -1;
          }

          if (b.tags?.primaryContact) {
            return 1;
          }

          if (a.tags?.student) {
            return -1;
          }

          if (b.tags?.student) {
            return 1;
          }

          return 0;
        }),
    [rawContacts]
  );

  return [contacts, loading, error] as const;
};
