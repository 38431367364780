import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ParsedInvoice } from '@shared/models/invoices';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import { addOutline, addSharp } from 'ionicons/icons';
import { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { useInvoicesQuery } from '@models/invoices/useInvoicesQuery';

import AddInvoice from './InvoiceDetail/AddInvoice';

const Invoices = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const organizationSlug = useOrganizationSlug();

  const location = useLocation();

  const accountId = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return params.get('a');
  }, [location.search]);

  const [invoices] = useInvoicesQuery({
    constraints: accountId ? [where('accountId', '==', accountId)] : [],
  });

  const invoiceGroups = useMemo(
    () =>
      Object.values(
        (invoices || []).reduce(
          (
            previous: Record<
              string,
              { invoices: ParsedInvoice[]; label: string; key: number }
            >,
            invoice
          ) => {
            const groupDay = dayjs(invoice.start.toDate())
              // TODO: figure out timezone
              .add(5, 'hours');

            const groupId = groupDay.format('YY.MMMM');
            const previousGroup = previous[groupId] || {
              key: Number(groupDay.format('YYYYMM')),
              label: groupDay.format('MMMM YYYY'),
              invoices: [],
            };

            return {
              ...previous,
              [groupId]: {
                ...previousGroup,
                invoices: [...previousGroup.invoices, invoice],
              },
            };
          },
          {}
        )
      ).sort((a, b) => b.key - a.key),
    [invoices]
  );

  const page = useRef<HTMLElement | null>(null);

  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/${organizationSlug}/admin`}
              text="Admin"
              color="dark"
            />
          </IonButtons>
          <IonTitle>Invoices</IonTitle>
          {accountId ? (
            <IonButtons slot="end">
              <IonButton color="primary" onClick={() => setIsModalOpen(true)}>
                <IonIcon ios={addOutline} md={addSharp} />
              </IonButton>
            </IonButtons>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {invoiceGroups.map((invoiceGroup) => (
            <IonItemGroup key={invoiceGroup.key}>
              <IonItemDivider sticky>{invoiceGroup.label}</IonItemDivider>
              {invoiceGroup.invoices.map(({ id, start, status }) => {
                const startDate = start.toDate();
                const startDateUTC = new Date(
                  startDate.getUTCFullYear(),
                  startDate.getUTCMonth(),
                  startDate.getUTCDate(),
                  0,
                  0,
                  0
                );

                return (
                  <IonItem
                    key={id}
                    routerLink={`/${organizationSlug}/admin/invoices/${id}`}
                  >
                    <IonLabel>
                      {dayjs(startDateUTC).format('MMMM D, YYYY')}
                    </IonLabel>{' '}
                    <IonNote>{status === 'paid' ? 'Paid' : 'Not Paid'}</IonNote>
                  </IonItem>
                );
              })}
            </IonItemGroup>
          ))}
          {invoices?.length === 0 ? (
            <IonItem>
              <IonLabel>No invoices found</IonLabel>
            </IonItem>
          ) : null}
        </IonList>
      </IonContent>
      <AddInvoice
        page={page}
        isOpen={isModalOpen}
        onDone={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        accountId={accountId || undefined}
      />
    </IonPage>
  );
};

export default Invoices;
