import { useContext } from 'react';

import { UserAccessContext } from './UserAccessContext';

export const useUserAccess = () => {
  const currentUserContext = useContext(UserAccessContext);

  if (!currentUserContext) {
    throw new Error(
      'useUserAccess has to be used within <UserAccessContext.Provider>'
    );
  }

  return currentUserContext;
};
