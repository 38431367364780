import { doc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { usersCollection } from '@models/users/model';
import { signOut, useFirebaseAuthState } from '@utils/firebase';

interface UseUserQueryProps {
  settingUpUser: boolean;
}

export const useUserQuery = ({ settingUpUser }: UseUserQueryProps) => {
  const [user] = useFirebaseAuthState();

  const [userData, userLoading] = useDocumentData(
    !user ? null : doc(usersCollection, user.uid)
  );

  useEffect(() => {
    if (user && !userData && !userLoading && !settingUpUser) {
      (async () => {
        try {
          await user.reload();
        } catch (error) {
          if (
            error &&
            typeof error === 'object' &&
            'code' in error &&
            error.code === 'auth/user-token-expired'
          ) {
            console.log(
              "Signing out user because of 'auth/user-token-expired'"
            );

            await signOut();
          }
        }
      })();
    }
  }, [user, userData, userLoading, settingUpUser]);

  return { data: userData, loading: userLoading };
};

export type UseUserQueryReturn = ReturnType<typeof useUserQuery>;
