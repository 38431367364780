import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Button } from '@mui/joy';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface ShareWithLinkProps {
  onDone?: () => void;
  invoiceId: string;
}

export const ShareWithLink = ({ onDone, invoiceId }: ShareWithLinkProps) => {
  const [recentlyCopied, setRecentlyCopied] = useState<
    Promise<void> | undefined
  >();

  useEffect(() => {
    let mounted = true;

    if (!recentlyCopied) {
      return;
    }

    (async () => {
      await recentlyCopied;

      if (mounted) {
        setRecentlyCopied(undefined);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [recentlyCopied]);

  const invoiceLink = `${window.location.origin}/invoices/${invoiceId}`;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>Share invoice</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding flex-column" color="light">
        <Box sx={{ padding: '10px 20px' }}>
          Copy and paste this link to share the invoice.
        </Box>
        <IonList inset>
          <Box
            style={{
              padding: '6px',
              display: 'flex',
              justifyContent: 'space-between',
              gap: 15,
            }}
          >
            <input
              style={{
                border: 'none',
                padding: 3,
                outline: 'none',
                flex: 1,
                textOverflow: 'ellipsis',
              }}
              value={invoiceLink}
              onChange={() => undefined}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(invoiceLink);
                setRecentlyCopied(
                  new Promise((resolve) => setTimeout(resolve, 2000))
                );
              }}
            >
              {recentlyCopied ? 'Copied!' : 'Copy'}
            </Button>
          </Box>
        </IonList>
      </IonContent>
    </>
  );
};
