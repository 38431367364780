import { IonInput, IonItem, IonList, IonSelectOption } from '@ionic/react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHelperText } from '@components/ListHelperText';
import { SelectWrapper } from '@components/ionic-wrappers/SelectWrapper';
import { ToggleWrapper } from '@components/ionic-wrappers/ToggleWrapper';

import { useEditProductContext } from './ProductContext/useEditProductContext';

interface LastMinuteCancellationsContentProps {
  readOnly?: boolean;
}

const LastMinuteCancellationsContent = ({
  readOnly,
}: LastMinuteCancellationsContentProps) => {
  const {
    allowLastMinuteRefunds,
    allowMakeupCredits,
    allowMakingUpMakeups,
    allowMakupLessons,
    allowReschedulingMakeups,
    customRefundAmount,
    lastMinuteRefundAmount,
    maxMakeupOfMakeups,
    maximumReschedules,
    maximumSavedLessons,
    savedLessonExpiration,
    setAllowLastMinuteRefunds,
    setAllowMakeupCredits,
    setAllowMakeupLessons,
    setAllowMakingUpMakeups,
    setAllowReschedulingMakeups,
    setCustomRefundAmount,
    setLastMinuteRefundAmount,
    setMaxMakeupOfMakeups,
    setMaximumReschedules,
    setMaximumSavedLessons,
    setSavedLessonExpiration,
  } = useEditProductContext();

  const schedulingV1_2Enabled = useFeatureFlag('schedulingV1_2Enabled');

  return (
    <>
      <IonList inset>
        <IonItem>
          <SelectWrapper
            readOnly={readOnly}
            label="Last-Minute Deadline"
            interface="popover"
            value="24Hours"
          >
            {(
              [
                ['1Hour', '1 Hour'],
                ['24Hours', '24 Hours'],
                ['3days', '3 Days'],
                ['1week', '1 Week'],
                ['custom', 'Custom'],
              ] as const
            ).map(([value, label]) => (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            ))}
          </SelectWrapper>
        </IonItem>
      </IonList>
      <ListHelperText inset>
        The following rules will take effect if a student cancels or reschedules
        after the last-minute deadline.
      </ListHelperText>
      <IonList inset>
        <IonItem>
          <ToggleWrapper
            readOnly={readOnly}
            color="success"
            checked={allowLastMinuteRefunds}
            onIonChange={(event) =>
              setAllowLastMinuteRefunds(event.detail.checked)
            }
          >
            Last-Minute Refunds
          </ToggleWrapper>
        </IonItem>
        {allowLastMinuteRefunds ? (
          <>
            <IonItem>
              <SelectWrapper
                readOnly={readOnly}
                label="Refund Amount"
                interface="popover"
                value={lastMinuteRefundAmount}
                onIonChange={(event) =>
                  setLastMinuteRefundAmount(event.detail.value)
                }
              >
                {(
                  [
                    ['100', 'Full Refund'],
                    ['75', '75%'],
                    ['50', '50%'],
                    ['25', '25%'],
                    ['custom', 'Custom'],
                  ] as const
                ).map(([value, label]) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </SelectWrapper>
            </IonItem>
            {lastMinuteRefundAmount === 'custom' ? (
              <IonItem>
                <IonInput
                  style={{ textAlign: 'right', marginRight: 5 }}
                  label="Custom Refund"
                  type="number"
                  min={0}
                  max={100}
                  value={customRefundAmount}
                  onIonChange={(event) =>
                    setCustomRefundAmount(Number(event.target.value))
                  }
                ></IonInput>
                %
              </IonItem>
            ) : null}
          </>
        ) : null}
      </IonList>
      <ListHelperText inset>
        Allows you to return a portion of the payment to students who cancel
        their lessons last-minute.
      </ListHelperText>
      {schedulingV1_2Enabled ? (
        <>
          <IonList inset>
            <IonItem>
              <ToggleWrapper
                readOnly={readOnly}
                color="success"
                checked={allowMakupLessons}
                onIonChange={(event) =>
                  setAllowMakeupLessons(event.detail.checked)
                }
              >
                Makeup Lessons
              </ToggleWrapper>
            </IonItem>
          </IonList>
          <ListHelperText inset>
            Allows students to reschedule missed lessons with stricter rules and
            limitations set by the teacher, such as non-reschedulable make up
            lessons or expiring make up credits.
          </ListHelperText>
        </>
      ) : null}
      {allowMakupLessons ? (
        <>
          <IonList inset>
            <IonItem>
              <ToggleWrapper
                readOnly={readOnly}
                color="success"
                checked={allowReschedulingMakeups}
                onIonChange={(event) =>
                  setAllowReschedulingMakeups(event.detail.checked)
                }
              >
                Allow Rescheduling Makeups
              </ToggleWrapper>{' '}
            </IonItem>
            {allowReschedulingMakeups ? (
              <>
                <IonItem>
                  <SelectWrapper
                    readOnly={readOnly}
                    label="Maximum Reschedules"
                    interface="popover"
                    value={maximumReschedules}
                    onIonChange={(event) =>
                      setMaximumReschedules(event.target.value)
                    }
                  >
                    {(
                      [
                        ['1', '1'],
                        ['2', '2 '],
                        ['3', '3'],
                        ['custom', 'Custom'],
                      ] as const
                    ).map(([value, label]) => (
                      <IonSelectOption key={value} value={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                  </SelectWrapper>
                </IonItem>
                {maximumReschedules === 'custom' ? (
                  <IonItem>
                    <IonInput
                      style={{ textAlign: 'right', marginRight: 5 }}
                      label="Custom Max"
                      type="number"
                      min={0}
                      value="4"
                    ></IonInput>
                  </IonItem>
                ) : null}
              </>
            ) : null}

            <IonItem>
              <ToggleWrapper
                readOnly={readOnly}
                color="success"
                checked={allowMakingUpMakeups}
                onIonChange={(event) =>
                  setAllowMakingUpMakeups(event.detail.checked)
                }
              >
                Allow Making Up Makeups
              </ToggleWrapper>
            </IonItem>
            {allowMakingUpMakeups ? (
              <>
                <IonItem>
                  <SelectWrapper
                    readOnly={readOnly}
                    label="Max Makeup of Makeups"
                    interface="popover"
                    value={maxMakeupOfMakeups}
                    onIonChange={(event) =>
                      setMaxMakeupOfMakeups(event.target.value)
                    }
                  >
                    {(
                      [
                        ['1', '1'],
                        ['2', '2 '],
                        ['3', '3'],
                        ['custom', 'Custom'],
                      ] as const
                    ).map(([value, label]) => (
                      <IonSelectOption key={value} value={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                  </SelectWrapper>
                </IonItem>
                {maxMakeupOfMakeups === 'custom' ? (
                  <IonItem>
                    <IonInput
                      style={{ textAlign: 'right', marginRight: 5 }}
                      label="Custom Max"
                      type="number"
                      min={0}
                      value="4"
                    ></IonInput>
                  </IonItem>
                ) : null}
              </>
            ) : null}
          </IonList>

          <ListHelperText inset>
            Select whether students can reschedule a makeup lesson or be allowed
            to make up for a missed makeup lessons.
          </ListHelperText>

          <IonList inset>
            <IonItem>
              <ToggleWrapper
                readOnly={readOnly}
                color="success"
                checked={allowMakeupCredits}
                onIonChange={(event) =>
                  setAllowMakeupCredits(event.detail.checked)
                }
              >
                Allow Saving Makeup Lessons
              </ToggleWrapper>
            </IonItem>
          </IonList>

          <ListHelperText inset>
            Allows students to redeem cancelled lessons at a later date. These
            credits have an expiration date set by the teacher and can be used
            to schedule a makeup lesson.
          </ListHelperText>

          {allowMakeupCredits ? (
            <>
              <IonList inset>
                <IonItem>
                  <SelectWrapper
                    readOnly={readOnly}
                    label="Maximum Saved Lessons"
                    interface="popover"
                    value={maximumSavedLessons}
                    onIonChange={(event) =>
                      setMaximumSavedLessons(event.target.value)
                    }
                  >
                    {(
                      [
                        ['1Credit', '1 Lesson'],
                        ['2Credits', '2 Lessons'],
                        ['3Credits', '3 Lessons'],
                        ['5Credits', '5 Lessons'],
                        ['custom', 'Custom'],
                      ] as const
                    ).map(([value, label]) => (
                      <IonSelectOption key={value} value={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                  </SelectWrapper>
                </IonItem>
                {maximumSavedLessons === 'custom' ? (
                  <IonItem>
                    <IonInput
                      style={{ textAlign: 'right', marginRight: 5 }}
                      label="Custom Max"
                      type="number"
                      min={0}
                      value="4"
                    ></IonInput>{' '}
                    Lessons
                  </IonItem>
                ) : null}
              </IonList>

              <ListHelperText inset>
                The maximum number of makeup lessons a student can save at one
                time. If there is no room to save a new lesson when a
                last-minute cancellation occurs, the oldest saved lesson will be
                removed.
              </ListHelperText>

              <IonList inset>
                <IonItem>
                  <SelectWrapper
                    readOnly={readOnly}
                    label="Saved Lesson Expiration"
                    interface="popover"
                    value={savedLessonExpiration}
                    onIonChange={(event) =>
                      setSavedLessonExpiration(event.target.value)
                    }
                  >
                    {(
                      [
                        ['3Week', '3 Weeks'],
                        ['6Weeks', '6 Weeks'],
                        ['custom', 'Custom'],
                      ] as const
                    ).map(([value, label]) => (
                      <IonSelectOption key={value} value={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                  </SelectWrapper>
                </IonItem>
                {savedLessonExpiration === 'custom' ? (
                  <IonItem>
                    <IonInput
                      style={{ textAlign: 'right', marginRight: 5 }}
                      label="Custom Max"
                      type="number"
                      min={0}
                      value="14"
                    ></IonInput>{' '}
                    Days
                  </IonItem>
                ) : null}
              </IonList>

              <ListHelperText inset>
                An expiration sets a limit to how long students have to schedule
                a makeup lesson, relative to the original lesson date.
              </ListHelperText>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default LastMinuteCancellationsContent;
