import { Person } from '@mui/icons-material';
import { AvatarTypeMap, Avatar as MuiAvatar } from '@mui/joy';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { useState } from 'react';

import { transition } from '@theme/theme';

const Avatar = ({
  children,
  onLoad,
  src,
  sx,
  ...props
}: DefaultComponentProps<AvatarTypeMap<{}, 'div'>>) => {
  const [loading, setLoading] = useState(Boolean(src));
  const useFallbackIcon = !src && !children;

  return (
    <MuiAvatar
      onLoad={(event) => {
        setLoading(false);
        onLoad?.(event);
      }}
      sx={[
        {
          opacity: loading ? 0 : 1,
          transition: `opacity ${transition}`,
          color: 'white',
          background: '#9878ea',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      src={src}
      {...props}
    >
      {useFallbackIcon ? <Person sx={{ color: 'white' }} /> : children}
    </MuiAvatar>
  );
};

export default Avatar;
