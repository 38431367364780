import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import IonItemNavLink from '@components/IonItemNavLink';

import PrivacyPolicy from './legal/PrivacyPolicy';
import TermsOfService from './legal/TermsOfService';

interface AboutProps {
  onDone: () => void;
}

const About = ({ onDone }: AboutProps) => (
  <>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton color="dark" />
        </IonButtons>
        <IonTitle>About</IonTitle>
        <IonButtons slot="end">
          <IonButton color="dark" onClick={onDone}>
            Done
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <IonList inset>
        <IonItemNavLink
          button
          component={() => <TermsOfService onDone={onDone} />}
        >
          <IonLabel>Terms of Service</IonLabel>
        </IonItemNavLink>
        <IonItemNavLink
          button
          component={() => <PrivacyPolicy onDone={onDone} />}
        >
          <IonLabel>Privacy Policy</IonLabel>
        </IonItemNavLink>
      </IonList>

      <IonList inset>
        <IonItem>
          <IonLabel>Version</IonLabel>
          <IonNote>Version 1.0.0</IonNote>
        </IonItem>
      </IonList>
    </IonContent>
  </>
);
export default About;
