import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/joy';

import { useMedia } from '../MediaProvider/useMedia';

interface SidebarContentContainerProps {
  title?: string;
  children?: React.ReactNode;
}

export const SidebarContentContainer = ({
  title,
  children,
}: SidebarContentContainerProps) => {
  const { setSelectedSidebarItem } = useMedia();

  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.common.white,
        color: theme.palette.neutral[700],
        overflow: 'auto',
        height: '100%',
        padding: '15px 15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        borderLeft: `1px solid #E4E7EB`,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title ? (
          <Typography textColor="neutral.700" level="title-lg">
            {title}
          </Typography>
        ) : null}
        <IconButton onClick={() => setSelectedSidebarItem('')}>
          <CloseOutlined />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
};
