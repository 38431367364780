import { MusicNote, PlayCircleOutline } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';

import { BlockNote } from './BlockNote';

export const MediaDocument = () => {
  return (
    <Box
      sx={{
        flex: 1,
        margin: '0 auto',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: '800px',
        }}
      >
        {/* <Typography level="h3">12 Bar Blues</Typography> */}

        <Box
          sx={(theme) => ({
            border: `2px dashed ${theme.palette.neutral[200]}`,
            borderRadius: '5px',
            marginTop: '30px',
            background: '#f8f9fa',
          })}
        >
          <Box
            sx={{
              padding: '50px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
              }}
            >
              <MusicNote />
              <PlayCircleOutline />
            </Box>
            <Typography level="h3">Featured media</Typography>

            <Typography
              textColor="neutral.700"
              level="body-sm"
              sx={{ textAlign: 'center' }}
            >
              Drag your video/audio file or embed from Vimeo, YouTube, Wistia,
              Typeform and more.
            </Typography>

            <Button color="neutral" variant="outlined">
              Upload or embed media
            </Button>
          </Box>
        </Box>

        <Box sx={{ marginTop: '30px', marginLeft: '-50px' }}>
          <BlockNote />
        </Box>
      </Box>
    </Box>
  );
};
