import { IonContent, IonModal } from '@ionic/react';
import { Box, Button, Input, Typography } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';

interface FilterContentModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  contentModalOpen: boolean;
  setContentModalOpen: (value: boolean) => void;
  contentSearchInputRef: React.RefObject<HTMLInputElement>;
  onApplyFilter: (nextSearchValue: string) => void;
  contentSearchValue: string;
  setContentSearchValue: (value: string) => void;
}

export const FilterContentModal = ({
  page,
  contentModalOpen,
  contentSearchInputRef,
  onApplyFilter,
  setContentModalOpen,
  contentSearchValue,
  setContentSearchValue,
}: FilterContentModalProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  const handleApplyFilter = () => onApplyFilter(contentSearchValue);

  useHotkeys(
    'Enter',
    (event) => {
      event.preventDefault();
      handleApplyFilter();
    },
    { ignoredElementWhitelist: ['INPUT'], enabled: contentModalOpen }
  );

  return (
    <IonModal
      presentingElement={page.current || undefined}
      isOpen={contentModalOpen}
      onDidDismiss={() => setContentModalOpen(false)}
      style={
        isDesktop ? { '--height': '280px', '--max-width': '600px' } : undefined
      }
    >
      <IonContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <ModalListContainer
            sx={{ height: 'fit-content', paddingBottom: '0px !important' }}
          >
            <ModalTypographyContainer sx={{ width: '100%' }}>
              <Typography level="h4">Filter by content</Typography>
              <Typography>
                Enter a keyword to filter contacts by their content.
              </Typography>
              <Input
                value={contentSearchValue}
                onChange={(event) => setContentSearchValue(event.target.value)}
                ref={contentSearchInputRef}
              />
            </ModalTypographyContainer>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                width: '100%',
                maxWidth: '532px',
                margin: '0 auto !important',
              }}
            >
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                  setContentModalOpen(false);
                  setContentSearchValue('');
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleApplyFilter}>Apply</Button>
            </Box>
          </ModalListContainer>
        </Box>
      </IonContent>
    </IonModal>
  );
};
