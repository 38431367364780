import { Box } from '@mui/joy';
import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { useMeasure } from 'react-use';

import { useMedia } from '../MediaProvider/useMedia';
import { CanvasPage } from './CanvasPage';

export const MediaCanvas = () => {
  const stageRef = useRef<Konva.Stage | null>(null);

  const [
    canvasContainerRef,
    { width: canvasContainerWidth, height: canvasContainerHeight },
  ] = useMeasure();

  useEffect(() => {
    const stage = stageRef.current;

    if (!stage) {
      return;
    }

    const scaleBy = 1.04;
    stage.on('wheel', (event: any) => {
      if (!event.evt.ctrlKey) {
        return;
      }

      event.evt.preventDefault();

      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();

      if (!pointer) {
        return;
      }

      // TODO: use this when zoomed in
      // const pointerX = pointer.x;
      // const pointerY = pointer.y;

      const pointerX = canvasContainerWidth / 2;
      const pointerY = canvasContainerHeight / 2;

      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };

      let direction = event.evt.deltaY > 0 ? 1 : -1;

      if (event.evt.ctrlKey) {
        direction = -direction;
      }

      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      stage.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    });

    return () => {
      stage.off('wheel');
    };
  }, [stageRef, canvasContainerWidth, canvasContainerHeight]);

  const handleUnselect = () => {
    setSelectedNode('');
  };

  const { pages, currentPage, selectedNode, setSelectedNode } = useMedia();

  return (
    <Box
      ref={canvasContainerRef}
      sx={{
        flex: 1,
        background: '#ebecf0',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'absolute', left: 0, top: 0 }}>
        <Stage
          ref={stageRef}
          width={canvasContainerWidth}
          height={canvasContainerHeight}
        >
          <Layer>
            <Rect
              // fill="#e8e8e8"
              height={canvasContainerHeight}
              width={canvasContainerWidth}
              onClick={handleUnselect}
            />
          </Layer>
          {pages.map((page, pageIndex) => (
            <CanvasPage
              key={page.id}
              visible={pageIndex + 1 === currentPage}
              canvasContainerHeight={canvasContainerHeight}
              canvasContainerWidth={canvasContainerWidth}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              page={page}
            />
          ))}
        </Stage>
      </Box>
    </Box>
  );
};
