import { createContext } from 'react';

import {
  UseEditProductProps,
  UseEditProductReturn,
  useEditProduct,
} from './useEditProduct';

export const EditProductContext = createContext<
  UseEditProductReturn | undefined
>(undefined);

interface EditProductProviderProps extends UseEditProductProps {
  children?:
    | React.ReactNode
    | ((props: UseEditProductReturn) => React.ReactNode);
}

export const EditProductProvider = ({
  children,
  ...editProductProps
}: EditProductProviderProps) => {
  const result = useEditProduct(editProductProps);

  return (
    <EditProductContext.Provider value={result}>
      {typeof children === 'function' ? children(result) : children}
    </EditProductContext.Provider>
  );
};
