import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/joy';

import { useLesson } from '@features/Lessons/LessonDetail/LessonProvider/useLesson';

import { FlattenedItem } from '../../LessonProvider/useLessonProviderValue';
import { AssignmentItem } from '../AssignmentItem';
import { SectionItem } from '../SectionItem';
import { indentationWidth } from './SortableTree';

export interface TreeItemProps {
  assignmentListItem: FlattenedItem;
}

const transitionSpeed = 700;

export const SortableTreeItem = ({ assignmentListItem }: TreeItemProps) => {
  const {
    attributes,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: assignmentListItem.id,
    data: {
      type: assignmentListItem.type,
      id: assignmentListItem.id,
    },
    animateLayoutChanges: () => true,
    transition: {
      duration: transitionSpeed,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const { indicator } = useLesson();
  const insertPosition =
    indicator?.id === assignmentListItem.id ? indicator.position : undefined;

  const shouldIndent = indicator?.shouldIndent;
  const spacing =
    assignmentListItem.depth <= 1
      ? 0
      : indentationWidth * (assignmentListItem.depth - 1);

  return (
    <Box
      sx={{
        paddingLeft: `${spacing}px`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
        transition: [transition, `padding-left ${transitionSpeed}ms`]
          .filter(Boolean)
          .join(','),
        ...(insertPosition && {
          '&:before': {
            position: 'absolute',
            left: shouldIndent ? `${indentationWidth - 8}px` : `0px`,
            display: 'block',
            content: '""',
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            border: '2px solid #2389ff',
            zIndex: 1,
            ...(insertPosition === 'before' && { top: '-3.9px' }),
            ...(insertPosition === 'after' && { bottom: '-3px' }),
          },
          '&:after': {
            position: 'absolute',
            right: 0,
            left: shouldIndent ? `${indentationWidth}px` : `${8}px`,
            content: '""',
            height: '2px',
            backgroundColor: '#4c9ffe',
            zIndex: 1,
            ...(insertPosition === 'before' && { top: 0 }),
            ...(insertPosition === 'after' && { bottom: 0 }),
          },
        }),
      }}
      ref={setNodeRef}
    >
      {assignmentListItem.type === 'section' ? (
        <SectionItem
          section={assignmentListItem}
          handleProps={{ ...attributes, ...listeners }}
        />
      ) : (
        <AssignmentItem
          assignment={assignmentListItem}
          handleProps={{ ...attributes, ...listeners }}
        />
      )}
    </Box>
  );
};
