import { useContext } from 'react';

import { EditEventContext } from '.';

export const useEditEventContext = () => {
  const currentUserContext = useContext(EditEventContext);

  if (!currentUserContext) {
    throw new Error(
      'useEditEventContext has to be used within <EditEventContext.Provider>'
    );
  }

  return currentUserContext;
};
