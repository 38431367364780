import {
  calendarOutline,
  calendarSharp,
  cardSharp,
  chatbubbleEllipses,
  chatbubbleEllipsesOutline,
  chatbubbleEllipsesSharp,
  homeOutline,
  homeSharp,
  peopleOutline,
  peopleSharp,
  playCircleOutline,
  playCircleSharp,
  settingsOutline,
  time,
} from 'ionicons/icons';
import { useMemo } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import Admin from '@features/Admin';
import ContactGroup from '@features/Contacts/ContactGroup';
import Home from '@features/Home';
import Lessons from '@features/Lessons';
import Messages from '@features/Messages';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';
import Schedule from '@features/Schedule';

const homePaths = ['/:organizationSlug/home'];
const tabs = [
  {
    title: 'Home',
    paths: homePaths,
    selected: homePaths,
    url: '/home',
    Component: Home,
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    desktopIosIcon: homeOutline,
    desktopMdIcon: homeSharp,
    exact: false,
    hotkeys: { first: 'G', second: 'H' },
  },
  {
    title: 'Schedule',
    url: '/schedule',
    Component: Schedule,
    iosIcon: time,
    mdIcon: calendarSharp,
    desktopIosIcon: calendarOutline,
    desktopMdIcon: calendarSharp,
  },
  {
    title: 'Messages',
    url: '/messages',
    Component: Messages,
    iosIcon: chatbubbleEllipses,
    mdIcon: chatbubbleEllipsesSharp,
    desktopIosIcon: chatbubbleEllipsesOutline,
    desktopMdIcon: chatbubbleEllipsesSharp,
  },
  {
    title: 'Contacts',
    url: '/contacts',
    Component: ContactGroup,
    selected: [
      '/:organizationId/send-invite/:inviteId',
      '/:organizationId/lesson-notes',
    ],
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    desktopIosIcon: peopleOutline,
    desktopMdIcon: peopleSharp,
    hotkeys: { first: 'G', second: 'C' },
  },
  {
    title: 'Practice',
    url: '/lessons',
    Component: Lessons,
    iosIcon: playCircleOutline,
    mdIcon: playCircleSharp,
    desktopIosIcon: playCircleOutline,
    desktopMdIcon: playCircleSharp,
  },
  {
    title: 'Admin',
    url: '/admin',
    Component: Admin,
    iosIcon: settingsOutline,
    mdIcon: cardSharp,
    desktopIosIcon: settingsOutline,
    desktopMdIcon: cardSharp,
    hotkeys: { first: 'G', second: 'A' },
  },
];

interface UseTabsProps {
  type: 'routes' | 'ux';
}

export const useTabs = ({ type }: UseTabsProps = { type: 'ux' }) => {
  const schedulingV1_1Enabled = useFeatureFlag('schedulingV1_1Enabled');
  const messagingEnabled = useFeatureFlag('messaging');
  const permissions = useOrganizationPermissions();

  return useMemo(
    () =>
      tabs.filter(
        (tab) =>
          (tab.url !== '/messages' || messagingEnabled) &&
          (tab.url !== '/schedule' || schedulingV1_1Enabled) &&
          (tab.url !== '/contacts' ||
            type === 'routes' ||
            permissions?.isTeacher) &&
          (tab.url !== '/lessons' || permissions?.isTeacher === false)
      ),
    [messagingEnabled, schedulingV1_1Enabled, permissions, type]
  );
};
