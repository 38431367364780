import { AddOutlined, ImportExportOutlined } from '@mui/icons-material';
import {
  Box,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  avatarClasses,
} from '@mui/joy';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { Fragment, useEffect, useRef, useState } from 'react';

import { HotkeySpan } from '@components/Sidebar/TooltipHotkeyTitle';

import { Assignment } from '../../LessonProvider/useLessonProviderValue';

interface Option {
  id: string;
  title: React.ReactNode;
  description?: string;
  startDecorator?: React.ReactNode;
  divider?: boolean;
  value: string;
}

interface FilterOptionSelectProps {
  option?: Assignment;
  createNew?: boolean;
}

export interface AssignmentSelectorProps {
  onFilterOptionSelect: (option: FilterOptionSelectProps) => void;
  assignmentFilterSearchValue: string;
  setAssignmentFilterSearchValue: (value: string) => void;
}

export const AssignmentSelector = ({
  onFilterOptionSelect,
  assignmentFilterSearchValue,
  setAssignmentFilterSearchValue,
}: AssignmentSelectorProps) => {
  const filterSearchRef = useRef<HTMLInputElement | null>(null);

  const [optionIndex, setOptionIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => filterSearchRef.current?.querySelector('input')?.focus(),
      100
    );

    return () => clearTimeout(timeoutId);
  }, []);

  const options: Option[] = [
    {
      id: 'create-new-assignment',
      title: (
        <>
          Create from template
          {assignmentFilterSearchValue ? (
            <>: "{assignmentFilterSearchValue}"</>
          ) : null}{' '}
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <HotkeySpan>⌘</HotkeySpan>
            <HotkeySpan>E</HotkeySpan>
          </Box>
        </>
      ),
      startDecorator: <AddOutlined />,
      value: assignmentFilterSearchValue,
    },
    {
      id: 'import-from-another-lesson',
      title: (
        <>
          Import from another lesson
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <HotkeySpan>⇧</HotkeySpan>
            <HotkeySpan>⌘</HotkeySpan>
            <HotkeySpan>I</HotkeySpan>
          </Box>
        </>
      ),
      startDecorator: <ImportExportOutlined />,
      divider: true,
      value: assignmentFilterSearchValue,
    },
    {
      id: 'warm-ups',
      title: 'Warm ups',
      description: 'Make sure to complete this assignment by Friday.',
      value: 'Warm ups',
    },
    {
      id: 'eine-kleine-nachtmusik',
      title: 'Eine Kleine Nachtmusik',
      description: 'Play both hands together.',
      value: 'Eine Kleine Nachtmusik',
    },
    {
      id: '12-bar-blues',
      title: '12 bar blues',
      description: 'Practice the first 8 bars of the piece.',
      value: '12 bar blues',
    },
  ];

  const handFilterOptionSelect = (option: Option) => {
    setAssignmentFilterSearchValue('');
    onFilterOptionSelect({
      createNew: option.id === 'create-new-assignment',
      option: {
        id: option.id,
        title: option.value,
        description: option.description || '',
        emoji: '',
        type: 'assignment',
        children: [],
      },
    });
  };

  useHotkeys(
    'Enter',
    (event) => {
      event.preventDefault();
      const option = options[optionIndex];

      if (!option) {
        return;
      }

      handFilterOptionSelect(option);
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );
  useHotkeys(
    'ArrowDown',
    () => {
      setOptionIndex((prev) => Math.min(prev + 1, options.length - 1));
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );
  useHotkeys(
    'ArrowUp',
    () => {
      setOptionIndex((prev) => Math.max(prev - 1, 0));
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );

  return (
    <List
      size="sm"
      sx={{
        [`& .${avatarClasses.root}:not(.instruments)`]: {
          height: '20px',
          width: '20px',
        },
      }}
    >
      <ListItem
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Input
          placeholder="Search assignments…"
          variant="plain"
          size="sm"
          sx={{ paddingTop: 0, paddingBottom: 0, minHeight: 0 }}
          ref={filterSearchRef}
          value={assignmentFilterSearchValue}
          onChange={(event) =>
            setAssignmentFilterSearchValue(event.target.value)
          }
        />

        <HotkeySpan>C</HotkeySpan>
      </ListItem>
      {options.length > 0 ? <ListDivider /> : null}

      {options.map((option, index) => (
        <Fragment key={option.id}>
          <ListItem>
            <ListItemButton
              variant="plain"
              color="neutral"
              onMouseEnter={() => setOptionIndex(index)}
              selected={optionIndex === index}
              onClick={() => handFilterOptionSelect(option)}
              sx={{
                '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                  backgroundColor: 'unset',
                },
                '&.Mui-selected': {
                  fontWeight: 'unset',
                },
              }}
            >
              {option.startDecorator ? (
                <ListItemDecorator>{option.startDecorator}</ListItemDecorator>
              ) : null}
              <ListItemContent
                sx={{
                  display: 'flex',
                  gap: '20px',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                {option.title}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          {option.divider ? <ListDivider /> : null}
        </Fragment>
      ))}
    </List>
  );
};
