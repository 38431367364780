import { Typography } from '@mui/material';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { HomePageBlock } from './HomePageBlock';
import { useNextLesson } from './useNextLesson';

export const NextLessonBlock = () => {
  const nextLessonQuery = useNextLesson();
  const organizationSlug = useOrganizationSlug();

  return (
    <HomePageBlock
      title="Next Lesson"
      to={`/${organizationSlug}/schedule/${nextLessonQuery.eventSlug}`}
      color="#d1d3ff"
    >
      <Typography fontSize={50} variant="h1">
        {nextLessonQuery.loading ? '' : nextLessonQuery.dateString}
      </Typography>
    </HomePageBlock>
  );
};
