import { InvoiceStatus, LineItem } from '@shared/models/invoices';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Timestamp } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';

import { useUser } from '@features/Organization/organizationSlice';
import { useInvoiceQuery } from '@models/invoices/useInvoiceQuery';
import { useMutation } from '@models/mutations/useMutation';
import { serverTimestamp } from '@utils/serverTimestamp';

dayjs.extend(timezone);
dayjs.extend(utc);

const defaultLineItems: LineItem[] = [
  {
    amount: 0,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    createdBy: '',
    updatedBy: '',
    description: '',
    disabled: false,
    relatedEvents: [],
  },
];

const defaults = {
  invoiceIssueDate: dayjs().tz('UTC').startOf('month').toDate(),
  dueDate: dayjs().tz('UTC').startOf('month').toDate(),
  lineItems: defaultLineItems,
} as const;

export interface UseEditInvoiceProps {
  invoiceId: string;
  accountId?: string;
}

export const useEditInvoice = ({
  invoiceId,
  accountId,
}: UseEditInvoiceProps) => {
  const [invoice] = useInvoiceQuery(invoiceId);
  const user = useUser();

  const [lastUpdatedAt, setLastUpdatedAt] = useState(
    invoice?.updatedAt.toDate()
  );
  const [invoiceIssueDate, setInvoiceIssueDate] = useState(
    defaults.invoiceIssueDate
  );
  const [dueDate, setDueDate] = useState(defaults.dueDate);
  const [lineItems, setLineItems] = useState<LineItem[]>(defaults.lineItems);

  const reset = useCallback(
    () => () => {
      setInvoiceIssueDate(defaults.invoiceIssueDate);
      setDueDate(defaults.dueDate);
      setLineItems(defaults.lineItems);
    },
    []
  );

  useEffect(() => {
    if (
      !invoice ||
      lastUpdatedAt?.valueOf() === invoice.updatedAt.toDate().valueOf()
    ) {
      return;
    }

    setInvoiceIssueDate(invoice.issueDate.toDate());
    setDueDate(invoice.dueDate.toDate());
    setLineItems(invoice.lineItems);
    setLastUpdatedAt(invoice.updatedAt.toDate());
  }, [invoice, lastUpdatedAt]);

  const [updateInvoiceMutation] = useMutation('updateInvoice');
  const [createInvoiceMutation] = useMutation('createInvoice');

  /**
   * TODO
   * Convert this into a scheduled save to prevent ionic bug where
   * we don't save the last unblured input
   */
  const saveInvoice = async () => {
    if (invoiceId === 'add') {
      if (!accountId) {
        throw new Error('Account id is required');
      }

      return createInvoiceMutation({
        dueDate: Timestamp.fromDate(dueDate),
        issueDate: Timestamp.fromDate(invoiceIssueDate),
        lineItems,
        accountId,
      });
    } else {
      await updateInvoiceMutation({
        id: invoiceId,
        dueDate: Timestamp.fromDate(dueDate),
        issueDate: Timestamp.fromDate(invoiceIssueDate),
        lineItems,
      });
    }

    reset();
  };

  const addLineItem = () => {
    const userId = user?.userId;

    if (!userId) {
      throw new Error('User not found');
    }

    setLineItems([
      ...lineItems,
      {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        createdBy: userId,
        updatedBy: userId,
        description: '',
        amount: 0,
        relatedEvents: [],
        disabled: false,
      },
    ]);
  };

  const deleteLineItem = (index: number) =>
    setLineItems(lineItems.filter((_, i) => i !== index));

  const updateLineItem = (index: number, lineItem: LineItem) =>
    setLineItems(lineItems.map((item, i) => (i === index ? lineItem : item)));

  const [paymentType, setPaymentType] = useState<'cash' | 'check' | 'other'>(
    'cash'
  );

  const [datePaid, setDatePaid] = useState<Date>(new Date());
  const [paymentNotes, setPaymentNotes] = useState<string>('');

  const markAsPaid = {
    paymentType,
    setPaymentType,
    datePaid,
    setDatePaid,
    paymentNotes,
    setPaymentNotes,
  };

  const saveStatus = async (invoiceStatus: InvoiceStatus) => {
    if (!user?.userId) {
      throw new Error('User not found');
    }

    updateInvoiceMutation({
      id: invoiceId,
      status: invoiceStatus,
      payment:
        invoiceStatus === 'paid'
          ? {
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              createdBy: user.userId,
              updatedBy: user.userId,
              method: paymentType,
              notes: paymentNotes,
              datePaid: Timestamp.fromDate(datePaid),
            }
          : null,
    });

    setPaymentType('cash');
    setDatePaid(new Date());
    setPaymentNotes('');
  };

  return {
    invoice,
    saveInvoice,
    invoiceIssueDate,
    setInvoiceIssueDate,
    dueDate,
    setDueDate,
    lineItems,
    addLineItem,
    deleteLineItem,
    updateLineItem,
    reset,
    saveStatus,
    markAsPaid,
  };
};

export type UseEditInvoiceReturn = ReturnType<typeof useEditInvoice>;
