import styled from '@emotion/styled';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Divider } from '@mui/joy';
import { forwardRef } from 'react';

import './TabContainer.css';

const Container = styled(Box)(({ theme }) => ({
  padding: '0 2.5em',
  [theme.breakpoints.down('md')]: {
    padding: '0 1em',
  },
}));

export interface TabContainerProps {
  title: string;
  children?: React.ReactNode;
  inlineToolbar?: React.ReactNode;
  headerToolbar?: React.ReactNode;
}

const TabContainer = forwardRef(
  (
    { title, children, inlineToolbar, headerToolbar }: TabContainerProps,
    ref
  ) => (
    <IonPage ref={ref} className="tab-container">
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          {headerToolbar}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar className="inline-toolbar">
            <IonTitle size="large">{title}</IonTitle>
            {inlineToolbar}
          </IonToolbar>
        </IonHeader>
        <Container>
          <Divider sx={{ background: 'var(--joy-palette-neutral-100)' }} />
        </Container>
        <Container>{children}</Container>
      </IonContent>
    </IonPage>
  )
);

export default TabContainer;
