import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { eventsCollection } from './model';
import { parseEvent } from './parseEvent';

export const useEventQuery = (eventId?: string) => {
  const [eventSnapshot, loading, error] = useDocument(
    eventId ? doc(eventsCollection, eventId) : null
  );

  const event = useMemo(
    () => (eventSnapshot ? parseEvent(eventSnapshot) : undefined),
    [eventSnapshot]
  );

  return [event, loading, error] as const;
};
