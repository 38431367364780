import { DragIndicatorOutlined } from '@mui/icons-material';
import { Box } from '@mui/joy';
import { ClickAwayListener } from '@mui/material';
import { useCallback, useRef, useState } from 'react';

import { useLesson } from '../LessonProvider/useLesson';
import { FlattenedSection } from '../LessonProvider/useLessonProviderValue';
import { SectionOptions } from './SectionOptions';
import { TypographyInput } from './TypographyInput';
import { usePrioritizedInput } from './usePrioritizedInput';

interface SectionItemProps {
  section: FlattenedSection;
  handleProps?: any;
  children?: React.ReactNode;
}

export const SectionItem = ({
  section,
  handleProps,
  children,
}: SectionItemProps) => {
  const {
    flattenedAssignmentItems,
    setFlattenedAssignmentItems,
    insertAfterAndFocus,
    ensurePlaceholderAfter,
  } = useLesson();
  const [backupTitle, setBackupTitle] = useState('');
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const [title, setTitle] = usePrioritizedInput({
    assignmentId: section.id,
    assignmentKey: 'title',
  });

  const handleClickAway = useCallback(() => {
    if (!isFocused) {
      return;
    }

    setIsFocused(false);

    if (!title) {
      return setFlattenedAssignmentItems(
        flattenedAssignmentItems.filter(({ id }) => id !== section.id)
      );
    }

    ensurePlaceholderAfter(section.id);
  }, [
    section,
    setFlattenedAssignmentItems,
    flattenedAssignmentItems,
    ensurePlaceholderAfter,
    isFocused,
    title,
  ]);

  return (
    <Box sx={{ width: '100%' }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '100%',

            '& .drag-indicator': {
              cursor: 'grab',
              opacity: 0,
              color: theme.palette.neutral[400],
              transition: 'opacity 0.3s',
              position: 'absolute',
              top: '4px',
              left: '-28px',
              margin: 'auto',
              outline: 'none',
              '&:active': {
                cursor: 'grabbing',
              },
            },
            '&:hover .drag-indicator': {
              opacity: 1,
            },
          })}
          data-section-id={section.id}
        >
          <DragIndicatorOutlined className="drag-indicator" {...handleProps} />
          <TypographyInput
            ref={inputRef}
            level="title-lg"
            className="section-title"
            value={title}
            placeholder="New Section"
            onKeyDown={(event) => {
              if (event.code === 'Escape') {
                event.preventDefault();
                inputRef.current?.querySelector('input')?.blur();
                handleClickAway();

                return;
              }

              if (event.code === 'Enter') {
                event.preventDefault();
                insertAfterAndFocus(section.id);
              }
            }}
            onChange={(event) => setTitle(event.target.value)}
            sx={{
              color: 'neutral.500',
              fontSize: '16px',
              '&:hover': {
                color: 'neutral.500',
              },
            }}
            onFocus={() => {
              setBackupTitle(title);
              setIsFocused(true);
            }}
            onBlur={() => {
              if (!title) {
                setTitle(backupTitle);
              }
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            {/* <IconButton size="sm">
              <KeyboardArrowDownOutlined />
            </IconButton> */}
            <SectionOptions sectionId={section.id} />
          </Box>
        </Box>
      </ClickAwayListener>
      {children}
    </Box>
  );
};
