import { Box } from '@mui/joy';
import dayjs from 'dayjs';
import { startTransition } from 'react';
import 'swiper/css';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';

import { useInfiniteSlider } from '../useInfiniteSlider';
import CalendarDays, { calendarHeight } from './CalendarDays';

const WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const expandingTransitionSeconds = 0.4;
export const transitionTimingFunction = 'ease-in-out';

export interface CalendarProps {
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  expanded: boolean;
  expanding: boolean;
}

const Calendar = ({
  expanded,
  expanding,
  currentDate,
  setCurrentDate,
}: CalendarProps) => {
  const {
    previousDate,
    setPreviousDate,
    setSwiper,
    setTransitioning,
    transitioning,
  } = useInfiniteSlider();

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyItems: 'center',
          padding: 1,
        }}
      >
        {WEEKDAYS.map((day, index) => (
          <Box key={index} sx={{ textAlign: 'center', fontSize: 10 }}>
            {day}
          </Box>
        ))}
      </Box>
      <SwiperReact
        direction={expanded ? 'horizontal' : 'vertical'}
        onSwiper={setSwiper}
        onSlideChange={(event) => {
          if (event.activeIndex !== 1) {
            const current = dayjs(currentDate);
            const nextDate = (
              expanded
                ? current.add(event.activeIndex - 1, 'weeks')
                : current.add(event.activeIndex - 1, 'months').startOf('month')
            ).toDate();

            startTransition(() => {
              setPreviousDate(currentDate);
              setCurrentDate(nextDate);
            });
          }
        }}
        preventInteractionOnTransition
        onSlideChangeTransitionEnd={(event) => {
          if (event.activeIndex !== 1) {
            setTransitioning(event.activeIndex - 1);
          }
        }}
        initialSlide={1}
        style={{
          maxHeight: expanded ? 38 : calendarHeight,
          transition: `${expandingTransitionSeconds}s max-height ${transitionTimingFunction}`,
        }}
      >
        {[-1, 0, 1].map((offset) => (
          <SwiperSlide
            key={offset}
            style={{ overflow: 'hidden' }}
            onTouchMove={(event) => event.stopPropagation()}
            onTouchMoveCapture={(event) => event.stopPropagation()}
          >
            <CalendarDays
              currentDate={currentDate}
              inputDate={previousDate || currentDate}
              offset={offset === 0 ? transitioning ?? offset : offset}
              setCurrentDate={setCurrentDate}
              expanded={expanded}
              expanding={expanding}
              transitioning={transitioning !== undefined}
            />
          </SwiperSlide>
        ))}
      </SwiperReact>
    </Box>
  );
};

export default Calendar;
