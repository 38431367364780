import { IonItem } from '@ionic/react';
import { forwardRef, useCallback } from 'react';

type IonItemEvent = React.MouseEvent<HTMLIonItemElement, MouseEvent> & {
  target: HTMLIonItemElement;
};
type IonItemProps = Parameters<typeof IonItem>[0];
interface IonItemNavLinkProps extends IonItemProps {
  component?: () => JSX.Element;
}

const IonItemNavLink = forwardRef<HTMLIonItemElement, IonItemNavLinkProps>(
  ({ component, onClick, ...props }, ref) => (
    <IonItem
      ref={ref}
      onClick={useCallback(
        (event: IonItemEvent) => {
          onClick?.(event);
          if (component) {
            event.target?.closest('ion-nav')?.push(component);
          }
        },
        [component, onClick]
      )}
      {...props}
    />
  )
);

export default IonItemNavLink;
