import Box, { BoxProps } from '@mui/joy/Box';

export const HorizontalListItem = ({ sx, ...props }: BoxProps) => (
  <Box
    sx={{
      maxWidth: '500px',
      width: '80vw',
      height: '100%',
      padding: 1,
      scrollSnapAlign: 'start',
      ...sx,
    }}
    {...props}
  />
);
