import {
  IonActionSheet,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToggle,
  useIonRouter,
} from '@ionic/react';
import { Box, Textarea } from '@mui/joy';
import { billingCycleLabelMap } from '@shared/models/products';
import dayjs from 'dayjs';
import { deleteDoc, doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import IonItemNavLink from '@components/IonItemNavLink';
import { IonicLink } from '@components/IonicLink';
import { ListHeader } from '@components/ListHeader';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import {
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import {
  eventDetails,
  eventsCollection,
  getMultiTypeEntries,
  isEventType,
} from '@models/events/model';
import { frequencyOptions } from '@models/events/parseEvent';

import { useEditEventContext } from './EditEventContext/useEditEventContext';
import SelectTime from './SelectTime';

interface AddEventOverviewProps {
  onDone?: () => void;
  onDelete?: () => void;
  currentDate?: Date;
}

const AddEventOverview = ({
  onDone,
  onDelete,
  currentDate,
}: AddEventOverviewProps) => {
  const {
    fieldProps: {
      availableEvent,
      setAvailableEvent,
      setEventType,
      eventType,
      frequencyOption,
      setFrequencyOption,
      setSelectStudentIsOpen,
      registrationPolicy,
      setRegistrationPolicy,
      productId,
      setProductId,
      title,
      setTitle,
      duration,
      setDuration,
      billingCycle,
      setBillingCycle,
      setContactIds,
    },
    products,
    product,
    contacts,
    onDeleteEvent,
    eventId,
  } = useEditEventContext();
  const user = useUser();
  const organizationSlug = useOrganizationSlug();
  const router = useIonRouter();
  const isNew = eventId === 'add-event';
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [showTextarea, setShowTextarea] = useState(false);

  useEffect(() => {
    if (showTextarea) {
      return;
    }

    const timeout = setTimeout(() => setShowTextarea(true), 100);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <>
      <IonList inset>
        {isNew ? (
          <IonItem>
            <IonSelect
              label="Event Type"
              interface="popover"
              value={eventType === 'product' ? productId : eventType}
              onIonChange={(event) => {
                const rawType = String(event.detail.value);
                if (rawType !== eventType) {
                  const type = isEventType(rawType) ? rawType : 'product';

                  setFrequencyOption(
                    eventDetails[type].canRepeat &&
                      eventDetails[type].repeatByDefault
                      ? 'Every Week'
                      : 'Never'
                  );
                  setEventType(type);
                  if (type === 'product') {
                    setProductId(rawType);
                    // TODO
                    // setRegistrationPolicy(
                    //   rawType === 'group' ? 'public' : 'private'
                    // );
                  }
                }
              }}
            >
              {getMultiTypeEntries(products)
                .filter(([type]) => type !== 'reschedule')
                .map(([type, label]) => (
                  <IonSelectOption key={type} value={type}>
                    {label}
                  </IonSelectOption>
                ))}
            </IonSelect>
          </IonItem>
        ) : null}
        {eventType === 'trial' ? (
          <IonItem>
            <IonSelect
              label="Trial Type"
              interface="popover"
              value={productId}
              onIonChange={(event) => setProductId(event.detail.value)}
            >
              {products?.map(({ id, productName }) => (
                <IonSelectOption key={id} value={id}>
                  {productName}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        ) : null}
        {!eventDetails[eventType].hasProduct ? (
          <IonItem>
            <IonInput
              placeholder="Title"
              clearInput
              value={title}
              onIonChange={(event) => setTitle(String(event.detail.value))}
            />
          </IonItem>
        ) : null}
      </IonList>
      {eventDetails[eventType].hasProduct ? (
        <>
          {(product?.registrationPolicies.length || 0) > 1 ? (
            <IonList inset>
              <IonItem>
                <IonSelect
                  label="Registration Policy"
                  interface="popover"
                  value={registrationPolicy}
                  compareWith={(a, b) => a === b}
                  onIonChange={(event) =>
                    setRegistrationPolicy(event.detail.value)
                  }
                >
                  {product?.registrationPolicies.map((policyOption) => (
                    <IonSelectOption key={policyOption} value={policyOption}>
                      {policyOption === 'private' ? 'Private' : 'Public'}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>
          ) : null}
          {registrationPolicy === 'public' ? (
            <IonList inset>
              <IonItem>
                <IonSelect
                  label="Maximum Students"
                  interface="popover"
                  value={2}
                >
                  {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const).map(
                    (value) => (
                      <IonSelectOption key={value} value={value}>
                        {value} student{value === 1 ? '' : 's'}
                      </IonSelectOption>
                    )
                  )}
                </IonSelect>
              </IonItem>
            </IonList>
          ) : null}

          <ListHeader inset>
            Student{contacts?.length === 1 ? '' : 's'}
          </ListHeader>
          <IonList inset>
            {contacts?.map((contact) => (
              <IonItemSliding key={contact.id}>
                <IonItem>
                  <IonLabel>{getContactName(contact, user)}</IonLabel>
                </IonItem>

                <IonItemOptions>
                  <IonItemOption
                    color="danger"
                    onClick={() => {
                      setContactIds((existing) =>
                        existing.filter((id) => id !== contact.id)
                      );
                    }}
                  >
                    Delete
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))}
            <IonItem onClick={() => setSelectStudentIsOpen(true)}>
              <IonLabel>Add student…</IonLabel>
            </IonItem>
          </IonList>
        </>
      ) : null}
      <IonList inset>
        {eventDetails[eventType].hasProduct ? null : (
          <IonItem>
            <IonToggle color="success">All-day</IonToggle>
          </IonItem>
        )}
        <IonItemNavLink
          button
          routerDirection="forward"
          component={() => (
            <SelectTime
              onEventSelection={setAvailableEvent}
              currentDate={currentDate}
            />
          )}
        >
          <IonLabel>Starts</IonLabel>
          {dayjs(availableEvent.start).format('MMM D, YYYY h:mm A')}
        </IonItemNavLink>
        {eventDetails[eventType].hasProduct ? (
          <IonItem>
            <IonSelect
              label="Duration"
              interface="popover"
              value={duration}
              onIonChange={(event) => setDuration(Number(event.detail.value))}
            >
              {product?.lessonPricing.map(({ duration: pricingDuration }) => (
                <IonSelectOption key={pricingDuration} value={pricingDuration}>
                  {pricingDuration} minutes
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        ) : (
          <>
            <IonItem>
              <IonLabel>Ends</IonLabel>
              <IonNote slot="end">3:30 PM</IonNote>
            </IonItem>
            <IonItem>
              <IonSelect label="Travel Time" interface="popover" value={0}>
                {[
                  [0, 'None'],
                  [5, '5 minutes'],
                  [15, '15 minutes'],
                  [30, '30 minutes'],
                ].map(([value, label]) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </>
        )}
      </IonList>
      {eventDetails[eventType].canRepeat ? (
        <IonList inset>
          <IonItem>
            <IonSelect
              label="Repeat"
              interface="popover"
              value={frequencyOption}
              onIonChange={(event) => setFrequencyOption(event.detail.value)}
            >
              {frequencyOptions.map((label) => (
                <IonSelectOption key={label} value={label}>
                  {label}
                </IonSelectOption>
              ))}
              <IonSelectOption
                value="Custom"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                Custom
              </IonSelectOption>
            </IonSelect>
          </IonItem>
          {frequencyOption !== 'Never' ? (
            <IonItem>
              <IonSelect
                label="End Repeat"
                interface="popover"
                value="Never"
                onIonChange={(event) => setFrequencyOption(event.detail.value)}
              >
                {['Never'].map((label) => (
                  <IonSelectOption key={label} value={label}>
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          ) : null}
        </IonList>
      ) : null}
      {eventDetails[eventType].hasProduct &&
      eventType !== 'makeup' &&
      (product?.billingCycles.length || 0) > 1 ? (
        <IonList inset>
          <IonItem>
            <IonSelect
              label="Invoice Frequency"
              interface="popover"
              value={billingCycle}
              onIonChange={(event) => setBillingCycle(event.detail.value)}
            >
              {product?.billingCycles.map((value) => (
                <IonSelectOption key={value} value={value}>
                  {billingCycleLabelMap[value]}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>
      ) : null}
      {/*  */}
      <IonList inset>
        {showTextarea ? (
          <Textarea
            placeholder="Notes"
            color="neutral"
            variant="plain"
            sx={{
              padding: '15px',
              '--Textarea-focusedThickness': 0,
              minHeight: '150px',
            }}
          />
        ) : null}
      </IonList>
      {isNew ? null : (
        <IonList inset>
          <IonItem
            style={{ alignText: 'center' }}
            onClick={() => setIsActionSheetOpen(true)}
          >
            <IonText
              slot="start"
              color="danger"
              style={{ width: '100%', textAlign: 'center' }}
            >
              Delete Event
            </IonText>
          </IonItem>
        </IonList>
      )}
      {frequencyOption === 'Never' ? (
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header="Are you sure you want to delete this event?"
          buttons={[
            {
              text: 'Delete Event',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: () => {
                onDone?.();
                deleteDoc(doc(eventsCollection, eventId));
                onDelete?.();
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIsActionSheetOpen(false)}
        />
      ) : (
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header="Are you sure you want to delete this event? This is a repeating event."
          buttons={[
            {
              text: 'Delete This Event Only',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: () => {
                onDone?.();
                onDeleteEvent?.(true);
                onDelete?.();
              },
            },
            {
              text: 'Delete All Future Events',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: () => {
                onDone?.();
                onDeleteEvent?.();
                onDelete?.();
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIsActionSheetOpen(false)}
        />
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}>
        <IonicLink
          style={{
            color: 'var(--joy-palette-neutral-400)',
            textDecoration: 'none',
          }}
          to={`/${organizationSlug}/admin`}
          onClick={(event) => {
            // TODO: investigate popping the settings modal over the schedule modal
            event.preventDefault();
            onDone?.();
            router.push(`/${organizationSlug}/admin`, 'none');
          }}
        >
          Manage Products
        </IonicLink>
      </Box>
    </>
  );
};

export default AddEventOverview;
