import { Box, boxClasses, inputClasses } from '@mui/joy';

import { inputHeight } from '@theme/theme';

interface EditContactItemContainerProps {
  children: React.ReactNode;
  addButton?: React.ReactNode;
}

export const EditContactItemContainer = ({
  children,
  addButton,
}: EditContactItemContainerProps) => {
  return (
    <Box
      sx={{
        paddingLeft: '20px',
        borderTop: '1px solid lightgray',
        borderBottom: '1px solid lightgray',
        [`& > .${boxClasses.root}`]: {
          display: 'flex',
          alignItems: 'center',
          '&:not(:last-of-type)': {
            borderBottom: '1px solid lightgray',
          },

          [`& > .${inputClasses.root}`]: {
            flex: 1,
          },
        },
      }}
    >
      {children}
      {addButton ? (
        <Box
          sx={{
            height: inputHeight,
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-16px',
          }}
        >
          {addButton}
        </Box>
      ) : null}
    </Box>
  );
};
