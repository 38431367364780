import { RawContact } from '@shared/models/contacts';
import { doc, updateDoc } from 'firebase/firestore';
import { parseName } from 'humanparser';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';

import { getUserLoginFields } from '@components/UserData/getUserLoginFields';
import { useUserData } from '@components/UserData/useUserData';
import { contactsCollection } from '@models/contacts/model';
import { useMutation } from '@models/mutations/useMutation';
import { useFirebaseAuthState } from '@utils/firebase';
import { serverTimestamp } from '@utils/serverTimestamp';

const validFirstChacter = new RegExp(/[a-z]/, 'i');

const slugify = (input: string) => {
  const firstValidCharacter = input
    .split('')
    .findIndex((character) => validFirstChacter.test(character));

  return firstValidCharacter === -1
    ? ''
    : input
        .slice(firstValidCharacter)
        .split(' ')
        .join('-')
        .toLowerCase()
        .replace(/[^a-z0-9-]/gi, '')
        .replace(/(-)\1+/g, '$1');
};

interface UseAppIntroProviderValueProps {
  onDone: () => void;
}

export const useAppIntroProviderValue = ({
  onDone,
}: UseAppIntroProviderValueProps) => {
  const [firebaseUser] = useFirebaseAuthState();
  const { userContact } = useUserData();
  const [firstName, setFirstName] = useState<undefined | string>();
  const [lastName, setLastName] = useState<undefined | string>();
  const [businessName, setBusinessName] = useState<undefined | string>();
  const [urlSlugBase, setUrlSlugBase] = useState<undefined | string>();
  const [selectedInstruments, setSelectedInstruments] = useState<string[]>([]);
  const setUrlSlug = (input: string) => setUrlSlugBase(slugify(input));
  const [updateOrganizationMutation] = useMutation('updateOrganization');

  const urlSlug =
    urlSlugBase === undefined ? slugify(businessName || '') : urlSlugBase;

  const needsName = !firebaseUser?.displayName;

  useEffect(() => {
    if (!firebaseUser || firstName !== undefined || lastName !== undefined) {
      return;
    }

    const parsedName = firebaseUser.displayName
      ? parseName(firebaseUser.displayName)
      : { firstName: '', lastName: '' };

    setFirstName(parsedName.firstName);
    setLastName(parsedName.lastName);
  }, [firebaseUser, firstName, lastName]);

  const onSubmit = useCallback(async () => {
    if (!firebaseUser) {
      throw new Error('Firebase user not found');
    }

    if (!userContact) {
      throw new Error('User contact not found');
    }
    const email = firebaseUser.email;

    if (!email) {
      throw new Error('Email not found');
    }

    if (!businessName || !urlSlug) {
      throw new Error('Required fields not found');
    }

    const nextUserContact: Partial<RawContact> = {
      updatedAt: serverTimestamp(),
      updatedBy: firebaseUser.uid,
      emails: [{ address: email, type: 'mobile', isPrimary: true }],
      firstName,
      lastName,
      instruments: selectedInstruments,
      ...(firebaseUser.phoneNumber && {
        phoneNumber: firebaseUser.phoneNumber,
      }),
      ...getUserLoginFields(firebaseUser),
    };

    updateOrganizationMutation({
      updatedAt: serverTimestamp(),
      updatedBy: firebaseUser.uid,
      name: businessName,
      slug: urlSlug,
    });
    updateDoc(doc(contactsCollection, userContact.id), nextUserContact);

    onDone();
  }, [
    firebaseUser,
    firstName,
    lastName,
    onDone,
    userContact,
    selectedInstruments,
    businessName,
    urlSlug,
    updateOrganizationMutation,
  ]);

  return {
    needsName,
    onSubmit,
    firstName,
    setFirstName,
    businessName,
    setBusinessName,
    lastName,
    setLastName,
    urlSlug,
    setUrlSlug,
    selectedInstruments,
    setSelectedInstruments,
  };
};

export type UseAppIntroProviderReturn = ReturnType<
  typeof useAppIntroProviderValue
>;
