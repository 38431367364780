import { useMemo } from 'react';

import { GetEventsProps, unfurlEvents } from './unfurlEvents';

export const useUnfurlEvents = ({
  events,
  startDate,
  endDate,
  limit,
  startOf,
}: GetEventsProps) =>
  useMemo(
    () => unfurlEvents({ events, startDate, endDate, limit, startOf }),
    [events, startDate, endDate, limit, startOf]
  );
