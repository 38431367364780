import { RawContact } from '@shared/models/contacts';
import { where } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { usePrevious } from 'react-use';

import { useContactQuery } from '@models/contacts/useContactQuery';
import { useContactsQuery } from '@models/contacts/useContactsQuery';

import { useEditContact } from '../useEditContact';

interface UseContactProviderValueProps {
  contactId?: string;
}

const useContactProviderValue = ({
  contactId: contactIdOverride,
}: UseContactProviderValueProps) => {
  const { contactId: contactIdQueryParam } = useParams<{
    contactId: string;
  }>();
  const contactId = contactIdOverride || contactIdQueryParam;

  const [isEditing, setIsEditing] = useState(false);
  const page = useRef<HTMLElement | null>(null);
  const [addingContact, setAddingContact] = useState(false);
  const [contact] = useContactQuery(contactId);
  const [preset, setPreset] = useState<Partial<RawContact> | undefined>();
  const [isLinking, setIsLinking] = useState(false);

  const [linkedContacts] = useContactsQuery({
    constraints: [where('accountId', '==', contact?.accountId)],
    skip: !contact?.accountId,
    skipAccountFilter: true,
  });

  const handleAddContact = useCallback((nextPreset?: Partial<RawContact>) => {
    setPreset(nextPreset);
    setAddingContact(true);
  }, []);

  const previousContactId = usePrevious(contactId);
  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (contactId && previousContactId && contactId !== previousContactId) {
      setIsEditing(false);
    }
  }, [contactId, previousContactId, isEditing]);

  return {
    isEditing,
    page,
    setIsEditing,
    ...useEditContact({
      contactId,
      contact,
      onDone: () => setIsEditing(false),
    }),
    contactId,
    handleAddContact,
    linkedContacts,
    isLinking,
    setIsLinking,
    contact,
    addingContact,
    setAddingContact,
    preset,
  };
};

export type UseContactProviderQueryReturn = ReturnType<
  typeof useContactProviderValue
>;

export default useContactProviderValue;
