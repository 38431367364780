import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { invoicesCollection } from './model';
import { parseInvoice } from './parseInvoice';

export const useInvoiceQuery = (invoiceId: string | undefined) => {
  const [invoiceSnapshot, loading, error] = useDocument(
    invoiceId ? doc(invoicesCollection, invoiceId) : null
  );

  const invoice = useMemo(
    () => (invoiceSnapshot ? parseInvoice(invoiceSnapshot) : undefined),
    [invoiceSnapshot]
  );

  return [invoice, loading, error] as const;
};
