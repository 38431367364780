import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography } from '@mui/material';

/**
 * This page is shown when the user has zero organizations and
 * the user does not have the `userExists` flag (indicating that their account
 * is partially created).
 */
export const InviteExpired = () => {
  return (
    <IonPage data-auth="signed-out">
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Invite Expired</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Box sx={{ padding: 2 }}>
          <IonHeader collapse="condense">
            <IonToolbar className="inline-toolbar">
              <IonTitle size="large">Invite Expired</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Box
            sx={{
              padding: '10px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Typography sx={{ lineHeight: 1.5 }}>
              Invite link expired. For your security, please check your email
              for a fresh invite or ask your teacher to send a new one.
            </Typography>
          </Box>
        </Box>
      </IonContent>
    </IonPage>
  );
};
