import { useContext } from 'react';

import { TransferPrimaryContext } from './TransferPrimaryContext';

export const useTransferPrimary = () => {
  const currentUserContext = useContext(TransferPrimaryContext);

  if (!currentUserContext) {
    throw new Error(
      'useTransferPrimary has to be used within <TransferPrimaryContext.Provider>'
    );
  }

  return currentUserContext;
};
