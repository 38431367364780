import { LibraryMusicOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/joy';
import { Popover } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { useState } from 'react';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { useLesson } from '../../LessonProvider/useLesson';
import { Assignment } from '../../LessonProvider/useLessonProviderValue';
import { AssignmentSelector } from './AssignmentSelector';
import NewAssignmentModal from './NewAssignmentModal';

export interface AddFromLibraryProps {
  onFilterOptionSelect?: (option: Assignment) => void;
}

export const AddFromLibrary = ({
  onFilterOptionSelect,
}: AddFromLibraryProps) => {
  const {
    ref: iconButtonRef,
    filterIsOpen,
    setFilterIsOpen,
    anchorPosition,
  } = useFilterPopover<HTMLButtonElement>();
  const [assignmentFilterSearchValue, setAssignmentFilterSearchValue] =
    useState('');
  const { assignmentModalOpen, setAssignmentModalOpen, addNewAssignment } =
    useLesson();

  const initNewAssignment = (option?: Partial<Assignment>) => {
    addNewAssignment({
      title: option?.title || '',
      description: option?.description || '',
    });
  };

  const openFilter = () => {
    setAssignmentFilterSearchValue('');
    setFilterIsOpen(true);
  };

  useHotkeys('C', openFilter);
  useHotkeys(
    'Meta + E',
    () => {
      initNewAssignment({ title: assignmentFilterSearchValue });
      setAssignmentModalOpen(true);
      setFilterIsOpen(false);
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );

  return (
    <>
      <Tooltip variant="plain" title="Add from library">
        <IconButton
          color="neutral"
          variant="plain"
          size="sm"
          ref={iconButtonRef}
          onClick={openFilter}
        >
          <LibraryMusicOutlined />
        </IconButton>
      </Tooltip>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <AssignmentSelector
          assignmentFilterSearchValue={assignmentFilterSearchValue}
          setAssignmentFilterSearchValue={setAssignmentFilterSearchValue}
          onFilterOptionSelect={(next) => {
            setFilterIsOpen(false);
            if (next.createNew) {
              initNewAssignment(next.option);

              return setAssignmentModalOpen(true);
            }

            if (next.option) {
              onFilterOptionSelect?.(next.option);
            }
          }}
        />
      </Popover>
      <NewAssignmentModal
        isOpen={assignmentModalOpen}
        onDone={(nextAssignment) => {
          setAssignmentModalOpen(false);
          if (nextAssignment) {
            onFilterOptionSelect?.(nextAssignment);
          }
        }}
      />
    </>
  );
};
