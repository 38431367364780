/* eslint-disable */
import { z } from 'zod';

export class ZodTimestamp {
  static now(): ZodTimestamp {
    return ZodTimestamp.now();
  }

  static fromDate(date: Date): ZodTimestamp {
    return ZodTimestamp.now();
  }

  static fromMillis(milliseconds: number): ZodTimestamp {
    return ZodTimestamp.now();
  }

  constructor(public seconds: number, public nanoseconds: number) {}

  toDate(): Date {
    return new Date();
  }

  toMillis(): number {
    return 0;
  }

  isEqual(other: ZodTimestamp): boolean {
    return false;
  }

  valueOf(): string {
    return '';
  }
}

export const zodTimestamp = z.custom<ZodTimestamp>(
  (input) =>
    input && typeof input === 'object' && 'toDate' in (input as ZodTimestamp)
);
