import { and, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { contactsCollection } from '@models/contacts/model';
import { parseContact } from '@models/contacts/parseContact';
import { notEmpty } from '@models/events/useEventsQuery';

import { UseUserQueryReturn } from './useUserQuery';

interface UseUserContactQueryProps {
  user: UseUserQueryReturn['data'] | undefined;
  organizationId: string | undefined;
}

export const useUserContactQuery = ({
  user,
  organizationId,
}: UseUserContactQueryProps) => {
  const accounts = user?.organizationAccounts?.[organizationId || ''];
  const [rawContacts, loading, error] = useCollection(
    !user?.userId || !organizationId
      ? null
      : query(
          contactsCollection,
          and(
            where('organizationId', '==', organizationId),
            ...(accounts?.length ? [where('accountId', 'in', accounts)] : []),
            where('userId', '==', user.userId)
          )
        )
  );

  const contacts = useMemo(
    () => rawContacts?.docs?.map(parseContact).filter(notEmpty),
    [rawContacts]
  );

  return [contacts?.[0], loading, error] as const;
};
