import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { deleteDoc, doc } from 'firebase/firestore';
import { useMemo, useState } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import IonItemNavLink from '@components/IonItemNavLink';
import { ListHeader } from '@components/ListHeader';
import { ListHelperText } from '@components/ListHelperText';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { useModalState } from '@hooks/useModalState';
import { productsCollection } from '@models/products/model';

import CustomPricing from './CustomPricing';
import InvoiceDiscounts from './InvoiceDiscounts';
import Invoices from './Invoices';
import LastMinuteCancellations from './LastMinuteCancellations';
import LessonPricing, { lesssonPricingSlug } from './LessonPricing';
import LinkProducts from './LinkProducts';
import NoShows from './NoShows';
import { useEditProductContext } from './ProductContext/useEditProductContext';
import Refunds from './Refunds';
import ReschedulePolicies from './ReschedulePolicies';
import UserOptions from './UserOptions';

export const getSlidingElement = (
  event: React.MouseEvent<HTMLIonItemOptionElement, MouseEvent>
) => {
  const ionItemOptionElement = event.target as HTMLIonItemOptionElement | null;

  const ionItemSlidingElement = ionItemOptionElement?.parentElement
    ?.parentElement as HTMLIonItemSlidingElement | null | undefined;

  return ionItemSlidingElement;
};

interface AddProductOverviewProps {
  productModalRef: React.MutableRefObject<HTMLIonModalElement | null>;
}

const AddProductOverview = ({ productModalRef }: AddProductOverviewProps) => {
  const router = useIonRouter();

  const addStudentModalState = useModalState();
  const organizationSlug = useOrganizationSlug();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const {
    lessonPricing,
    setLessonPricing,
    scheduleSave: onSave,
    instrument,
    setInstrument,
    customProductName,
    productNameType,
    setCustomProductName,
    setProductNameType,
    productName,
    registrationPolicies,
    setRegistrationPolicies,
    productType,
    setProductType,
    productId,
  } = useEditProductContext();

  const isNew = productId === 'add';
  const schedulingV1_2Enabled = useFeatureFlag('schedulingV1_2Enabled');
  const schedulingV1_3Enabled = useFeatureFlag('schedulingV1_3Enabled');

  const { nextDuration, nextPrice } = useMemo(() => {
    const highestDurationLesson = lessonPricing
      .sort((a, b) => a.duration - b.duration)
      .at(-1);

    if (!highestDurationLesson) {
      return { nextDuration: 30, nextPrice: 30 };
    }

    const largestDuration = highestDurationLesson.duration;
    const pricePerHour = highestDurationLesson.price / (largestDuration / 60);
    const next = largestDuration + (largestDuration < 60 ? 15 : 30);

    return {
      nextDuration: next,
      nextPrice: pricePerHour * (next / 60),
    };
  }, [lessonPricing]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="dark"
              onClick={() => productModalRef.current?.dismiss()}
            >
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{isNew ? 'New Product' : 'Product Details'}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onSave}>
              {isNew ? 'Add' : 'Done'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        className="ion-padding"
        color="light"
        ref={addStudentModalState.page as any}
      >
        <IonList inset>
          <IonItem>
            <IonSelect
              label="Product Name"
              interface="popover"
              value={productNameType}
              compareWith={(a, b) => a === b}
              onIonChange={(event) => setProductNameType(event.detail.value)}
            >
              {(
                [
                  ['auto', productName],
                  ['custom', 'Custom'],
                ] as const
              ).map(([value, label]) => (
                <IonSelectOption key={value} value={value}>
                  {label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          {productNameType === 'custom' ? (
            <IonItem>
              <IonInput
                clearInput
                placeholder="e.g., Group Lessons"
                value={customProductName}
                onIonChange={(event) =>
                  setCustomProductName(String(event.detail.value))
                }
              />
            </IonItem>
          ) : null}
          <IonItem>
            <IonSelect
              label="Instrument"
              interface="popover"
              value={instrument}
              compareWith={(a, b) => a === b}
              onIonChange={(event) => setInstrument(event.detail.value)}
            >
              {(
                [
                  ['Piano', 'Piano'],
                  ['Guitar', 'Guitar'],
                  ['Basoon', 'Basoon'],
                  ['Clarinet', 'Clarinet'],
                  ['Flute', 'Flute'],
                  ['Saxophone', 'Saxophone'],
                  ['custom', 'Custom'],
                ] as const
              ).map(([value, label]) => (
                <IonSelectOption key={value} value={value}>
                  {label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>

        <ListHeader inset>Lesson Pricing</ListHeader>
        <IonList inset>
          {lessonPricing
            .sort((a, b) => a.duration - b.duration)
            .map(({ duration, price, isDefault }) => (
              <IonItemSliding key={duration}>
                {/*  */}
                <IonItemOptions side="start">
                  <IonItemOption
                    color="primary"
                    onClick={(event) => {
                      setLessonPricing(
                        lessonPricing.map((item) => ({
                          ...item,
                          isDefault: item.duration === duration,
                        }))
                      );

                      getSlidingElement(event)?.close();
                    }}
                  >
                    Default
                  </IonItemOption>
                </IonItemOptions>
                <IonItemNavLink
                  button
                  routerDirection="forward"
                  component={() => (
                    <LessonPricing
                      pricingId={`${duration}${lesssonPricingSlug}`}
                    />
                  )}
                >
                  {duration} Minutes
                  <IonNote slot="end">
                    ${price}
                    {isDefault ? ' (Default)' : ''}
                  </IonNote>
                </IonItemNavLink>
                <IonItemOptions>
                  <IonItemOption
                    color="danger"
                    onClick={(event) => {
                      setLessonPricing(
                        lessonPricing.filter(
                          (item) => item.duration !== duration
                        )
                      );

                      getSlidingElement(event)?.close();
                    }}
                  >
                    Delete
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))}
          <IonItemNavLink
            button
            routerDirection="forward"
            component={() => (
              <LessonPricing
                pricingId={`${nextDuration}${lesssonPricingSlug}`}
              />
            )}
            onClick={() =>
              setLessonPricing([
                ...lessonPricing,
                { duration: nextDuration, price: nextPrice },
              ])
            }
          >
            Add…
          </IonItemNavLink>
        </IonList>

        <ListHelperText inset>
          Easily create multiple lesson lengths and establish specific pricing
          structures for each duration.
        </ListHelperText>

        {productType === 'advanced' ? (
          <>
            <ListHeader inset>Payment Structure</ListHeader>
            <IonList inset>
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <Invoices />}
              >
                <IonLabel>Invoices</IonLabel>
                <IonNote slot="end">Monthly Invoices</IonNote>
              </IonItemNavLink>
              {schedulingV1_3Enabled ? (
                <>
                  <IonItemNavLink
                    button
                    routerDirection="forward"
                    component={() => <InvoiceDiscounts />}
                  >
                    Discounts and Fees
                    <IonNote slot="end">None</IonNote>
                  </IonItemNavLink>
                </>
              ) : null}
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <CustomPricing />}
              >
                Custom Pricing Options
                <IonNote slot="end">None</IonNote>
              </IonItemNavLink>
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <Refunds />}
              >
                Refunds
                <IonNote slot="end">Full Refunds</IonNote>
              </IonItemNavLink>
            </IonList>

            <ListHelperText inset>
              Customize your payment structure with adjustable dates, billing
              cycles, discounts, and fees.
            </ListHelperText>

            <ListHeader inset>Reschedule Settings</ListHeader>
            <IonList inset>
              {schedulingV1_2Enabled ? (
                <>
                  <IonItemNavLink
                    button
                    routerDirection="forward"
                    component={() => <ReschedulePolicies />}
                  >
                    Reschedule Policies
                    <IonNote slot="end">None</IonNote>
                  </IonItemNavLink>
                </>
              ) : null}
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <LastMinuteCancellations />}
              >
                Last-Minute Cancellations
                <IonNote slot="end">On</IonNote>
              </IonItemNavLink>
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <NoShows />}
              >
                No-Shows
                <IonNote slot="end">Same As Last-Minute</IonNote>
              </IonItemNavLink>
            </IonList>

            <ListHelperText inset>
              Reschedule policy settings control automated features. You can
              still manually issue refunds and make other adjustments as needed.
            </ListHelperText>

            <IonList inset>
              <IonItem>
                <IonSelect
                  label="Registration Policies"
                  multiple
                  interface="popover"
                  value={registrationPolicies}
                  onIonChange={(event) => {
                    const selected = event.detail.value;
                    const value = Array.isArray(selected)
                      ? selected
                      : [selected];

                    setRegistrationPolicies(value);
                  }}
                >
                  {(
                    [
                      ['private', 'Private'],
                      ['public', 'Public'],
                    ] as const
                  ).map(([value, label]) => (
                    <IonSelectOption key={value} value={value}>
                      {label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>
            <ListHelperText inset>
              Registration policies control how students can register for your
              teaching services. Private classes will not be visible for
              registration within the app.
            </ListHelperText>

            <IonList inset>
              <IonItemNavLink
                button
                routerDirection="forward"
                component={() => <LinkProducts />}
              >
                <IonLabel>Link Products</IonLabel>
                <IonNote slot="end">2 Linked</IonNote>
              </IonItemNavLink>
            </IonList>

            <ListHelperText inset>
              Sync settings between different instruments, ensuring consistent
              pricing and invoice options across your teaching services.
            </ListHelperText>
          </>
        ) : null}

        <IonList inset>
          {productType === 'advanced' ? (
            <IonItemNavLink
              button
              routerDirection="forward"
              component={() => <UserOptions />}
            >
              <IonLabel>User Options</IonLabel>
              <IonNote slot="end">Default</IonNote>
            </IonItemNavLink>
          ) : null}
          {schedulingV1_3Enabled ? (
            <IonItem>
              <IonToggle
                checked={productType === 'advanced'}
                onIonChange={(event) =>
                  setProductType(event.target.checked ? 'advanced' : 'standard')
                }
                color="success"
              >
                Custom Mode
              </IonToggle>
            </IonItem>
          ) : null}
          {schedulingV1_3Enabled ? (
            <IonItem>
              <IonToggle checked color="success">
                Active
              </IonToggle>
            </IonItem>
          ) : null}
        </IonList>

        {isNew ? null : (
          <IonList inset>
            <IonItem
              style={{ alignText: 'center' }}
              onClick={() => setIsActionSheetOpen(true)}
            >
              <IonText
                slot="start"
                color="danger"
                style={{ width: '100%', textAlign: 'center' }}
              >
                Delete
              </IonText>
            </IonItem>
          </IonList>
        )}
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header="Do you want to delete this product?"
          buttons={[
            {
              text: 'Delete',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: () => {
                deleteDoc(doc(productsCollection, productId));
                productModalRef.current?.dismiss();
                router.push(`/${organizationSlug}/admin`, 'none');
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIsActionSheetOpen(false)}
        />
      </IonContent>
    </>
  );
};

export default AddProductOverview;
