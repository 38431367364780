import { useMemo } from 'react';
import { useParams } from 'react-router';

export const useEventId = () => {
  const { eventId: rawEventId } = useParams<{ eventId: string }>();

  return useMemo(() => {
    const [eventId, timestamp] = rawEventId.split('.');

    const parsedTimestamp = timestamp ? parseInt(timestamp, 10) : undefined;
    const eventInstanceDate = parsedTimestamp
      ? new Date(parsedTimestamp)
      : undefined;

    return { eventId, eventInstanceDate };
  }, [rawEventId]);
};
