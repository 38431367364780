import { createContext } from 'react';

import {
  UseLessonProviderReturn,
  useLessonProviderValue,
} from './useLessonProviderValue';

export const LessonProviderContext = createContext<
  UseLessonProviderReturn | undefined
>(undefined);

interface LessonProviderProviderProps {
  children?:
    | React.ReactNode
    | ((value: UseLessonProviderReturn) => React.ReactNode);
}

export const LessonProvider = ({ children }: LessonProviderProviderProps) => {
  const value = useLessonProviderValue();

  return (
    <LessonProviderContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </LessonProviderContext.Provider>
  );
};
