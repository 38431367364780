import { and, getCountFromServer, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { contactsCollection } from '@models/contacts/model';

export const useContactsTagCount = (tagName: string) => {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState<number | undefined>(undefined);
  const organizationId = useOrganizationId();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    let mounted = true;

    const getCount = async () => {
      const snapshot = await getCountFromServer(
        query(
          contactsCollection,
          and(
            where('organizationId', '==', organizationId),
            where(`tags.${tagName}`, '==', true)
          )
        )
      );

      const nextCount = snapshot.data().count;

      if (mounted) {
        if (loading === true) {
          setLoading(false);
        }
        if (nextCount === undefined || count !== nextCount) {
          setCount(nextCount);
        }
      }
    };

    const interval = setInterval(getCount, 5000);
    getCount();

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [organizationId, count, loading, tagName]);

  return { count, loading };
};
