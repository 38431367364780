import { useIonRouter } from '@ionic/react';
import useHotkeys from '@reecelucas/react-use-hotkeys';

import { useUserData } from '@components/UserData/useUserData';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

interface HotkeysProviderProps {
  children: React.ReactNode;
}

export const HotkeysProvider = ({ children }: HotkeysProviderProps) => {
  const router = useIonRouter();
  const organizationSlug = useOrganizationSlug();
  const { setSettingsIsOpen } = useUserData();

  useHotkeys('g h', () => router.push(`/${organizationSlug}/home`, 'none'));
  useHotkeys('g c', () => router.push(`/${organizationSlug}/contacts`, 'none'));
  useHotkeys('g a', () => router.push(`/${organizationSlug}/admin`, 'none'));
  useHotkeys('g s', () => {
    router.push(`/${organizationSlug}/home`, 'none');
    setSettingsIsOpen(true);
  });

  return <>{children}</>;
};
