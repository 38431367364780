import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { Box, Button, Input } from '@mui/joy';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useUserData } from '@components/UserData/useUserData';
import { ValidateEmailBlock } from '@features/Home/ValidateEmailBlock';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { useContactQuery } from '@models/contacts/useContactQuery';
import { useInvitesQuery } from '@models/invites/useInvitesQuery';
import { useMutation } from '@models/mutations/useMutation';

export const SendInvite = () => {
  const { emailVerified } = useUserData();
  const { contactId } = useParams<{ contactId: string }>();
  const pageRef = useRef(null);
  const organizationSlug = useOrganizationSlug();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [createInviteMutation] = useMutation('createInvite');
  const [removeInviteMutation] = useMutation('removeInvite');
  const [invites, loadingInvites] = useInvitesQuery({ contactId });
  const invite = invites?.[0];

  const [contact, loadingContact] = useContactQuery(contactId);

  const expiresAt = invite?.expiresAt?.toDate();
  const hourAfterCreatedAt = invite
    ? new Date(invite.createdAt.toDate().valueOf() + 60 * 60 * 1000)
    : undefined;

  const getExpired = useCallback(
    () => Boolean(expiresAt && expiresAt < new Date()),
    [expiresAt]
  );
  const [expired, setExpired] = useState(getExpired());

  useEffect(() => {
    const updateExpired = () => {
      const nextExpired = getExpired();

      if (nextExpired !== expired) {
        setExpired(nextExpired);
      }

      return nextExpired;
    };

    if (updateExpired()) {
      console.log('early return');
      return;
    }

    const interval = setInterval(() => {
      if (updateExpired()) {
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [expired, getExpired]);

  const AtLeastAnHourAterCreatedAt = Boolean(
    hourAfterCreatedAt && hourAfterCreatedAt < new Date()
  );

  const primaryEmail = contact?.emails?.find((email) => email.isPrimary);

  if (loadingContact) {
    return <div>loading contact…</div>;
  }

  return (
    <IonPage ref={pageRef}>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/${organizationSlug}/contacts`}
              color="dark"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        color="light"
        className="view-contact"
        style={{ paddingTop: 50 }}
        fullscreen
      >
        {emailVerified ? (
          <>
            {!primaryEmail ? (
              <Box sx={{ padding: 3 }}>Cannot send invite without email</Box>
            ) : (
              <>
                {!expired && (contact?.userId || invites?.length) ? (
                  <>
                    <Box sx={{ padding: 3 }}>
                      <Button
                        loading={mutationLoading}
                        onClick={() => {
                          setMutationLoading(true);

                          removeInviteMutation({ contactId });

                          setMutationLoading(false);
                        }}
                      >
                        Uninvite (remove user from org)
                      </Button>
                    </Box>
                  </>
                ) : null}

                <Box sx={{ padding: 3 }}>
                  {expired ||
                  AtLeastAnHourAterCreatedAt ||
                  (!contact?.userId &&
                    !loadingInvites &&
                    invites?.length === 0) ? (
                    <Button
                      loading={mutationLoading}
                      onClick={async () => {
                        setMutationLoading(true);

                        await createInviteMutation({ contactId });

                        setMutationLoading(false);
                      }}
                    >
                      Send {expired || AtLeastAnHourAterCreatedAt ? 'New ' : ''}
                      Invite
                    </Button>
                  ) : null}

                  {expired ? (
                    <Box sx={{ padding: '20px 2px' }}>Invite expired</Box>
                  ) : (
                    <Box sx={{ padding: 2 }}>
                      {invite ? (
                        <>
                          <Input
                            value={`${window.location.origin}/sign-up?invite=${invite.code}`}
                          />
                          <Box sx={{ padding: '10px 0' }}>
                            Expires at {expiresAt?.toLocaleString()}
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </>
        ) : (
          <ValidateEmailBlock featureName="inviting users" />
        )}
      </IonContent>
    </IonPage>
  );
};
