import { Input } from '@mui/joy';
import { InputProps } from '@mui/joy/Input';
import { useState } from 'react';

import { BusinessUrlContainer } from './BusinessUrlContainer';

export const BusinessUrlInput = ({
  sx,
  onFocus,
  onBlur,
  ...props
}: InputProps) => {
  const [urlSlugFocused, setUrlSlugFocused] = useState(false);

  return (
    <BusinessUrlContainer focused={urlSlugFocused}>
      <Input
        type="text"
        name="businessUrl"
        spellCheck={false}
        sx={[
          {
            height: '55px',
            paddingLeft: '116px',
            '& input': {
              height: 'fit-content',
              marginTop: '23px',
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        onFocus={(event) => {
          setUrlSlugFocused(true);
          onFocus?.(event);
        }}
        onBlur={(event) => {
          setUrlSlugFocused(false);
          onBlur?.(event);
        }}
        {...props}
      />
    </BusinessUrlContainer>
  );
};
