import { createContext } from 'react';

import {
  UseFeatureFlagsReturn,
  useFeatureFlagsValue,
} from './useFeatureFlagsValue';

export const FeatureFlagsContext = createContext<
  UseFeatureFlagsReturn | undefined
>(undefined);

interface FeatureFlagsProviderProps {
  children?: React.ReactNode;
}

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => (
  <FeatureFlagsContext.Provider value={useFeatureFlagsValue()}>
    {children}
  </FeatureFlagsContext.Provider>
);
