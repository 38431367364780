import { createContext } from 'react';

import useUserDataValue, { UseUserDataQueryReturn } from './useUserDataValue';

export const UserDataContext = createContext<
  UseUserDataQueryReturn | undefined
>(undefined);

interface UserDataProps {
  children: React.ReactNode;
}

export const UserDataProvider = ({ children }: UserDataProps) => (
  <UserDataContext.Provider value={useUserDataValue()}>
    {children}
  </UserDataContext.Provider>
);
