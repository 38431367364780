import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  MoreHoriz,
  Star,
  StarBorder,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  inputClasses,
} from '@mui/joy';

import { useLesson } from './LessonProvider/useLesson';

export const LessonNavbar = () => {
  const { setStared, stared, title, setTitle } = useLesson();

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 18px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        gap: '10px',

        '@media (min-width: 747px)': {
          background: 'rgb(255 255 255 / 71%)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgb(0 0 0 / 71%)',
          },
          backdropFilter: 'blur(30px)',
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Button
          variant="plain"
          color="neutral"
          startDecorator={<ArrowBackIosOutlined />}
          sx={{ paddingLeft: 0, paddingRight: '10px' }}
        >
          Back to student profile
        </Button>
        <IconButton>
          <MoreHoriz />
        </IconButton>
        <IconButton onClick={() => setStared((current) => !current)}>
          {stared ? (
            <Star sx={{ color: 'rgb(242, 190, 0)', fontSize: '22px' }} />
          ) : (
            <StarBorder sx={{ fontSize: '22px' }} />
          )}
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Input
          variant="plain"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          sx={(theme) => ({
            width: '75px',
            borderRadius: '5px',
            padding: '0 10px',
            border: `1px solid ${theme.palette.common.white}`,
            marginRight: '-10px',
            [`&.${inputClasses.focused},&:hover`]: {
              borderColor: theme.palette.neutral[500],
            },
          })}
          placeholder="Untitled"
        />
        <Divider
          orientation="vertical"
          sx={{
            marginTop: '5px',
            marginBottom: '5px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        />
        <Button color="neutral" endDecorator={<ArrowForwardIosOutlined />}>
          Finish Lesson
        </Button>
      </Box>
    </Box>
  );
};
