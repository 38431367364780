import dayjs from 'dayjs';
import {
  QueryFieldFilterConstraint,
  Timestamp,
  and,
  or,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useOrganizationId } from '@features/Organization/organizationSlice';

import { eventsCollection } from './model';
import { parseEvent } from './parseEvent';

export const notEmpty = <T>(event: T | null | undefined): event is T =>
  Boolean(event);

interface UseEventsQueryProps {
  currentDate: Date;
  constraints?: QueryFieldFilterConstraint[];
  skip?: boolean;
}

export const useEventsQuery = ({
  currentDate,
  constraints,
  skip,
}: UseEventsQueryProps) => {
  const organizationId = useOrganizationId();

  const [eventsSnapshot, loading, error] = useCollection(
    !organizationId || skip
      ? null
      : query(
          eventsCollection,
          and(
            where('organizationId', '==', organizationId),
            or(
              where(
                'end',
                '>=',
                Timestamp.fromDate(
                  dayjs(currentDate).subtract(1, 'day').startOf('day').toDate()
                )
              ),
              where('ongoing', '==', true)
            ),
            ...(constraints || [])
          )
        )
  );

  const parsedEvents = useMemo(
    () =>
      eventsSnapshot?.docs
        ?.map(parseEvent)
        .filter(notEmpty)
        .sort((a, b) => a.start.getTime() - b.start.getTime()),
    [eventsSnapshot]
  );

  const [cachedEvents, setCachedEvents] = useState(parsedEvents);

  useEffect(() => {
    if (parsedEvents) {
      setCachedEvents(parsedEvents);
    }
  }, [parsedEvents]);

  const events = parsedEvents || cachedEvents;

  return [events, loading, error] as const;
};
