import { useIonRouter } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useSearchParam } from 'react-use';

import { useEmailVerification } from './useEmailVerification';
import { useLoginEvent } from './useLoginEvent';
import { useOrganizationsQuery } from './useOrganizationsQuery';
import { usePermissionsQuery } from './usePermissionsQuery';
import { useProfileQuery } from './useProfileQuery';
import { useSetupUser } from './useSetupUser';
import { useUserContactValue } from './useUserContactValue';
import { useUserQuery } from './useUserQuery';

const useUserDataValue = () => {
  const [settingUpUser, setSettingUpUser] = useState(false);
  const user = useUserQuery({ settingUpUser });
  const organizations = useOrganizationsQuery({ user: user.data });
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);

  const orgRoute = useRouteMatch<{ organizationSlug: string }>(
    '/:organizationSlug'
  );
  const routeSlug = orgRoute?.params.organizationSlug;
  const organization = {
    data: useMemo(
      () => organizations.data?.find(({ slug }) => slug === routeSlug),
      [organizations.data, routeSlug]
    ),
    loading: organizations.loading,
  };

  const permissions = usePermissionsQuery({
    organizationId: organization.data?.id,
    user: user.data,
  });

  const profile = useProfileQuery({
    organizationId: organization.data?.id,
    user: user.data,
  });

  const router = useIonRouter();
  const handleAuth = useRouteMatch('/__/auth/action');
  const inviteCode = useSearchParam('invite');
  const { emailVerified, watchEmailVerificationClosely } =
    useEmailVerification();
  const setupUser = useSetupUser({
    settingUpUser,
    setSettingUpUser,
    watchEmailVerificationClosely,
  });
  const userExists = useSearchParam('userExists') === 'true';
  const organizationsLength = user.data?.organizations.length;
  const isErrorPage = useRouteMatch('/(almost-done|invite-expired)');

  const { userContact } = useUserContactValue({
    user: user.data,
    organizationId: organization.data?.id,
  });

  const { onLoginStart } = useLoginEvent({ userContact });

  useEffect(() => {
    if (
      setupUser.loading ||
      handleAuth ||
      inviteCode ||
      organizationsLength === undefined
    ) {
      return;
    }

    if (organizationsLength === 0) {
      if (isErrorPage) {
        return;
      }

      router.push(userExists ? '/almost-done' : '/invite-expired', 'none');

      return;
    }

    const firstOrganization = organizations.data?.[0];

    if (!organization.data && firstOrganization) {
      router.push(`/${firstOrganization.slug}/home`, 'none');
    }
  }, [
    organizationsLength,
    setupUser.loading,
    handleAuth,
    inviteCode,
    userExists,
    router,
    organizations.data,
    organization.data,
    isErrorPage,
  ]);

  return {
    user,
    userContact,
    organizations,
    organization,
    permissions,
    profile,
    setupUser,
    emailVerified,
    watchEmailVerificationClosely,
    onLoginStart,
    settingsIsOpen,
    setSettingsIsOpen,
  };
};

export type UseUserDataQueryReturn = ReturnType<typeof useUserDataValue>;

export default useUserDataValue;
