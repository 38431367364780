import {
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ChecklistOutlined,
  FormatListBulletedOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/joy';
import { useCallback, useMemo } from 'react';

import { useLesson } from '../../LessonProvider/useLesson';
import { FlattenedItem } from '../../LessonProvider/useLessonProviderValue';
import { DndContextListener } from './DndContextListener';
import { SortableTreeItem } from './SortableTreeItem';
import { getTargetIndex } from './getTargetIndex';

export const indentationWidth = 60;

export function SortableTree() {
  const {
    flattenedAssignmentItems,
    setFlattenedAssignmentItems,
    overId,
    setOverId,
    setActiveY,
    setActiveId,
    indicator,
    setOffsetLeft,
    setIsDragging,
    isDragging,
  } = useLesson();

  const sensors = useSensors(useSensor(PointerSensor));

  const findSection = useCallback(
    (itemId: UniqueIdentifier) => {
      const { sectionId } =
        flattenedAssignmentItems.find(({ id }) => id === itemId) ?? {};

      return flattenedAssignmentItems.find(({ id }) => id === sectionId);
    },
    [flattenedAssignmentItems]
  );

  const sortableItems = useMemo(
    () => flattenedAssignmentItems.map(({ id }) => id),
    [flattenedAssignmentItems]
  );

  const resetState = useCallback(() => {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setIsDragging(false);

    document.body.style.setProperty('cursor', '');
  }, [setOverId, setActiveId, setOffsetLeft, setIsDragging]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        const { id: nextActiveId } = active;
        setActiveId(nextActiveId);

        document.body.style.setProperty('cursor', 'grabbing');
      }}
      onDragMove={({ over, active, delta }) => {
        if (!over || !active) {
          return;
        }

        const activeRect = active.rect.current.translated;

        if (!activeRect) {
          return;
        }

        setActiveY(activeRect.top);
        setOffsetLeft(delta.x);

        const threshold = 4;

        if (
          !isDragging &&
          (Math.abs(delta.x) > threshold || Math.abs(delta.y) > threshold)
        ) {
          setIsDragging(true);
        }
      }}
      onDragOver={({ over }) => {
        if (over?.id) {
          setOverId(over.id);
        }
      }}
      onDragEnd={({ active }) => {
        resetState();

        const activeContainer = findSection(active.id);

        if (!activeContainer) {
          return;
        }

        if (!overId) {
          return;
        }

        const overContainer = findSection(overId);

        if (!overContainer) {
          return;
        }

        if (active.data.current?.type === 'section') {
          const activeIndex = flattenedAssignmentItems.findIndex(
            ({ id }) => id === active.id
          );
          const { sectionId } =
            flattenedAssignmentItems.find(({ id }) => id === overId) ?? {};

          const sectionIndex = flattenedAssignmentItems.findIndex(
            ({ id }) => id === sectionId
          );

          const targetIndex = getTargetIndex(
            sectionIndex,
            activeIndex,
            indicator?.position === 'after'
          );

          return setFlattenedAssignmentItems((current) =>
            arrayMove(current, activeIndex, targetIndex)
          );
        }

        const activeItem = flattenedAssignmentItems.find(
          ({ id }) => id === active.id
        );

        if (!activeItem || !indicator) {
          return;
        }

        const indicatorIndex = flattenedAssignmentItems.findIndex(
          ({ id }) => id === indicator.id
        );

        if (indicatorIndex === -1) {
          return;
        }

        const clonedItems: FlattenedItem[] = JSON.parse(
          JSON.stringify(flattenedAssignmentItems)
        );

        const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);

        const targetIndex = getTargetIndex(
          indicatorIndex,
          activeIndex,
          indicator?.position === 'after'
        );
        const previousItem =
          clonedItems[
            activeIndex < indicatorIndex ? targetIndex : targetIndex - 1
          ];

        const activeTreeItem = clonedItems[activeIndex];

        if (!activeTreeItem) {
          return;
        }

        const parentId = (() => {
          if (previousItem?.type === 'section') {
            return previousItem?.id;
          }

          if (indicator?.shouldIndent) {
            if (previousItem?.isSubtask) {
              return previousItem?.parentId;
            }

            return previousItem?.id;
          }

          return previousItem?.sectionId;
        })();

        if (!parentId) {
          return;
        }

        clonedItems[activeIndex] = {
          ...activeTreeItem,
          depth: indicator?.shouldIndent
            ? activeTreeItem.depth + 1
            : activeTreeItem.depth,
          parentId,
        };

        const sortedItems = arrayMove(clonedItems, activeIndex, targetIndex);
        return setFlattenedAssignmentItems(sortedItems);
      }}
      onDragCancel={() => resetState()}
    >
      <DndContextListener />
      <SortableContext
        items={sortableItems}
        strategy={verticalListSortingStrategy}
      >
        {flattenedAssignmentItems.map((flattenedAssignmentItem) => (
          <SortableTreeItem
            key={flattenedAssignmentItem.id}
            assignmentListItem={flattenedAssignmentItem}
          />
        ))}
      </SortableContext>
      <DragOverlay
        dropAnimation={{ duration: 0 }}
        modifiers={[
          ({ transform }) => ({
            ...transform,
            y: transform.y + 19,
            x: transform.x - 8,
          }),
        ]}
      >
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '10px',
            background: theme.palette.background.body,
            padding: '5px',
            width: 'fit-content',
            display: 'flex',
            gap: '10px',
            '& svg': {
              fontSize: 12,
            },
          })}
        >
          <ChecklistOutlined />
          <FormatListBulletedOutlined />
        </Box>
      </DragOverlay>
    </DndContext>
  );
}
