import { IonContent, IonPage } from '@ionic/react';
import { useState } from 'react';

import { useOrgainzation } from '@features/Organization/organizationSlice';

import { EditProductProvider } from '../Products/AddProduct/ProductContext';
import AdminEditHeader from './AdminEditHeader';

const GlobalTeachingHours = () => {
  const [isEditing, setIsEditing] = useState(false);
  const globalProductId = useOrgainzation()?.globalProductId;

  if (!globalProductId) {
    return null;
  }

  return (
    <EditProductProvider productId={globalProductId}>
      <IonPage>
        <AdminEditHeader isEditing={isEditing} setIsEditing={setIsEditing}>
          Teaching Hours
        </AdminEditHeader>
        <IonContent className="ion-padding" color="light">
          {/* TODO: Add date picker here */}
          Teaching hours goes here
        </IonContent>
      </IonPage>
    </EditProductProvider>
  );
};

export default GlobalTeachingHours;
