import styled from '@emotion/styled';
import Typography, { TypographyProps } from '@mui/joy/Typography';
import { useMemo } from 'react';

import { ParsedEvent, eventTypeLightColorMap } from '@models/events/model';

import { durationEventHeight, durationMarginLeft } from './EventViewContainer';

type DurationDisplayProps = TypographyProps &
  Pick<ParsedEvent, 'type' | 'duration'> & {
    hour: number;
    hidden: boolean | undefined;
  };

const DurationDisplay = styled(Typography)<DurationDisplayProps>(
  ({ hour, type, duration, hidden }) => ({
    position: 'absolute',
    top: hour * durationEventHeight + 1,
    opacity: hidden ? 0 : 1,
    background: eventTypeLightColorMap[type],
    height: durationEventHeight * (duration / 60) - 4,
    left: durationMarginLeft,
    right: 2,
    borderRadius: 5,
    padding: '1px 8px',
    display: 'flex',
    overflow: 'hidden',
  })
);

export const getHour = (date: Date) => date.getHours() + date.getMinutes() / 60;

interface DurationEventProps
  extends Pick<ParsedEvent, 'title' | 'start' | 'duration' | 'type'> {
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  hidden?: boolean;
}

const DurationEvent = ({
  title,
  start,
  duration,
  type,
  onClick,
  hidden,
}: DurationEventProps) => {
  const hour = useMemo(() => getHour(start), [start]);

  return (
    <DurationDisplay
      onClick={onClick}
      duration={duration}
      type={type}
      hour={hour}
      hidden={hidden}
    >
      {title}
    </DurationDisplay>
  );
};

export default DurationEvent;
