import { IonIcon } from '@ionic/react';
import { Box, Button, List, Typography } from '@mui/joy';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import {
  atCircleOutline,
  iceCreamOutline,
  sparklesOutline,
} from 'ionicons/icons';
import { useState } from 'react';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import ProfileAvatar from '@components/ProfileAvatar';
import { useTabs } from '@components/Tabs/tabs.routes';
import { ThemeButtons } from '@components/Theme/ThemeButtons';
import { ThemeColors } from '@components/Theme/ThemeColors';
import { ThemeTypography } from '@components/Theme/ThemeTypography';
import { useUserData } from '@components/UserData/useUserData';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import Settings from '@features/Settings';
import { useContactsQuery } from '@models/contacts/useContactsQuery';

import './Sidebar.css';
import { SidebarLink } from './SidebarLink';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  desktopIosIcon?: string;
  desktopMdIcon?: string;
  title: string;
  className?: string;
  selected?: string[];
  hotkeys?: { first: string; second: string };
}

export const themePages: (AppPage & { Component: () => JSX.Element })[] = [
  {
    title: 'Colors',
    url: '/theme/colors',
    iosIcon: sparklesOutline,
    mdIcon: sparklesOutline,
    Component: ThemeColors,
  },
  {
    title: 'Typography',
    url: '/theme/typography',
    iosIcon: atCircleOutline,
    mdIcon: atCircleOutline,
    Component: ThemeTypography,
  },
  {
    title: 'Buttons',
    url: '/theme/buttons',
    iosIcon: iceCreamOutline,
    mdIcon: iceCreamOutline,
    Component: ThemeButtons,
  },
];

export const sidebarWidth = 280;

const Sidebar = () => {
  const organizationSlug = useOrganizationSlug();
  const tabs = useTabs();
  const { userContact, settingsIsOpen, setSettingsIsOpen } = useUserData();
  useContactsQuery();
  const [{ allSidebarItemsEnabled, viewThemeEnabled }] = useFeatureFlags();
  const [showSidebar, setShowSidebar] = useState(true);
  useHotkeys('[', async () => {
    setShowSidebar(!showSidebar);
  });

  const appPages: AppPage[] = tabs.map((tab) => ({
    ...tab,
    className: 'tab-menu-item',
  }));

  const lists = [
    {
      title: 'App',
      items: appPages,
    },
    {
      title: 'Theme',
      items: themePages,
    },
  ];

  if (!showSidebar) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.level1,
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: `${sidebarWidth}px`,
        })}
      >
        <Box
          className="Navigation"
          sx={[
            {
              p: '30px 16px',
              borderRight: '1px solid',
              borderColor: 'divider',
              border: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            },
          ]}
        >
          {lists
            .filter(
              ({ title }) =>
                (title !== 'More' || allSidebarItemsEnabled) &&
                (title !== 'Theme' || viewThemeEnabled)
            )
            .map(({ title, items }) => (
              <Box key={title}>
                <Typography
                  level="body-md"
                  fontWeight="800"
                  variant="plain"
                  fontSize="sm"
                  sx={{ marginLeft: '0em' }}
                >
                  {title}
                </Typography>
                <List
                  aria-labelledby="nav-list-browse"
                  size="sm"
                  sx={{
                    '& .MuiListItem-root': {
                      margin: 0,
                      padding: 0,
                    },
                    '& .MuiListItemButton-root': {
                      p: '0 1em',
                      margin: '1px 0',
                    },
                    '--ListItem-radius': '6px',
                    '--joy-palette-neutral-solidBg':
                      'var(--joy-palette-neutral-800)',
                  }}
                >
                  {items.map(
                    ({
                      desktopIosIcon,
                      desktopMdIcon,
                      iosIcon,
                      mdIcon,
                      title: itemTitle,
                      url,
                      selected,
                      hotkeys,
                    }) => (
                      <SidebarLink
                        key={url}
                        title={itemTitle}
                        selected={selected}
                        {...hotkeys}
                        href={`/${organizationSlug}${url}`}
                      >
                        <IonIcon
                          ios={desktopIosIcon || iosIcon}
                          md={desktopMdIcon || mdIcon}
                        />
                      </SidebarLink>
                    )
                  )}
                </List>
              </Box>
            ))}
        </Box>
        <Box
          sx={{
            marginTop: 'auto',
            padding: '1em',
          }}
        >
          <Button
            color="neutral"
            variant="plain"
            onClick={() => setSettingsIsOpen(true)}
          >
            <ProfileAvatar
              size="sm"
              sx={{ width: 50, height: 50, marginRight: '1em' }}
            />{' '}
            {userContact ? (
              <Typography level="body-md" sx={{ fontWeight: 400 }}>
                {getContactName(userContact, undefined)}
              </Typography>
            ) : null}
          </Button>
        </Box>
      </Box>
      <Settings
        presentingPage={null}
        isOpen={settingsIsOpen}
        setIsOpen={setSettingsIsOpen}
      />
    </Box>
  );
};

export default Sidebar;
