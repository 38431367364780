import { IonModal, IonNav } from '@ionic/react';
import { useRef } from 'react';

import { useOrganizationId } from '@features/Organization/organizationSlice';

import AddProductOverview from './AddProductOverview';
import { EditProductProvider } from './ProductContext';

export interface AddProductProps {
  presentingPage: React.MutableRefObject<HTMLElement | null>;
  productId?: string;
  isOpen?: boolean;
  onDone?: () => void;
}

const AddProduct = ({
  presentingPage,
  productId = 'add',
  isOpen,
  onDone,
}: AddProductProps) => {
  const productModalRef = useRef<HTMLIonModalElement | null>(null);
  const organizationId = useOrganizationId();

  if (!presentingPage.current || !organizationId) {
    return null;
  }

  return (
    <EditProductProvider
      productId={productId}
      onSave={() => productModalRef.current?.dismiss()}
    >
      <IonModal
        presentingElement={presentingPage.current || undefined}
        isOpen={isOpen}
        onDidDismiss={onDone}
        ref={productModalRef}
      >
        <IonNav
          root={() => <AddProductOverview productModalRef={productModalRef} />}
        />
      </IonModal>
    </EditProductProvider>
  );
};

export default AddProduct;
