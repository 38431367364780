import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { getEventSlug } from '@features/Schedule';
import { useEventsQuery } from '@models/events/useEventsQuery';

export const useNextLesson = () => {
  const [events, loading] = useEventsQuery({ currentDate: new Date() });
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setStartDate(new Date()), 5000);

    return () => {
      clearInterval(interval);
    };
  }, [startDate]);

  const endDate = dayjs(startDate).add(3, 'months').toDate();

  const nextEvent = events?.reduce(
    (
      previous: { date: Date; eventId: string; ongoing: boolean } | undefined,
      current
    ) => {
      const [firstOccurance] = current.rrule
        ? current.rrule.between(
            dayjs(startDate).startOf('day').toDate(),
            dayjs(endDate).endOf('day').toDate()
          )
        : [current.start];

      if (!firstOccurance) {
        return previous;
      }

      if (!previous) {
        return {
          date: firstOccurance,
          eventId: current.id,
          ongoing: current.ongoing,
        };
      }

      return firstOccurance.getTime() < previous.date.getTime()
        ? {
            date: firstOccurance,
            eventId: current.id,
            ongoing: current.ongoing,
          }
        : previous;
    },
    undefined
  );

  if (!nextEvent) {
    return {
      dateString: 'No upcoming lessons',
      eventSlug: undefined,
      loading,
    };
  }

  const timeString = dayjs(nextEvent.date).format('h:mm A');
  const dateString = dayjs(nextEvent.date).format('YYYY-MM-D');
  const isToday = dayjs(nextEvent.date).isSame(dayjs(), 'day');
  const isLessThanSevenDaysAway = dayjs(nextEvent.date).isBefore(
    dayjs().add(7, 'days'),
    'day'
  );
  const isNextWeek = dayjs(nextEvent.date).isSame(
    dayjs().add(1, 'week'),
    'week'
  );
  const dayOfWeek = dayjs(nextEvent.date).format('dddd');

  const dayString = isToday
    ? 'Today'
    : isLessThanSevenDaysAway
    ? `This ${dayOfWeek}`
    : isNextWeek
    ? `Next ${dayOfWeek}`
    : dateString;

  const eventSlug = getEventSlug({
    id: nextEvent.eventId,
    start: nextEvent.date,
    ongoing: nextEvent.ongoing,
  });

  return {
    dateString: `${dayString} at ${timeString}`,
    eventSlug,
    loading,
  };
};
