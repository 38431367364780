import {
  ArrowBackIosOutlined,
  CloudDoneOutlined,
  DashboardOutlined,
  IosShareOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PlayArrowOutlined,
  RedoOutlined,
  UndoOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  Typography,
  inputClasses,
} from '@mui/joy';
import { dividerClasses } from '@mui/material';

import { useUserData } from '@components/UserData/useUserData';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';

import { useMedia } from '../MediaProvider/useMedia';

export const MediaNavbar = () => {
  const { title, setTitle } = useMedia();
  const { userContact } = useUserData();

  return (
    <Box
      sx={(theme) => ({
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        borderBottom: `1px solid #E4E7EB`,

        '& > div': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          [`.${dividerClasses.root}`]: {
            marginTop: '5px',
            marginBottom: '5px',
          },
        },
      })}
    >
      <Box>
        <Button
          variant="plain"
          color="neutral"
          startDecorator={<ArrowBackIosOutlined />}
        >
          Back to lesson
        </Button>
        <Button
          variant="plain"
          color="neutral"
          startDecorator={<DashboardOutlined />}
        >
          File
        </Button>

        <Divider orientation="vertical" />

        <IconButton variant="plain">
          <UndoOutlined />
        </IconButton>
        <IconButton variant="plain">
          <RedoOutlined />
        </IconButton>

        <Divider orientation="vertical" />

        <CloudDoneOutlined />
      </Box>
      <Box>
        <Input
          variant="plain"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          sx={(theme) => ({
            width: '105px',
            borderRadius: '5px',
            padding: '0 10px',
            border: `1px solid ${theme.palette.common.white}`,
            marginRight: '5px',
            [`&.${inputClasses.focused},&:hover`]: {
              borderColor: theme.palette.neutral[500],
            },
          })}
          placeholder="Untitled"
        />

        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography>1</Typography>{' '}
          <Typography textColor="neutral.400">/ 3</Typography>
        </Box>

        <Box>
          <IconButton
            size="sm"
            sx={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            size="sm"
            sx={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <KeyboardArrowDown />
          </IconButton>
        </Box>

        <Divider
          orientation="vertical"
          sx={{
            marginTop: '5px',
            marginBottom: '5px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
        />

        {userContact ? <ContactAvatar contact={userContact} /> : null}
        <Button
          color="neutral"
          variant="plain"
          startDecorator={<PlayArrowOutlined />}
        >
          36.7s
        </Button>
        <Button color="neutral" startDecorator={<IosShareOutlined />}>
          Share
        </Button>
      </Box>
    </Box>
  );
};
