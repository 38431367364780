import {
  AssignmentListItem,
  FlattenedItem,
} from '../../LessonProvider/useLessonProviderValue';

interface RootItem {
  type: 'root';
  children: AssignmentListItem[];
  id: string;
}

export const buildTree = (
  flattenedItems: FlattenedItem[]
): AssignmentListItem[] => {
  const root: RootItem = {
    id: 'root',
    children: [],
    type: 'root',
  };

  const nodes: Record<string, AssignmentListItem | RootItem> = {
    [root.id]: root,
  };

  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? items.find(({ id }) => id === parentId);

    nodes[item.id] = {
      id: item.id,
      children: item.children,
      type: item.type,
    } as any;

    parent?.children.push(item as any);
  }

  return root.children;
};
