import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography } from '@mui/joy';

import { useMoveContacts } from './useMoveContacts';

export const MoveContactsError = () => {
  const { onDone } = useMoveContacts();

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Box sx={{ padding: '0 20px' }}>
          <IonHeader collapse="condense">
            <IonToolbar style={{ paddingTop: 0 }}>
              <Typography
                sx={{ textAlign: 'center', padding: '0 30px' }}
                level="h4"
              >
                Cannot Move Selected Contacts
              </Typography>
            </IonToolbar>
          </IonHeader>
          <Typography
            sx={{
              textAlign: 'center',
              padding: '0 30px',
              marginBottom: '50px',
            }}
          >
            The contacts you selected cannot be moved to this group.
          </Typography>

          <Typography
            sx={{
              padding: '0 30px',
              marginBottom: '50px',
            }}
          >
            A primary contact cannot be moved without moving all of its
            connected contacts.
          </Typography>

          <Typography
            sx={{
              padding: '0 30px',
              marginBottom: '50px',
            }}
          >
            To move just a portion of the contacts, first transfer the primary
            contact to another member of the group.
          </Typography>
        </Box>
      </IonContent>
    </>
  );
};
