import { useUserContactQuery } from './useUserContactQuery';
import { UseUserQueryReturn } from './useUserQuery';

interface UseUserContactValueProps {
  user: UseUserQueryReturn['data'] | undefined;
  organizationId: string | undefined;
}

export const useUserContactValue = ({
  user,
  organizationId,
}: UseUserContactValueProps) => {
  const [userContact] = useUserContactQuery({
    user,
    organizationId,
  });

  return { userContact };
};
