import { GridViewOutlined, MoreHoriz } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  avatarClasses,
} from '@mui/joy';
import { Popover } from '@mui/material';
import { Fragment } from 'react';
import uuid4 from 'uuid4';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { useLesson } from '../LessonProvider/useLesson';

interface SectionOptionsProps {
  sectionId: string;
}

export const SectionOptions = ({ sectionId }: SectionOptionsProps) => {
  const { flattenedAssignmentItems, setFlattenedAssignmentItems } = useLesson();
  const { ref, filterIsOpen, setFilterIsOpen, anchorPosition } =
    useFilterPopover<HTMLButtonElement>();

  return (
    <>
      <IconButton size="sm" ref={ref} onClick={() => setFilterIsOpen(true)}>
        <MoreHoriz />
      </IconButton>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List
          size="sm"
          sx={{
            [`& .${avatarClasses.root}:not(.instruments)`]: {
              height: '20px',
              width: '20px',
            },
          }}
        >
          {[
            {
              id: uuid4(),
              title: 'Delete Section',
              startDecorator: <GridViewOutlined />,
              onClick: () => {
                setFilterIsOpen(false);
                setFlattenedAssignmentItems(
                  flattenedAssignmentItems.filter(
                    (assignment) => assignment.id !== sectionId
                  )
                );
              },
            },
          ].map((option) => (
            <Fragment key={option.id}>
              <ListItem>
                <ListItemButton
                  variant="plain"
                  color="neutral"
                  onClick={option.onClick}
                  sx={{
                    '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                      backgroundColor: 'unset',
                    },
                    '&.Mui-selected': {
                      fontWeight: 'unset',
                    },
                  }}
                >
                  {option.startDecorator ? (
                    <ListItemDecorator>
                      {option.startDecorator}
                    </ListItemDecorator>
                  ) : null}
                  <ListItemContent
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                  >
                    {option.title}
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              {/* {option.divider ? <ListDivider /> : null} */}
            </Fragment>
          ))}
        </List>
      </Popover>
    </>
  );
};
