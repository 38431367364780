import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ParsedContact } from '@shared/models/contacts';
import { where } from 'firebase/firestore';

import { useContactsQuery } from '@models/contacts/useContactsQuery';

export interface SelectContactProps {
  onContactSelected?: (contact: ParsedContact) => void;
  hiddenContactIds?: string[];
  onCancel?: () => void;
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  title?: string;
}

const SelectContact = ({
  onContactSelected,
  hiddenContactIds,
  onCancel,
  page,
  isOpen,
  title,
}: SelectContactProps) => {
  const [contacts] = useContactsQuery({
    constraints: hiddenContactIds?.length
      ? [where('__name__', 'not-in', hiddenContactIds ?? [])]
      : undefined,
  });

  if (!page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={page.current}
      isOpen={isOpen}
      onDidDismiss={onCancel}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="dark" onClick={onCancel}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar />
        <IonList>
          {contacts?.map((contact) => {
            const { id, firstName, lastName } = contact;

            return (
              <IonItem
                key={id}
                routerDirection="none"
                onClick={() => onContactSelected?.(contact)}
              >
                <IonLabel>
                  {firstName} {lastName}
                </IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SelectContact;
