import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import InvoiceDiscountsContent from './InvoiceDiscountsContent';

const InvoiceDiscounts = () => (
  <>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton color="dark" />
        </IonButtons>
        <IonTitle>Discounts and Fees</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding" color="light">
      <InvoiceDiscountsContent />
    </IonContent>
  </>
);

export default InvoiceDiscounts;
