import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography, useTheme } from '@mui/joy';

import { ContentContainer } from '@features/Contacts/ContentContainer';

export const ThemeColors = () => {
  const theme = useTheme();

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar class="no-background">
          <IonTitle>Colors</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <Box sx={{ p: 5, display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {Object.entries(theme.palette).flatMap(([channelName, value]) => {
              if (!value.mainChannel) {
                return [];
              }

              return [
                <Box
                  key={`${channelName}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  {['Main', 'Shades', 'Variables'].flatMap((text) => [
                    <Box
                      key={`${channelName}-${text}`}
                      sx={{ fontSize: 20, marginTop: 2 }}
                    >
                      <Typography level="title-lg">
                        {channelName[0]?.toUpperCase()}
                        {channelName.slice(1)}
                      </Typography>
                      <Typography>{text}</Typography>
                    </Box>,
                    ...Object.entries(value).flatMap(
                      ([colorVariantName, v]) => {
                        if (typeof v !== 'string') {
                          return [];
                        }

                        if (
                          text === 'Main' &&
                          (v.startsWith('#') || v.startsWith('var'))
                        ) {
                          return [];
                        }

                        if (text === 'Shades' && !v.startsWith('#')) {
                          return [];
                        }

                        if (text === 'Variables' && !v.startsWith('var')) {
                          return [];
                        }

                        const backgroundColor = (() => {
                          if (v.startsWith('#') || v.startsWith('var')) {
                            return v;
                          }

                          return `rgb(${v})`;
                        })();

                        const readableName =
                          text === 'Main'
                            ? colorVariantName.split('Channel').join('')
                            : colorVariantName;

                        return [
                          <Box key={`${channelName}-${colorVariantName}`}>
                            <Box
                              sx={{
                                height: 100,
                                width: 100,
                                backgroundColor,
                                borderRadius: '4px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                            <Typography
                              level="title-sm"
                              sx={{
                                color: 'black',
                              }}
                            >
                              {readableName}
                            </Typography>
                            {text === 'Variables' && (
                              <Box
                                sx={{
                                  fontSize: 12,
                                  color: 'gray',
                                  maxWidth: '120px',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {v}
                              </Box>
                            )}
                          </Box>,
                        ];
                      }
                    ),
                  ])}
                </Box>,
              ];
            })}
          </Box>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};
