import { EventType } from '@shared/models/events';
import { MutationPayload } from '@shared/models/mutations';
import { BillingCycle, RegistrationPolicy } from '@shared/models/products';
import dayjs from 'dayjs';
import { Timestamp, where } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import {
  CancelProductType,
  CancelationOption,
} from '@features/Schedule/EventDetail/EditProductEvent/CancelProductEvent';
import { RescheduleProductOption } from '@features/Schedule/EventDetail/EditProductEvent/RescheduleProductEvent';
import { useModalState } from '@hooks/useModalState';
import { useContactsQuery } from '@models/contacts/useContactsQuery';
import { useContractQuery } from '@models/contracts/useContractQuery';
import { eventDetails } from '@models/events/model';
import { FrequencyOption } from '@models/events/parseEvent';
import { useEventQuery } from '@models/events/useEventQuery';
import { useMutation } from '@models/mutations/useMutation';
import { useProductsQuery } from '@models/products/useProductsQuery';

import { AddEventProps } from '..';
import { AvailableEvent } from '../SelectTime/AvailabilityEvent';

// TODO: improve defaulting to prevent double-bookings
const dayjsStart = dayjs().add(60, 'minutes').startOf('hour');
const start = dayjsStart.toDate();
const defaultEventBase: AvailableEvent = {
  duration: 30,
  start,
  end: dayjsStart.add(30, 'minutes').toDate(),
};

export interface UseEditEventProps
  extends Pick<AddEventProps, 'contactId' | 'onDone'> {
  eventId: string | undefined;
  eventInstanceDate?: Date;
}

export const useEditEvent = ({
  eventId,
  onDone,
  contactId,
  eventInstanceDate,
}: UseEditEventProps) => {
  const scheduleModalRef = useRef<HTMLIonModalElement | null>(null);
  const [eventType, setEventType] = useState<EventType>('trial');
  const [selectStudentIsOpen, setSelectStudentIsOpen] = useState(false);
  const addStudentModalState = useModalState();
  const defaultEvent = useMemo(
    () => ({
      ...defaultEventBase,
      start: eventInstanceDate || defaultEventBase.start,
    }),
    [eventInstanceDate]
  );

  const [availableEvent, setAvailableEvent] =
    useState<AvailableEvent>(defaultEvent);
  const [frequencyOption, setFrequencyOption] =
    useState<FrequencyOption>('Never');

  const [contactIds, setContactIds] = useState<string[]>(
    contactId ? [contactId] : []
  );
  const lastContactId = usePrevious(contactId);
  const isNew = eventId === 'add-event';
  const [duration, setDuration] = useState(30);
  const [productId, setProductId] = useState<string | undefined>();
  const [billingCycle, setBillingCycle] = useState<BillingCycle | undefined>();

  const [registrationPolicy, setRegistrationPolicy] =
    useState<RegistrationPolicy>('private');
  const [title, setTitle] = useState('');

  const [products] = useProductsQuery();
  const product = useMemo(
    () => products?.find(({ id }) => id === productId),
    [productId, products]
  );

  const [event] = useEventQuery(eventId);
  const [contract] = useContractQuery(event?.contractIds?.[0]);

  const reset = useCallback(() => {
    if (event) {
      if (event.consumers?.[0]) {
        setContactIds(event.consumers);
      }
    } else {
      setContactIds(contactId ? [contactId] : []);
    }

    setDuration(event?.duration || 30);
    setFrequencyOption(event?.frequency || 'Never');
    setEventType(event?.type || 'trial');
    setTitle(event?.title || '');
    setAvailableEvent(
      event
        ? {
            start: eventInstanceDate || event.start,
            end: event.end || undefined,
            duration: event.duration,
          }
        : defaultEvent
    );

    // Reset product fields
    setProductId(undefined);
    setBillingCycle(undefined);
    setRegistrationPolicy('private');
  }, [event, eventInstanceDate, defaultEvent, contactId]);

  useEffect(reset, [reset]);

  useEffect(() => {
    if (product) {
      const validRegistrationPolicy = product.registrationPolicies?.some(
        (registrationPolicyOption) =>
          registrationPolicyOption === registrationPolicy
      );

      if (!validRegistrationPolicy) {
        const defaultRegistrationPolicy = product.registrationPolicies[0];

        if (defaultRegistrationPolicy) {
          setRegistrationPolicy(defaultRegistrationPolicy);
        }
      }

      const validBillingCycle = product.billingCycles?.some(
        (billingCycleOption) => billingCycleOption === billingCycle
      );

      if (!validBillingCycle) {
        const defaultBillingCycle = product.billingCycles[0];

        if (defaultBillingCycle) {
          setBillingCycle(defaultBillingCycle);
        }
      }

      const validDuration = product.lessonPricing?.some(
        (lessonPrice) => lessonPrice.duration === duration
      );

      if (!validDuration) {
        const defaultLessonPricing = product.lessonPricing.find(
          ({ isDefault }) => isDefault
        );

        if (defaultLessonPricing) {
          setDuration(defaultLessonPricing.duration);
        }
      }
    }
  }, [product, duration, billingCycle, registrationPolicy]);

  // useDefaultProduct
  useEffect(() => {
    if (!productId && products?.[0]) {
      setProductId(products[0].id);
    }
  }, [products, productId]);

  const isValid = useMemo(() => {
    if (!isNew) {
      return true;
    }

    if (eventDetails[eventType].hasProduct) {
      return registrationPolicy === 'public' || contactIds.length > 0;
    } else {
      return Boolean(title);
    }
  }, [title, eventType, registrationPolicy, isNew, contactIds]);

  useEffect(() => {
    if (contactId && lastContactId !== contactId) {
      setContactIds([contactId]);
    }
  }, [lastContactId, contactId]);

  const ongoing = frequencyOption !== 'Never';

  const [contacts] = useContactsQuery({
    skip: contactIds.length === 0,
    constraints: [where('__name__', 'in', contactIds)],
  });

  const basePayload: MutationPayload<'createEvent'> = useMemo(
    () => ({
      duration: availableEvent.duration,
      start: Timestamp.fromDate(availableEvent.start),
      end:
        !ongoing && availableEvent.end
          ? Timestamp.fromDate(availableEvent.end)
          : null,
      type: eventType,
      title,
      frequency: frequencyOption,
      // TODO: add support for notes
      // notes,
    }),
    [availableEvent, ongoing, title, frequencyOption, eventType]
  );

  // Edit product event fields
  const [cancelType, setCancelType] =
    useState<CancelProductType>('cancelThisLesson');
  const [productEditType, setProductEditType] = useState<
    'cancel' | 'reschedule' | undefined
  >(undefined);
  const [cancelationOption, setCancelationOption] = useState<
    CancelationOption | undefined
  >();
  const [rescheduleOption, setRescheduleOption] =
    useState<RescheduleProductOption>('rescheduleThisLesson');

  const [updateEventMutation] = useMutation('updateEvent');
  const [updateProductEventMutation] = useMutation('updateProductEvent');
  const onUpdateEvent = useCallback(
    async (instanceOnly?: boolean) => {
      if (!eventInstanceDate || !eventId) {
        throw new Error('Event instance date and event id are required');
      }

      const baseUpdateFields = {
        eventInstanceDate: Timestamp.fromDate(eventInstanceDate),
        id: eventId,
        instanceOnly: Boolean(instanceOnly),
      };

      onDone?.();

      if (eventType === 'product') {
        return updateProductEventMutation({
          ...baseUpdateFields,
          // TODO: handle `custom` reschedule option
          instanceOnly: rescheduleOption === 'rescheduleThisLesson',
          start: Timestamp.fromDate(availableEvent.start),
          // ...(availableEvent.end && {
          //   end: Timestamp.fromDate(availableEvent.end),
          // }),
        });
      } else {
        return updateEventMutation({ ...basePayload, ...baseUpdateFields });
      }
    },
    [
      eventType,
      onDone,
      updateEventMutation,
      updateProductEventMutation,
      basePayload,
      eventInstanceDate,
      eventId,
      rescheduleOption,
      availableEvent,
    ]
  );

  const [deleteEventMutation] = useMutation('deleteEvent');
  const [deleteProductEventMutation] = useMutation('deleteProductEvent');
  const onDeleteEvent = useCallback(
    (instanceOnly?: boolean) => {
      if (!eventInstanceDate || !eventId) {
        throw new Error('Event instance date and event id are required');
      }

      if (eventType === 'product') {
        if (cancelationOption === undefined) {
          throw new Error('Cancelation option is required');
        }

        return deleteProductEventMutation({
          id: eventId,
          instanceOnly: cancelType === 'cancelThisLesson',
          eventInstanceDate: Timestamp.fromDate(eventInstanceDate),
          cancelationOption,
        });
      } else {
        return deleteEventMutation({
          id: eventId,
          instanceOnly: Boolean(instanceOnly),
          eventInstanceDate: Timestamp.fromDate(eventInstanceDate),
        });
      }
    },
    [
      deleteEventMutation,
      deleteProductEventMutation,
      eventInstanceDate,
      eventId,
      eventType,
      cancelType,
      cancelationOption,
    ]
  );

  const [createEventMutation] = useMutation('createEvent');
  const [createProductEventMutation] = useMutation('createProductEvent');
  const onCreateEvent = useCallback(async () => {
    if (eventType === 'product') {
      if (!billingCycle || !productId) {
        throw new Error('Billing cycle and product id are required');
      }

      createProductEventMutation({
        ...basePayload,
        consumers: contactIds,
        billingCycle,
        registrationPolicy,
        productId,
      });
    } else {
      createEventMutation(basePayload);
    }

    onDone?.();
  }, [
    createEventMutation,
    createProductEventMutation,
    basePayload,
    contactIds,
    billingCycle,
    registrationPolicy,
    productId,
    eventType,
    onDone,
  ]);

  return {
    reset,
    scheduleModalRef,
    editProductEventFields: {
      cancelType,
      setCancelType,
      productEditType,
      setProductEditType,
      cancelationOption,
      setCancelationOption,
      rescheduleOption,
      setRescheduleOption,
    },
    fieldProps: {
      availableEvent,
      setAvailableEvent,
      eventType,
      setEventType,
      frequencyOption,
      setFrequencyOption,
      setSelectStudentIsOpen,
      registrationPolicy,
      setRegistrationPolicy,
      productId,
      setProductId,
      title,
      setTitle,
      duration,
      setDuration,
      billingCycle,
      setBillingCycle,
      contactIds,
      setContactIds,
      selectStudentIsOpen,
    },
    isValid,
    products,
    product,
    contacts,
    addStudentModalState,
    event,
    contract,
    onCreateEvent,
    onUpdateEvent,
    onDeleteEvent,
    ongoingEvent: ongoing,
    recurranceChanged: event?.frequency !== frequencyOption,
    eventId,
    eventInstanceDate,
  };
};

type UseEditEvent = typeof useEditEvent;

export type UseEditEventReturn = ReturnType<UseEditEvent>;
export type EditEventFieldProps = UseEditEventReturn['fieldProps'];
