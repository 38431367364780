import { IonRouterLink } from '@ionic/react';
import { Avatar, Button } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { ParsedContact } from '@shared/models/contacts';

import { AvatarBadge } from '@components/AvatarBadge';
import { useContactAvatar } from '@components/useContactAvatar';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

interface LinkedContactProps {
  linkedContact: ParsedContact;
  onContactSelection?: (contactId: string) => void;
}

export const LinkedContact = ({
  linkedContact,
  onContactSelection,
}: LinkedContactProps) => {
  const organizationSlug = useOrganizationSlug();
  const isDesktop = useMediaQuery('(min-width:747px)');
  const [src] = useContactAvatar(linkedContact);

  if (!src) {
    return null;
  }

  return (
    <IonRouterLink
      routerLink={`/${organizationSlug}/contacts/${linkedContact.id}`}
      routerDirection={isDesktop ? 'none' : 'forward'}
    >
      <Button
        variant="verticalPlain"
        onClick={() => onContactSelection?.(linkedContact.id)}
        startDecorator={
          <AvatarBadge isPrimary={linkedContact.tags.primaryContact}>
            <Avatar
              sx={{
                width: 60,
                height: 60,
              }}
              src={src}
            />
          </AvatarBadge>
        }
      >
        {linkedContact.firstName}
      </Button>
    </IonRouterLink>
  );
};
