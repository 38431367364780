import {
  AddOutlined,
  ArrowForwardIosOutlined,
  SegmentOutlined,
} from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip, buttonClasses } from '@mui/joy';

import { useLesson } from '../LessonProvider/useLesson';
import { initFlattenedSection } from '../LessonProvider/useLessonProviderValue';
import { AddFromLibrary } from './AddFromLibrary';
import { SortableTree } from './Dnd/SortableTree';

export const AssignmentArea = () => {
  const {
    flattenedAssignmentItems,
    setFlattenedAssignmentItems,
    assignmentListRef,
    focusAfter,
    focusAssignmentItem,
  } = useLesson();

  return (
    <Box sx={{ marginTop: '0px', marginLeft: '1px' }}>
      {flattenedAssignmentItems.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Button
            endDecorator={
              <ArrowForwardIosOutlined
                sx={{
                  transform: 'rotate(90deg) translateY(8px) !important',
                }}
              />
            }
            variant="plain"
            color="neutral"
            size="lg"
            sx={(theme) => ({
              paddingLeft: 0,
              paddingRight: 0,
              color: theme.palette.text.primary,
              [`& .${buttonClasses.startDecorator}`]: {
                marginRight: 0,
              },
            })}
          >
            Assignments
          </Button>
          <Box>
            <Tooltip variant="plain" title="Add section">
              <IconButton
                onClick={() => {
                  if (
                    flattenedAssignmentItems.some(
                      (item) => item.type === 'section' && !item.title
                    )
                  ) {
                    return;
                  }

                  const nextSection = initFlattenedSection();
                  setFlattenedAssignmentItems([
                    ...flattenedAssignmentItems,
                    nextSection,
                  ]);
                  setTimeout(() => focusAssignmentItem(nextSection.id));
                }}
              >
                <SegmentOutlined />
              </IconButton>
            </Tooltip>
            {/* <AssignmentHeaderOptions /> */}

            <AddFromLibrary
              onFilterOptionSelect={(option) => {
                console.log({ option });
              }}
            />
            <Tooltip variant="plain" title="Add assignment">
              <IconButton onClick={() => setTimeout(() => focusAfter())}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        ref={assignmentListRef}
      >
        <SortableTree />
        {/* {assignmentList.map((assignmentItem) =>
          assignmentItem.type === 'section' ? (
            <SectionItem key={assignmentItem.id} section={assignmentItem} />
          ) : (
            <AssignmentItem
              key={assignmentItem.id}
              assignment={assignmentItem}
            />
          )
        )} */}
      </Box>
    </Box>
  );
};
