import { useCallback, useEffect, useState } from 'react';

export const useScheduleSave = (handleSave?: () => void) => {
  const [scheduleSave, setScheduleSave] = useState<Promise<void> | undefined>();

  useEffect(() => {
    let mounted = true;

    if (scheduleSave) {
      (async () => {
        await scheduleSave;
        if (mounted) {
          setScheduleSave(undefined);
          handleSave?.();
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [scheduleSave, handleSave]);

  return [
    useCallback(
      () => setScheduleSave(new Promise((resolve) => setTimeout(resolve))),
      []
    ),
  ];
};
