import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { RawOrganization } from '@shared/models/organizations';
import { initializeApp } from 'firebase/app';
import {
  ActionCodeSettings,
  GoogleAuthProvider,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  signOut as firebaseAuthSignOut,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  signInWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  CollectionReference,
  collection,
  connectFirestoreEmulator,
  getFirestore,
} from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';

const url = new URL(window.location.href);

export const devMode =
  process.env.REACT_APP_DEV_MODE === 'true' ||
  url.searchParams.get('dev-mode') === 'true';

if (devMode) {
  console.log('Running app in dev mode…');
}

process.env.__FIREBASE_DEFAULTS__ = '{"forceEnvironment":"browser"}';

export const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PUBLIC_FIREBASE_APP_ID,
});

export const storage = (() => {
  const storageInstance = getStorage(firebaseApp);

  if (devMode) {
    connectStorageEmulator(storageInstance, '127.0.0.1', 9199);
  }

  return storageInstance;
})();

const getFirebaseAuth = () => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    return getAuth();
  }
};

export const auth = (() => {
  if (devMode) {
    const authInstance = getAuth();
    connectAuthEmulator(authInstance, 'http://127.0.0.1:9099', {
      disableWarnings: true,
    });

    return authInstance;
  }

  return getFirebaseAuth();
})();

export const firestore = (() => {
  const firestoreInstance = getFirestore(firebaseApp);
  if (devMode) {
    connectFirestoreEmulator(firestoreInstance, '127.0.0.1', 8080);
  }

  return firestoreInstance;
})();

export const signInWithGoogle = async () => {
  // 1. Create credentials on the native layer
  const result = await FirebaseAuthentication.signInWithGoogle();

  // 2. Sign in on the web layer using the id token
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  const authInstance = getAuth();
  await signInWithCredential(authInstance, credential);

  return result.user?.uid;
};

export const linkWithGoogle = () => FirebaseAuthentication.linkWithGoogle();

export const signUpWithEmail = async (email: string, password: string) =>
  createUserWithEmailAndPassword(auth, email, password);

export const signInWithEmail = async (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password);

export const signOut = async () => {
  // 1. Sign out on the native layer
  await FirebaseAuthentication.signOut();
  // 1. Sign out on the web layer
  const authInstance = getAuth();
  await firebaseAuthSignOut(authInstance);
};

export const useFirebaseAuthState = () => useAuthState(auth);

export const sendPasswordResetEmail = (email: string) =>
  firebaseSendPasswordResetEmail(auth, email);

export const sendEmailVerification = (
  actionCodeSettings?: ActionCodeSettings | null
) => {
  if (!auth.currentUser) {
    throw new Error('No user signed in');
  }

  return firebaseSendEmailVerification(auth.currentUser, actionCodeSettings);
};
export interface OrganizationData extends RawOrganization {
  id: string;
}

export const organizationsCollection = collection(
  firestore,
  'organizations'
) as CollectionReference<RawOrganization>;

export const useAuthUser = () => useAuthState(auth);
