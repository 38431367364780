import { Typography } from '@mui/material';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { HomePageBlock, HomePageBlockProps } from './HomePageBlock';
import { useContactsTagCount } from './useContactsTagCount';

interface ContactTagCountBlockProps extends HomePageBlockProps {
  tag: string;
}

export const ContactTagCountBlock = ({
  tag,
  ...props
}: Omit<ContactTagCountBlockProps, 'to'>) => {
  const organizationSlug = useOrganizationSlug();
  const studentCountQuery = useContactsTagCount(tag);

  return (
    <HomePageBlock to={`/${organizationSlug}/contacts?tags=${tag}`} {...props}>
      <Typography sx={{ color: 'gray' }}>
        <span style={{ paddingRight: 10 }}>{props.title}</span>{' '}
        {studentCountQuery.loading ? '' : studentCountQuery.count}
      </Typography>
    </HomePageBlock>
  );
};
