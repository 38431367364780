import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import InvoicesContent from './InvoicesContent';

const Invoices = () => {
  const organizationSlug = useOrganizationSlug();
  const baseUrl = `/${organizationSlug}/admin`;
  const { productId } = useParams<{ productId: string }>();

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`${baseUrl}/${productId}/overview`}
              color="dark"
            />
          </IonButtons>
          <IonTitle>Invoices</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light">
        <InvoicesContent />
      </IonContent>
    </>
  );
};

export default Invoices;
