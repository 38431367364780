import { useContext } from 'react';

import { EditInvoiceContext } from './EditInvoiceContext';

export const useEditInvoiceContext = () => {
  const currentUserContext = useContext(EditInvoiceContext);

  if (!currentUserContext) {
    throw new Error(
      'useEditInvoiceContext has to be used within <EditInvoiceContext.Provider>'
    );
  }

  return currentUserContext;
};
