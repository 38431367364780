import { useRef, useState } from 'react';

interface UseModalStateProps {
  isOpen?: boolean;
}

export const useModalState = (props?: UseModalStateProps) => {
  const page = useRef<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(props?.isOpen || false);

  return { isOpen, setIsOpen, page };
};

export type ModalState = ReturnType<typeof useModalState>;
