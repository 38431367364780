import { FeatureFlagType } from '@shared/models/featureFlags';
import { and, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { notEmpty } from '@models/events/useEventsQuery';

import { featureFlagsCollection } from './model';
import { parseFeatureFlags } from './parseFeatureFlags';

interface UseFeatureFlagsQueryProps {
  type: FeatureFlagType;
  organizationId?: string;
  userId?: string;
  skip?: boolean;
}

export const useFeatureFlagsQuery = ({
  skip,
  type,
  organizationId,
  userId,
}: UseFeatureFlagsQueryProps) => {
  const [rawFeatureFlags, loading, error] = useCollection(
    skip
      ? null
      : query(
          featureFlagsCollection,
          and(
            ...[
              where('type', '==', type),
              ...(organizationId
                ? [where('organizationId', '==', organizationId)]
                : []),
              ...(userId ? [where('userId', '==', userId)] : []),
            ]
          )
        )
  );

  const featureFlags = useMemo(
    () => rawFeatureFlags?.docs?.map(parseFeatureFlags).filter(notEmpty),
    [rawFeatureFlags]
  );

  return [featureFlags, loading, error] as const;
};
