import { z } from 'zod';

import { zodTimestamp } from '../zodTimestamp';

export const phoneSchema = z.object({
  number: z.string(),
  type: z.string(),
  isPrimary: z.boolean(),
});

export type Phone = z.infer<typeof phoneSchema>;

export const emailAddressSchema = z.string().email();

export const emailSchema = z.object({
  address: emailAddressSchema,
  type: z.string(),
  isPrimary: z.boolean(),
});

export type Email = z.infer<typeof emailSchema>;

export const addressSchema = z.object({
  street1: z.string(),
  street2: z.string(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  country: z.string(),
  type: z.string(),
});

export type Address = z.infer<typeof addressSchema>;

export const userInviteStatus = z.union([
  z.literal('pending'),
  z.literal('accepted'),
]);

export type UserInviteStatus = z.infer<typeof userInviteStatus>;

export const rawContactSchema = z.object({
  createdAt: zodTimestamp,
  updatedAt: zodTimestamp,
  createdBy: z.string(),
  updatedBy: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phones: z.array(phoneSchema).optional(),
  emails: z.array(emailSchema).optional(),
  addresses: z.array(addressSchema).optional(),
  organizationId: z.string(),
  accountId: z.string(),
  tags: z.object({
    primaryContact: z.boolean().optional(),
    student: z.boolean().optional(),
    potentialStudent: z.boolean().optional(),
    teacher: z.boolean().optional(),
  }),
  userId: z.string().optional(),
  userEmail: z.string().optional(),
  userCredentialType: z.string().optional(),
  userLastLogin: zodTimestamp.optional(),
  userInviteStatus: userInviteStatus.optional(),
  instruments: z.array(z.string()).optional(),
  notes: z.string().optional(),
  // 'custom' or name of pre-defined avatar
  avatarSlug: z.string(),
  // Custom avatar file name
  avatar: z.string().optional(),
});

export type RawContact = z.infer<typeof rawContactSchema>;

export interface ParsedContact extends RawContact {
  id: string;
}
