import { RawUser } from '@shared/models/users';
import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { profilesCollection } from '@models/profiles/model';

interface UseProfileQueryProps {
  user: RawUser | undefined;
  organizationId: string | undefined;
}

export const useProfileQuery = ({
  organizationId,
  user,
}: UseProfileQueryProps) => {
  const profileId = user ? `${organizationId}.${user.userId}` : undefined;

  const [profileData, profileLoading] = useDocumentData(
    !user || !organizationId ? null : doc(profilesCollection, profileId)
  );

  return {
    data: useMemo(
      () => (profileData ? { ...profileData, id: profileId } : undefined),
      [profileData, profileId]
    ),
    loading: profileLoading,
  };
};
