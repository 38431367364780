export const instrumentsList = [
  'Piano',
  'Guitar',
  'Voice',
  'Violin',
  'Drums',
  'Flute',
  'Cello',
  'Clarinet',
  'Saxophone',
  'Trumpet',
  'Trombone',
  'Viola',
  'Oboe',
  'Bassoon',
  'Ukulele',
  'Recorder',
  'Mandolin',
  'Harmonica',
  'Accordion',
  'Keyboard',
  'Banjo',
  'Harp',
  'Double Bass',
  'Bass Guitar',
  'Piccolo',
  'French Horn',
  'Tuba',
  'English Horn',
  'Xylophone',
  'Dulcimer',
  'Bongo Drums',
  'Percussion Instruments',
  'Marimba',
  'Synthesizer',
  'Electric Guitar',
  'Organ',
  'Steelpan Drum',
  'Vibraphone',
  'Bagpipes',
  'Conga Drum',
  'Lute',
  'Sitar',
  'Harpsichord',
  'Kalimba',
  'Didgeridoo',
  'Zither',
  'Flugelhorn',
  'Bass Clarinet',
  'Cornet',
  'Baritone Horn',
  'Timpani',
  'Electronic Keyboard',
  'Mridangam',
  'Tabla',
  'Kazoo',
  'Djembe',
  'Steel Guitar',
  'Theremin',
  'Alto Saxophone',
  'Tenor Saxophone',
  'Soprano Saxophone',
  'Melodica',
  'Balalaika',
  'Tambourine',
  'Triangle',
  'Maracas',
  'Ocarina',
  'Hang Drum',
  'Castanets',
  'Cymbals',
  'Pan Flute',
  'Santoor',
  'Bugle',
  'Guzheng',
  'Erhu',
  'Snare Drum',
  'Sarod',
  'Veena',
  'Clavichord',
  'Pan Pipes',
  'Lyre',
  'Gong',
  'Koto',
  'Glockenspiel',
  'Tambura',
  'Guiro',
  'Berimbau',
  'Autoharp',
  'Shruti Box',
  'Quena',
  'Yangqin',
  'Bodhran',
  'Cajon',
  'Sousaphone',
  'Euphonium',
  'Bells',
  'Concertina',
  'Bouzouki',
  'Mandola',
  'Ney (Middle Eastern Flute)',
  'Rubab',
  'Zurna',
  'Mbira',
  'Cuatro',
  'Tar',
  'Pipa',
  'Daf',
  'Duduk',
  'Tzouras',
  'Charango',
  'Darbuka',
  'Keytar',
  'Fiddle',
  'Chapman Stick',
  'Kubing',
  'Jaw Harp',
  'Bandurria',
  'Hammered Dulcimer',
  'Sheng',
  'Pennywhistle',
  'Cor Anglais',
  'Electric Violin',
  'Rainmaker',
  'Udu',
  'Bawu',
  'Mountain Dulcimer',
  'Reyong',
  'Cavaquinho',
  'Lira',
  'Erxian',
  'Barbat',
  'Washboard',
  'Hurdy Gurdy',
  'Contrabassoon',
  'Sarangi',
  'Sursingar',
  'Shehnai',
  'Setar',
  'Tres',
  'Ghatam',
  'Fortepiano',
  'Gittern',
  'Qanun',
  'Cornamuse',
  'Chimes',
  'Shakuhachi',
  'Kora',
  'Octoban',
  'Chitarrone',
  'Castanet',
  'Swanee Whistle',
  'Irish Bouzouki',
  'Native American Flute',
  'Timbales',
  'Tom-tom Drum',
  'Tarogato',
  'Sopranino Recorder',
  'Cittern',
  'Zampogna',
  'Rototom',
  'Khol',
  'Poi Awhiowhio',
  'Pungi',
  'Glass Armonica',
  'Harpejji',
  'Xun (Chinese Flute)',
  'Komuz',
  'Kokle',
  'Hyoshigi',
  "Jew's Harp",
  'Bodhrán Drum',
  'Mountain Banjo',
  'Highland Bagpipes',
  'Cajón Flamenco',
  'Balafon',
  'Domra',
  'Oud',
  'Octapad',
  'Dilruba',
  'Ashbory Bass',
  'Hang (handpan)',
  'Cajon Drum',
  'Mando-Bass',
  'Tiple',
  'Digeridoo',
  'Talkbox',
  'Crwth',
  'Angklung',
  'Tenor Horn',
  'Serpent (wind instrument)',
  'Bandura',
  'Psaltery',
  'Suling',
  'Ruan (Chinese Plucked Lute)',
  'Gaida (Bagpipe)',
  'Morin Khuur (Horsehead Fiddle)',
  'Biwa',
  'Garklein Recorder',
  'Guitalele',
  'Saung (Burmese Harp)',
  'Rabab (Afghan Lute)',
];
