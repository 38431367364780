import {
  ArrowForwardIosOutlined,
  AttachmentOutlined,
  SendOutlined,
} from '@mui/icons-material';
import { Box, Button, IconButton, buttonClasses } from '@mui/joy';

import { useUserData } from '@components/UserData/useUserData';
import { ContactAvatar } from '@features/Contacts/ContactAvatar';

export const Comments = () => {
  const { userContact } = useUserData();

  return (
    <>
      {/* Comments header */}
      <Box sx={{ marginTop: '40px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            endDecorator={
              <ArrowForwardIosOutlined
                sx={{
                  transform: 'rotate(90deg) translateY(8px) !important',
                }}
              />
            }
            variant="plain"
            color="neutral"
            size="lg"
            sx={(theme) => ({
              paddingLeft: 0,
              color: theme.palette.text.primary,
              [`& .${buttonClasses.startDecorator}`]: {
                marginRight: 0,
              },
            })}
          >
            Comments
          </Button>
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <Button variant="plain" color="neutral">
              Unsubscribe
            </Button>
            <IconButton>
              <ContactAvatar
                contact={userContact}
                sx={{ height: '25px', width: '25px' }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Comments */}
      <Box>
        <Box sx={{ position: 'relative' }}>
          <Box
            component="textarea"
            placeholder="Leave a comment…"
            sx={(theme) => ({
              width: '100%',
              marginTop: '15px',
              padding: '15px 15px',
              border: `1px solid ${theme.palette.divider}`,
              outline: 'none',
              resize: 'none',
              borderRadius: '10px',
              minHeight: '100px',
            })}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '10px',
              right: '10px',
              display: 'flex',
              alignContent: 'center',
            }}
          >
            <IconButton
              size="sm"
              sx={(theme) => ({
                color: theme.palette.neutral[500],
                marginBottom: '-5px',
              })}
            >
              <AttachmentOutlined
                sx={{
                  fontSize: '18px',
                  transform: 'rotate(-45deg)',
                }}
              />
            </IconButton>
            <IconButton
              size="sm"
              sx={(theme) => ({
                color: theme.palette.neutral[500],
              })}
            >
              <SendOutlined
                sx={{
                  fontSize: '20px',
                  transform: 'rotate(-40deg)',
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
