import { ParsedContact, RawContact } from '@shared/models/contacts';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useSet } from 'react-use';

import { useUser } from '@features/Organization/organizationSlice';
import { accountsCollection } from '@models/accounts/model';
import { contactsCollection } from '@models/contacts/model';
import { useContactsQuery } from '@models/contacts/useContactsQuery';
import { useMutation } from '@models/mutations/useMutation';
import { serverTimestamp } from '@utils/serverTimestamp';

export interface UseMoveContactsProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  accountId: string;
}

export const useMoveContactsValue = ({
  isOpen,
  accountId,
  page,
  onDone,
}: UseMoveContactsProps) => {
  const user = useUser();
  const [removeInviteMutation] = useMutation('removeInvite');
  const [searchValue, setSearchValue] = useState('');
  const [selected, actions] = useSet(new Set<string>());

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setSearchValue('');
    actions.reset();
  }, [isOpen, actions]);

  const [contacts] = useContactsQuery();

  const accountGroups = useMemo(
    () =>
      (contacts || []).reduce(
        (previous: Record<string, ParsedContact[]>, contact) => ({
          ...previous,
          [contact.accountId]: [
            ...(previous?.[contact.accountId] || []),
            contact,
          ],
        }),
        {}
      ),
    [contacts]
  );

  const showErrorPage = useMemo(
    () =>
      Object.values(accountGroups).some((accountContacts) => {
        const movedContacts = accountContacts.filter((contact) =>
          selected.has(contact.id)
        );
        const movingPrimary = movedContacts.some(
          (contact) => contact.tags.primaryContact
        );

        return (
          movedContacts.length !== 0 &&
          movingPrimary &&
          movedContacts.length !== accountContacts.length
        );
      }),
    [accountGroups, selected]
  );

  const onSubmit = async () => {
    if (!user) {
      throw new Error('User not found');
    }

    await Promise.all(
      [...selected].map(async (contactId) => {
        console.log('Moving contact', contactId, 'to', accountId);

        const existingContact = contacts?.find(
          (contact) => contact.id === contactId
        );

        if (!existingContact) {
          throw new Error('Contact not found');
        }

        if (existingContact.tags.primaryContact) {
          // TODO: also remove pending invites
          if (existingContact.userId) {
            await removeInviteMutation({ contactId: existingContact.id });
          }

          const accountContacts = accountGroups[existingContact.accountId];

          if (!accountContacts) {
            throw new Error('Account contacts not found');
          }

          const movedContacts = accountContacts.filter((contact) =>
            selected.has(contact.id)
          );

          // Remove empty accounts if all contacts are moved
          if (movedContacts.length === accountContacts.length) {
            await deleteDoc(doc(accountsCollection, existingContact.accountId));
          }
        }

        const nextUserContact: Partial<RawContact> = {
          updatedAt: serverTimestamp(),
          updatedBy: user.userId,
          accountId,
          tags: { ...existingContact.tags, primaryContact: false },
        };

        await updateDoc(doc(contactsCollection, contactId), nextUserContact);
      })
    );

    onDone?.();
  };

  return {
    contacts,
    searchValue,
    setSearchValue,
    selected,
    actions,
    isOpen,
    accountId,
    page,
    onDone,
    onSubmit,
    showErrorPage,
  };
};

export type UseMoveContactsReturn = ReturnType<typeof useMoveContactsValue>;
