import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';

import { useLesson } from '../LessonProvider/useLesson';
import { FlattenedItem } from '../LessonProvider/useLessonProviderValue';

interface UsePrioritizedInput<T extends FlattenedItem> {
  assignmentId: string;
  assignmentKey: keyof T;
}

export const usePrioritizedInput = <T extends FlattenedItem>({
  assignmentId,
  assignmentKey,
}: UsePrioritizedInput<T>) => {
  const { setAssignment, flattenedAssignmentItems } = useLesson();

  const assignment = useMemo(
    () =>
      flattenedAssignmentItems.find(({ id }) => id === assignmentId) as
        | T
        | undefined,
    [flattenedAssignmentItems, assignmentId]
  );

  const [isPending, startTransition] = useTransition();
  const [localValue, setLocalValue] = useState(() =>
    String(assignment?.[assignmentKey] ?? '')
  );

  useEffect(() => {
    if (
      isPending ||
      assignment?.[assignmentKey] === undefined ||
      localValue === assignment[assignmentKey]
    ) {
      return;
    }

    const next = assignment[assignmentKey];

    if (next) {
      setLocalValue(String(next));
    }
  }, [
    isPending,
    assignment,
    setLocalValue,
    startTransition,
    localValue,
    assignmentKey,
  ]);

  const setValue = useCallback(
    (next: string) => {
      setLocalValue(next);
      startTransition(() => {
        if (!assignment) {
          return;
        }

        setAssignment({
          ...assignment,
          [assignmentKey]: next,
          ...(assignment.type === 'assignment' && { isPlaceholder: false }),
        });
      });
    },
    [assignment, assignmentKey, setAssignment, startTransition, setLocalValue]
  );

  return [localValue, setValue, isPending] as const;
};
