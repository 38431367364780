import { useUserData } from '@components/UserData/useUserData';

export const useOrganizationId = () => useUserData().organization.data?.id;

export const useUser = () => useUserData().user.data;

export const useOrgainzation = () => useUserData().organization.data;

export const useOrganizationPermissions = () => useUserData().permissions.data;

export const useOrganizationSlug = () => useUserData().organization.data?.slug;
