import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { useProductQuery } from '@models/products/useProductQuery';

import AddProduct from '../AddProduct';

export const ProductDetail = () => {
  const { productId } = useParams<{ productId: string }>();
  const organizationSlug = useOrganizationSlug();
  const [product] = useProductQuery(productId);
  const [isEditing, setIsEditing] = useState(false);
  const pageRef = useRef<HTMLElement | null>(null);

  return (
    <IonPage ref={pageRef}>
      {isEditing ? (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color="dark" onClick={() => setIsEditing(false)}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Product Details</IonTitle>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => setIsEditing(false)}>
                Done
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                defaultHref={`/${organizationSlug}/admin`}
                color="dark"
              />
            </IonButtons>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => setIsEditing(true)}>
                Edit
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        color="light"
        style={{ paddingTop: 50 }}
        className="view-contact"
        fullscreen
      >
        Product Detail Page
        <pre>{JSON.stringify(product, undefined, 2)}</pre>
      </IonContent>
      <AddProduct
        productId={productId}
        presentingPage={pageRef}
        isOpen={isEditing}
        onDone={() => setIsEditing(false)}
      />
    </IonPage>
  );
};
