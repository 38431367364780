import {
  Box,
  Checkbox,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import Avatar from '@components/Avatar';

import { instrumentsList } from './instrumentsList';

interface ReactWindowData {
  selected: Set<string>;
  toggleInstrument: (instrument: string) => void;
  instruments: string[];
}

const itemKey = (index: number, data: ReactWindowData): string =>
  data.instruments[index] ?? 'unknown';

interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: ReactWindowData;
}

const Row = ({
  index,
  style,
  data: { selected, toggleInstrument, instruments },
}: RowProps) => {
  const instrument = instruments[index] || 'unknown';

  return (
    <Box style={style}>
      <ListItem
        onClick={() => toggleInstrument(instrument)}
        data-testid={`instrument-${instrument.toLowerCase()}`}
      >
        <ListItemButton>
          <ListItemDecorator>
            <Avatar
              sx={(theme) => ({
                width: 40,
                height: 40,
                fontSize: 20,
                fontWeight: 400,
                marginRight: '8px',
                backgroundColor: 'transparent',
                padding: '5px',
                [theme.getColorSchemeSelector('dark')]: {
                  filter: 'invert(1)',
                },
              })}
              src={`assets/instruments/${instrument.toLowerCase()}.svg`}
            />
          </ListItemDecorator>
          <ListItemContent>{instrument}</ListItemContent>
          <Checkbox overlay checked={selected.has(instrument)} />
        </ListItemButton>
      </ListItem>
      <ListDivider />
    </Box>
  );
};

interface InstrumentsSelectionProps {
  selectedInstruments: string[];
  setSelectedInstruments: (next: string[]) => void;
  searchValue: string;
}

export const InstrumentsSelection = ({
  selectedInstruments,
  setSelectedInstruments,
  searchValue,
}: InstrumentsSelectionProps) => {
  const selected = useMemo(
    () => new Set(selectedInstruments),
    [selectedInstruments]
  );

  const toggleInstrument = (instrument: string) => {
    if (selected.has(instrument)) {
      selected.delete(instrument);
    } else {
      selected.add(instrument);
    }
    setSelectedInstruments([...selected]);
  };

  const instruments = useMemo(
    () =>
      instrumentsList.filter(
        (instrument) =>
          searchValue === '' || instrument.toLowerCase().includes(searchValue)
      ),
    [searchValue]
  );

  return (
    <List variant="centered" className="full-width">
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={instruments.length}
            itemSize={55}
            itemKey={itemKey}
            itemData={{ selected, toggleInstrument, instruments }}
          >
            {Row}
          </FixedSizeList>
        )}
      </AutoSizer>
    </List>
  );
};
