import { useRef, useState } from 'react';

export const useFilterPopover = <
  T extends HTMLElement = HTMLAnchorElement
>() => {
  const [anchorPosition, setAnchorPosition] = useState<
    { top: number; left: number } | undefined
  >();
  const ref = useRef<T | null>(null);
  const setFilterIsOpen = (open: boolean) => {
    if (!open) {
      setAnchorPosition(undefined);
      return;
    }

    const rect = ref.current?.getBoundingClientRect();

    if (!rect) {
      return;
    }

    setAnchorPosition({ top: rect.bottom, left: rect.left });
  };
  const filterIsOpen = Boolean(anchorPosition);

  return {
    ref,
    anchorPosition,
    setFilterIsOpen,
    filterIsOpen,
  };
};
