import { InfoOutlined, WarningOutlined } from '@mui/icons-material';
import { Typography } from '@mui/joy';

import { themeColors } from '@theme/theme';

interface BuisnessUrlHelperTextProps {
  businessNameIsValid: boolean;
}

export const BuisnessUrlHelperText = ({
  businessNameIsValid,
}: BuisnessUrlHelperTextProps) => (
  <>
    {!businessNameIsValid ? (
      <Typography
        level="body-sm"
        sx={{ padding: '6px 0', color: themeColors.warning }}
      >
        <WarningOutlined
          sx={{
            marginBottom: '-3px',
            fontSize: '14px',
            color: 'currentColor',
          }}
        />{' '}
        Business URL is already taken
      </Typography>
    ) : (
      <Typography level="body-sm" sx={{ padding: '6px 0' }}>
        This will show up in the URL your students see.
        <InfoOutlined sx={{ marginBottom: '-3px', fontSize: '14px' }} />
      </Typography>
    )}
  </>
);
