import { useState } from 'react';

import { expandingTransitionSeconds } from './Calendar';

export const useDrawerState = (initialDate?: Date) => {
  const [expanded, setExpandedBase] = useState(false);
  const [expanding, setExpanding] = useState(false);
  const [currentDate, setCurrentDate] = useState(initialDate || new Date());

  const toggleExpanded = () => {
    setExpandedBase((current) => !current);
    setExpanding(true);
    setTimeout(() => {
      setExpanding(false);
    }, expandingTransitionSeconds * 1000 + 100);
  };

  return {
    expanded,
    expanding,
    currentDate,
    setCurrentDate,
    toggleExpanded,
  };
};
