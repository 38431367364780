import {
  ParsedFeatureFlags,
  RawFeatureFlags,
} from '@shared/models/featureFlags';
import { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';

import { genericParser } from '@utils/genericParser';

export const parseFeatureFlags = (
  doc:
    | QueryDocumentSnapshot<RawFeatureFlags>
    | DocumentSnapshot<RawFeatureFlags>
): ParsedFeatureFlags | undefined => genericParser<RawFeatureFlags>(doc);
