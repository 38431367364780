import { IonModal, IonNav } from '@ionic/react';
import { useMediaQuery } from '@mui/material';

import { MoveContactsProvider } from './MoveContactsContext';
import { MoveContactsOverview } from './MoveContactsOverview';

interface MoveContactsModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  accountId: string;
}

export const MoveContactsModal = (props: MoveContactsModalProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!props.page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={props.page.current}
      isOpen={props.isOpen}
      onDidDismiss={props.onDone}
      style={
        isDesktop ? { '--height': '650px', '--max-width': '900px' } : undefined
      }
    >
      <MoveContactsProvider {...props}>
        <IonNav root={() => <MoveContactsOverview />} />
      </MoveContactsProvider>
    </IonModal>
  );
};
