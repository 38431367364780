import { createContext } from 'react';

import {
  UseEditInvoiceProps,
  UseEditInvoiceReturn,
  useEditInvoice,
} from './useEditInvoice';

export const EditInvoiceContext = createContext<
  UseEditInvoiceReturn | undefined
>(undefined);

interface EditInvoiceProviderProps extends UseEditInvoiceProps {
  children?:
    | React.ReactNode
    | ((props: UseEditInvoiceReturn) => React.ReactNode);
}

export const EditInvoiceProvider = ({
  children,
  ...editInvoiceProps
}: EditInvoiceProviderProps) => {
  const result = useEditInvoice(editInvoiceProps);

  return (
    <EditInvoiceContext.Provider value={result}>
      {typeof children === 'function' ? children(result) : children}
    </EditInvoiceContext.Provider>
  );
};
