import Box, { BoxProps } from '@mui/joy/Box';

export const ModalListContainer = ({ sx, ...props }: BoxProps) => (
  <Box
    sx={[
      {
        paddingBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        textAlign: 'center',
        gap: '40px',
        '& > *:not(.scrollable):not(.full-width)': {
          margin: '0 30px',
        },
        '& .scrollable': {
          flex: 1,
          overflowY: 'auto',
          '& > *': {
            margin: '0 30px',
          },
        },
        '@media (min-width: 747px)': {
          paddingBottom: '30px',
          '& > *:not(.scrollable):not(.full-width)': {
            margin: '0 80px',
          },
          '& .scrollable': {
            '& > *': {
              margin: '0 80px',
            },
          },
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);
