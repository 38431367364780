import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Input, Typography } from '@mui/joy';
import { useEffect, useRef, useState } from 'react';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';

import { BuisnessUrlHelperText } from './BuisnessUrlHelperText';
import { BusinessUrlInput } from './BusinessUrlInput';
import { InnerInput } from './FloatingLabelInput';
import { Page3 } from './Page3';
import { useAppIntro } from './useAppIntro';
import { useValidateBusinessName } from './useValidateBusinessName';

export const Page2 = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    urlSlug,
    setUrlSlug,
    businessName,
    setBusinessName,
  } = useAppIntro();

  const [businessNameIsValid, validationLoading] = useValidateBusinessName({
    urlSlug,
  });

  /**
   * Focus on input when page loads
   */
  useEffect(() => {
    if (focused || firstName) {
      return;
    }

    let mounted = true;

    const timeoutId = setTimeout(() => {
      if (mounted) {
        inputRef.current?.querySelector('input')?.focus();
        setFocused(true);
      }
    }, 400);

    return () => {
      clearTimeout(timeoutId);
      mounted = false;
    };
  }, [focused, firstName]);

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer>
          <ModalTypographyContainer>
            <Typography level="h4">What's your name?</Typography>
            <Typography>
              This is where we collect your name, instruments, and business
              name.
            </Typography>
          </ModalTypographyContainer>

          <Box
            className="scrollable"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <Input
              slots={{ input: InnerInput }}
              slotProps={{
                input: {
                  placeholder: '',
                  type: 'text',
                  name: 'firstName',
                  labelText: 'First Name',
                },
              }}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />

            <Input
              slots={{ input: InnerInput }}
              slotProps={{
                input: {
                  placeholder: '',
                  type: 'text',
                  name: 'lastName',
                  labelText: 'Last Name',
                },
              }}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />

            <Input
              slots={{ input: InnerInput }}
              slotProps={{
                input: {
                  placeholder: '',
                  type: 'text',
                  name: 'businessName',
                  labelText: 'Business Name',
                },
              }}
              value={businessName || ''}
              onChange={(event) => setBusinessName(event.target.value)}
            />

            <Box>
              <BusinessUrlInput
                value={urlSlug}
                onChange={(event) => setUrlSlug(event.target.value)}
              />
              <Box sx={{ padding: '0 8px', textAlign: 'left' }}>
                <BuisnessUrlHelperText
                  businessNameIsValid={businessNameIsValid}
                />
              </Box>
            </Box>
          </Box>

          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            disabled={
              !firstName ||
              !lastName ||
              !businessName ||
              !urlSlug ||
              !businessNameIsValid
            }
            loading={validationLoading}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (event.target instanceof HTMLElement) {
                event.target.closest('ion-nav')?.push(() => <Page3 />);
              }
            }}
            data-testid="page-2-next-button"
          >
            Next
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
