import { animate } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface CounterProps {
  from: number;
  to: number;
}

export const Counter = ({ from, to }: CounterProps) => {
  const nodeRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (!node) {
          return;
        }

        node.textContent = `$${value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
    });

    return () => {
      controls.stop();
    };
  }, [from, to]);

  return <p ref={nodeRef} />;
};
