import { useCallback, useEffect, useState } from 'react';

import { useFirebaseAuthState } from '@utils/firebase';

const slowPollFrequency = 5000;
const mediumPollFrequency = 3000;
const fastPollFrequency = 1000;
const temporaryPollDuration = 60000;

export const useEmailVerification = () => {
  const [authUser] = useFirebaseAuthState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [pollFrequency, setPollFrequency] = useState(slowPollFrequency);
  const [slowDown, setSlowDown] = useState<Promise<void> | undefined>();

  useEffect(() => {
    let mounted = true;
    if (!slowDown) {
      return;
    }

    (async () => {
      await slowDown;

      if (!mounted) {
        return;
      }

      if (pollFrequency === fastPollFrequency) {
        setPollFrequency(mediumPollFrequency);
        setSlowDown(
          new Promise((resolve) =>
            setTimeout(() => resolve, temporaryPollDuration)
          )
        );

        return;
      }

      setSlowDown(undefined);
      setPollFrequency(slowPollFrequency);
    })();

    return () => {
      mounted = false;
    };
  }, [slowDown, pollFrequency]);

  useEffect(() => {
    if (emailVerified) {
      if (!authUser) {
        setEmailVerified(false);
      }

      return;
    }

    let interval: NodeJS.Timeout;

    const update = () => {
      if (authUser?.emailVerified) {
        setEmailVerified(true);
        clearInterval(interval);

        return;
      }
    };

    interval = setInterval(() => {
      update();
      authUser?.reload();
    }, pollFrequency);

    update();

    return () => clearInterval(interval);
  }, [authUser, emailVerified, pollFrequency]);

  const watchEmailVerificationClosely = useCallback(() => {
    setPollFrequency(fastPollFrequency);
    setSlowDown(
      new Promise((resolve) => setTimeout(() => resolve, temporaryPollDuration))
    );
  }, []);

  return { emailVerified, watchEmailVerificationClosely };
};
