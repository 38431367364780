import Typography, { TypographyProps } from '@mui/joy/Typography';

const tagMap = {
  teacher: 'Teacher',
  primary: 'Primary Contact',
  student: 'Student',
  potentialStudent: 'Potential Student',
};

interface ContactTagProps extends TypographyProps {
  type: keyof typeof tagMap;
}

export const ContactTag = ({ type, sx, ...props }: ContactTagProps) => (
  <Tag sx={sx} {...props}>
    {tagMap[type]}
  </Tag>
);

export const Tag = ({ fontSize, sx, ...props }: TypographyProps) => (
  <Typography
    fontSize={fontSize || 12}
    sx={[
      (theme) => ({
        textAlign: 'center',
        borderRadius: '2px',
        padding: '0 8px',
        width: 'fit-content',
        color: theme.palette.text.primary,
        background: theme.palette.background.level1,
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);
