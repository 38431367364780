import { useTheme } from '@emotion/react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import {
  ArrowForwardIos,
  ErrorOutline,
  VpnKeyOutlined,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';

import { TransferPrimarySelection } from './TransferPrimarySelection';
import { useTransferPrimary } from './useTransferPrimary';

interface TransferPrimaryOverviewProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
}

export const TransferPrimaryOverview = (
  props: TransferPrimaryOverviewProps
) => {
  const { existingPrimaryContact } = useTransferPrimary();
  const theme = useTheme();

  return (
    <>
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton color="dark" onClick={props.onDone}>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer>
          <ModalTypographyContainer>
            <Typography level="h4">Transfer Primary Contact</Typography>
            <Typography>
              Transfering the primary contact will move responsibility for the
              this group to another contact.
            </Typography>
          </ModalTypographyContainer>

          <Box className="scrollable">
            {existingPrimaryContact?.userInviteStatus ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '50px',
                  margin: '10px auto 0 auto !important',
                  maxWidth: '400px',
                  textAlign: 'left',
                  '@media (max-width: 450px)': {
                    padding: '0 30px',
                  },
                }}
              >
                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Box
                    sx={{
                      fontSize: '35px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      lineHeight: 1,
                      marginTop: '5px',
                    }}
                  >
                    <ErrorOutline
                      sx={{
                        fontSize: '30px',
                        color: `rgb(${theme.palette.primary.mainChannel})`,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      level="h4"
                      sx={{
                        fontSize: '15px',
                      }}
                    >
                      User{' '}
                      {existingPrimaryContact.userInviteStatus === 'accepted'
                        ? 'Access'
                        : 'Invite'}{' '}
                      Removal
                    </Typography>
                    <Typography
                      sx={{
                        color: 'gray',
                        fontSize: '15px',
                      }}
                    >
                      User{' '}
                      {existingPrimaryContact.userInviteStatus === 'accepted'
                        ? 'access'
                        : 'invite'}{' '}
                      will be removed from the current primary contact.
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: '15px' }}>
                  <Box
                    sx={{
                      fontSize: '35px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      lineHeight: 1,
                      marginTop: '5px',
                    }}
                  >
                    <VpnKeyOutlined
                      sx={{
                        fontSize: '30px',
                        color: `rgb(${theme.palette.primary.mainChannel})`,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      level="h4"
                      sx={{
                        fontSize: '15px',
                      }}
                    >
                      Invite New Primary
                    </Typography>
                    <Typography
                      sx={{
                        color: 'gray',
                        fontSize: '15px',
                      }}
                    >
                      After the transfer you can invite the new primary to log
                      into the app.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Button
            variant="outlined"
            color="neutral"
            size="lg"
            endDecorator={<ArrowForwardIos />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (event.target instanceof HTMLElement) {
                event.target
                  .closest('ion-nav')
                  ?.push(() => <TransferPrimarySelection />);
              }
            }}
          >
            Next
          </Button>
        </ModalListContainer>
      </IonContent>
    </>
  );
};
