import {
  QueryFieldFilterConstraint,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { notEmpty } from '@models/events/useEventsQuery';
import { lessonsCollection } from '@models/lessons/model';

import { parseLesson } from './parseLesson';

interface UseLessonsQueryProps {
  constraints?: QueryFieldFilterConstraint[];
  limit?: number;
  skip?: boolean;
}

export const useLessonsQuery = ({
  skip,
  limit: limitBase,
  constraints,
}: UseLessonsQueryProps = {}) => {
  const user = useUser();
  const organizationId = useOrganizationId();

  const accountConstraints =
    (organizationId &&
      user?.organizationAccounts[organizationId]?.map((accountId) =>
        where('accountId', '==', accountId)
      )) ||
    [];

  const [rawLessons, loading, error] = useCollection(
    !user || !organizationId || skip
      ? null
      : query(
          lessonsCollection,
          ...[
            where('organizationId', '==', organizationId),
            ...(constraints || []),
          ],
          ...accountConstraints,
          orderBy('lessonDate', 'desc'),
          ...(limitBase ? [limit(limitBase)] : [])
        )
  );

  const lessons = useMemo(
    () =>
      rawLessons?.docs
        ?.map(parseLesson)
        .filter(notEmpty)
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt.toMillis() - b.createdAt.toMillis();
          }

          return 0;
        }),
    [rawLessons]
  );

  return [lessons, loading, error] as const;
};
