import { createContext } from 'react';

import {
  UseMediaProviderReturn,
  useMediaProviderValue,
} from './useMediaProviderValue';

export const MediaProviderContext = createContext<
  UseMediaProviderReturn | undefined
>(undefined);

interface MediaProviderProviderProps {
  children?:
    | React.ReactNode
    | ((props: UseMediaProviderReturn) => React.ReactNode);
}

export const MediaProvider = ({ children }: MediaProviderProviderProps) => {
  const result = useMediaProviderValue();

  return (
    <MediaProviderContext.Provider value={result}>
      {typeof children === 'function' ? children(result) : children}
    </MediaProviderContext.Provider>
  );
};
