import { and, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { notEmpty } from '@models/events/useEventsQuery';

import { invitesCollection } from './model';
import { parseInvite } from './parseInvite';

interface UseInvitesQueryProps {
  contactId: string;
  skip?: boolean;
}

//
export const useInvitesQuery = ({ skip, contactId }: UseInvitesQueryProps) => {
  const organizationId = useOrganizationId();
  const [rawInvites, loading, error] = useCollection(
    !contactId || !organizationId || skip
      ? null
      : query(
          invitesCollection,
          and(
            where('organizationId', '==', organizationId),
            where('contactId', '==', contactId)
          )
        )
  );

  const invites = useMemo(
    () => rawInvites?.docs?.map(parseInvite).filter(notEmpty),
    [rawInvites]
  );

  return [invites, loading, error] as const;
};
