import { Star } from '@mui/icons-material';
import { Box, Button, Input, Tooltip } from '@mui/joy';
import { Email } from '@shared/models/contacts';

import { EditContactItemContainer } from './EditContactItemContainer';
import { EditContactItemType } from './EditContactItemType';
import { normalizeIsPrimary } from './EditPhones';

interface EditEmailsProps {
  emails: Email[];
  setEmails: React.Dispatch<React.SetStateAction<Email[]>>;
  page: React.MutableRefObject<HTMLElement | null>;
}

export const EditEmails = ({
  emails,
  setEmails: setEmailsBase,
  page,
}: EditEmailsProps) => {
  const setEmails = (next: Email[]) => setEmailsBase(normalizeIsPrimary(next));

  return (
    <EditContactItemContainer
      addButton={
        <Button
          variant="plain"
          color="neutral"
          data-testid="add-email-button"
          onClick={() =>
            setEmails([
              ...emails,
              { address: '', type: 'mobile', isPrimary: emails.length === 0 },
            ])
          }
        >
          + Add Email
        </Button>
      }
    >
      {emails.map((email, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="email"
            placeholder="Email"
            name="email"
            variant="plain"
            color="neutral"
            value={email.address}
            onChange={(event) => {
              setEmails(
                emails.map((item, i) =>
                  i === index
                    ? { ...item, address: String(event.target.value) }
                    : item
                )
              );
            }}
          />
          {email.isPrimary ? (
            <Tooltip
              variant="plain"
              title="The primary email is used for sending automated email messages"
            >
              <Star sx={{ marginRight: '8px', color: 'lightgray' }} />
            </Tooltip>
          ) : null}
          <EditContactItemType
            label={email.type}
            setLabel={(nextValue) =>
              setEmails(
                emails.map((item, i) =>
                  i === index ? { ...item, type: nextValue } : item
                )
              )
            }
            page={page}
            labelType="Email"
            onDeleteEntity={() =>
              setEmails(emails.filter((_, itemIndex) => itemIndex !== index))
            }
            isPrimary={email.isPrimary}
            setPrimary={(nextValue) =>
              setEmails(
                emails.map((item, i) =>
                  i === index
                    ? { ...item, isPrimary: nextValue }
                    : { ...item, isPrimary: false }
                )
              )
            }
          />
        </Box>
      ))}
    </EditContactItemContainer>
  );
};
