import { Typography } from '@mui/material';
import { usePrevious } from 'react-use';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { Counter } from './Counter';
import { HomePageBlock } from './HomePageBlock';
import { useXMonthsRevenue } from './useXMonthsRevenue';

interface LastMonthsRevenueBlockProps {
  title?: string;
  monthOffset?: number;
}

export const XMonthsRevenueBlock = ({
  title = 'This',
  monthOffset = 0,
}: LastMonthsRevenueBlockProps) => {
  const organizationSlug = useOrganizationSlug();
  const lastMonthsRevenueQuery = useXMonthsRevenue(monthOffset);
  const previousLastMonthsRevenue =
    usePrevious(lastMonthsRevenueQuery.amount) || 0;

  return (
    <HomePageBlock
      title={`${title} Month's Revenue`}
      color="#ffdee3"
      to={`/${organizationSlug}/admin/invoices`}
    >
      <Typography
        fontSize={50}
        variant="h1"
        style={{ display: 'flex', width: 268 }}
      >
        {!lastMonthsRevenueQuery.loading &&
        lastMonthsRevenueQuery.amount !== undefined ? (
          <Counter
            from={previousLastMonthsRevenue}
            to={lastMonthsRevenueQuery.amount || 0}
          />
        ) : null}
      </Typography>
    </HomePageBlock>
  );
};
