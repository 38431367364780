import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, buttonClasses } from '@mui/joy';
import { useMediaQuery } from '@mui/system';
import { checkmark } from 'ionicons/icons';
import { useState } from 'react';

import { ListHeader } from '@components/ListHeader';
import { ListHelperText } from '@components/ListHelperText';
import { inputHeight } from '@theme/theme';

const labelOptions = [
  'mobile',
  'home',
  'work',
  'school',
  'main',
  'home Fax',
  'work Fax',
  'page',
  'other',
];

const labelTypeMap: Record<string, string> = {
  Phone: 'SMS',
  Email: 'email',
};

interface EditContactItemTypeProps {
  labelType: string;
  onDeleteEntity: () => void;
  label: string;
  setLabel: (newLabel: string) => void;
  isPrimary?: boolean;
  setPrimary?: (next: boolean) => void;
  page: React.MutableRefObject<HTMLElement | null>;
}

export const EditContactItemType = ({
  labelType,
  onDeleteEntity,
  label,
  setLabel,
  page,
  isPrimary,
  setPrimary,
}: EditContactItemTypeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width:747px)');

  return (
    <>
      <Box
        className="contact-item-buttons"
        sx={{
          display: 'flex',
          alignItems: 'center',
          [`& .${buttonClasses.root}`]: {
            borderLeft: '1px solid lightgray',
            borderRadius: 0,
            padddingTop: 0,
            padddingBottom: 0,
            height: '100%',
            minHeight: inputHeight,
            '@media (max-width: 746px)': {
              paddingLeft: '5px',
              paddingRight: '5px',
            },
          },
        }}
      >
        <Button
          variant="plain"
          color="neutral"
          className="edit-contact-item-type-button"
          endDecorator={<ArrowForwardIos sx={{ fontSize: '10px' }} />}
          onClick={() => setIsOpen(true)}
        >
          {label}
        </Button>
        <Button
          className="edit-contact-item-delete-button"
          onClick={onDeleteEntity}
          variant="plain"
          color="neutral"
        >
          Delete
        </Button>
      </Box>

      <IonModal
        presentingElement={page.current || undefined}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        style={
          isDesktop
            ? { '--height': isPrimary === undefined ? '530px' : '650px' }
            : undefined
        }
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{labelType} Type</IonTitle>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => setIsOpen(false)}>
                Done
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
          {isPrimary !== undefined ? (
            <Box>
              <IonList
                className="hide-last-border"
                style={{ marginTop: '20px' }}
              >
                <IonItem>
                  <IonToggle
                    checked={isPrimary}
                    onIonChange={(event) => setPrimary?.(event.detail.checked)}
                    color="success"
                  >
                    Primary {labelType}
                  </IonToggle>
                </IonItem>
              </IonList>

              <ListHelperText sx={{ marginTop: '10px', paddingLeft: '16px' }}>
                The primary {labelType.toLowerCase()} is used for sending
                automated {labelTypeMap[labelType]} messages.
              </ListHelperText>
            </Box>
          ) : null}
          <Box>
            <ListHeader sx={{ marginBottom: '10px', marginTop: 0 }}>
              {labelType} Type
            </ListHeader>
            <IonList className="hide-last-border">
              {labelOptions.map((option, index) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setLabel(option);
                  }}
                >
                  {option === label ? (
                    <IonIcon icon={checkmark} slot="end" color="primary" />
                  ) : undefined}
                  {option}
                </IonItem>
              ))}
            </IonList>
          </Box>
        </IonContent>
      </IonModal>
    </>
  );
};
