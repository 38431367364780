import { IonItem, IonList } from '@ionic/react';
import { where } from 'firebase/firestore';

import { ListHeader } from '@components/ListHeader';
import { getContactName } from '@features/Contacts/ContactDetail/ViewContact';
import {
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import { useContactsQuery } from '@models/contacts/useContactsQuery';

import { useEditEventContext } from '../AddEvent/EditEventContext/useEditEventContext';
import AgendaEvent from '../AgendaEvent';

export const pluralize = (word: string, count?: number) =>
  count === 1 ? word : `${word}s`;

export const ViewEvent = () => {
  const {
    fieldProps: { contactIds, eventType },
    editProductEventFields: { setProductEditType },
    event,
  } = useEditEventContext();
  const organizationSlug = useOrganizationSlug();
  const user = useUser();

  const [relatedContacts] = useContactsQuery({
    constraints: [where('__name__', 'in', contactIds)],
    skip: !contactIds?.length,
  });

  return (
    <>
      {event ? (
        <IonList inset>
          <AgendaEvent event={event} />
        </IonList>
      ) : null}
      <ListHeader inset>
        {pluralize('Student', relatedContacts?.length)}
      </ListHeader>
      <IonList inset>
        {relatedContacts?.map((linkedContact) => (
          <IonItem
            key={linkedContact.id}
            routerLink={`/${organizationSlug}/contacts/${linkedContact.id}`}
          >
            {getContactName(linkedContact, user)}
          </IonItem>
        ))}
      </IonList>
      {eventType === 'product' ? (
        <IonList inset>
          <IonItem onClick={() => setProductEditType('reschedule')}>
            Reschedule Lesson
          </IonItem>
          <IonItem onClick={() => setProductEditType('cancel')}>
            Cancel Lesson
          </IonItem>
        </IonList>
      ) : null}
    </>
  );
};
