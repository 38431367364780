import { IonContent, IonHeader, IonToolbar } from '@ionic/react';
import {
  AccessAlarm,
  ArrowForwardIos,
  MusicNoteOutlined,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';

import { ModalListContainer } from '@components/Modal/ModalListContainer';
import { ModalTypographyContainer } from '@components/Modal/ModalTypographyContainer';
import theme from '@theme/theme';

import { Page2 } from './Page2';

export const Page1 = () => (
  <>
    <IonHeader>
      <IonToolbar class="no-background" />
    </IonHeader>
    <IonContent>
      <ModalListContainer>
        <ModalTypographyContainer>
          {/* TODO: move into theme */}
          <Typography
            sx={{
              fontFamily: '"Times New Roman", Times, serif',
              fontSize: '38px',
              fontWeight: 500,
            }}
            level="h4"
          >
            Let's Harmonize
          </Typography>
        </ModalTypographyContainer>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '50px',
            marginTop: '55px',
            padding: '0 10px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Box
              sx={{
                fontSize: '35px',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              <MusicNoteOutlined
                sx={{
                  fontSize: '50px',
                  color: theme.palette.primary.mainChannel,
                }}
              />
            </Box>
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: '15px',
                }}
              >
                Benifit #1 of using Harmonize
              </Typography>
              <Typography
                sx={{
                  color: 'gray',
                  fontSize: '15px',
                }}
              >
                Details of using Harmonize related to benifit #1.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Box
              sx={{
                fontSize: '35px',
                display: 'flex',
                alignItems: 'center',
                lineHeight: 1,
              }}
            >
              <AccessAlarm
                sx={{
                  fontSize: '50px',
                  color: theme.palette.primary.mainChannel,
                }}
              />
            </Box>
            <Box>
              <Typography
                level="h4"
                sx={{
                  fontSize: '15px',
                }}
              >
                Benifit #2 of using Harmonize
              </Typography>
              <Typography
                sx={{
                  color: 'gray',
                  fontSize: '15px',
                }}
              >
                Details of using Harmonize related to benifit #2.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="scrollable" />

        <Button
          variant="outlined"
          color="neutral"
          size="lg"
          endDecorator={<ArrowForwardIos />}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            if (event.target instanceof HTMLElement) {
              event.target.closest('ion-nav')?.push(() => <Page2 />);
            }
          }}
          data-testid="page-1-next-button"
        >
          Next
        </Button>
      </ModalListContainer>
    </IonContent>
  </>
);
