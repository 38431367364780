import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const IonicLinkBase = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'unstyled',
})<{ unstyled?: boolean }>(({ unstyled }) =>
  unstyled ? { textDecoration: 'none' } : undefined
);

type IonicLinkProps = Omit<React.ComponentProps<typeof IonicLinkBase>, 'to'> & {
  to?: string;
};

/**
 * @deprecated Use `IonicLink2` instead.
 */
export const IonicLink = ({
  to,
  children,
  unstyled,
  ...props
}: IonicLinkProps) => {
  if (!to) {
    return <span {...props}>{children}</span>;
  }

  return (
    <IonicLinkBase to={to} unstyled={unstyled} {...props}>
      {children}
    </IonicLinkBase>
  );
};
