import { IonButton, IonDatetime, IonItem } from '@ionic/react';
import dayjs from 'dayjs';

import './DatePickerItem.css';

interface DatePickerItemProps {
  ionDatetimeProps?: React.ComponentProps<typeof IonDatetime>;
  children?: React.ReactNode;
  utcDate?: Date;
  onUtcDate?: (next: Date) => void;
  hideBottomDivider?: boolean;
  index?: number;
  openIndex?: number;
  onOpenIndexChange?: (index?: number) => void;
}

export const DatePickerItem = ({
  children,
  ionDatetimeProps,
  onUtcDate,
  utcDate,
  hideBottomDivider,
  index,
  openIndex,
  onOpenIndexChange,
}: DatePickerItemProps) => {
  if (!utcDate) {
    return null;
  }

  const showDatePicker = index === openIndex;

  return (
    <>
      <IonItem
        className={
          showDatePicker || !hideBottomDivider ? undefined : 'hide-item-border'
        }
      >
        {children}
        <div slot="end">
          <IonButton
            onClick={() =>
              onOpenIndexChange?.(showDatePicker ? undefined : index)
            }
            color="light"
          >
            {dayjs(utcDate).tz('UTC').format('MMM D, YYYY')}
          </IonButton>
        </div>
      </IonItem>
      <IonItem
        style={{
          maxHeight: showDatePicker ? 351 : 0,
          transition: '0.3s all ease-in-out',
        }}
        className="hide-active-color"
      >
        <IonDatetime
          presentation="date"
          max="2050-10-31"
          value={new Date(
            Date.UTC(
              utcDate.getUTCFullYear(),
              utcDate.getUTCMonth(),
              utcDate.getUTCDate(),
              0,
              0,
              0
            )
          )?.toISOString()}
          onIonChange={(event) => {
            if (typeof event.detail.value === 'string') {
              const base = dayjs(event.detail.value).tz('UTC').toDate();

              onUtcDate?.(
                new Date(
                  Date.UTC(
                    base.getUTCFullYear(),
                    base.getUTCMonth(),
                    base.getUTCDate(),
                    0,
                    0,
                    0
                  )
                )
              );
            }
          }}
          {...ionDatetimeProps}
        />
      </IonItem>
    </>
  );
};
