import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography } from '@mui/joy';

import { ContentContainer } from '@features/Contacts/ContentContainer';

export const ThemeTypography = () => {
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar class="no-background">
          <IonTitle>Typography</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <Box sx={{ p: 5 }}>
            <Typography level="h1">
              h1 - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="h2">
              h2 - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="h3">
              h3 - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="h4">
              h4 - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="title-lg">
              title-lg - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="title-md">
              title-md - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="title-sm">
              title-sm - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="body-lg">
              body-lg - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="body-md">
              body-md - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="body-sm">
              body-sm - The quick brown fox jumps over the lazy dog
            </Typography>
            <Typography level="body-xs">
              body-xs - The quick brown fox jumps over the lazy dog
            </Typography>
          </Box>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};
