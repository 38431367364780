import { doc, getDoc } from '@firebase/firestore';
import { useIonRouter } from '@ionic/react';
import { RawOrganization } from '@shared/models/organizations';
import { useCallback } from 'react';
import { useSearchParam } from 'react-use';

import { getRunMutation } from '@models/mutations/runMutation';
import { devMode, firestore, sendEmailVerification } from '@utils/firebase';

interface SetupUserProps {
  userId?: string;
  verifyEmail?: boolean;
}

interface UseSetupUserProps {
  settingUpUser: boolean;
  setSettingUpUser: (input: boolean) => void;
  watchEmailVerificationClosely: () => void;
}

export const useSetupUser = ({
  settingUpUser,
  setSettingUpUser,
  watchEmailVerificationClosely,
}: UseSetupUserProps) => {
  const code = useSearchParam('invite');
  const router = useIonRouter();

  const setupUser = useCallback(
    async ({ userId, verifyEmail }: SetupUserProps) => {
      if (!userId) {
        throw new Error('Missing user ID');
      }

      setSettingUpUser(true);
      const { response } = code
        ? await getRunMutation({
            action: 'acceptInvite',
            organizationId: undefined,
            userId,
          })({ code }, userId)
        : await getRunMutation({
            action: 'createOrganization',
            organizationId: undefined,
            userId,
          })({ userId }, userId);

      const meta = response?.meta;
      const expired = Boolean(meta?.expired);

      if (expired) {
        setSettingUpUser(false);

        router.push('/almost-done');

        return;
      }

      const organizationId = response?.id;
      if (!organizationId || typeof organizationId !== 'string') {
        throw new Error('Organization not created successfully');
      }

      const organizationDoc = await getDoc(
        doc(firestore, 'organizations', organizationId)
      );

      const organization = organizationDoc.data() as
        | RawOrganization
        | undefined;

      if (!organization) {
        throw new Error('Organization not found');
      }

      if (!code && verifyEmail) {
        const devModeParam = devMode ? '?dev-mode=true' : '';
        await sendEmailVerification({
          url: `${window.location.origin}/${organization.slug}/home${devModeParam}`,
        });
        watchEmailVerificationClosely();
      }

      router.push(`/${organization.slug}/home`);
      setSettingUpUser(false);
    },
    [router, code, setSettingUpUser, watchEmailVerificationClosely]
  );

  return {
    run: setupUser,
    loading: settingUpUser,
  };
};
