import { RawContact } from '@shared/models/contacts';
import { User as FirebaseUser } from 'firebase/auth';

import { serverTimestamp } from '@utils/serverTimestamp';

export const getUserLoginFields = (
  authUser: FirebaseUser | null | undefined
): Partial<RawContact> => {
  const userEmail = authUser?.email;
  const userCredentialType = authUser?.providerData[0]?.providerId;

  return {
    ...(userEmail && { userEmail }),
    ...(userCredentialType && { userCredentialType }),
    userLastLogin: serverTimestamp(),
  };
};
