import { IonIcon } from '@ionic/react';
import { Box } from '@mui/joy';
import { chevronForward } from 'ionicons/icons';
import { useState } from 'react';

import { themeColors } from '@theme/theme';

interface InvoiceItemProps {
  keyName?: string;
  value?: string;
  to?: string;
  details?: React.ReactNode;
  size?: 'small' | 'large';
}

export const InvoiceItem = ({
  to,
  details,
  keyName,
  value,
  size,
}: InvoiceItemProps) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: size === 'small' ? '0 0 8px 0' : '20px',
        }}
      >
        <Box
          sx={{
            color: to || details ? themeColors.darkerBlue : 'gray',
            fontSize: size === 'small' ? 12 : undefined,
          }}
          onClick={() => setShowDetails((current) => !current)}
        >
          {keyName}
          {to || details ? (
            <>
              {' '}
              <IonIcon
                ios={chevronForward}
                md={chevronForward}
                style={{
                  fontSize: 12,
                  transition: '0.4s transform',
                  ...(details
                    ? { transform: 'rotate(90deg)' }
                    : { marginBottom: -2, marginLeft: -4 }),
                  ...(showDetails
                    ? { transform: 'rotate(-90deg)' }
                    : undefined),
                }}
              />
            </>
          ) : null}
        </Box>
        <Box
          color="black"
          sx={{
            fontSize: size === 'small' ? 12 : undefined,
            paddingLeft: '10px',
            textAlign: 'right',
          }}
        >
          {value}
        </Box>
      </Box>
      {showDetails ? <Box>{details}</Box> : null}
    </>
  );
};
