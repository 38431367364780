import {
  IonActionSheet,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonNote,
  IonText,
  IonToggle,
  useIonRouter,
} from '@ionic/react';
import { Box } from '@mui/joy';
import { deleteDoc, doc } from 'firebase/firestore';
import { addCircleOutline, removeCircle } from 'ionicons/icons';
import { useState } from 'react';

import { DatePickerItem } from '@components/DatePickerItem';
import { ListHeader } from '@components/ListHeader';
import { ListHelperText } from '@components/ListHelperText';
import { getSlidingElement } from '@features/Admin/Products/AddProduct/AddProductOverview';
import { toCurrency } from '@features/Admin/Products/AddProduct/pricingUtils';
import { invoicesCollection } from '@models/invoices/model';
import { themeColors } from '@theme/theme';

import './EditInvoice.css';
import { useEditInvoiceContext } from './useEditProductContext';

export const EditInvoice = () => {
  const {
    invoiceIssueDate,
    setInvoiceIssueDate,
    dueDate,
    setDueDate,
    lineItems,
    addLineItem,
    deleteLineItem,
    updateLineItem,
    invoice,
  } = useEditInvoiceContext();
  const [openIndex, setOpenIndex] = useState<number | undefined>(undefined);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const router = useIonRouter();
  const base = lineItems.map((lineItem, index) => ({ ...lineItem, index }));

  const systemLineItems = base.filter(
    ({ createdBy }) => createdBy === 'system'
  );
  const manualLineItems = base.filter(
    ({ createdBy }) => createdBy !== 'system'
  );

  return (
    <>
      {invoice?.createdBy !== 'system' ? (
        <>
          <IonList inset>
            <DatePickerItem
              utcDate={invoiceIssueDate}
              onUtcDate={setInvoiceIssueDate}
              index={0}
              openIndex={openIndex}
              onOpenIndexChange={setOpenIndex}
            >
              Issue Date
            </DatePickerItem>
            <DatePickerItem
              utcDate={dueDate}
              onUtcDate={setDueDate}
              index={2}
              openIndex={openIndex}
              onOpenIndexChange={setOpenIndex}
              hideBottomDivider
            >
              Due Date
            </DatePickerItem>
          </IonList>
        </>
      ) : null}

      {systemLineItems.length > 0 ? (
        <>
          <ListHeader inset>Generated Line Items</ListHeader>
          <IonList inset>
            {systemLineItems.map((lineItem, index) => (
              <IonItem
                key={index}
                style={
                  lineItem.disabled
                    ? { textDecoration: 'line-through', color: 'gray' }
                    : undefined
                }
              >
                <IonToggle
                  checked={!lineItem.disabled}
                  onIonChange={(event) =>
                    updateLineItem(lineItem.index, {
                      ...lineItem,
                      disabled: !event.detail.checked,
                    })
                  }
                  color="success"
                >
                  ${toCurrency(lineItem.amount)}
                  <IonNote> - {lineItem.description}</IonNote>
                </IonToggle>
              </IonItem>
            ))}
          </IonList>

          <ListHelperText inset>
            You can disable any generated line item that you do not want to
            appear on the invoice.
          </ListHelperText>
        </>
      ) : null}

      {manualLineItems.length > 0 ? (
        <ListHeader inset>
          {invoice?.createdBy === 'system' ? 'Manual ' : ''}Line Items
        </ListHeader>
      ) : null}
      {manualLineItems.map((lineItem) => (
        <IonList inset key={lineItem.index}>
          <IonItemSliding>
            <IonItem className="hide-item-border">
              <IonButton
                slot="start"
                className="remove-button"
                onClick={(event: any) => {
                  event.target?.parentElement?.parentElement?.open();
                }}
              >
                <IonIcon
                  style={{ fontSize: 25 }}
                  slot="icon-only"
                  icon={removeCircle}
                  color="danger"
                />
              </IonButton>
              <div>
                <Box sx={{ borderBottom: '1px solid lightgray' }}>
                  <IonInput
                    style={{ textAlign: 'right', marginRight: 5 }}
                    label="Description"
                    placeholder="Line item description…"
                    value={lineItem.description}
                    onIonChange={(event) =>
                      updateLineItem(lineItem.index, {
                        ...lineItem,
                        description: String(event.detail.value),
                      })
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IonInput
                    style={{ textAlign: 'right', marginRight: 5 }}
                    label="Amount"
                    type="number"
                    value={lineItem.amount}
                    onIonChange={(event) =>
                      updateLineItem(lineItem.index, {
                        ...lineItem,
                        amount: Number(event.detail.value),
                      })
                    }
                  />
                  USD
                </Box>
              </div>
            </IonItem>
            <IonItemOptions>
              <IonItemOption
                color="danger"
                onClick={(event) => {
                  deleteLineItem(lineItem.index);
                  getSlidingElement(event)?.close();
                }}
              >
                Delete
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        </IonList>
      ))}

      <IonList inset>
        <IonItem
          button
          detail={false}
          onClick={addLineItem}
          style={{ color: themeColors.darkerBlue }}
          lines="none"
        >
          <IonIcon
            icon={addCircleOutline}
            style={{ color: themeColors.darkerBlue, marginRight: 5 }}
          />{' '}
          Add Line Item
        </IonItem>
      </IonList>

      {invoice && invoice.createdBy !== 'system' ? (
        <>
          <IonList inset>
            <IonItem
              style={{ alignText: 'center' }}
              onClick={() => setIsActionSheetOpen(true)}
            >
              <IonText
                slot="start"
                color="danger"
                style={{ width: '100%', textAlign: 'center' }}
              >
                Delete
              </IonText>
            </IonItem>
          </IonList>
          <IonActionSheet
            isOpen={isActionSheetOpen}
            header="Are you sure you want to delete this invoice?"
            buttons={[
              {
                text: 'Delete',
                role: 'destructive',
                data: {
                  action: 'delete',
                },
                handler: async () => {
                  if (!invoice) {
                    return;
                  }

                  await deleteDoc(doc(invoicesCollection, invoice.id));
                  router.goBack();
                },
              },
              {
                text: 'Cancel',
                role: 'cancel',
                data: {
                  action: 'cancel',
                },
              },
            ]}
            onDidDismiss={() => setIsActionSheetOpen(false)}
          />
        </>
      ) : null}

      <Box sx={{ paddingBottom: 8 }} />
    </>
  );
};
