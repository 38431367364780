import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { contractsCollection } from './model';
import { parseContract } from './parseContract';

export const useContractQuery = (contractId: string | undefined) => {
  const [contractSnapshot, loading, error] = useDocument(
    contractId ? doc(contractsCollection, contractId) : null
  );

  const contract = useMemo(
    () => (contractSnapshot ? parseContract(contractSnapshot) : undefined),
    [contractSnapshot]
  );

  return [contract, loading, error] as const;
};
