import { IonModal } from '@ionic/react';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
} from '@mui/joy';
import { EventHistoryType } from '@shared/models/eventHistory';
import { ParsedInvoice, PaymentMethod } from '@shared/models/invoices';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';

import { IonicLink } from '@components/IonicLink';
import { toCurrency } from '@features/Admin/Products/AddProduct/pricingUtils';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { useEditInvoiceContext } from '../EditInvoice/useEditProductContext';
import { InvoiceHeader } from '../InvoiceHeader';
import { InvoiceItem } from '../InvoiceItem';
import { MarkAsPaidModal } from './MarkAsPaidModal';
import { ShareWithLink } from './ShareWithLink';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getInvoiceDateRange = (invoice: ParsedInvoice) =>
  invoice.createdBy === 'system'
    ? `${dayjs(invoice.start.toDate()).tz('UTC').format('M/D/YYYY')} - ${dayjs(
        invoice.end?.toDate()
      )
        .tz('UTC')
        .format('M/D/YYYY')}`
    : `${dayjs(invoice.issueDate.toDate()).tz('UTC').format('MMMM D, YYYY')}`;

const cancellationOptionMap: Record<EventHistoryType, string> = {
  cancelPermanently: 'Cancelled permanently',
  cancelWithoutRefund: 'Cancelled without refund',
  refundLesson: 'Refunded',
  saveLesson: 'Lesson saved',
  immediateReschedule: 'Rescheduled',
};

const invoicePaymentMap: Record<PaymentMethod, string> = {
  cash: 'Cash',
  check: 'Check',
  other: 'Other',
  credit: 'Credit',
};

interface ViewInvoiceProps {
  pageRef: React.RefObject<HTMLDivElement>;
  viewOnly?: boolean;
}

export const ViewInvoice = ({ pageRef, viewOnly }: ViewInvoiceProps) => {
  const organizationSlug = useOrganizationSlug();
  const { invoice } = useEditInvoiceContext();
  const [markingAsPaid, setMarkingAsPaid] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  return (
    <Box sx={{ padding: '12px 20px' }}>
      {invoice ? (
        <>
          <InvoiceHeader
            amount={invoice.amount}
            invoiceStatus={invoice.status}
          />
          {!viewOnly ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '32px 18px',
                gap: 1,
              }}
            >
              <Button onClick={() => setMarkingAsPaid(true)}>
                {invoice.status === 'paid' ? 'Mark as Unpaid' : 'Mark as Paid'}
              </Button>
              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{
                    root: { variant: 'outlined', color: 'neutral' },
                  }}
                >
                  <MoreVert />
                </MenuButton>
                <Menu>
                  <MenuItem onClick={() => setShareModalOpen(true)}>
                    Share
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Box>
          ) : null}
          <Divider sx={{ margin: '32px 18px' }} />
          <Box>
            {[
              invoice.createdBy === 'system'
                ? {
                    keyName: 'Date Range',
                    value: getInvoiceDateRange(invoice),
                  }
                : {
                    keyName: 'Issue Date',
                    value: getInvoiceDateRange(invoice),
                  },
              {
                keyName: 'Due Date',
                value: dayjs(invoice.dueDate.toDate())
                  .tz('UTC')
                  .endOf('month')
                  .format('MMMM D, YYYY'),
              },
              ...(invoice.payment
                ? [
                    {
                      keyName: 'Payment',
                      value: invoicePaymentMap[invoice.payment.method],
                      details: (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '10px 30px',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <Box>
                            Payment date:{' '}
                            {dayjs(invoice.payment.datePaid.toDate())
                              .tz('UTC')
                              .format('M/D/YYYY')}
                          </Box>
                          {invoice.payment.notes ? (
                            <Box>Notes: {invoice.payment.notes}</Box>
                          ) : null}
                        </Box>
                      ),
                    },
                  ]
                : []),
            ].map((itemProps) => (
              <InvoiceItem key={itemProps.keyName} {...itemProps} />
            ))}
          </Box>
          <Divider sx={{ margin: '32px 18px' }} />
          <Box>
            {invoice.lineItems
              .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
              .filter(({ disabled }) => !disabled)
              .map(
                ({
                  contractId,
                  description,
                  amount,
                  relatedEvents,
                  createdBy,
                  createdAt,
                }) => (
                  <InvoiceItem
                    key={
                      createdBy === 'system'
                        ? contractId
                        : `${createdBy}-${createdAt.valueOf()}`
                    }
                    keyName={description}
                    value={`${toCurrency(amount)} USD`}
                    details={
                      createdBy === 'system' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '10px 30px',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          {relatedEvents.map(({ start, slug, history }) => (
                            <IonicLink
                              key={slug}
                              to={
                                viewOnly
                                  ? undefined
                                  : `/${organizationSlug}/schedule/${slug}`
                              }
                              unstyled
                            >
                              <Box>
                                {dayjs(start.toDate()).format('M/D/YYYY')}
                                {history?.type ? (
                                  <span style={{ color: 'gray', fontSize: 12 }}>
                                    {' '}
                                    ({cancellationOptionMap[history.type]})
                                  </span>
                                ) : null}
                              </Box>
                            </IonicLink>
                          ))}
                        </Box>
                      ) : undefined
                    }
                  />
                )
              )}
          </Box>
          <Box
            sx={{
              margin: '32px 18px',
              borderBottom: '1px dashed lightgray',
            }}
          />
          <Box>
            {[
              {
                keyName: 'Total Amount',
                value: `${toCurrency(invoice.amount)} USD`,
              },
            ].map((itemProps) => (
              <InvoiceItem key={itemProps.keyName} {...itemProps} />
            ))}
          </Box>
          <MarkAsPaidModal
            presentingElement={pageRef.current || undefined}
            isOpen={markingAsPaid}
            onDone={() => setMarkingAsPaid(false)}
            invoiceStatus={invoice.status}
            invoiceAmount={invoice.amount}
          />
          <IonModal
            presentingElement={pageRef.current || undefined}
            isOpen={shareModalOpen}
            onDidDismiss={() => setShareModalOpen(false)}
          >
            <ShareWithLink
              onDone={() => setShareModalOpen(false)}
              invoiceId={invoice.id}
            />
          </IonModal>
        </>
      ) : null}
    </Box>
  );
};
