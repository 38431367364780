import { Box } from '@mui/joy';
import { SxProps } from '@mui/material';
import { RawContact } from '@shared/models/contacts';

import { ContactTag, Tag } from './ContactTag';

interface ContactTagsProps {
  contact: RawContact;
  sx?: SxProps | undefined;
}

export const ContactTags = ({ contact, sx }: ContactTagsProps) => (
  <Box
    sx={{
      display: 'flex',
      gap: 1,
      flexWrap: 'wrap',
      overflowX: 'auto',
    }}
  >
    {contact.tags.teacher ? <ContactTag type="teacher" sx={sx} /> : null}
    {contact.tags?.primaryContact && !contact.tags.teacher ? (
      <ContactTag type="primary" sx={sx} />
    ) : null}
    {contact.tags?.student && !contact.tags.teacher ? (
      <ContactTag type="student" sx={sx} />
    ) : null}
    {contact.tags?.potentialStudent ? (
      <ContactTag type="potentialStudent" sx={sx} />
    ) : null}
    {contact.instruments?.map((instrument) => (
      <Tag key={instrument}>{instrument}</Tag>
    ))}
  </Box>
);
