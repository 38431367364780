import { Actions } from '@shared/models/mutations';
import { useMemo } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { auth } from '@utils/firebase';

import { getRunMutation } from './runMutation';

export const useMutation = <T extends Actions>(action: T) => {
  const organizationId = useOrganizationId();
  const [user] = useAuthState(auth);
  const userId = user?.uid;

  const mutateFunction = useMemo(
    () => getRunMutation({ action, organizationId, userId }),
    [action, organizationId, userId]
  );

  return [mutateFunction] as const;
};
