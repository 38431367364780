import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import dayjs from 'dayjs';
import { addOutline, addSharp } from 'ionicons/icons';
import { useState } from 'react';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';
import { useModalState } from '@hooks/useModalState';

import AddEvent from './AddEvent';
import AgendaEvent from './AgendaEvent';
import Calendar from './Calendar';
import Drawer from './Drawer';
import DurationEvent from './DurationEvent';
import EventViewContainer from './EventViewContainer';
import './Schedule.css';
import { useDrawerState } from './useDrawerState';

interface GetEventSlugProps {
  id: string;
  ongoing?: boolean;
  start: Date;
}

export const getEventSlug = (event?: GetEventSlugProps) =>
  event
    ? [event.id, event.ongoing ? event.start.valueOf() : undefined]
        .filter(Boolean)
        .join('.')
    : undefined;

const Schedule = () => {
  const addEventModalState = useModalState();
  const [agendaView, setAgendaView] = useState(false);
  const router = useIonRouter();
  const organizationSlug = useOrganizationSlug();
  const { currentDate, setCurrentDate, expanded, expanding, toggleExpanded } =
    useDrawerState();

  return (
    <IonPage ref={addEventModalState.page}>
      <IonHeader translucent>
        <IonToolbar className="calendar">
          <IonTitle>{dayjs(currentDate).format('MMMM YYYY')}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              onClick={() => setCurrentDate(new Date())}
            >
              Today
            </IonButton>
            <IonButton
              color="primary"
              onClick={() => addEventModalState.setIsOpen(true)}
            >
              <IonIcon ios={addOutline} md={addSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="schedule-page">
        <Calendar
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          expanded={expanded}
          expanding={expanding}
        />
        <Drawer
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          agendaView={agendaView}
          setAgendaView={setAgendaView}
        >
          {({ eventGroup }) => (
            <EventViewContainer agendaView={agendaView}>
              {eventGroup?.events?.map((event) => {
                const slug = getEventSlug(event);

                return agendaView ? (
                  <AgendaEvent
                    key={slug}
                    routerLink={`/${organizationSlug}/schedule/${slug}`}
                    event={event}
                  />
                ) : (
                  <IonRouterLink
                    key={slug}
                    routerLink={`/${organizationSlug}/schedule/${slug}`}
                  >
                    <DurationEvent {...event} />
                  </IonRouterLink>
                );
              })}
              {eventGroup?.availabileEvents?.map((event) => (
                <DurationEvent
                  key={event.start.toString()}
                  onClick={() => addEventModalState.setIsOpen(true)}
                  {...event}
                  title="Available"
                  type="other"
                  hidden
                />
              ))}
            </EventViewContainer>
          )}
        </Drawer>
      </IonContent>
      <AddEvent
        isOpen={addEventModalState.isOpen}
        onDone={() => {
          addEventModalState.setIsOpen(false);
          router.push(`/${organizationSlug}/schedule`, 'none');
        }}
        onCancel={() => addEventModalState.setIsOpen(false)}
        page={addEventModalState.page}
      />
    </IonPage>
  );
};

export default Schedule;
