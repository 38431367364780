import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import {
  AvailableFeatureFlags,
  RawFeatureFlags,
} from '@shared/models/featureFlags';
import { doc, updateDoc } from 'firebase/firestore';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { featureFlagsCollection } from '@models/featureFlags/model';
import { useFeatureFlagsQuery } from '@models/featureFlags/useFeatureFlagsQuery';
import { serverTimestamp } from '@utils/serverTimestamp';

type FeatureFlagKey = keyof AvailableFeatureFlags;

const fancyNames: Partial<Record<FeatureFlagKey, string>> = {
  showFeatureFlagsEnabled: 'Show Feature Flags',
};

const groups: FeatureFlagKey[][] = [
  ['showFeatureFlagsEnabled'],
  ['crmFunnelsEnabled'],
  [
    'schedulingV1_1Enabled',
    'schedulingV1_2Enabled',
    'schedulingV1_3Enabled',
    'schedulingV1_4Enabled',
  ],
];

const groupSets = groups.map((group) => new Set(group));
const allGroups = new Set(groups.flat());

export interface OrganizationInformation {
  name: string;
  slug: string;
}

interface FeatureFlagsProps {
  onDone: () => void;
}

const FeatureFlags = ({ onDone }: FeatureFlagsProps) => {
  const [featureFlags] = useFeatureFlags();
  const user = useUser();
  const userId = user?.userId;
  const organizationId = useOrganizationId();

  const [userFeatureFlagItems] = useFeatureFlagsQuery({
    type: 'user',
    userId,
    organizationId,
    skip: !userId || !organizationId,
  });

  const [userFeatureFlagItem] = userFeatureFlagItems || [];
  const userFeatureFlagsId = userFeatureFlagItem?.id;

  const handleUpdateFeatueFlags = async (
    flagKey: keyof AvailableFeatureFlags,
    flagValue: boolean
  ) => {
    if (!user) {
      throw new Error('No user');
    }

    if (!userFeatureFlagsId) {
      throw new Error('No user feature flags id');
    }

    const partialFeatureFlagDoc: Partial<RawFeatureFlags> = {
      updatedAt: serverTimestamp(),
      updatedBy: user.userId,
      flags: { ...featureFlags, [flagKey]: flagValue },
    };

    await updateDoc(
      doc(featureFlagsCollection, userFeatureFlagsId),
      partialFeatureFlagDoc
    );
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonTitle>Feature Flags</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light">
        {[
          ...groupSets.map(
            (groupSet) =>
              ([key]: [FeatureFlagKey, boolean]) =>
                groupSet.has(key)
          ),
          ([key]: [FeatureFlagKey, boolean]) => !allGroups.has(key),
        ].map((filterFunction, index) => (
          <IonList inset key={index}>
            {Object.entries(featureFlags)
              .sort(([key1], [key2]) => key1.localeCompare(key2))
              .filter((entry) =>
                filterFunction(entry as [FeatureFlagKey, boolean])
              )
              .map(([key, value]) => (
                <IonItem key={key}>
                  <IonToggle
                    color="success"
                    checked={value}
                    onIonChange={(event) =>
                      handleUpdateFeatueFlags(
                        key as keyof AvailableFeatureFlags,
                        event.detail.checked
                      )
                    }
                    data-testid={`feature-flag-${key}`}
                  >
                    {key in fancyNames ? (
                      fancyNames[key as keyof AvailableFeatureFlags]
                    ) : (
                      <pre style={{ fontSize: 12 }}>{key}</pre>
                    )}
                  </IonToggle>
                </IonItem>
              ))}
          </IonList>
        ))}
      </IonContent>
    </>
  );
};

export default FeatureFlags;
