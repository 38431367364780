import { Star } from '@mui/icons-material';
import { Box, Button, Input, Tooltip } from '@mui/joy';
import { Phone } from '@shared/models/contacts';
import { AsYouType } from 'libphonenumber-js';

import { EditContactItemContainer } from './EditContactItemContainer';
import { EditContactItemType } from './EditContactItemType';

export const formatPhoneNumber = (value?: string) => {
  const countryCode = value?.startsWith('+') ? undefined : 'US';

  if (!value) {
    return '';
  }

  value = value.toString().trim();

  if (value.endsWith(')') || (value.includes('(') && !value.includes(')'))) {
    value = value.replace('(', '');
    value = value.replace(')', '');

    return value;
  }

  return new AsYouType(countryCode).input(value);
};

export const normalizeIsPrimary = <T extends { isPrimary: boolean }>(
  items: T[]
) =>
  items.some(({ isPrimary }) => isPrimary)
    ? items
    : items.map((item, index) =>
        index === 0 ? { ...item, isPrimary: true } : item
      );

interface EditPhonesProps {
  phones: Phone[];
  setPhones: React.Dispatch<React.SetStateAction<Phone[]>>;
  page: React.MutableRefObject<HTMLElement | null>;
}

export const EditPhones = ({
  phones,
  setPhones: setPhonesBase,
  page,
}: EditPhonesProps) => {
  const setPhones = (next: Phone[]) => setPhonesBase(normalizeIsPrimary(next));

  return (
    <EditContactItemContainer
      addButton={
        <Button
          variant="plain"
          color="neutral"
          data-testid="add-phone-button"
          onClick={() =>
            setPhones([
              ...phones,
              { number: '', type: 'mobile', isPrimary: phones.length === 0 },
            ])
          }
        >
          + Add Phone
        </Button>
      }
    >
      {phones.map((phone, index) => (
        <Box key={index}>
          <Input
            placeholder="Phone"
            type="tel"
            name="phone"
            variant="plain"
            value={phone.number}
            onChange={(event) => {
              setPhones(
                phones.map((item, i) =>
                  i === index
                    ? {
                        ...item,
                        number: formatPhoneNumber(String(event.target.value)),
                      }
                    : item
                )
              );
            }}
          />
          {phone.isPrimary ? (
            <Tooltip
              variant="plain"
              title="The primary phone is used for sending automated SMS messages"
            >
              <Star sx={{ marginRight: '8px', color: 'lightgray' }} />
            </Tooltip>
          ) : null}
          <EditContactItemType
            label={phone.type}
            setLabel={(nextValue) =>
              setPhones(
                phones.map((item, i) =>
                  i === index ? { ...item, type: nextValue } : item
                )
              )
            }
            page={page}
            labelType="Phone"
            onDeleteEntity={() =>
              setPhones(phones.filter((_, itemIndex) => itemIndex !== index))
            }
            isPrimary={phone.isPrimary}
            setPrimary={(nextValue) =>
              setPhones(
                phones.map((item, i) =>
                  i === index
                    ? { ...item, isPrimary: nextValue }
                    : { ...item, isPrimary: false }
                )
              )
            }
          />
        </Box>
      ))}
    </EditContactItemContainer>
  );
};
