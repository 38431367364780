import {
  ParsedContact,
  RawContact,
  emailAddressSchema,
} from '@shared/models/contacts';
import { formatDistance } from 'date-fns';
import { doc, updateDoc } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUserData } from '@components/UserData/useUserData';
import { useUser } from '@features/Organization/organizationSlice';
import { contactsCollection } from '@models/contacts/model';
import { useInvitesQuery } from '@models/invites/useInvitesQuery';
import { useMutation } from '@models/mutations/useMutation';
import { serverTimestamp } from '@utils/serverTimestamp';

const TIME_UPDATE_INTERVAL = 1500;

export interface UseUserAccessProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  contact: ParsedContact;
}

export const useUserAccessValue = ({
  isOpen,
  page,
  onDone,
  contact,
}: UseUserAccessProps) => {
  const user = useUser();
  const { emailVerified } = useUserData();
  const primaryEmail = contact?.emails?.find((email) => email.isPrimary);
  const [inviteEmail, setInviteEmail] = useState(primaryEmail?.address || '');
  const [createInviteMutation] = useMutation('createInvite');
  const [removeInviteMutation] = useMutation('removeInvite');
  const [invites, loadingInvites] = useInvitesQuery({
    contactId: contact.id,
    skip: !isOpen,
  });
  const invite = invites?.[0];
  const emailValid = useMemo(
    () => emailAddressSchema.safeParse(inviteEmail).success,
    [inviteEmail]
  );

  const [lastSentAgo, setLastSentAgo] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!invite) {
      return;
    }
    let mounted = true;

    const update = () => {
      const next = invite.lastSentAt
        ? formatDistance(invite.lastSentAt.toDate(), new Date(), {
            addSuffix: true,
          })
        : undefined;

      if (lastSentAgo !== next) {
        setLastSentAgo(next);
      }
    };

    const interval = setInterval(() => {
      if (mounted) {
        update();
      }
    }, TIME_UPDATE_INTERVAL);

    update();

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [invite, lastSentAgo]);

  const [lastLoginAgo, setLastLoginAgo] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (!contact) {
      if (lastLoginAgo) {
        setLastLoginAgo(undefined);
      }

      return;
    }

    let mounted = true;

    const update = () => {
      const next = contact.userLastLogin
        ? formatDistance(contact.userLastLogin.toDate(), new Date(), {
            addSuffix: true,
          })
        : undefined;

      if (lastLoginAgo !== next) {
        setLastLoginAgo(next);
      }
    };

    const interval = setInterval(() => {
      if (mounted) {
        update();
      }
    }, TIME_UPDATE_INTERVAL);

    update();

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [contact, lastLoginAgo]);

  const expiresAt = invite?.expiresAt?.toDate();
  const hourAfterCreatedAt = invite
    ? new Date(invite.createdAt.toDate().valueOf() + 60 * 60 * 1000)
    : undefined;

  const getExpired = useCallback(
    () => Boolean(expiresAt && expiresAt < new Date()),
    [expiresAt]
  );
  const [expired, setExpired] = useState(getExpired());

  useEffect(() => {
    const updateExpired = () => {
      const nextExpired = getExpired();

      if (nextExpired !== expired) {
        setExpired(nextExpired);
      }

      return nextExpired;
    };

    if (updateExpired()) {
      console.log('early return');
      return;
    }

    const interval = setInterval(() => {
      if (updateExpired()) {
        clearInterval(interval);
      }
    }, TIME_UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [expired, getExpired]);

  const removeInvite = useCallback(async () => {
    await removeInviteMutation({ contactId: contact.id });
  }, [removeInviteMutation, contact]);

  const [inviteLoading, setInviteLoading] = useState(false);

  const createInvite = useCallback(async () => {
    if (!contact) {
      throw new Error('Contact not found');
    }

    setInviteLoading(true);

    // Update contact if email is different
    if (!contact.emails?.some((email) => email.address === inviteEmail)) {
      if (!user) {
        throw new Error('User not found');
      }

      const nextUserContact: Partial<RawContact> = {
        updatedAt: serverTimestamp(),
        updatedBy: user.userId,
        emails: [
          ...(contact.emails || []).map((email) => ({
            ...email,
            isPrimary: false,
          })),
          { address: inviteEmail, type: 'mobile', isPrimary: true },
        ],
      };

      await updateDoc(doc(contactsCollection, contact.id), nextUserContact);
    }

    await createInviteMutation({ contactId: contact.id });

    setInviteLoading(false);
  }, [createInviteMutation, contact, inviteEmail, user]);

  const atLeastAnHourAterCreatedAt = Boolean(
    hourAfterCreatedAt && hourAfterCreatedAt < new Date()
  );

  const notInvited =
    (!contact?.userId && !loadingInvites && invites?.length === 0) || expired;

  return {
    notInvited,
    page,
    isOpen,
    invites,
    loadingInvites,
    createInvite,
    removeInvite,
    emailVerified,
    atLeastAnHourAterCreatedAt,
    primaryEmail,
    onDone,
    inviteEmail,
    setInviteEmail,
    lastSentAgo,
    invite,
    inviteExpired: expired,
    contact,
    lastLoginAgo,
    inviteLoading,
    emailValid,
  };
};

export type UseUserAccessReturn = ReturnType<typeof useUserAccessValue>;
