import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonNav,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { eventDetails } from '@models/events/model';

import AddEventOverview from './AddEventOverview';
import { EditEventProvider } from './EditEventContext';
import SelectStudent from './SelectStudent';

export interface AddEventProps {
  isOpen: boolean;
  page: React.MutableRefObject<HTMLElement | null>;
  currentDate?: Date;
  accountId?: string;
  contactId?: string;
  onDone?: () => void;
  onCancel?: () => void;
}

const AddEvent = ({
  isOpen,
  page,
  accountId,
  onDone,
  onCancel,
  contactId,
}: AddEventProps) => {
  if (!page.current) {
    return null;
  }

  return (
    <EditEventProvider
      contactId={contactId}
      eventId="add-event"
      onDone={onDone}
    >
      {({
        scheduleModalRef,
        reset,
        fieldProps: { selectStudentIsOpen, eventType },
        isValid,
        onCreateEvent,
        addStudentModalState,
      }) => (
        <IonModal
          presentingElement={page.current || undefined}
          isOpen={isOpen}
          onDidDismiss={() => {
            reset();
            onCancel?.();
          }}
          ref={scheduleModalRef}
        >
          <IonNav
            root={() => (
              <>
                <IonHeader>
                  <IonToolbar>
                    <IonButtons slot="start">
                      <IonButton
                        color="dark"
                        onClick={() => {
                          reset();
                          onCancel?.();
                        }}
                      >
                        Cancel
                      </IonButton>
                    </IonButtons>
                    <IonTitle>New {eventDetails[eventType].label}</IonTitle>
                    <IonButtons slot="end">
                      <IonButton
                        color="dark"
                        disabled={!isValid}
                        onClick={() => onCreateEvent()}
                        data-testid="add-event-button"
                      >
                        Add
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>
                <IonContent
                  className="ion-padding"
                  color="light"
                  ref={addStudentModalState.page as any}
                >
                  <AddEventOverview
                    onDone={() => {
                      reset();
                      onDone?.();
                    }}
                  />
                </IonContent>
              </>
            )}
          />
          <SelectStudent
            modalState={{
              page: scheduleModalRef,
              isOpen: selectStudentIsOpen,
              setIsOpen: addStudentModalState.setIsOpen,
            }}
            accountId={accountId}
          />
        </IonModal>
      )}
    </EditEventProvider>
  );
};

export default AddEvent;
