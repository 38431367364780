import { Box, Typography } from '@mui/joy';
import React from 'react';

interface BusinessUrlContainerProps {
  children: React.ReactNode;
  focused: boolean;
}

export const BusinessUrlContainer = ({
  children,
  focused,
}: BusinessUrlContainerProps) => (
  <Box sx={{ position: 'relative' }}>
    {children}
    <Typography
      sx={{
        position: 'absolute',
        left: '12px',
        top: '8px',
        color: focused
          ? 'var(--joy-palette-neutral-900)'
          : 'var(--joy-palette-text-tertiary)',
        fontSize: '0.75rem',
        fontWeight: 'var(--joy-fontWeight-md)',
        lineHeight: 1,
      }}
    >
      Business URL
    </Typography>
    <Typography
      sx={{
        position: 'absolute',
        left: '12px',
        top: '18px',
        bottom: 0,
        margin: 'auto',
        height: 'fit-content',
        color: 'gray',
        fontSize: '14px',
      }}
    >
      harmonize.com/
    </Typography>
  </Box>
);
