import { IonNote, IonToggle } from '@ionic/react';

type IonToggleProps = Parameters<typeof IonToggle>[0];

interface ToggleWrapperProps extends IonToggleProps {
  readOnly: boolean | undefined;
}

export const ToggleWrapper = ({
  children,
  readOnly,
  checked,
  ...props
}: ToggleWrapperProps) => {
  if (readOnly) {
    return (
      <>
        {children}
        <IonNote slot="end">{checked ? 'On' : 'Off'}</IonNote>
      </>
    );
  }

  return (
    <IonToggle color="success" checked={checked} {...props}>
      {children}
    </IonToggle>
  );
};
