import { useContext } from 'react';

import { LessonProviderContext } from './LessonProvider';

export const useLesson = () => {
  const context = useContext(LessonProviderContext);

  if (!context) {
    throw new Error(
      'useLesson has to be used within a <LessonProviderProvider />'
    );
  }

  return context;
};
