import { useContext } from 'react';

import { FeatureFlagsContext } from './FeatureFlagsProvider';

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'useFeatureFlags has to be used within a <FeatureFlagsProvider />'
    );
  }

  return context;
};
