import { IonContent, IonPage } from '@ionic/react';
import { Box } from '@mui/joy';
import { useMediaQuery } from '@mui/material';

import Sidebar from '@components/Sidebar';

import { LessonDetail } from './LessonDetail';
import { LessonPreview } from './LessonPreview';
import { LessonProvider } from './LessonProvider/LessonProvider';

export const LessonDetailRouter = () => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!isDesktop) {
    return null;
  }

  return (
    <LessonProvider>
      {({ page, previewOpen }) => (
        <IonPage ref={page}>
          <IonContent>
            <Box sx={{ display: 'flex' }}>
              <Sidebar />
              <Box
                sx={{
                  display: 'flex',
                  position: 'relative',
                  height: '100vh',
                  flex: 1,
                }}
              >
                <LessonDetail />
                {previewOpen ? <LessonPreview /> : null}
              </Box>
            </Box>
          </IonContent>
        </IonPage>
      )}
    </LessonProvider>
  );
};
