import { IonRouterOutlet } from '@ionic/react';
import { useWindowSize } from 'react-use';

import Tabs from '@components/Tabs';

interface RouteContainerProps {
  children: React.ReactNode;
}

export const sidebarBreakpoint = 992;

export const RouteContainer = ({ children }: RouteContainerProps) => {
  const { width } = useWindowSize();

  if (width >= sidebarBreakpoint) {
    return <IonRouterOutlet id="main-2">{children}</IonRouterOutlet>;
  }

  return <Tabs>{children}</Tabs>;
};
