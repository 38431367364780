import { ParsedContact } from '@shared/models/contacts';
import { useMemo, useState } from 'react';

export interface UseMoveContactProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  contactId: string;
  linkedContacts: ParsedContact[] | undefined;
}

export const useTransferPrimaryValue = ({
  isOpen,
  contactId,
  page,
  onDone,
  linkedContacts,
}: UseMoveContactProps) => {
  const [selected, setSelected] = useState<string | undefined>();

  const existingPrimaryContact = useMemo(
    () => linkedContacts?.find((contact) => contact.id === contactId),
    [contactId, linkedContacts]
  );

  return {
    selected,
    setSelected,
    contacts: linkedContacts,
    isOpen,
    contactId,
    page,
    onDone,
    existingPrimaryContact,
  };
};

export type UseTransferPrimaryReturn = ReturnType<
  typeof useTransferPrimaryValue
>;
