import { IonItem, IonLabel } from '@ionic/react';
import { Box } from '@mui/joy';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import {
  ParsedEvent,
  eventDetails,
  eventTypeSolidColorMap,
} from '@models/events/model';

const timeFormat = 'h:mm A';

interface AgendaEventProps {
  event: ParsedEvent;
  routerLink?: string;
}

const AgendaEvent = ({
  routerLink,
  event: { start, duration, type, title },
}: AgendaEventProps) => {
  const time = useMemo(() => {
    const day = dayjs(start);

    return {
      start: day.format(timeFormat),
      end: day.add(duration, 'minutes').format(timeFormat),
    };
  }, [start, duration]);

  return (
    <IonItem routerLink={routerLink}>
      <Box
        style={{
          minHeight: 40,
          width: 3,
          background: eventTypeSolidColorMap[type],
          margin: 1,
          marginRight: 10,
        }}
        slot="start"
      />
      <IonLabel>
        <h2>{title}</h2>
        <p>{eventDetails[type].label}</p>
      </IonLabel>
      <IonLabel slot="end">
        <p style={{ textAlign: 'right' }}>{time.start}</p>
        <p style={{ textAlign: 'right' }}>{time.end}</p>
      </IonLabel>
    </IonItem>
  );
};

export default AgendaEvent;
