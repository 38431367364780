import {
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { Textarea } from '@mui/joy';
import dayjs from 'dayjs';

import IonItemNavLink from '@components/IonItemNavLink';
import { useEditEventContext } from '@features/Schedule/AddEvent/EditEventContext/useEditEventContext';
import SelectTime from '@features/Schedule/AddEvent/SelectTime';

export type RescheduleProductOption =
  | 'rescheduleThisLesson'
  | 'rescheduleFutureLessons'
  | 'custom';

const rescheduleOptions: RescheduleProductOption[] = [
  'rescheduleThisLesson',
  'rescheduleFutureLessons',
  'custom',
];

const cancelTypeLabels: Record<RescheduleProductOption, string> = {
  rescheduleThisLesson: 'Reschedule This Lesson',
  rescheduleFutureLessons: 'Reschedule All Future Lessons',
  custom: 'Custom',
};

export const RescheduleProductEvent = () => {
  const {
    editProductEventFields: {
      productEditType,
      rescheduleOption,
      setRescheduleOption,
    },
    fieldProps: { availableEvent, setAvailableEvent },
    eventInstanceDate,
  } = useEditEventContext();

  if (productEditType !== 'reschedule') {
    return null;
  }

  const currentDate = eventInstanceDate || new Date();

  return (
    <>
      <IonList inset>
        <IonItem>
          <IonSelect
            label="Reschedule Type"
            interface="popover"
            value={rescheduleOption}
            onIonChange={(event) => setRescheduleOption(event.detail.value)}
          >
            {rescheduleOptions.map((option) => (
              <IonSelectOption key={option} value={option}>
                {cancelTypeLabels[option]}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItemNavLink
          button
          routerDirection="forward"
          component={() => (
            <SelectTime
              onEventSelection={setAvailableEvent}
              currentDate={currentDate}
            />
          )}
        >
          <IonLabel>Starts</IonLabel>
          {dayjs(availableEvent.start).format('MMM D, YYYY h:mm A')}
        </IonItemNavLink>
      </IonList>

      <IonList inset>
        <Textarea
          placeholder="Notes"
          color="neutral"
          variant="plain"
          sx={{
            padding: '15px',
            '--Textarea-focusedThickness': 0,
            minHeight: '150px',
          }}
        />
      </IonList>
    </>
  );
};
