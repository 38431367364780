import { useContext } from 'react';

import { UserDataContext } from './index';

export const useUserData = () => {
  const context = useContext(UserDataContext);

  if (!context) {
    throw new Error('useUserData has to be used within a <UserDataProvider />');
  }

  return context;
};
