import styled from '@emotion/styled';
import { Box } from '@mui/joy';
import Typography, { TypographyTypeMap } from '@mui/joy/Typography';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import dayjs from 'dayjs';
import { startTransition, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { themeColors, transition } from '@theme/theme';

import { expandingTransitionSeconds, transitionTimingFunction } from '.';
import { CalendarDayData, TODAY, dateFormat } from './useCalendar';

const CalendarDayDisplay: OverridableComponent<TypographyTypeMap<{}, 'span'>> =
  styled(Typography)(() => ({
    position: 'relative',
    '&:active': {
      transition: `${transition} color, ${transition} background`,
      color: 'var(--joy-palette-neutral-100)',
      background: 'var(--joy-palette-neutral-500)',
    },
  }));

interface CalendarDayProps {
  transitioning: boolean;
  day: CalendarDayData;
  setCurrentDate: (input: Date) => void;
  currentDate: Date;
}

const CalendarDay = ({
  day,
  setCurrentDate,
  currentDate,
  transitioning,
}: CalendarDayProps) => {
  const [internalCurrentDate, setInternalCurrentDate] = useState(currentDate);
  const previousInputDate = usePrevious(currentDate);

  useEffect(() => {
    if (
      previousInputDate !== currentDate &&
      currentDate !== internalCurrentDate
    ) {
      setInternalCurrentDate(currentDate);
    }
  }, [internalCurrentDate, currentDate, previousInputDate]);
  const currentDateString = dayjs(internalCurrentDate).format(dateFormat);
  const calendarDotsEnabled = useFeatureFlag('calendarDotsEnabled');

  return (
    <CalendarDayDisplay
      disabled={!day.isCurrentMonth}
      component="button"
      sx={{
        ...(day.date === TODAY ? { fontWeight: 800 } : undefined),
        ...(day.date === currentDateString
          ? {
              color: 'var(--joy-palette-neutral-100)',
              background: themeColors.darkPurple,
            }
          : { background: 'transparent' }),
        margin: '0.4em 0',
        width: '1.8em',
        height: '1.8em',
        transition: transitioning
          ? undefined
          : `${expandingTransitionSeconds}s opacity ${transitionTimingFunction}`,
        borderRadius: '50%',
        ...(day.isCurrentMonth ? { opacity: 1 } : { opacity: 0 }),
        ...((day.hasEvent || Math.random() > 0.8) && calendarDotsEnabled
          ? {
              '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -6,
                left: 0,
                right: 0,
                margin: 'auto',
                height: '5px',
                width: '5px',
                background: 'darkgray',
                borderRadius: '50%',
              },
            }
          : undefined),
      }}
      onClick={() => {
        const next = dayjs(day.date).toDate();

        setInternalCurrentDate(next);
        startTransition(() => {
          setCurrentDate(next);
        });
      }}
    >
      <Box>{day.dayNumber}</Box>
    </CalendarDayDisplay>
  );
};

export default CalendarDay;
