import Avatar, { AvatarProps } from '@mui/joy/Avatar';
import { ParsedContact } from '@shared/models/contacts';

import { useContactAvatar } from '@components/useContactAvatar';

interface ContactAvatarProps extends Omit<AvatarProps, 'src'> {
  contact: ParsedContact | undefined;
}

export const ContactAvatar = ({ contact, ...rest }: ContactAvatarProps) => {
  const [avatarSrc] = useContactAvatar(contact);

  if (!avatarSrc) {
    return null;
  }

  return <Avatar src={avatarSrc} {...rest} />;
};
