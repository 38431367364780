import { doc } from 'firebase/firestore';
import { useMemo } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';

import { lessonsCollection } from './model';
import { parseLesson } from './parseLesson';

export const useLessonQuery = (lessonId: string | undefined) => {
  const [lessonSnapshot, loading, error] = useDocument(
    lessonId ? doc(lessonsCollection, lessonId) : null
  );

  const lesson = useMemo(
    () => (lessonSnapshot ? parseLesson(lessonSnapshot) : undefined),
    [lessonSnapshot]
  );

  return [lesson, loading, error] as const;
};
