import {
  AddOutlined,
  GridViewOutlined,
  HelpOutlineOutlined,
  OpenInFullOutlined,
} from '@mui/icons-material';
import { Box, Button, IconButton, Slider } from '@mui/joy';
import { sliderClasses } from '@mui/material';

import { useMedia } from '../MediaProvider/useMedia';

const multiplier = 0.05;
const pagePreviewWidth = 1280 * multiplier;
const pagePreviewHeight = 1780 * multiplier;

export const MediaBottomBar = () => {
  const {
    pages,
    handleAddPage,
    currentPage,
    setCurrentPage,
    zoom,
    setZoom,
    zoomPercentage,
  } = useMedia();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: `1px solid #E4E7EB`,
      }}
    >
      <Box
        sx={{
          padding: '15px',
          paddingBottom: 0,
        }}
      >
        <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
          {pages?.map((page, index) => (
            <Box
              key={index}
              sx={(theme) => ({
                width: pagePreviewWidth,
                height: pagePreviewHeight,
                borderRadius: '5px',
                border: `1px solid ${
                  currentPage === index + 1
                    ? theme.palette.primary[400]
                    : theme.palette.divider
                }`,
                overflow: 'hidden',
                cursor: 'pointer',
              })}
              onClick={() => setCurrentPage(index + 1)}
            >
              {page.imageUrl ? (
                <img
                  src={page.imageUrl}
                  alt="test"
                  style={{ maxHeight: '100%' }}
                />
              ) : (
                <Box></Box>
              )}
            </Box>
          ))}
          <Box
            sx={(theme) => ({
              width: pagePreviewWidth,
              height: pagePreviewHeight,
              borderRadius: '5px',
              border: `1px solid ${theme.palette.neutral[50]}`,
              background: theme.palette.neutral[50],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            })}
            onClick={handleAddPage}
          >
            <AddOutlined />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <Box />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button variant="plain" color="neutral">
            Page {currentPage} / {pages.length}
          </Button>
          <Slider
            color="neutral"
            sx={(theme) => ({
              width: '200px',
              [`& .${sliderClasses.track}:not(:hover)`]: {
                backgroundColor: theme.palette.neutral[500],
              },
            })}
            value={zoom}
            onChange={(event) => setZoom((event.target as any)?.value)}
          />
          <Button variant="plain" color="neutral">
            {zoomPercentage}%
          </Button>
          <IconButton>
            <GridViewOutlined />
          </IconButton>
          <IconButton>
            <OpenInFullOutlined />
          </IconButton>
          <IconButton>
            <HelpOutlineOutlined />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
