import { IonInput, IonItem, IonList, IonSelectOption } from '@ionic/react';
import {
  invoiceDueLabelMap,
  invoiceIssuedLabelMap,
} from '@shared/models/products';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHelperText } from '@components/ListHelperText';
import { SelectWrapper } from '@components/ionic-wrappers/SelectWrapper';

import { useEditProductContext } from './ProductContext/useEditProductContext';

interface InvoicesContentProps {
  readOnly?: boolean;
}

const InvoicesContent = ({ readOnly }: InvoicesContentProps) => {
  const {
    billingCycles,
    setBillingCycles,
    setCycleFormula,
    invoiceIssued,
    setInvoiceIssued,
    invoiceDue,
    setInvoiceDue,
    invoiceIssuedOffset,
    setInvoiceIssuedOffset,
    invoiceDueOffset,
    setInvoiceDueOffset,
  } = useEditProductContext();

  const schedulingV1_2Enabled = useFeatureFlag('schedulingV1_2Enabled');
  const schedulingV1_3Enabled = useFeatureFlag('schedulingV1_3Enabled');

  return (
    <>
      {schedulingV1_2Enabled ? (
        <>
          <IonList inset>
            <IonItem>
              <SelectWrapper
                readOnly={readOnly}
                label="Invoice Frequency"
                multiple
                interface="popover"
                value={billingCycles}
                onIonChange={(event) => {
                  const selected = event.detail.value;
                  const value = Array.isArray(selected) ? selected : [selected];

                  setBillingCycles(value);

                  if (value.includes('perWeek')) {
                    setCycleFormula('lessonsTimesPrice');
                  }
                }}
              >
                {(
                  [
                    ...(schedulingV1_2Enabled
                      ? [['perLesson', 'Each Lesson']]
                      : []),
                    ...(schedulingV1_3Enabled
                      ? [['perWeek', 'Each Week']]
                      : []),
                    ['perMonth', 'Each Month'],
                    ...(schedulingV1_3Enabled
                      ? [['perQuarter', 'Each Quarter']]
                      : []),
                  ] as const
                ).map(([value, label]) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </SelectWrapper>
            </IonItem>
          </IonList>
          <ListHelperText inset>
            The period of time between consecutive invoices for the lessons
            provided.
          </ListHelperText>
        </>
      ) : null}
      <IonList inset>
        <IonItem>
          <SelectWrapper
            readOnly={readOnly}
            label="Issue Date"
            interface="popover"
            value={invoiceIssued}
            onIonChange={(event) => setInvoiceIssued(event.detail.value)}
          >
            {Object.entries(invoiceIssuedLabelMap).map(([value, label]) => (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            ))}
          </SelectWrapper>
        </IonItem>

        {invoiceIssued !== 'firstLessonOfCycle' ? (
          <IonItem>
            <IonInput
              style={{ textAlign: 'right' }}
              label={`Days ${
                invoiceIssued === 'beforeCycle' ? 'before' : 'after'
              } cycle`}
              type="number"
              min="0"
              onIonChange={(event) => {
                const value = Number(event.detail.value);
                setInvoiceIssuedOffset(('beforeBillingCycle' ? -1 : 1) * value);
              }}
              value={Math.abs(invoiceIssuedOffset)}
            />
          </IonItem>
        ) : null}
      </IonList>
      <ListHelperText inset>
        An issue date is the date when the invoice is created and becomes valid.
      </ListHelperText>
      <IonList inset>
        <IonItem>
          <SelectWrapper
            readOnly={readOnly}
            label="Due Date"
            interface="popover"
            value={invoiceDue}
            onIonChange={(event) => setInvoiceDue(event.detail.value)}
            interfaceOptions={{ size: 'auto', '--min-width': '500px' }}
          >
            {Object.entries(invoiceDueLabelMap).map(([value, label]) => (
              <IonSelectOption key={value} value={value}>
                {label}
              </IonSelectOption>
            ))}
          </SelectWrapper>
        </IonItem>

        {invoiceDue !== 'firstLessonOfCycle' &&
        invoiceDue !== 'lastLessonOfCycle' ? (
          <IonItem>
            <IonInput
              style={{ textAlign: 'right' }}
              label={`Days ${
                invoiceDue === 'afterCycle' ? 'after' : 'before'
              } cycle`}
              type="number"
              onIonChange={(event) => {
                const value = Number(event.detail.value);
                setInvoiceDueOffset(
                  (invoiceDue === 'beforeCycle' ? -1 : 1) * value
                );
              }}
              value={Math.abs(invoiceDueOffset)}
            ></IonInput>
          </IonItem>
        ) : null}
      </IonList>
      <ListHelperText inset>
        A due date is the deadline by which the payment for the lessons must be
        received.
      </ListHelperText>
    </>
  );
};

export default InvoicesContent;
