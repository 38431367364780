interface AvatarCategory {
  fullName: string;
  shortName: string;
  fileName?: string;
}

export const avatarCategories: Record<string, AvatarCategory[]> = {
  baroque: [
    {
      fullName: 'Johann Sebastian Bach',
      shortName: 'Johann S. Bach',
      fileName: 'bach',
    },
    {
      fullName: 'Élisabeth Jacquet de La Guerre',
      shortName: 'Jacquet de la Guerre',
    },
    {
      fullName: 'Antonio Vivaldi',
      shortName: 'Antonio Vivaldi',
      fileName: 'vivaldi',
    },
    {
      fullName: 'Barbara Strozzi',
      shortName: 'Barbara Strozzi',
    },
    {
      fullName: 'George Frideric Handel',
      shortName: 'George F. Handel',
      fileName: 'handel',
    },
    {
      fullName: 'Maria Theresa von Paradis',
      shortName: 'von Paradis',
    },
    {
      fullName: 'Johann Pachelbel',
      shortName: 'Pachelbel',
    },
    {
      fullName: 'Claudio Monteverdi',
      shortName: 'Monteverdi',
    },
    {
      fullName: 'Francesca Caccini',
      shortName: 'Caccini',
    },
    {
      fullName: 'Jean-Baptiste Lully',
      shortName: 'Lully',
    },
    {
      fullName: 'Johann Christian Bach',
      shortName: 'J.C. Bach',
    },
    {
      fullName: 'Antonio Salieri',
      shortName: 'Salieri',
    },
    {
      fullName: 'Arcangelo Corelli',
      shortName: 'Corelli',
    },
    {
      fullName: 'Antonio Caldara',
      shortName: 'Caldara',
    },
    {
      fullName: 'Tomaso Albinoni',
      shortName: 'Albinoni',
    },
    {
      fullName: 'Domenico Scarlatti',
      shortName: 'Scarlatti',
    },
    {
      fullName: 'Johann Adolf Hasse',
      shortName: 'Hasse',
    },
    {
      fullName: 'Johann Kuhnau',
      shortName: 'Kuhnau',
    },
    {
      fullName: 'Carl Ditters von Dittersdorf',
      shortName: 'Dittersdorf',
    },
    {
      fullName: 'Georg Philipp Telemann',
      shortName: 'Telemann',
    },
    {
      fullName: 'Johann David Heinichen',
      shortName: 'Heinichen',
    },
  ],
  classical: [
    {
      fullName: 'Ludwig van Beethoven',
      shortName: 'Ludwig van Beethoven',
      fileName: 'beethoven',
    },
    {
      fullName: 'Maria Anna Mozart',
      shortName: 'Maria Anna Mozart',
    },
    {
      fullName: 'Wolfgang Amadeus Mozart',
      shortName: 'Wolfgang A. Mozart',
      fileName: 'mozart',
    },
    {
      fullName: 'Maria Szymanowska',
      shortName: 'Maria Szymanowska',
      fileName: 'szymanowska',
    },
    {
      fullName: 'Franz Joseph Haydn',
      shortName: 'Franz J. Haydn',
      fileName: 'haydn',
    },
    {
      fullName: 'Maria Theresia von Paradis',
      shortName: 'von Paradis',
    },
    {
      fullName: 'Joseph Martin Kraus',
      shortName: 'Kraus',
    },
    {
      fullName: 'Luigi Boccherini',
      shortName: 'Boccherini',
    },
    {
      fullName: 'Johann Nepomuk Hummel',
      shortName: 'Hummel',
    },
    {
      fullName: 'Anna Bon',
      shortName: 'Bon',
    },
    {
      fullName: 'Carl Philipp Emanuel Bach',
      shortName: 'C.P.E. Bach',
    },
    {
      fullName: 'Leopold Mozart',
      shortName: 'L. Mozart',
    },
  ],
  romantic: [
    {
      fullName: 'Johannes Brahms',
      shortName: 'Johannes Brahms',
      fileName: 'brahms',
    },
    {
      fullName: 'Clara Schumann',
      shortName: 'Clara Schumann',
    },
    {
      fullName: 'Franz Schubert',
      shortName: 'Franz Schubert',
      fileName: 'schubert',
    },
    {
      fullName: 'Fanny Mendelssohn',
      shortName: 'Fanny Mendelssohn',
    },
    {
      fullName: 'Richard Wagner',
      shortName: 'Richard Wagner',
      fileName: 'wagner',
    },
    {
      fullName: 'Pauline Viardot',
      shortName: 'Viardot',
    },
    {
      fullName: 'Frédéric Chopin',
      shortName: 'Chopin',
    },
    {
      fullName: 'Louise Farrenc',
      shortName: 'Farrenc',
    },
    {
      fullName: 'Giuseppe Verdi',
      shortName: 'Verdi',
    },
    {
      fullName: 'Pauline Oliveros',
      shortName: 'Oliveros',
    },
    {
      fullName: 'Johann Strauss',
      shortName: 'J. Strauss',
    },
    {
      fullName: 'Agnesi Sisters',
      shortName: 'Agnesi',
    },
    {
      fullName: 'Antonio Dvořák',
      shortName: 'Dvořák',
    },
    {
      fullName: 'Teresa Carreño',
      shortName: 'Carreño',
    },
    {
      fullName: 'Felix Mendelssohn',
      shortName: 'Fe. Mendelssohn',
    },
    {
      fullName: 'Augusta Holmès',
      shortName: 'Holmès',
    },
    {
      fullName: 'Franz Liszt',
      shortName: 'Liszt',
    },
    {
      fullName: 'Robert Schumann',
      shortName: 'R. Schumann',
    },
    {
      fullName: 'Cécile Chaminade',
      shortName: 'Chaminade',
    },
    {
      fullName: 'Pyotr Ilyich Tchaikovsky',
      shortName: 'Tchaikovsky',
    },
    {
      fullName: 'Amy Beach',
      shortName: 'Beach',
    },
    {
      fullName: 'Giacomo Puccini',
      shortName: 'Puccini',
    },
    {
      fullName: 'Louise Bertin',
      shortName: 'Bertin',
    },
    {
      fullName: 'Johann Strauss II',
      shortName: 'Strauss II',
    },
    {
      fullName: 'Hildegard von Bingen',
      shortName: 'von Bingen',
    },
    {
      fullName: 'Vincenzo Bellini',
      shortName: 'Bellini',
    },
    {
      fullName: 'Gaetano Donizetti',
      shortName: 'Donizetti',
    },
    {
      fullName: 'Jean Philippe Rameau',
      shortName: 'Rameau',
    },
    {
      fullName: 'Carl Maria von Weber',
      shortName: 'von Weber',
    },
    {
      fullName: 'Gioachino Rossini',
      shortName: 'Rossini',
    },
    {
      fullName: 'Charles Gounod',
      shortName: 'Gounod',
    },
    {
      fullName: 'Modest Mussorgsky',
      shortName: 'Mussorgsky',
    },
    {
      fullName: 'Nicola Paganini',
      shortName: 'Paganini',
    },
    {
      fullName: 'César Franck',
      shortName: 'Franck',
    },
    {
      fullName: 'Bedřich Smetana',
      shortName: 'Smetana',
    },
    {
      fullName: 'Arthur Sullivan',
      shortName: 'Sullivan',
    },
    {
      fullName: 'Jacques Offenbach',
      shortName: 'Offenbach',
    },
    {
      fullName: 'Jules Massenet',
      shortName: 'Massenet',
    },
    {
      fullName: 'Edvard Grieg',
      shortName: 'Grieg',
    },
    {
      fullName: 'Camille Saint-Saëns',
      shortName: 'Saint-Saëns',
    },
    {
      fullName: 'Maurice Duruflé',
      shortName: 'Duruflé',
    },
    {
      fullName: 'Jean Sibelius',
      shortName: 'Sibelius',
    },
    {
      fullName: 'Johan Severin Svendsen',
      shortName: 'Svendsen',
    },
    {
      fullName: 'Hector Berlioz',
      shortName: 'Berlioz',
    },
    {
      fullName: 'Christoph Willibald Gluck',
      shortName: 'Gluck',
    },
  ],
  '20th century': [
    {
      fullName: 'Igor Stravinsky',
      shortName: 'Igor Stravinsky',
      fileName: 'stravinsky',
    },
    {
      fullName: 'Rebecca Clarke',
      shortName: 'Rebecca Clarke',
    },
    {
      fullName: 'Carl Orff',
      shortName: 'Carl Orff',
    },
    {
      fullName: 'Ruth Crawford Seeger',
      shortName: 'Ruth C. Seeger',
      fileName: 'seeger',
    },
    {
      fullName: 'Richard Strauss',
      shortName: 'Richard Strauss',
      fileName: 'strauss',
    },
    {
      fullName: 'Lili Boulanger',
      shortName: 'Boulanger',
    },
    {
      fullName: 'Sergei Rachmaninov',
      shortName: 'Rachmaninov',
    },
    {
      fullName: 'Ethel Smyth',
      shortName: 'Smyth',
    },
    {
      fullName: 'Dmitri Shostakovich',
      shortName: 'Shostakovich',
    },
    {
      fullName: 'Olga Neuwirth',
      shortName: 'Neuwirth',
    },
    {
      fullName: 'Grażyna Bacewicz',
      shortName: 'Bacewicz',
    },
    {
      fullName: 'Dieter Schnebel',
      shortName: 'Schnebel',
    },
    {
      fullName: 'Mikhail Glinka',
      shortName: 'Glinka',
    },
    {
      fullName: 'Elisabeth Lutyens',
      shortName: 'Lutyens',
    },
    {
      fullName: 'Nikolai Rimsky-Korsakov',
      shortName: 'Rimsky-Korsakov',
    },
    {
      fullName: 'Thea Musgrave',
      shortName: 'Musgrave',
    },
    {
      fullName: 'Madeleine Dring',
      shortName: 'Dring',
    },
    {
      fullName: 'Béla Bartók',
      shortName: 'Bartók',
    },
    {
      fullName: 'Florence Price',
      shortName: 'Price',
    },
    {
      fullName: 'Anton Bruckner',
      shortName: 'Bruckner',
    },
    {
      fullName: 'Rhoda Coghill',
      shortName: 'Coghill',
    },
    {
      fullName: 'Imogen Holst',
      shortName: 'Holst',
    },
    {
      fullName: 'Gabriel Fauré',
      shortName: 'Fauré',
    },
    {
      fullName: 'Meredith Monk',
      shortName: 'Monk',
    },
    {
      fullName: 'Maurice Ravel',
      shortName: 'Ravel',
    },
    {
      fullName: 'Lucia Quinciani',
      shortName: 'Quinciani',
    },
    {
      fullName: 'Joan Tower',
      shortName: 'Tower',
    },
    {
      fullName: 'Gustav Mahler',
      shortName: 'Mahler',
    },
    {
      fullName: 'Ellen Taaffe Zwilich',
      shortName: 'Zwilich',
    },
    {
      fullName: 'Johann Fux',
      shortName: 'Fux',
    },
    {
      fullName: 'Joan Trimble',
      shortName: 'Trimble',
    },
    {
      fullName: 'Galina Ustvolskaya',
      shortName: 'Ustvolskaya',
    },
    {
      fullName: 'Jeanne Demessieux',
      shortName: 'Demessieux',
    },
    {
      fullName: 'Giovanni Palestrina',
      shortName: 'Palestrina',
    },
    {
      fullName: 'Betty Roe',
      shortName: 'Roe',
    },
    {
      fullName: 'Sergei Prokofiev',
      shortName: 'Prokofiev',
    },
    {
      fullName: 'Judith Bingham',
      shortName: 'Bingham',
    },
    {
      fullName: 'Libby Larsen',
      shortName: 'Larsen',
    },
    {
      fullName: 'Jane Vowles',
      shortName: 'Vowles',
    },
    {
      fullName: 'Judith Lang Zaimont',
      shortName: 'Zaimont',
    },
    {
      fullName: 'Augusta Read Thomas',
      shortName: 'Read Thomas',
    },
    {
      fullName: 'Ralph Vaughan Williams',
      shortName: 'Vaughan Williams',
    },
    {
      fullName: 'Karl Jenkins',
      shortName: 'Jenkins',
    },
    {
      fullName: 'Nancy Van de Vate',
      shortName: 'Van de Vate',
    },
    {
      fullName: 'Carl Nielsen',
      shortName: 'Nielsen',
    },
    {
      fullName: 'Errollyn Wallen',
      shortName: 'Wallen',
    },
    {
      fullName: 'Roxanna Panufnik',
      shortName: 'Panufnik',
    },
    {
      fullName: 'Judith Shatin',
      shortName: 'Shatin',
    },
    {
      fullName: 'Marguerite Roesgen-Champion',
      shortName: 'Roesgen-Champion',
    },
    {
      fullName: 'Isabella Leonarda',
      shortName: 'Leonarda',
    },
    {
      fullName: 'Benjamin Britten',
      shortName: 'Britten',
    },
    {
      fullName: 'Léon Boëllmann',
      shortName: 'Boëllmann',
    },
    {
      fullName: 'Olivier Messiaen',
      shortName: 'Messiaen',
    },
    {
      fullName: 'Samuel Barber',
      shortName: 'Barber',
    },
    {
      fullName: 'Max Bruch',
      shortName: 'Bruch',
    },
    {
      fullName: 'Edward Elgar',
      shortName: 'Elgar',
    },
    {
      fullName: 'Aaron Copland',
      shortName: 'Copland',
    },
    {
      fullName: 'Francis Poulenc',
      shortName: 'Poulenc',
    },
    {
      fullName: 'Erik Satie',
      shortName: 'Satie',
    },
    {
      fullName: 'Mikhail Ivanovich Glinka',
      shortName: 'M. I. Glinka',
    },
    {
      fullName: 'Annie Fortescue Harrison',
      shortName: 'Fortescue Harrison',
    },
    {
      fullName: 'Benjamin Godard',
      shortName: 'Godard',
    },
    {
      fullName: 'Paule Maurice',
      shortName: 'Maurice',
    },
    {
      fullName: 'Mstislav Rostropovich',
      shortName: 'Rostropovich',
    },
    {
      fullName: 'Barbara Kolb',
      shortName: 'Kolb',
    },
    {
      fullName: 'Yelena Firsova',
      shortName: 'Firsova',
    },
    {
      fullName: 'Ernst Toch',
      shortName: 'Toch',
    },
    {
      fullName: 'Mary Howe',
      shortName: 'Howe',
    },
    {
      fullName: 'Anton Webern',
      shortName: 'Webern',
    },
    {
      fullName: 'Avril Coleridge-Taylor',
      shortName: 'Coleridge-Taylor',
    },
    {
      fullName: 'Tansy Davies',
      shortName: 'Davies',
    },
    {
      fullName: 'Charles-Valentin Alkan',
      shortName: 'Alkan',
    },
    {
      fullName: 'Nadia Boulanger',
      shortName: 'N. Boulanger',
    },
    {
      fullName: 'Johann Jakob Froberger',
      shortName: 'Froberger',
    },
    {
      fullName: 'Kerry Andrew',
      shortName: 'Andrew',
    },
    {
      fullName: 'Unsuk Chin',
      shortName: 'Chin',
    },
    {
      fullName: 'Jean Sibelius',
      shortName: 'Sibelius',
    },
    {
      fullName: 'Alice Mary Smith',
      shortName: 'Smith',
    },
    {
      fullName: 'Paul Hindemith',
      shortName: 'Hindemith',
    },
    {
      fullName: 'Louise Talma',
      shortName: 'Talma',
    },
    {
      fullName: 'Alexander Borodin',
      shortName: 'Borodin',
    },
    {
      fullName: 'Maria Grever',
      shortName: 'Grever',
    },
    {
      fullName: 'Marie Jaëll',
      shortName: 'Jaëll',
    },
  ],
  contemporary: [
    {
      fullName: 'John Adams',
      shortName: 'Adams',
    },
    {
      fullName: 'Sofia Gubaidulina',
      shortName: 'Gubaidulina',
    },
    {
      fullName: 'Kaija Saariaho',
      shortName: 'Saariaho',
    },
    {
      fullName: 'Judith Weir',
      shortName: 'Weir',
    },
    {
      fullName: 'Meredith Monk',
      shortName: 'Monk',
    },
    {
      fullName: 'Caroline Shaw',
      shortName: 'Shaw',
    },
    {
      fullName: 'Jennifer Higdon',
      shortName: 'Higdon',
    },
    {
      fullName: 'Olga Neuwirth',
      shortName: 'Neuwirth',
    },
    {
      fullName: 'Wolfgang Rihm',
      shortName: 'Rihm',
    },
    {
      fullName: 'Laura Kaminsky',
      shortName: 'Kaminsky',
    },
    {
      fullName: 'Anna Clyne',
      shortName: 'Clyne',
    },
    {
      fullName: 'Henriette Renié',
      shortName: 'Renié',
    },
    {
      fullName: 'Chen Yi',
      shortName: 'Chen Yi',
    },
    {
      fullName: 'Maria Schneider',
      shortName: 'Schneider',
    },
    {
      fullName: 'Sally Beamish',
      shortName: 'Beamish',
    },
    {
      fullName: 'Nicola LeFanu',
      shortName: 'LeFanu',
    },
    {
      fullName: 'Errollyn Wallen',
      shortName: 'Wallen',
    },
    {
      fullName: 'Roxanna Panufnik',
      shortName: 'Panufnik',
    },
    {
      fullName: 'Missy Mazzoli',
      shortName: 'Mazzoli',
    },
    {
      fullName: 'Anne Boyd',
      shortName: 'Boyd',
    },
    {
      fullName: 'Maria Newman',
      shortName: 'Newman',
    },
    {
      fullName: 'Ingrid Stölzel',
      shortName: 'Stölzel',
    },
    {
      fullName: 'Moya Henderson',
      shortName: 'Henderson',
    },
    {
      fullName: 'Cecilia McDowall',
      shortName: 'McDowall',
    },
    {
      fullName: 'Judith Bingham',
      shortName: 'Bingham',
    },
    {
      fullName: 'Augusta Read Thomas',
      shortName: 'Read Thomas',
    },
    {
      fullName: 'Nancy Van de Vate',
      shortName: 'Van de Vate',
    },

    {
      fullName: 'Jean Coulthard',
      shortName: 'Coulthard',
    },
    {
      fullName: 'Alma Mahler',
      shortName: 'Mahler',
    },
    {
      fullName: 'Peggy Glanville-Hicks',
      shortName: 'Glanville-Hicks',
    },
    {
      fullName: 'Phyllis Tate',
      shortName: 'Tate',
    },
    {
      fullName: 'Doreen Carwithen',
      shortName: 'Carwithen',
    },
    {
      fullName: 'Jocelyn Pook',
      shortName: 'Pook',
    },
    {
      fullName: 'Betty Roe',
      shortName: 'Roe',
    },
    {
      fullName: 'Tansy Davies',
      shortName: 'Davies',
    },
    {
      fullName: 'Kerry Andrew',
      shortName: 'Andrew',
    },
    {
      fullName: 'Unsuk Chin',
      shortName: 'Chin',
    },
    {
      fullName: 'Anna Thorvaldsdottir',
      shortName: 'Thorvaldsdottir',
    },
    {
      fullName: 'Yoko Ono',
      shortName: 'Ono',
    },
    {
      fullName: 'Libby Larsen',
      shortName: 'Larsen',
    },
    {
      fullName: 'Judith Shatin',
      shortName: 'Shatin',
    },
    {
      fullName: 'Karl Jenkins',
      shortName: 'Jenkins',
    },
    {
      fullName: 'Emma Lou Diemer',
      shortName: 'Diemer',
    },
  ],
  swing: [
    {
      fullName: 'Duke Ellington',
      shortName: 'Duke Ellington',
      fileName: 'ellington',
    },
    {
      fullName: 'Count Basie',
      shortName: 'Count Basie',
      fileName: 'basie',
    },
    {
      fullName: 'Benny Goodman',
      shortName: 'Benny Goodman',
      fileName: 'goodman',
    },
    {
      fullName: 'Glenn Miller',
      shortName: 'Glenn Miller',
      fileName: 'miller',
    },
    {
      fullName: 'Artie Shaw',
      shortName: 'Artie Shaw',
      fileName: 'shaw',
    },
    {
      fullName: 'Tommy Dorsey',
      shortName: 'Tommy Dorsey',
      fileName: 'dorsey',
    },
    {
      fullName: 'Jimmy Dorsey',
      shortName: 'Jimmy Dorsey',
      fileName: 'dorsey',
    },
    {
      fullName: 'Earl Hines',
      shortName: 'Earl Hines',
      fileName: 'hines',
    },
    {
      fullName: 'Cab Calloway',
      shortName: 'Cab Calloway',
      fileName: 'calloway',
    },
    {
      fullName: 'Fletcher Henderson',
      shortName: 'Fletcher Henderson',
      fileName: 'henderson',
    },
    {
      fullName: 'Chick Webb',
      shortName: 'Chick Webb',
      fileName: 'webb',
    },
    {
      fullName: 'Lionel Hampton',
      shortName: 'Lionel Hampton',
      fileName: 'hampton',
    },
    {
      fullName: 'Benny Carter',
      shortName: 'Benny Carter',
      fileName: 'carter',
    },
    {
      fullName: 'Coleman Hawkins',
      shortName: 'Coleman Hawkins',
      fileName: 'hawkins',
    },
    {
      fullName: 'Lester Young',
      shortName: 'Lester Young',
      fileName: 'young',
    },
    {
      fullName: 'Django Reinhardt',
      shortName: 'Django Reinhardt',
      fileName: 'reinhardt',
    },
    {
      fullName: 'Charlie Christian',
      shortName: 'Charlie Christian',
      fileName: 'christian',
    },
    {
      fullName: 'Dizzy Gillespie',
      shortName: 'Dizzy Gillespie',
      fileName: 'gillespie',
    },
  ],
  bebop: [
    {
      fullName: 'Charlie Parker',
      shortName: 'Charlie Parker',
      fileName: 'parker',
    },
    {
      fullName: 'Thelonious Monk',
      shortName: 'Thelonious Monk',
      fileName: 'monk',
    },
    {
      fullName: 'Dizzy Gillespie',
      shortName: 'Dizzy Gillespie',
      fileName: 'gillespie',
    },
    {
      fullName: 'Charlie Christian',
      shortName: 'Charlie Christian',
      fileName: 'christian',
    },
    {
      fullName: 'Kenny Clarke',
      shortName: 'Kenny Clarke',
      fileName: 'clarke',
    },
    {
      fullName: 'Max Roach',
      shortName: 'Max Roach',
      fileName: 'roach',
    },
    {
      fullName: 'Sonny Stitt',
      shortName: 'Sonny Stitt',
      fileName: 'stitt',
    },
    {
      fullName: 'Bud Powell',
      shortName: 'Bud Powell',
      fileName: 'powell',
    },
    {
      fullName: 'Fats Navarro',
      shortName: 'Fats Navarro',
      fileName: 'navarro',
    },
    {
      fullName: 'J. J. Johnson',
      shortName: 'J. J. Johnson',
      fileName: 'johnson',
    },
    {
      fullName: 'Lennie Tristano',
      shortName: 'Lennie Tristano',
      fileName: 'tristano',
    },
    {
      fullName: 'Benny Goodman',
      shortName: 'Benny Goodman',
      fileName: 'goodman',
    },
    {
      fullName: 'Stan Getz',
      shortName: 'Stan Getz',
      fileName: 'getz',
    },
    {
      fullName: 'Oscar Pettiford',
      shortName: 'Oscar Pettiford',
      fileName: 'pettiford',
    },
    {
      fullName: 'Dexter Gordon',
      shortName: 'Dexter Gordon',
      fileName: 'gordon',
    },
    {
      fullName: 'Lucky Thompson',
      shortName: 'Lucky Thompson',
      fileName: 'thompson',
    },
    {
      fullName: 'Benny Carter',
      shortName: 'Benny Carter',
      fileName: 'carter',
    },
  ],
  'cool jazz': [
    {
      fullName: 'Miles Davis',
      shortName: 'Miles Davis',
      fileName: 'davis',
    },
    {
      fullName: 'Chet Baker',
      shortName: 'Chet Baker',
      fileName: 'baker',
    },
  ],
  'hard bop': [
    {
      fullName: 'John Coltrane',
      shortName: 'John Coltrane',
      fileName: 'coltrane',
    },
    {
      fullName: 'Art Blakey',
      shortName: 'Art Blakey',
      fileName: 'blakey',
    },
  ],
  'modal jazz': [
    {
      fullName: 'Bill Evans',
      shortName: 'Bill Evans',
      fileName: 'evans',
    },
    {
      fullName: 'Herbie Hancock',
      shortName: 'Herbie Hancock',
      fileName: 'hancock',
    },
  ],
};
