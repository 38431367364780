import {
  CakeOutlined,
  EditCalendarOutlined,
  EmailOutlined,
  EventOutlined,
  FlagOutlined,
  HomeOutlined,
  KeyOutlined,
  LabelOutlined,
  PeopleOutline,
  PersonAddOutlined,
  PersonOutline,
  PhoneOutlined,
  PianoOutlined,
  SchoolOutlined,
  SettingsOutlined,
  SupervisorAccountOutlined,
  TextFieldsOutlined,
} from '@mui/icons-material';
import {
  Checkbox,
  Input,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { avatarClasses, checkboxClasses } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { Address, ParsedContact } from '@shared/models/contacts';
import { ZodTimestamp } from '@shared/zodTimestamp';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uuid4 from 'uuid4';

import { HotkeySpan } from '@components/Sidebar/TooltipHotkeyTitle';
import { useUser } from '@features/Organization/organizationSlice';

import { ContactAvatar } from './ContactAvatar';
import { getContactName } from './ContactDetail/ViewContact';
import { bucketDate, getBeforeDateMap } from './ContactGroup';
import { InstrumentAvatar } from './InstrumentAvatar';

type FilterKey =
  | 'people'
  | 'creator'
  | 'emails'
  | 'phones'
  | 'addresses'
  | 'labels'
  | 'content'
  | 'instruments'
  | 'duplicates'
  | 'hasLogin'
  | 'isPrimary'
  | 'teachers'
  | 'students'
  | 'createdDate'
  | 'updatedDate'
  | 'birthdate';

interface FilterConfigItem {
  title: string;
  pluralTitle?: string;
  icon: JSX.Element;
  subIcon?: JSX.Element;
  hasAvatar?: boolean;
  manyToMany?: boolean;
  dateFilter?: boolean;
  noSubOptionTitle?: string;
}

export const filterConfig: Record<FilterKey, FilterConfigItem> = {
  people: {
    title: 'Contact',
    pluralTitle: 'Contacts',
    icon: <PeopleOutline />,
    subIcon: <PersonOutline />,
    hasAvatar: true,
  },
  creator: {
    title: 'Creator',
    pluralTitle: 'Creators',
    icon: <PersonAddOutlined />,
    subIcon: <PersonOutline />,
    hasAvatar: true,
  },
  emails: {
    title: 'Emails',
    icon: <EmailOutlined />,
    manyToMany: true,
  },
  phones: {
    title: 'Phones',
    icon: <PhoneOutlined />,
    subIcon: <PersonOutline />,
    manyToMany: true,
  },
  addresses: {
    title: 'Addresses',
    icon: <HomeOutlined />,
    subIcon: <PersonOutline />,
    manyToMany: true,
  },
  labels: {
    title: 'Labels',
    icon: <LabelOutlined />,
    subIcon: <PersonOutline />,
    manyToMany: true,
  },
  content: {
    title: 'Content',
    icon: <TextFieldsOutlined />,
    subIcon: <PersonOutline />,
  },
  instruments: {
    title: 'Instruments',
    icon: <PianoOutlined />,
    manyToMany: true,
    hasAvatar: true,
  },
  duplicates: {
    title: 'Duplicates',
    icon: <FlagOutlined />,
    subIcon: <PersonOutline />,
    noSubOptionTitle: 'contact with duplicate',
  },
  hasLogin: {
    title: 'Has Login',
    icon: <KeyOutlined />,
    subIcon: <PersonOutline />,
    noSubOptionTitle: 'contact with login',
  },
  isPrimary: {
    title: 'Primary contacts',
    icon: <SettingsOutlined />,
    noSubOptionTitle: 'primary',
  },
  teachers: {
    title: 'Teachers',
    icon: <SupervisorAccountOutlined />,
    noSubOptionTitle: 'a teacher',
  },
  students: {
    title: 'Students',
    icon: <SchoolOutlined />,
    noSubOptionTitle: 'a student',
  },
  createdDate: {
    title: 'Created date',
    icon: <EventOutlined />,
    dateFilter: true,
  },
  updatedDate: {
    title: 'Updated date',
    icon: <EditCalendarOutlined />,
    dateFilter: true,
  },
  birthdate: {
    title: 'Birthdate',
    icon: <CakeOutlined />,
    dateFilter: true,
  },
} as const;

interface FilterOptionBase {
  bucketFilter?: boolean;
  dateFilter?: boolean;
  index?: number;
  checked?: boolean;
}

interface FilterOption extends FilterOptionBase {
  key: FilterKey;
  type: 'option';
  subOptions?: FilterSubOption[];
}

interface FilterSubOption extends FilterOptionBase {
  key: string;
  type: 'subOption';
  title: string;
  icon?: JSX.Element;
  count?: number;
}

interface FilterDivider {
  type: 'divider';
}

type FilterItem = FilterOption | FilterDivider;

interface FilterProperties {
  checked?: string[];
  filterKey: FilterKey;
  inclusive: boolean;
  all: boolean | undefined;
  dateOperator: 'before' | 'after' | 'in';
}
export type FilterState = Record<string, FilterProperties>;

export const stringifyAddress = (address: Address) => {
  const addressStringBase = [
    address.street1,
    address.street2,
    address.city,
    address.state,
  ]
    .filter(Boolean)
    .join(', ');

  return [addressStringBase, address.zip, address.country].join(' ');
};

interface FilterOverviewProps {
  onFilterSelected?: (
    filter: FilterState | undefined,
    shouldClose: boolean,
    openContentModal?: boolean
  ) => void;
  filterState?: FilterState;
  currentFilterId?: string;
  currentFilterOptionKey?: string;
  contacts: ParsedContact[] | undefined;
}

export const FilterOverview = ({
  onFilterSelected,
  filterState,
  currentFilterId: currentFilterIdExternal,
  currentFilterOptionKey: currentFilterOptionKeyExternal,
  contacts,
}: FilterOverviewProps) => {
  const user = useUser();
  const filterSearchRef = useRef<HTMLInputElement | null>(null);

  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [currentFilterId, setCurrentFilterId] = useState<string | undefined>(
    currentFilterIdExternal
  );

  const currentFilter = useMemo(
    () => filterState?.[currentFilterId || ''],
    [filterState, currentFilterId]
  );
  const currentFilterCheckedSet = useMemo(
    () => new Set(currentFilter?.checked || []),
    [currentFilter]
  );
  const filterOptionsBase: FilterItem[] = useMemo(() => {
    let noPhonesCount = 0;
    const phones: Record<string, number> = {};
    let noEmailsCount = 0;
    const emails: Record<string, number> = {};
    let noAddressesCount = 0;
    const addresses: Record<string, number> = {};
    let noInstrumentsCount = 0;
    const instruments: Record<string, number> = {};
    const getEmptyDateCount = () => ({
      dayAgo: 0,
      weekAgo: 0,
      monthAgo: 0,
      threeMonthsAgo: 0,
    });
    const createdAtAfterCount = getEmptyDateCount();
    const createdAtBeforeCount = getEmptyDateCount();
    const updatedAtAfterCount = getEmptyDateCount();
    const updatedAtBeforeCount = getEmptyDateCount();

    (contacts || []).forEach((contact) => {
      if (contact.phones?.length) {
        contact.phones.forEach((phone) => {
          phones[phone.number] = (phones[phone.number] || 0) + 1;
        });
      } else {
        noPhonesCount += 1;
      }

      if (contact.emails?.length) {
        contact.emails.forEach((email) => {
          emails[email.address] = (emails[email.address] || 0) + 1;
        });
      } else {
        noEmailsCount += 1;
      }

      if (contact.addresses?.length) {
        contact.addresses.forEach((address) => {
          addresses[stringifyAddress(address)] =
            (addresses[stringifyAddress(address)] || 0) + 1;
        });
      } else {
        noAddressesCount += 1;
      }

      if (contact.instruments?.length) {
        contact.instruments.forEach((instrument) => {
          instruments[instrument] = (instruments[instrument] || 0) + 1;
        });
      } else {
        noInstrumentsCount += 1;
      }

      const now = new Date();

      const incrementAfterDate = (
        input: ZodTimestamp,
        count: Record<string, number>
      ) => {
        const countKey = bucketDate(input.toDate(), now);
        if (countKey && countKey in count) {
          count[countKey] += 1;
        }
      };

      const beforeDateMap = getBeforeDateMap(now);
      const incrementBeforeDate = (
        input: ZodTimestamp,
        count: Record<string, number>
      ) => {
        const date = input.toDate();

        Object.entries(beforeDateMap).forEach(([key, dateFn]) => {
          if (date < dateFn()) {
            count[key] += 1;
          }
        });
      };

      incrementAfterDate(contact.createdAt, createdAtAfterCount);
      incrementAfterDate(contact.updatedAt, updatedAtAfterCount);

      if (currentFilter?.dateOperator === 'before') {
        incrementBeforeDate(contact.createdAt, createdAtBeforeCount);
        incrementBeforeDate(contact.updatedAt, updatedAtBeforeCount);
      }
    });

    return [
      {
        type: 'option',
        key: 'people',
        bucketFilter: true,
        subOptions: (contacts || []).map(
          (contact): FilterSubOption => ({
            type: 'subOption',
            title: getContactName(contact, user),
            key: contact.id,
            icon: <ContactAvatar contact={contact} />,
            checked:
              currentFilter?.filterKey === 'people' &&
              currentFilterCheckedSet.has(contact.id),
          })
        ),
      },
      {
        type: 'option',
        key: 'creator',
        bucketFilter: true,
        subOptions: (contacts || []).flatMap((contact): FilterSubOption[] =>
          contact.userId
            ? [
                {
                  type: 'subOption',
                  title: getContactName(contact, user),
                  key: contact.userId,
                  icon: <ContactAvatar contact={contact} />,
                  checked:
                    currentFilter?.filterKey === 'creator' &&
                    currentFilterCheckedSet.has(contact.userId),
                },
              ]
            : []
        ),
      },
      {
        type: 'option',
        key: 'emails',
        bucketFilter: true,
        subOptions: [
          {
            type: 'subOption',
            title: 'No emails',
            key: 'meta-no-entry',
            count: noEmailsCount,
            checked:
              currentFilter?.filterKey === 'emails' &&
              currentFilterCheckedSet.has('meta-no-entry'),
          },
          ...Object.entries(emails).map(
            ([email, count]): FilterSubOption => ({
              type: 'subOption',
              title: email,
              key: email,
              count,
              checked:
                currentFilter?.filterKey === 'emails' &&
                currentFilterCheckedSet.has(email),
            })
          ),
        ],
      },
      {
        type: 'option',
        key: 'phones',
        bucketFilter: true,
        subOptions: [
          {
            type: 'subOption',
            title: 'No phones',
            key: 'meta-no-entry',
            count: noPhonesCount,
            checked:
              currentFilter?.filterKey === 'emails' &&
              currentFilterCheckedSet.has('meta-no-entry'),
          },
          ...Object.entries(phones).map(
            ([phone, count]): FilterSubOption => ({
              type: 'subOption',
              title: phone,
              key: phone,
              count,
              checked:
                currentFilter?.filterKey === 'phones' &&
                currentFilterCheckedSet.has(phone),
            })
          ),
        ],
      },
      {
        type: 'option',
        key: 'addresses',
        bucketFilter: true,
        subOptions: [
          {
            type: 'subOption',
            title: 'No addresses',
            key: 'meta-no-entry',
            count: noAddressesCount,
            checked:
              currentFilter?.filterKey === 'emails' &&
              currentFilterCheckedSet.has('meta-no-entry'),
          },
          ...Object.entries(addresses).map(
            ([address, count]): FilterSubOption => ({
              type: 'subOption',
              title: address,
              key: address,
              count,
              checked:
                currentFilter?.filterKey === 'addresses' &&
                currentFilterCheckedSet.has(address),
            })
          ),
        ],
      },
      // TODO: Add labels
      // { type: 'option', key: 'labels' },
      { type: 'divider' },
      { type: 'option', key: 'content' },
      {
        type: 'option',
        key: 'instruments',
        bucketFilter: true,
        subOptions: [
          {
            type: 'subOption',
            title: 'No instruments',
            key: 'meta-no-entry',
            count: noInstrumentsCount,
            checked:
              currentFilter?.filterKey === 'emails' &&
              currentFilterCheckedSet.has('meta-no-entry'),
          },
          ...Object.entries(instruments).map(
            ([instrument, count]): FilterSubOption => ({
              type: 'subOption',
              title: instrument,
              key: instrument,
              count,
              icon: (
                <InstrumentAvatar
                  instrument={instrument}
                  sx={{ padding: '5px' }}
                />
              ),
              checked:
                currentFilter?.filterKey === 'instruments' &&
                currentFilterCheckedSet.has(instrument),
            })
          ),
        ],
      },
      { type: 'divider' },
      { type: 'option', key: 'students' },
      { type: 'option', key: 'teachers' },
      { type: 'option', key: 'isPrimary' },
      { type: 'option', key: 'duplicates' },
      { type: 'option', key: 'hasLogin' },

      { type: 'divider' },
      {
        type: 'option',
        key: 'createdDate',
        subOptions: Object.entries({
          dayAgo: '1 day ago',
          weekAgo: '1 week ago',
          monthAgo: '1 month ago',
          threeMonthsAgo: '3 months ago',
        }).map(
          ([key, title]): FilterSubOption => ({
            type: 'subOption',
            title,
            key,
            count:
              currentFilter?.dateOperator === 'before'
                ? createdAtBeforeCount[key as keyof typeof createdAtBeforeCount]
                : createdAtAfterCount[key as keyof typeof createdAtAfterCount],
            checked:
              currentFilter?.filterKey === 'createdDate' &&
              currentFilterCheckedSet.has(key),
          })
        ),
      },
      {
        type: 'option',
        key: 'updatedDate',
        subOptions: Object.entries({
          dayAgo: '1 day ago',
          weekAgo: '1 week ago',
          monthAgo: '1 month ago',
          threeMonthsAgo: '3 months ago',
        }).map(
          ([key, title]): FilterSubOption => ({
            type: 'subOption',
            title,
            key,
            count:
              currentFilter?.dateOperator === 'before'
                ? updatedAtBeforeCount[key as keyof typeof updatedAtBeforeCount]
                : updatedAtAfterCount[key as keyof typeof updatedAtAfterCount],
            checked:
              currentFilter?.filterKey === 'createdDate' &&
              currentFilterCheckedSet.has(key),
          })
        ),
      },
      // TODO
      // { type: 'option', key: 'birthdate' },
    ];
  }, [currentFilter, currentFilterCheckedSet, contacts, user]);

  const filteredOptions: FilterItem[] = useMemo(() => {
    const lowerCasedFilterSearchValue = filterSearchValue.toLowerCase();

    let index = -1;

    return filterOptionsBase
      .filter(
        (option) =>
          option.type === 'divider' ||
          filterConfig[option.key]?.title
            .toLowerCase()
            .includes(lowerCasedFilterSearchValue)
      )
      .filter(
        (option, optionIndex, optionsArray) =>
          option.type !== 'divider' ||
          (optionIndex !== optionsArray.length - 1 &&
            optionIndex !== 0 &&
            optionsArray[optionIndex + 1]?.type !== 'divider' &&
            optionsArray[optionIndex - 1]?.type !== 'divider')
      )
      .map((option) => {
        if (option.type === 'divider') {
          return option;
        }

        index += 1;

        return { ...option, index };
      });
  }, [filterOptionsBase, filterSearchValue]);

  const [filterItemIndex, setFilterItemIndex] = useState(0);
  const [showEmptySubOptions, setShowEmptySubOptions] = useState(false);
  const [currentFilterOptionKey, setCurrentFilterOptionKey] = useState<
    FilterKey | undefined
  >(
    currentFilterOptionKeyExternal &&
      currentFilterOptionKeyExternal in filterConfig
      ? (currentFilterOptionKeyExternal as keyof typeof filterConfig)
      : undefined
  );
  const currentFilterOption = useMemo(
    () =>
      filterOptionsBase.find(
        (option) =>
          option.type === 'option' && option.key === currentFilterOptionKey
      ) as FilterOption | undefined,
    [filterOptionsBase, currentFilterOptionKey]
  );

  useHotkeys(
    'ArrowUp',
    () => {
      updateFilterItemIndex(-1);
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );
  useHotkeys(
    'ArrowDown',
    () => {
      updateFilterItemIndex(1);
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );

  const focusInput = useCallback(
    () =>
      setTimeout(
        () => filterSearchRef.current?.querySelector('input')?.focus(),
        100
      ),
    []
  );

  useEffect(() => {
    focusInput();
  }, [focusInput]);

  const filteredSubOptions: (FilterSubOption | FilterDivider)[] | undefined =
    useMemo(() => {
      if (!currentFilterOption?.subOptions?.length) {
        return undefined;
      }

      const lowerCasedFilterSearchValue = filterSearchValue.toLowerCase();

      const baseSubOptions = currentFilterOption.subOptions.filter((option) =>
        option?.title.toLowerCase().includes(lowerCasedFilterSearchValue)
      );

      const nonEmptySubOptions = baseSubOptions.filter(
        ({ count }) => count !== 0
      );
      const totalEmptyOptions =
        baseSubOptions.length - nonEmptySubOptions.length;

      let index = -1;

      return (() => {
        if (totalEmptyOptions === 0) {
          return baseSubOptions;
        }

        if (showEmptySubOptions) {
          return [
            ...nonEmptySubOptions,
            ...baseSubOptions.filter(({ count }) => count === 0),
          ];
        }

        const config = currentFilterOptionKey
          ? filterConfig[currentFilterOptionKey]
          : undefined;

        const emptySubOptions: (FilterSubOption | FilterDivider)[] = [
          ...nonEmptySubOptions,
          { type: 'divider' },
          {
            type: 'subOption',
            title: `${totalEmptyOptions} option${
              totalEmptyOptions !== 1 ? 's' : ''
            } not matching any contacts`,
            key: 'options-not-matching-any-contacts',
            icon: config?.icon,
          },
        ];

        return emptySubOptions;
      })().map((option) => {
        if (option.type === 'divider') {
          return option;
        }

        index += 1;

        return { ...option, index };
      });
    }, [
      filterSearchValue,
      currentFilterOption,
      showEmptySubOptions,
      currentFilterOptionKey,
    ]);

  const updateFilterItemIndex = useCallback(
    (change: number) => {
      setFilterItemIndex((index) =>
        Math.min(
          Math.max(index + change, 0),
          (filteredSubOptions
            ? // Duplicated to satisfy TypeScript
              filteredSubOptions.filter(({ type }) => type !== 'divider')
            : filteredOptions.filter(({ type }) => type !== 'divider')
          ).length - 1
        )
      );
    },
    [filteredOptions, filteredSubOptions]
  );

  const handleSelectFilter = useCallback(
    (
      filterOption: FilterOption | FilterSubOption,
      shouldCloseBase: boolean
    ) => {
      if (filterOption.key === 'options-not-matching-any-contacts') {
        setShowEmptySubOptions(true);
        focusInput();

        return;
      }

      if (filterOption.key === 'content') {
        onFilterSelected?.(undefined, true, true);
        return;
      }

      const shouldClose = shouldCloseBase || !currentFilterOption?.bucketFilter;

      if (filterOption.type === 'option' && filterOption.subOptions) {
        setFilterSearchValue('');
        setCurrentFilterOptionKey(filterOption.key);
        setFilterItemIndex(0);

        setTimeout(
          () => filterSearchRef.current?.querySelector('input')?.focus(),
          100
        );

        return;
      }

      const filterId = currentFilterId ?? uuid4();

      const filterConfigKey =
        filterOption.type === 'option' ? filterOption.key : undefined;
      const config = filterConfigKey
        ? filterConfig[filterConfigKey]
        : undefined;

      if (filterOption.type === 'subOption') {
        if (currentFilterOption?.bucketFilter) {
          if (currentFilterCheckedSet.has(filterOption.key)) {
            currentFilterCheckedSet.delete(filterOption.key);
          } else {
            currentFilterCheckedSet.add(filterOption.key);
          }

          if (!currentFilterId) {
            setCurrentFilterId(filterId);
          }
        } else {
          currentFilterCheckedSet.clear();
          currentFilterCheckedSet.add(filterOption.key);
        }

        const key = currentFilterOption?.key;

        if (!key) {
          throw new Error('Filter option parent key is not defined');
        }

        onFilterSelected?.(
          {
            ...filterState,
            [filterId]: {
              ...currentFilter,
              checked: [...currentFilterCheckedSet],
              filterKey: key,
              inclusive: currentFilter?.inclusive ?? true,
              all:
                currentFilter?.all ??
                (config?.manyToMany
                  ? currentFilterCheckedSet.size > 1
                  : undefined),
              dateOperator: currentFilter?.dateOperator || 'after',
            },
          },
          shouldClose
        );

        return;
      }

      onFilterSelected?.(
        {
          ...filterState,
          [filterId]: {
            ...currentFilter,
            filterKey: filterOption.key,
            inclusive: currentFilter?.inclusive ?? true,
            all: undefined,
            dateOperator: currentFilter?.dateOperator || 'after',
          },
        },
        shouldClose
      );
    },
    [
      currentFilterOption,
      onFilterSelected,
      filterState,
      currentFilterId,
      currentFilterCheckedSet,
      currentFilter,
      focusInput,
    ]
  );

  useHotkeys(
    'Enter',
    (event) => {
      // Duplicated code to satisfy TypeScript
      const filterOption = (filteredSubOptions?.filter(
        ({ type }) => type !== 'divider'
      ) || filteredOptions.filter(({ type }) => type !== 'divider'))[
        filterItemIndex
      ];

      if (
        filterOption?.type !== 'option' &&
        filterOption?.type !== 'subOption'
      ) {
        throw new Error('Filter option is not an option');
      }

      event.preventDefault();
      handleSelectFilter(filterOption, true);
    },
    { ignoredElementWhitelist: ['INPUT'] }
  );

  const options = filteredSubOptions ?? filteredOptions;

  return (
    <List
      size="sm"
      sx={{
        [`& .${avatarClasses.root}:not(.instruments)`]: {
          height: '20px',
          width: '20px',
        },
      }}
    >
      <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <Input
          placeholder={
            currentFilterOption
              ? filterConfig[currentFilterOption.key]?.title
              : 'Filter…'
          }
          variant="plain"
          size="sm"
          sx={{ paddingTop: 0, paddingBottom: 0, minHeight: 0 }}
          ref={filterSearchRef}
          value={filterSearchValue}
          onChange={(event) => setFilterSearchValue(event.target.value)}
        />

        {filteredSubOptions ? null : <HotkeySpan>F</HotkeySpan>}
      </ListItem>
      {options.length > 0 ? <ListDivider /> : null}
      {options.map((filterOption, filterOptionIndex) => {
        const resolvedIndex = currentFilterOption
          ? (filterOption.type === 'subOption' && filterOption?.index) || 0
          : (filterOption.type === 'option' && filterOption?.index) || 0;
        const decoratorValue = (() => {
          if (filterOption.type === 'option') {
            return filterConfig[filterOption.key]?.icon;
          }

          if (filterOption.type === 'subOption' && currentFilterOptionKey) {
            return (
              filterOption?.icon ??
              filterConfig[currentFilterOptionKey]?.subIcon
            );
          }

          return null;
        })();

        return filterOption.type === 'option' ||
          filterOption.type === 'subOption' ? (
          <ListItem key={filterOption.key}>
            <ListItemButton
              selected={filterItemIndex === resolvedIndex}
              onClick={() => handleSelectFilter(filterOption, true)}
              onMouseEnter={() => setFilterItemIndex(resolvedIndex)}
              sx={{
                '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                  backgroundColor: 'unset',
                },
                '&.Mui-selected': {
                  fontWeight: 'unset',
                },
                [`&:not(.Mui-selected) .${checkboxClasses.root}:not(.${checkboxClasses.checked})`]:
                  {
                    opacity: 0,
                  },
              }}
            >
              {currentFilterOption?.bucketFilter ? (
                <Checkbox
                  checked={filterOption.checked}
                  onClick={(event) => {
                    event.stopPropagation();

                    handleSelectFilter(filterOption, false);
                  }}
                />
              ) : null}
              {decoratorValue ? (
                <ListItemDecorator>{decoratorValue}</ListItemDecorator>
              ) : null}
              <ListItemContent
                sx={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}
              >
                {filterOption.type === 'option'
                  ? filterConfig[filterOption.key]?.title
                  : filterOption.title}{' '}
                {filterOption.type === 'subOption' &&
                (filterOption.count || 0) > 0 ? (
                  <Typography
                    component="span"
                    level="body-xs"
                    textColor="neutral.400"
                  >
                    {filterOption.count} contact
                    {filterOption.count !== 1 ? 's' : ''}
                  </Typography>
                ) : null}
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ) : (
          <ListDivider key={filterOptionIndex} />
        );
      })}
    </List>
  );
};
