import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  AddOutlined,
  AttachMoneyOutlined,
  Bedtime,
  CalendarMonthOutlined,
  DiscountOutlined,
  HourglassEmptyOutlined,
  ManageHistoryOutlined,
  People,
  PointOfSaleOutlined,
  Settings as SettingsIcon,
  StoreOutlined,
} from '@mui/icons-material';
import ReceiptOutlined from '@mui/icons-material/ReceiptOutlined';
import List from '@mui/joy/List';
import { sheetClasses } from '@mui/joy/Sheet';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

import Avatar from '@components/Avatar';
import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHeader } from '@components/ListHeader';
import { ContentContainer } from '@features/Contacts/ContentContainer';
import {
  useOrganizationPermissions,
  useOrganizationSlug,
} from '@features/Organization/organizationSlice';
import Settings from '@features/Settings';
import { useModalState } from '@hooks/useModalState';
import { useProductsQuery } from '@models/products/useProductsQuery';

import { AdminInstruments } from './AdminInstruments';
import { AdminLink, AdminLinkBase } from './AdminLink';
import AddProduct from './Products/AddProduct';

const Admin = () => {
  const modalState = useModalState();
  const organizationSlug = useOrganizationSlug();
  const permissions = useOrganizationPermissions();

  // get non-global products
  const [products] = useProductsQuery();
  const [addingProduct, setAddingProduct] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const schedulingV1_1Enabled = useFeatureFlag('schedulingV1_1Enabled');
  const schedulingV1_2Enabled = useFeatureFlag('schedulingV1_2Enabled');
  const schedulingV1_3Enabled = useFeatureFlag('schedulingV1_2Enabled');
  const isDesktop = useMediaQuery('(min-width:747px)');

  return (
    <IonPage ref={modalState.page}>
      {isDesktop ? null : (
        <IonHeader translucent>
          <IonToolbar class="no-background">
            <IonTitle>Admin</IonTitle>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen>
        <ContentContainer>
          <List
            aria-label="Network"
            sx={{
              '--ListItem-paddingY': '0.72rem',
              paddingBottom: 10,
              '.MuiListDivider-root': {
                margin: 0,
                marginLeft: '28px',
              },
              '.MuiListItem-root': {
                marginLeft: '15px',
              },
              [`& .${sheetClasses.root}`]: {
                p: 0.5,
                lineHeight: 0,
                borderRadius: 'sm',
              },

              '@media (min-width: 747px)': {
                paddingRight: '80px',
              },
            }}
          >
            {permissions?.isTeacher === false ? (
              <AdminLink
                text="Contacts"
                to={`/${organizationSlug}/contacts`}
                icon={<People />}
              />
            ) : null}

            {!schedulingV1_1Enabled && permissions?.isOwner ? (
              <>
                <ListHeader firstHeader border>
                  My Instruments
                </ListHeader>

                <AdminInstruments page={modalState.page} />
              </>
            ) : null}

            {schedulingV1_1Enabled && permissions?.isOwner ? (
              <>
                <ListHeader firstHeader border>
                  What I Teach
                </ListHeader>

                {products?.map((product) => (
                  <AdminLink
                    key={product.id}
                    text={product.productName}
                    to={`/${organizationSlug}/admin/products/${product.id}`}
                    icon={
                      <Avatar
                        style={{
                          width: 40,
                          height: 40,
                          fontWeight: 400,
                          filter: 'invert(1)',
                          marginRight: '8px',
                          backgroundColor: 'black',
                          padding: '5px',
                        }}
                        src={`assets/instruments/${
                          product.productName.startsWith('Group')
                            ? 'note'
                            : product.instrument.toLowerCase()
                        }.svg`}
                      />
                    }
                  />
                ))}

                <AdminLinkBase
                  text="Add"
                  icon={<AddOutlined sx={{ marginLeft: '9px' }} />}
                  onClick={() => setAddingProduct(true)}
                />

                <ListHeader border sx={{ marginTop: 2 }}>
                  Billing
                </ListHeader>

                <AdminLink
                  text="Invoices"
                  to={`/${organizationSlug}/admin/invoices`}
                  icon={<ReceiptOutlined />}
                />

                <AdminLink
                  text="Hourly Rate"
                  to={`/${organizationSlug}/admin/hourly-rate`}
                  icon={<PointOfSaleOutlined />}
                />

                <AdminLink
                  text="Invoice Rules"
                  to={`/${organizationSlug}/admin/invoice-rules`}
                  icon={<ReceiptOutlined />}
                />

                <AdminLink
                  text="Refund Policies"
                  to={`/${organizationSlug}/admin/refund-policies`}
                  icon={<StoreOutlined />}
                />

                {schedulingV1_3Enabled ? (
                  <>
                    <AdminLink
                      text="Discounts"
                      to={`/${organizationSlug}/admin/discounts`}
                      icon={<DiscountOutlined />}
                    />
                  </>
                ) : null}

                <ListHeader border sx={{ marginTop: 2 }}>
                  Scheduling
                </ListHeader>

                <AdminLink
                  text="Teaching Hours"
                  to={`/${organizationSlug}/admin/teaching-hours`}
                  icon={<CalendarMonthOutlined />}
                />
                {schedulingV1_2Enabled ? (
                  <AdminLink
                    text="Reschedule Policies"
                    to={`/${organizationSlug}/admin/reschedule-policies`}
                    icon={<ManageHistoryOutlined />}
                  />
                ) : null}
                <AdminLink
                  text="Last-minute Cancellations"
                  to={`/${organizationSlug}/admin/last-minute-cancellations`}
                  icon={<HourglassEmptyOutlined />}
                />
                <AdminLink
                  text="No-shows"
                  to={`/${organizationSlug}/admin/no-shows`}
                  icon={<Bedtime />}
                />
              </>
            ) : null}

            <ListHeader border sx={{ marginTop: 3 }}>
              Account
            </ListHeader>
            <AdminLinkBase
              text="My Account"
              onClick={() => setIsSettingsOpen(true)}
              icon={<SettingsIcon />}
            />
            <AdminLinkBase
              text="Billing"
              onClick={() => setIsSettingsOpen(true)}
              icon={<AttachMoneyOutlined />}
            />
          </List>

          <AddProduct
            presentingPage={modalState.page}
            isOpen={addingProduct}
            onDone={() => setAddingProduct(false)}
          />

          <Settings
            presentingPage={modalState.page}
            isOpen={isSettingsOpen}
            setIsOpen={setIsSettingsOpen}
          />
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default Admin;
