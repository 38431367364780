import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Route, useHistory } from 'react-router-dom';

import { AppIntro } from '@components/AppIntro';
import { themePages } from '@components/Sidebar';
import { useTabs } from '@components/Tabs/tabs.routes';
import Admin from '@features/Admin';
import GlobalDiscounts from '@features/Admin/Billing/GlobalDiscounts';
import GlobalHourlyRate from '@features/Admin/Billing/GlobalHourlyRate';
import GlobalInvoiceRules from '@features/Admin/Billing/GlobalInvoiceRules';
import GlobalRefundPolicies from '@features/Admin/Billing/GlobalRefundPolicies';
import { SendInvite } from '@features/Admin/Invites/SendInvite';
import Invoices from '@features/Admin/Invoices';
import { InvoiceDetail } from '@features/Admin/Invoices/InvoiceDetail';
import { ProductDetail } from '@features/Admin/Products/ProductDetail';
import GlobalLastMinuteCancellations from '@features/Admin/Scheduling/GlobalLastMinuteCancellations';
import GlobalNoShows from '@features/Admin/Scheduling/GlobalNoShows';
import GlobalReschedulePolicies from '@features/Admin/Scheduling/GlobalReschedulePolicies';
import GlobalTeachingHours from '@features/Admin/Scheduling/GlobalTeachingHours';
import { ContactDetail } from '@features/Contacts/ContactDetail';
import ContactGroup from '@features/Contacts/ContactGroup';
import Lessons from '@features/Lessons';
import { LessonDetailRouter } from '@features/Lessons/LessonDetail';
import { MediaDetail } from '@features/Media/MediaDetail';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';
import { EventDetail } from '@features/Schedule/EventDetail';
import { auth } from '@utils/firebase';

import { RouteContainer } from './RouteContainer';

const basePath = '/:organizationSlug';
const AuthenticatedRoutes = () => {
  const [user, loading] = useAuthState(auth);
  const router = useHistory();
  const permission = useOrganizationPermissions();

  useEffect(() => {
    if (!user && !loading) {
      router.push('/');
    }
  }, [user, loading, router]);

  const tabs = useTabs({ type: 'routes' });

  const isDesktop = useMediaQuery('(min-width:747px)');

  if (loading) {
    return <div>auth loading…</div>;
  }

  if (!permission) {
    return <div>loading permissions…</div>;
  }

  if (!user) {
    return <div>no user for authenticated route</div>;
  }

  return (
    <AppIntro>
      <div data-auth="signed-in" style={{ height: '100%' }}>
        <RouteContainer>
          <Route
            path={`${basePath}/admin/invoices`}
            render={() => <Invoices />}
            exact
          />
          <Route
            path={`${basePath}/admin/invoices/:invoiceId`}
            render={() => <InvoiceDetail />}
            exact
          />
          <Route
            path={`${basePath}/lesson-notes`}
            render={() => <Lessons />}
            exact
          />
          <Route
            path={`${basePath}/media/:mediaId`}
            render={() => <MediaDetail />}
            exact
          />
          <Route
            path={`${basePath}/lesson-notes/:lessonId`}
            render={() => <LessonDetailRouter />}
            exact
          />
          <Route
            path={`${basePath}/admin/products/:productId`}
            render={() => <ProductDetail />}
          />
          <Route path={`${basePath}/contacts/:contactId`}>
            {isDesktop ? <ContactGroup showDetails /> : <ContactDetail />}
          </Route>
          <Route path={`${basePath}/send-invite/:contactId`}>
            <SendInvite />
          </Route>
          <Route path={`${basePath}/schedule/:eventId`}>
            <EventDetail />
          </Route>
          <Route
            path={`${basePath}/admin/reschedule-policies`}
            render={() => <GlobalReschedulePolicies />}
            exact
          />
          <Route
            path={`${basePath}/admin/last-minute-cancellations`}
            render={() => <GlobalLastMinuteCancellations />}
            exact
          />
          <Route
            path={`${basePath}/admin/no-shows`}
            render={() => <GlobalNoShows />}
            exact
          />
          <Route
            path={`${basePath}/admin/invoice-rules`}
            render={() => <GlobalInvoiceRules />}
            exact
          />
          <Route
            path={`${basePath}/admin/discounts`}
            render={() => <GlobalDiscounts />}
            exact
          />
          <Route
            path={`${basePath}/admin/teaching-hours`}
            render={() => <GlobalTeachingHours />}
            exact
          />
          <Route
            path={`${basePath}/admin/refund-policies`}
            render={() => <GlobalRefundPolicies />}
            exact
          />
          <Route
            path={`${basePath}/admin/hourly-rate`}
            render={() => <GlobalHourlyRate />}
            exact
          />

          <Route
            path={`${basePath}/admin/add-product`}
            render={() => <Admin />}
          />

          {themePages.map(({ url, Component }) => (
            <Route
              key={url}
              path={`${basePath}${url}`}
              render={() => <Component />}
            />
          ))}

          {tabs.map(({ url, Component, paths, exact = true }) => (
            <Route
              key={url}
              path={paths ?? `${basePath}${url}`}
              exact={exact}
              render={() => <Component />}
            />
          ))}
        </RouteContainer>
      </div>
    </AppIntro>
  );
};

export default AuthenticatedRoutes;
