import { IonNote, IonSelect } from '@ionic/react';

type IonSelectProps = Parameters<typeof IonSelect>[0];

interface SelectWrapperProps extends IonSelectProps {
  readOnly: boolean | undefined;
}

export const SelectWrapper = ({
  readOnly,
  label,
  value,
  ...props
}: SelectWrapperProps) => {
  if (readOnly) {
    return (
      <>
        {label}
        <IonNote slot="end">{value}</IonNote>
      </>
    );
  }

  return <IonSelect color="success" label={label} value={value} {...props} />;
};
