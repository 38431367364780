import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { ParsedContact, RawContact } from '@shared/models/contacts';
import { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { EditContact } from './ContactDetail/EditContact';
import { useEditContact } from './ContactDetail/useEditContact';

interface AddContactProps {
  page: React.MutableRefObject<HTMLElement | null>;
  addingContact: boolean;
  setAddingContact: (addingContact: boolean) => void;
  accountId?: string;
  preset?: Partial<RawContact>;
  onDone?: (newContact?: ParsedContact) => void;
}

const isNew = true;

const AddContact = ({
  page,
  addingContact,
  setAddingContact,
  accountId,
  preset,
  onDone,
}: AddContactProps) => {
  const modalRef = useRef<HTMLIonModalElement | null>(null);
  const { scheduleSave, fieldProps, initialize } = useEditContact({
    isNew,
    preset,
    onDone: useCallback(
      (newContactId?: ParsedContact) => {
        setAddingContact(false);
        onDone?.(newContactId);
      },
      [setAddingContact, onDone]
    ),
    accountId,
  });

  const previousAddingContact = usePrevious(addingContact);
  useEffect(() => {
    if (addingContact && !previousAddingContact) {
      initialize();
    }
  }, [addingContact, previousAddingContact, initialize]);

  if (!page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={page.current}
      isOpen={addingContact}
      ref={modalRef}
      onDidDismiss={() => setAddingContact(false)}
    >
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="start">
            <IonButton color="dark" onClick={() => setAddingContact(false)}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>New Contact</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color="dark"
              onClick={scheduleSave}
              data-testid="add-new-contact-button"
            >
              Add
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        // color="light"
        className="padding-bottom"
        style={{ paddingTop: 50 }}
        fullscreen
      >
        <EditContact isNew={isNew} page={modalRef} {...fieldProps} />
      </IonContent>
    </IonModal>
  );
};

export default AddContact;
