import {
  BillingCycle,
  CycleFormula,
  InvoiceDue,
  InvoiceIssued,
  LastMinuteRefundAmount,
  MaxMakeupOfMakeups,
  MaximumReschedules,
  MaximumSavedLessons,
  ProductNameType,
  ProductType,
  RefundAmount,
  RegistrationPolicy,
  SavedLessonExpiration,
} from '@shared/models/products';
import { RawProduct } from '@shared/models/products';
import { addDoc, doc, updateDoc } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { productsCollection } from '@models/products/model';
import { useProductQuery } from '@models/products/useProductQuery';
import { serverTimestamp } from '@utils/serverTimestamp';

import { LessonPricingItem } from '../LessonPricing';
import { useScheduleSave } from '../useScheduleSave';

export interface UseEditProductProps {
  productId: string;
  onSave?: () => void;
}

export const useEditProduct = ({ productId, onSave }: UseEditProductProps) => {
  const user = useUser();
  const userId = user?.userId;
  const isNew = productId === 'add';

  const organizationId = useOrganizationId();
  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([
    'perMonth',
  ]);
  const [cycleFormula, setCycleFormula] =
    useState<CycleFormula>('lessonsTimesPrice');
  const [flatFee, setFlatFee] = useState(260.89);
  const [productType, setProductType] = useState<ProductType>('standard');
  /**
   * @deprecated
   */
  const [basePricing, setBasePricing] = useState(60);
  const [lessonPricing, setLessonPricingBase] = useState<LessonPricingItem[]>([
    { duration: 30, price: 30, isDefault: true },
    { duration: 60, price: 60 },
  ]);

  const setLessonPricing = useCallback(
    (newLessonPricing: LessonPricingItem[]) => {
      const [firstLessonPricing, ...rest] = newLessonPricing.sort(
        (a, b) => a.duration - b.duration
      );

      if (!firstLessonPricing) {
        return;
      }

      const hasDefault = newLessonPricing.some((item) => item.isDefault);

      setLessonPricingBase([
        {
          ...firstLessonPricing,
          isDefault: !hasDefault ? true : firstLessonPricing.isDefault,
        },
        ...rest,
      ]);
    },
    []
  );

  const [allowRefunds, setAllowRefunds] = useState(false);
  const [refundAmount, setRefundAmount] = useState<RefundAmount>('100');

  const [unlimitedRescheduling, setUnlimitedRescheduling] = useState(true);
  const [allowSavingCancelledLessons, setAllowSavingCancelledLessons] =
    useState(true);

  const [allowLastMinuteRefunds, setAllowLastMinuteRefunds] = useState(false);
  const [allowMakupLessons, setAllowMakeupLessons] = useState(false);
  const [allowMakeupCredits, setAllowMakeupCredits] = useState(false);
  const [allowReschedulingMakeups, setAllowReschedulingMakeups] =
    useState(false);
  const [allowMakingUpMakeups, setAllowMakingUpMakeups] = useState(false);
  const [lastMinuteRefundAmount, setLastMinuteRefundAmount] =
    useState<LastMinuteRefundAmount>('100');
  const [customRefundAmount, setCustomRefundAmount] = useState(100);

  const [maximumReschedules, setMaximumReschedules] =
    useState<MaximumReschedules>('1');
  const [maxMakeupOfMakeups, setMaxMakeupOfMakeups] =
    useState<MaxMakeupOfMakeups>('1');
  const [maximumSavedLessons, setMaximumSavedLessons] =
    useState<MaximumSavedLessons>('3Credits');
  const [savedLessonExpiration, setSavedLessonExpiration] =
    useState<SavedLessonExpiration>('6Weeks');

  const [registrationPolicies, setRegistrationPolicies] = useState<
    RegistrationPolicy[]
  >(['private']);

  const [instrument, setInstrument] = useState('Basoon');
  const [productNameType, setProductNameType] =
    useState<ProductNameType>('auto');
  const [customProductName, setCustomProductName] = useState('Basson Lessons');
  const [invoiceIssued, setInvoiceIssued] =
    useState<InvoiceIssued>('beforeCycle');
  const [invoiceIssuedOffset, setInvoiceIssuedOffset] = useState(14);
  const [invoiceDue, setInvoiceDue] = useState<InvoiceDue>('beforeCycle');
  const [invoiceDueOffset, setInvoiceDueOffset] = useState(0);

  // TODO: persist these
  const [proratedDiscount, setProratedDiscount] = useState('billedPerLesson');
  const [proratedReduction, setProratedReduction] = useState('100Rounded');
  const [proratedPerLesson, setProratedPerLesson] = useState(0.15);
  const [customProratedAmount, setCustomProratedAmount] = useState(60);
  const [hasFirstMonthDiscount, setHasFirstMonthDiscount] = useState(false);
  const [firstMonthRate, setFirstMonthRate] = useState('baseRate');
  const [durationDiscount, setDurationDiscount] = useState('60');
  const [discountRate, setDiscountRate] = useState(10);
  const [hasBulkDiscount, setHasBulkDiscount] = useState(false);
  const [hasLateFees, setHasLateFees] = useState(false);
  const [hasFamilyDiscount, setHasFamilyDiscount] = useState(false);

  const productName = useMemo(() => {
    if (productNameType === 'auto') {
      return `${instrument} Lessons`;
    }

    return customProductName;
  }, [productNameType, customProductName, instrument]);

  const [product] = useProductQuery(
    productId === 'add' ? undefined : productId
  );

  useEffect(() => {
    if (!product) {
      return;
    }

    setBillingCycles(product.billingCycles);
    setCycleFormula(product.cycleFormula);
    setProductType(product.type);
    if (product.flatFee !== undefined) {
      setFlatFee(product.flatFee);
    }
    if (product.basePricing !== undefined) {
      setBasePricing(product.basePricing);
    }
    setLessonPricing(product.lessonPricing);
    setAllowRefunds(product.allowRefunds);
    if (product.refundAmount !== undefined) {
      setRefundAmount(product.refundAmount);
    }
    setUnlimitedRescheduling(product.unlimitedRescheduling);
    setAllowSavingCancelledLessons(product.allowSavingCancelledLessons);
    setAllowLastMinuteRefunds(product.allowLastMinuteRefunds);
    setAllowMakeupLessons(product.allowMakupLessons);
    setAllowMakeupCredits(product.allowMakeupCredits);
    setAllowReschedulingMakeups(product.allowReschedulingMakeups);
    setAllowMakingUpMakeups(product.allowMakingUpMakeups);
    setRegistrationPolicies(product.registrationPolicies);
    setInvoiceIssued(product.invoiceIssued);
    setInvoiceDue(product.invoiceDue);
    if (product.invoiceIssuedOffset) {
      setInvoiceIssuedOffset(product.invoiceIssuedOffset);
    }
    if (product.invoiceDueOffset) {
      setInvoiceDueOffset(product.invoiceDueOffset);
    }
    if (product.lastMinuteRefundAmount !== undefined) {
      setLastMinuteRefundAmount(product.lastMinuteRefundAmount);
    }
    if (product.customRefundAmount !== undefined) {
      setCustomRefundAmount(product.customRefundAmount);
    }
    if (product.maximumReschedules !== undefined) {
      setMaximumReschedules(product.maximumReschedules);
    }
    if (product.maxMakeupOfMakeups !== undefined) {
      setMaxMakeupOfMakeups(product.maxMakeupOfMakeups);
    }
    if (product.maximumSavedLessons !== undefined) {
      setMaximumSavedLessons(product.maximumSavedLessons);
    }
    if (product.savedLessonExpiration !== undefined) {
      setSavedLessonExpiration(product.savedLessonExpiration);
    }
    setInstrument(product.instrument);
    setProductNameType(product.productNameType);
    if (product.customProductName) {
      setCustomProductName(product.customProductName);
    }
  }, [product, setLessonPricing]);

  const handleSave = useCallback(async () => {
    if (!userId) {
      throw new Error('userId is not defined');
    }

    if (!organizationId) {
      throw new Error('organizationId is not defined');
    }

    onSave?.();
    const rawProduct: RawProduct = {
      createdAt: product?.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: product?.createdBy || userId,
      updatedBy: userId,
      type: productType,
      billingCycles,
      invoiceDue,
      invoiceIssuedOffset,
      invoiceIssued,
      invoiceDueOffset,
      allowLastMinuteRefunds,
      allowMakeupCredits,
      allowMakingUpMakeups,
      allowMakupLessons,
      allowRefunds,
      allowReschedulingMakeups,
      allowSavingCancelledLessons,
      cycleFormula,
      lessonPricing,
      maximumReschedules,
      maximumSavedLessons,
      maxMakeupOfMakeups,
      organizationId,
      savedLessonExpiration,
      unlimitedRescheduling,
      customRefundAmount,
      flatFee,
      lastMinuteRefundAmount,
      refundAmount,
      instrument,
      productNameType,
      customProductName,
      productName,
      registrationPolicies,
    };

    if (isNew) {
      addDoc(productsCollection, rawProduct);
    } else {
      updateDoc(doc(productsCollection, productId), rawProduct);
    }
  }, [
    invoiceDue,
    invoiceIssued,
    billingCycles,
    allowLastMinuteRefunds,
    allowMakeupCredits,
    allowMakingUpMakeups,
    allowMakupLessons,
    allowRefunds,
    allowReschedulingMakeups,
    allowSavingCancelledLessons,
    cycleFormula,
    lessonPricing,
    maximumReschedules,
    maximumSavedLessons,
    maxMakeupOfMakeups,
    organizationId,
    savedLessonExpiration,
    unlimitedRescheduling,
    customRefundAmount,
    flatFee,
    lastMinuteRefundAmount,
    refundAmount,
    instrument,
    productNameType,
    customProductName,
    productName,
    isNew,
    productId,
    product,
    registrationPolicies,
    onSave,
    invoiceIssuedOffset,
    invoiceDueOffset,
    productType,
    userId,
  ]);

  const [scheduleSave] = useScheduleSave(handleSave);

  return {
    lessonPricing,
    setLessonPricing,
    instrument,
    setInstrument,
    productNameType,
    setProductNameType,
    customProductName,
    setCustomProductName,
    productName,
    scheduleSave,
    registrationPolicies,
    setRegistrationPolicies,
    billingCycles,
    setBillingCycles,
    setCycleFormula,
    basePricing,
    cycleFormula,
    flatFee,
    setBasePricing,
    setFlatFee,
    allowRefunds,
    setAllowRefunds,
    refundAmount,
    setRefundAmount,
    unlimitedRescheduling,
    setUnlimitedRescheduling,
    allowSavingCancelledLessons,
    setAllowSavingCancelledLessons,
    allowLastMinuteRefunds,
    setAllowLastMinuteRefunds,
    allowMakupLessons,
    setAllowMakeupLessons,
    allowMakeupCredits,
    setAllowMakeupCredits,
    allowReschedulingMakeups,
    setAllowReschedulingMakeups,
    allowMakingUpMakeups,
    setAllowMakingUpMakeups,
    lastMinuteRefundAmount,
    setLastMinuteRefundAmount,
    customRefundAmount,
    setCustomRefundAmount,
    maximumReschedules,
    setMaximumReschedules,
    maxMakeupOfMakeups,
    setMaxMakeupOfMakeups,
    maximumSavedLessons,
    setMaximumSavedLessons,
    savedLessonExpiration,
    setSavedLessonExpiration,
    invoiceIssued,
    setInvoiceIssued,
    invoiceDue,
    setInvoiceDue,
    proratedDiscount,
    setProratedDiscount,
    proratedReduction,
    setProratedReduction,
    proratedPerLesson,
    setProratedPerLesson,
    customProratedAmount,
    setCustomProratedAmount,
    hasFirstMonthDiscount,
    setHasFirstMonthDiscount,
    firstMonthRate,
    setFirstMonthRate,
    productId,
    durationDiscount,
    setDurationDiscount,
    discountRate,
    setDiscountRate,
    hasBulkDiscount,
    setHasBulkDiscount,
    hasLateFees,
    setHasLateFees,
    hasFamilyDiscount,
    setHasFamilyDiscount,
    invoiceIssuedOffset,
    setInvoiceIssuedOffset,
    invoiceDueOffset,
    setInvoiceDueOffset,
    productType,
    setProductType,
  };
};

export type UseEditProductReturn = ReturnType<typeof useEditProduct>;
