import { IonInput, IonItem, IonList, IonSelectOption } from '@ionic/react';

import { ListHelperText } from '@components/ListHelperText';
import { SelectWrapper } from '@components/ionic-wrappers/SelectWrapper';
import { ToggleWrapper } from '@components/ionic-wrappers/ToggleWrapper';

import { useEditProductContext } from './ProductContext/useEditProductContext';

interface RefundsContentProps {
  readOnly?: boolean;
}

const RefundsContent = ({ readOnly }: RefundsContentProps) => {
  const { allowRefunds, setAllowRefunds, refundAmount, setRefundAmount } =
    useEditProductContext();

  return (
    <>
      <IonList inset>
        <IonItem>
          <ToggleWrapper
            readOnly={readOnly}
            color="success"
            checked={allowRefunds}
            onIonChange={(event) => setAllowRefunds(event.detail.checked)}
          >
            Allow Refunds
          </ToggleWrapper>
        </IonItem>
      </IonList>

      <ListHelperText inset>
        When a student cancels a lesson with sufficient notice they may receive
        a full or partial refund.
      </ListHelperText>

      {allowRefunds ? (
        <>
          <IonList inset>
            <IonItem>
              <SelectWrapper
                readOnly={readOnly}
                label="Refund Amount"
                interface="popover"
                value={refundAmount}
                onIonChange={(event) => setRefundAmount(event.target.value)}
              >
                {(
                  [
                    ['100', 'Full Refund'],
                    ['75', '75%'],
                    ['50', '50%'],
                    ['25', '25%'],
                    ['custom', 'Custom'],
                  ] as const
                ).map(([value, label]) => (
                  <IonSelectOption key={value} value={value}>
                    {label}
                  </IonSelectOption>
                ))}
              </SelectWrapper>
            </IonItem>
            {refundAmount === 'custom' ? (
              <IonItem>
                <IonInput
                  style={{ textAlign: 'right', marginRight: 5 }}
                  label="Custom Max"
                  type="number"
                  min={0}
                  value={100}
                ></IonInput>{' '}
                %
              </IonItem>
            ) : null}
          </IonList>
          <ListHelperText inset>
            By default, students qualify for a refund unless it's deemed
            last-minute, as defined in Last-Minute Cancellations.
          </ListHelperText>
        </>
      ) : null}
    </>
  );
};

export default RefundsContent;
