import { useContext } from 'react';

import { MoveContactsContext } from './MoveContactsContext';

export const useMoveContacts = () => {
  const currentUserContext = useContext(MoveContactsContext);

  if (!currentUserContext) {
    throw new Error(
      'useMoveContacts has to be used within <MoveContactsContext.Provider>'
    );
  }

  return currentUserContext;
};
