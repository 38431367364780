import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonNav,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { useEditEventContext } from '@features/Schedule/AddEvent/EditEventContext/useEditEventContext';

import { CancelProductEvent } from './CancelProductEvent';
import { RescheduleProductEvent } from './RescheduleProductEvent';

interface EditProductEventProps {
  page: React.MutableRefObject<HTMLElement | null>;
}

export const EditProductEvent = ({ page }: EditProductEventProps) => {
  const {
    editProductEventFields: { setProductEditType, productEditType },
    onDeleteEvent,
    onUpdateEvent,
  } = useEditEventContext();
  const router = useIonRouter();
  const organizationId = useOrganizationId();

  return (
    <IonModal
      presentingElement={page.current || undefined}
      isOpen={Boolean(productEditType)}
      onDidDismiss={() => setProductEditType(undefined)}
    >
      <IonNav
        root={() => (
          <>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton
                    color="dark"
                    onClick={() => {
                      setProductEditType(undefined);
                    }}
                  >
                    Cancel
                  </IonButton>
                </IonButtons>
                <IonTitle>
                  {productEditType === 'cancel' ? 'Cancel' : 'Reschedule'}{' '}
                  Lesson
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton
                    color="dark"
                    onClick={async () => {
                      setProductEditType(undefined);

                      if (productEditType === 'cancel') {
                        onDeleteEvent();
                        router.goBack();
                      }

                      if (productEditType === 'reschedule') {
                        const mutationResult = await onUpdateEvent();
                        const newEventId = mutationResult.response?.id;

                        if (newEventId) {
                          // TODO: ensure this redirects without interfering with animations
                          router.push(
                            `/${organizationId}/schedule/${newEventId}`
                          );
                        }
                      }
                    }}
                  >
                    Done
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent color="light" style={{ paddingTop: 50 }}>
              <CancelProductEvent />
              <RescheduleProductEvent />
            </IonContent>
          </>
        )}
      />
    </IonModal>
  );
};
