import {
  IonActionSheet,
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { Box, Button, Divider, Input, ListDivider, Typography } from '@mui/joy';
import { deleteUser } from 'firebase/auth';
import { deleteObject, ref, uploadBytesResumable } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import uuid4 from 'uuid4';

import { BuisnessUrlHelperText } from '@components/AppIntro/BuisnessUrlHelperText';
import { BusinessUrlInput } from '@components/AppIntro/BusinessUrlInput';
import { InnerInput } from '@components/AppIntro/FloatingLabelInput';
import { useValidateBusinessName } from '@components/AppIntro/useValidateBusinessName';
import Avatar from '@components/Avatar';
import {
  useOrgainzation,
  useOrganizationPermissions,
} from '@features/Organization/organizationSlice';
import { useMutation } from '@models/mutations/useMutation';
import { auth, storage } from '@utils/firebase';

export const getAvatarInitials = (input: string | undefined | null) => {
  if (!input) {
    return '';
  }

  const words = input.split(' ');
  const [firstWord, secondWord] = words;

  if (!firstWord) {
    return '';
  }

  if (!secondWord) {
    return firstWord.charAt(0);
  }

  return firstWord.charAt(0) + secondWord.charAt(0);
};

export interface OrganizationInformation {
  name: string;
  slug: string;
}

interface GeneralProps {
  onDone: () => void;
}

const General = ({ onDone }: GeneralProps) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const router = useIonRouter();
  const permissions = useOrganizationPermissions();
  const organization = useOrgainzation();
  const [signOut] = useSignOut(auth);
  const [updateOrganizationMutation] = useMutation('updateOrganization');
  const [user] = useAuthState(auth);
  const [organizationAvatarSrc] = useDownloadURL(
    organization?.logo
      ? ref(storage, `/organizations/${organization.id}/${organization.logo}`)
      : null
  );
  const canDeleteOrganization = Boolean(
    permissions?.organizations.canDeleteOrganization
  );

  const defaultValues = useMemo(
    () => ({ name: organization?.name || '', slug: organization?.slug || '' }),
    [organization]
  );

  const [businessName, setBusinessName] = useState(defaultValues.name);
  const [urlSlug, setUrlSlug] = useState(defaultValues.slug);
  const [businessSlugIsAvailable, validationLoading] = useValidateBusinessName({
    urlSlug,
    currentSlug: defaultValues.slug,
  });

  useEffect(() => {
    setBusinessName(defaultValues.name);
    setUrlSlug(defaultValues.slug);
  }, [defaultValues]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonTitle>Workspace Settings</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={onDone}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light">
        <ListDivider sx={{ mt: 3, mb: 3 }} />
        <Typography level="body-md">Logo</Typography> 
        <label>
          <Avatar
            src={organizationAvatarSrc}
            size="lg"
            sx={{ width: 85, height: 85 }}
          >
            {getAvatarInitials(organization?.name)}
          </Avatar>
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={async (event) => {
              const file = event.target.files?.[0];
              if (!file || !organization) {
                return;
              }
              const extension = file.name.split('.').at(-1);
              const { readAndCompressImage } = require('browser-image-resizer');
              const resizedImage = await readAndCompressImage(file, {
                quality: 0.8,
                maxWidth: 256,
                maxHeight: 256,
              });

              if (!extension) {
                return;
              }
              const originalLogoFilename = organization.logo;

              const fileName = [uuid4(), extension].join('.');
              const storageRef = ref(
                storage,
                `/organizations/${organization.id}/${fileName}`
              );
              const uploadTask = uploadBytesResumable(storageRef, resizedImage);

              uploadTask.on(
                'state_changed',
                undefined,
                console.error,
                async () => {
                  await updateOrganizationMutation({ logo: fileName });

                  if (originalLogoFilename) {
                    await deleteObject(
                      ref(
                        storage,
                        `/organizations/${organization.id}/${originalLogoFilename}`
                      )
                    );
                  }
                }
              );
            }}
          />
        </label>
        <Typography level="body-md" sx={{ mt: 1 }}>
          Pick a logo for your workspace. Recommended size is 256x256px.
        </Typography>
        <Divider sx={{ mt: 3, mb: 3 }} />
        <Typography level="body-md">General</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Input
            slots={{ input: InnerInput }}
            slotProps={{
              input: {
                placeholder: '',
                type: 'text',
                name: 'businessName',
                labelText: 'Business Name',
              },
            }}
            value={businessName || ''}
            onChange={(event) => setBusinessName(event.target.value)}
          />

          <Box>
            <BusinessUrlInput
              value={urlSlug}
              onChange={(event) => setUrlSlug(event.target.value)}
            />
            <Box sx={{ padding: '0 8px' }}>
              <BuisnessUrlHelperText
                businessNameIsValid={businessSlugIsAvailable}
              />
              <Typography level="body-sm">
                If you change the workspace slug, all old links that point to
                your organization will no longer work.
              </Typography>
            </Box>
          </Box>

          <Button
            size="sm"
            sx={{ width: 'fit-content' }}
            disabled={
              businessName.length === 0 ||
              urlSlug.length === 0 ||
              !businessSlugIsAvailable ||
              (businessName === defaultValues.name &&
                urlSlug === defaultValues.slug) ||
              validationLoading
            }
            loading={validationLoading}
            onClick={() => {
              if (defaultValues.slug !== urlSlug) {
                setIsAlertOpen(true);
                return;
              }

              updateOrganizationMutation({ name: businessName, slug: urlSlug });
            }}
          >
            Update
          </Button>
        </Box>
        <Divider sx={{ mt: 3, mb: 3 }} />
        {permissions ? (
          <>
            <Typography level="body-md">
              Delete user{canDeleteOrganization ? ' and organization' : ''}
            </Typography>
            <Typography level="body-sm" sx={{ mt: 1 }}>
              {canDeleteOrganization
                ? `If you want to permanently delete this organization, your user,
              and all of its data, including but not limited to users, contacts,
              and lesson notes, you can do so below.`
                : `If you want to permanently delete your user, you can do so below.`}
            </Typography>
            <Typography level="body-sm" sx={{ mt: 1 }}>
              To delete your user
              {canDeleteOrganization ? ' and organization' : ''}, you need to
              have recently logged in.
            </Typography>
            <Box sx={{ mt: 3, paddingBottom: 5 }}>
              <Button
                color="danger"
                size="sm"
                onClick={() => setIsActionSheetOpen(true)}
              >
                Delete this user
                {canDeleteOrganization ? ' and organization' : ''}
              </Button>
            </Box>
          </>
        ) : null}
        <IonActionSheet
          isOpen={isActionSheetOpen}
          header={
            canDeleteOrganization
              ? `Are you sure you want to to permanently delete everything in your organization, your user, and all of its data,
          including but not limited to users, contacts, and lesson notes?`
              : `Are you sure you want to to permanently delete everything, including your user, and all of its related data,
          including but not limited to user data, contacts, and lesson notes?`
          }
          buttons={[
            {
              text: 'Delete Everything',
              role: 'destructive',
              data: {
                action: 'delete',
              },
              handler: async () => {
                if (user) {
                  try {
                    await deleteUser(user);
                  } catch (error) {
                    alert(`Please sign out and back in again. ${error}`);

                    return;
                  }
                }

                await signOut();
                router.push('/');
              },
            },
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setIsActionSheetOpen(false)}
        />
        <IonAlert
          isOpen={isAlertOpen}
          header="Change workspace URL"
          subHeader="If you change the workspace slug, all old links that point to your organization will no longer work."
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: () =>
                updateOrganizationMutation({
                  name: businessName,
                  slug: urlSlug,
                }),
            },
          ]}
          onDidDismiss={() => setIsAlertOpen(false)}
        />
      </IonContent>
    </>
  );
};

export default General;
