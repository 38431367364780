import { IonInput, IonItem, IonList } from '@ionic/react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHelperText } from '@components/ListHelperText';
import { ToggleWrapper } from '@components/ionic-wrappers/ToggleWrapper';

import { useEditProductContext } from './ProductContext/useEditProductContext';

interface ReschedulePoliciesContentProps {
  readOnly?: boolean;
}

const ReschedulePoliciesContent = ({
  readOnly,
}: ReschedulePoliciesContentProps) => {
  const {
    allowSavingCancelledLessons,
    setAllowSavingCancelledLessons,
    setUnlimitedRescheduling,
    unlimitedRescheduling,
  } = useEditProductContext();

  const schedulingV1_2Enabled = useFeatureFlag('schedulingV1_2Enabled');
  const schedulingV1_4Enabled = useFeatureFlag('schedulingV1_4Enabled');

  return (
    <>
      {schedulingV1_4Enabled ? (
        <>
          <IonList inset>
            <IonItem>
              <ToggleWrapper
                color="success"
                checked={unlimitedRescheduling}
                readOnly={readOnly}
                onIonChange={(event) =>
                  setUnlimitedRescheduling(event.detail.checked)
                }
              >
                Unlimited Rescheduling
              </ToggleWrapper>
            </IonItem>
            {!unlimitedRescheduling ? (
              <IonItem>
                <IonInput
                  style={{ textAlign: 'right', marginRight: 5 }}
                  label="Maximum Reschedules"
                  type="number"
                  value={5}
                />
                Times
              </IonItem>
            ) : null}
          </IonList>
          <ListHelperText inset>
            The number of times a student can reschedule a lesson.
          </ListHelperText>
        </>
      ) : null}

      {schedulingV1_2Enabled ? (
        <>
          <IonList inset>
            <IonItem>
              <ToggleWrapper
                color="success"
                checked={allowSavingCancelledLessons}
                readOnly={readOnly}
                onIonChange={(event) =>
                  setAllowSavingCancelledLessons(event.detail.checked)
                }
              >
                Allow Saving Cancelled Lessons
              </ToggleWrapper>
            </IonItem>
          </IonList>
          <ListHelperText inset>
            Allows students to save cancelled lessons to be rescheduled at a
            later time without penalties. If a saved lesson is not used before
            the last-minute cancellation window, it will be converted into a
            saved makeup lesson if available.{' '}
            <a href="https://google.com" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </ListHelperText>
        </>
      ) : null}

      {schedulingV1_4Enabled ? (
        <>
          <IonList inset>
            <IonItem>
              <ToggleWrapper readOnly={readOnly} color="success">
                Lock After Original Lesson Date
              </ToggleWrapper>
            </IonItem>
          </IonList>
          <ListHelperText inset>
            Any changes made to a rescheduled lesson after the original lesson
            date fall under the last-minute cancellation policy, even if they
            occur before the rescheduled lesson date.{' '}
            <a href="https://google.com" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </ListHelperText>
        </>
      ) : null}
    </>
  );
};

export default ReschedulePoliciesContent;
