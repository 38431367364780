import { Typography } from '@mui/joy';
import Box, { BoxProps } from '@mui/joy/Box';

import { transition } from '@theme/theme';

interface NotePreviewProps extends BoxProps {
  title?: string;
  subTitle?: string;
  special?: boolean;
}

export const NotePreview = ({
  children,
  title,
  subTitle,
  special,
  ...rest
}: NotePreviewProps) => {
  return (
    <Box
      {...rest}
      sx={(theme) => ({
        background: 'transparent',
        '&, & .title-area': {
          transition: `opacity ${transition}`,
        },
        '& svg': {
          transition: `color ${transition}`,
        },
        '&:hover': {
          opacity: 0.6,
          ...(special && {
            opacity: 1,
            '& .title-area': {
              opacity: 0.6,
            },
            '& svg': {
              color: `rgb(${theme.palette.primary.mainChannel})`,
            },
          }),
        },
      })}
    >
      <Box
        sx={{
          width: '146px',
          height: '185px',
          borderRadius: '4px',
          border: '1px solid #E0E0E0',
          marginBottom: '10px',
          overflow: 'hidden',
          ...(rest.onClick ? { cursor: 'pointer' } : undefined),
        }}
      >
        {children}
      </Box>
      <Box className="title-area" sx={{ margin: '0 5px' }}>
        <Typography
          sx={{
            fontSize: '14px',
            ...(special && { fontWeight: 600, textAlign: 'center' }),
            marginBottom: '1px',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ color: 'gray', fontSize: '14px', lineHeight: 1 }}>
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};
