import Avatar, { AvatarProps } from '@mui/joy/Avatar';

interface InstrumentAvatarProps extends Omit<AvatarProps, 'src' | 'className'> {
  instrument: string;
}

export const InstrumentAvatar = ({
  instrument,
  sx,
  ...props
}: InstrumentAvatarProps) => {
  return (
    <Avatar
      className="instruments"
      sx={[
        (theme) => ({
          width: 30,
          height: 30,
          fontSize: 20,
          fontWeight: 400,
          marginRight: '8px',
          backgroundColor: 'transparent',

          [theme.getColorSchemeSelector('dark')]: {
            filter: 'invert(1)',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      src={`assets/instruments/${instrument.toLowerCase()}.svg`}
      {...props}
    />
  );
};
