import {
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
  tabClasses,
} from '@mui/joy';

import { hasGuidedPractice } from '.';
import { SidebarContentContainer } from './SidebarContentContainer';

export const GuidedPracticeConfiguration = () => (
  <SidebarContentContainer
    title={hasGuidedPractice ? 'Guided Practice' : 'Practice Notes'}
  >
    {hasGuidedPractice ? (
      <>
        <Tabs
          aria-label="Basic tabs"
          defaultValue={0}
          sx={(theme) => ({
            backgroundColor: 'transparent',
            '--joy-palette-neutral-plainHoverBg': 'transparent',
            '--joy-palette-neutral-plainActiveBg': 'transparent',
            '--joy-palette-neutral-plainHoverColor': theme.palette.neutral[100],
            '--joy-palette-neutral-plainColor': theme.palette.neutral[100],
            [`.${tabClasses.root}`]: {
              color: theme.palette.neutral[300],
            },
          })}
        >
          <TabList disableUnderline>
            <Tab>All</Tab>
            <Tab>Steps</Tab>
            <Tab>Segments</Tab>
          </TabList>
          <TabPanel value={0}>
            <b>First</b> tab panel
          </TabPanel>
          <TabPanel value={1}>
            <b>Second</b> tab panel
          </TabPanel>
          <TabPanel value={2}>
            <b>Third</b> tab panel
          </TabPanel>
        </Tabs>
      </>
    ) : (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <Typography textColor="neutral.600">
          Students will see notes entered here when this piece is assigned to
          them.
        </Typography>
        <Textarea
          sx={{
            background: 'transparent',
            color: 'inherit',
            resize: 'none',
            width: '100%',
            height: '100px',
          }}
          placeholder="Leave a note for your students..."
        />
      </Box>
    )}
  </SidebarContentContainer>
);
