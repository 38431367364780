// import useHotkeys from '@reecelucas/react-use-hotkeys';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import * as pdfjsLib from 'pdfjs-dist';
import { useMemo, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import uuid4 from 'uuid4';

pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

export interface MediaPage {
  id: string;
  imageUrl?: string;
}

export const useMediaProviderValue = () => {
  const page = useRef<HTMLElement | null>(null);
  const [pages = [], setPages] = useLocalStorage<MediaPage[]>('mediaPages', [
    { id: uuid4() },
  ]);
  const [title, setTitle] = useLocalStorage<string>('mediaTitle');
  const [selectedSidebarItem, setSelectedSidebarItem] = useLocalStorage<string>(
    'mediaSidebarItem',
    'uploads'
  );
  const [zoom = 36, setZoom] = useLocalStorage('mediaZoom', 36);
  const [editorType, setEditorType] = useLocalStorage<'canvas' | 'document'>(
    'mediaEditorType',
    'document'
  );

  const zoomPercentage = useMemo(
    () =>
      Math.round(
        zoom <= 66.6
          ? (zoom / 66.6) * 90 + 10
          : Math.min(((zoom - 66.6) / 33.3) * 400 + 100, 500)
      ),
    [zoom]
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [currentPage, setCurrentPageBase] = useState<number>(1);

  const handleAddPage = () => {
    setPages((current) => [...(current || []), { id: uuid4() }]);
    setCurrentPage(pages.length + 1);
  };

  const [selectedNode, setSelectedNode] = useState('');

  const setCurrentPage = (next: number) => {
    setCurrentPageBase(next);
    setSelectedNode('');
  };

  useHotkeys('ArrowRight', () =>
    setCurrentPage(Math.min(currentPage + 1, pages?.length || 1))
  );
  useHotkeys('ArrowLeft', () => setCurrentPage(Math.max(currentPage - 1, 1)));
  useHotkeys('Backspace', () => {
    if (currentPage === pages.length) {
      setCurrentPage(pages.length - 1);
    }

    setPages((current) =>
      (current || []).filter((_, pageIndex) => pageIndex + 1 !== currentPage)
    );
  });

  const handleUploadFiles = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileResults = await Promise.all(
      [...(event.target.files || [])].map(async (file) => {
        const extension = file.name.split('.').at(-1);

        if (!extension) {
          return;
        }

        if (extension !== 'pdf') {
          return [URL.createObjectURL(file)];
        }

        const documentSource = await (() =>
          new Promise<Uint8Array>((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = function () {
              if (!this.result || typeof this.result === 'string') {
                return reject();
              }

              resolve(new Uint8Array(this.result));
            };

            fileReader.readAsArrayBuffer(file);
          }))();

        const task = pdfjsLib.getDocument(documentSource);
        const pdfProxy = await task.promise;

        if (!pdfProxy) {
          return;
        }

        const numPages = pdfProxy.numPages;

        const pageImages = await Promise.all(
          Array.from({ length: numPages }).map(async (_, index) => {
            const nextPage = await pdfProxy.getPage(index + 1);
            const viewport = nextPage.getViewport({ scale: 2 });
            const canvas = document.createElement('canvas');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const canvasContext = canvas.getContext('2d');

            if (!canvasContext) {
              return;
            }

            const renderTask = nextPage.render({
              canvasContext,
              viewport: viewport,
            });

            await renderTask.promise;

            const data = canvas.toDataURL();

            canvas.remove();

            return data;
          })
        );

        return pageImages.flatMap((x) => (x ? [x] : []));
      })
    );

    const newUrls = fileResults.flatMap((x) => (x ? x : []));

    // Check if the page has any content
    const filteredExistingPages = pages.filter(({ imageUrl }) => imageUrl);

    setPages(() => [
      ...filteredExistingPages,
      ...newUrls.map((imageUrl) => ({
        id: uuid4(),
        imageUrl,
      })),
    ]);

    setCurrentPage(filteredExistingPages.length + 1);
  };

  return {
    page,
    fileInputRef,
    currentPage,
    setCurrentPage,
    pages,
    setPages,
    handleUploadFiles,
    handleAddPage,
    selectedNode,
    setSelectedNode,
    title,
    setTitle,
    selectedSidebarItem,
    setSelectedSidebarItem,
    zoom,
    setZoom,
    zoomPercentage,
    editorType,
    setEditorType,
  };
};

export type UseMediaProviderReturn = ReturnType<typeof useMediaProviderValue>;
