import dayjs from 'dayjs';

import { ParsedEvent } from '@models/events/model';

export const unfurlEventsBetween = (
  events: ParsedEvent[],
  startDate: Date,
  endDate: Date
) =>
  events
    .flatMap((event): ParsedEvent[] => {
      const start = dayjs(event.start);
      const end = event.end ? dayjs(event.end) : undefined;

      // Start date is after current date
      if (start.isAfter(endDate, 'day')) {
        return [];
      }

      // End date is before current date
      if (end?.isBefore(startDate, 'day')) {
        return [];
      }

      if (event.rrule) {
        const datesBetween = event.rrule.between(
          dayjs(startDate).startOf('day').toDate(),
          dayjs(endDate).endOf('day').toDate()
        );

        return datesBetween.map(
          (dateBetween): ParsedEvent => ({
            ...event,
            start: dateBetween,
            end: dayjs(dateBetween).add(event.duration, 'minutes').toDate(),
            rrule: undefined,
          })
        );
      }

      return [event];
    })
    .sort((a, b) => a.start.getTime() - b.start.getTime());
