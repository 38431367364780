import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { Box } from '@mui/joy';
import dayjs from 'dayjs';
import { chevronForwardOutline, chevronForwardSharp } from 'ionicons/icons';
import { useMemo } from 'react';

export const timeFormat = 'h:mm A';

export interface AvailableEvent {
  start: Date;
  end?: Date;
  duration: number;
}

interface AvailabilityEventProps extends AvailableEvent {
  onClick?: React.MouseEventHandler<HTMLIonItemElement>;
}

const AvailabilityEvent = ({
  start,
  duration,
  onClick,
}: AvailabilityEventProps) => {
  const time = useMemo(() => {
    const day = dayjs(start);

    return {
      start: day.format(timeFormat),
      end: day.add(duration, 'minutes').format(timeFormat),
    };
  }, [start, duration]);

  return (
    <IonItem onClick={onClick}>
      <Box
        style={{ minHeight: 40, width: 3, margin: 1, marginRight: 10 }}
        slot="start"
      />
      <IonLabel>
        <p>{time.start}</p>
        <p>{time.end}</p>
      </IonLabel>
      <IonIcon
        style={{ color: 'var(--joy-palette-neutral-300)' }}
        ios={chevronForwardOutline}
        md={chevronForwardSharp}
        slot="end"
      ></IonIcon>
    </IonItem>
  );
};

export default AvailabilityEvent;
