import TabContainer from '@components/TabContainer';

const Messages = () => {
  return (
    <TabContainer title="Messages">
      <div>
        <strong>Messages</strong>
      </div>
    </TabContainer>
  );
};

export default Messages;
