import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {
  Add,
  ArrowForwardIosRounded,
  Close,
  FilterList,
  KeyboardArrowRight,
  Search,
  Star,
  StarBorder,
  Tune,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Tooltip,
  Typography,
  buttonClasses,
  svgIconClasses,
} from '@mui/joy';
import { Divider, Popover, useMediaQuery } from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { ZodTimestamp } from '@shared/zodTimestamp';
import { addOutline, addSharp } from 'ionicons/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useLocalStorage, usePrevious } from 'react-use';

import { AvatarBadge } from '@components/AvatarBadge';
import { sidebarWidth } from '@components/Sidebar';
import { HotkeySpan } from '@components/Sidebar/TooltipHotkeyTitle';
import {
  useOrgainzation,
  useOrganizationId,
  useOrganizationPermissions,
  useOrganizationSlug,
  useUser,
} from '@features/Organization/organizationSlice';
import { useContactsQuery } from '@models/contacts/useContactsQuery';
import { transition } from '@theme/theme';

import AddContact from './AddContact';
import { ContactAvatar } from './ContactAvatar';
import { ContactDetail } from './ContactDetail';
import { getContactName } from './ContactDetail/ViewContact';
import { ContactTags } from './ContactDetail/ViewContact/ContactTags';
import { ContentContainer } from './ContentContainer';
import { FilterContentModal } from './FilterContentModal';
import { FilterControl } from './FilterControl';
import {
  FilterOverview,
  FilterState,
  filterConfig,
  stringifyAddress,
} from './FilterOverview';
import { useFilterPopover } from './useFilterPopover';

export const desktopNavHeightPixels = 42;

const desktopFilterBarHeight = 44;
export const bothBarHeights = desktopNavHeightPixels + desktopFilterBarHeight;
export const desktopContactNavHeight = `${bothBarHeights}px`;

interface ContactGroupProps {
  showDetails?: boolean;
}

export const bucketDate = (input: Date, now = new Date()) => {
  if (now.getTime() - input.getTime() < 1000 * 60 * 60 * 24) {
    return 'dayAgo';
  } else if (now.getTime() - input.getTime() < 1000 * 60 * 60 * 24 * 7) {
    return 'weekAgo';
  } else if (now.getTime() - input.getTime() < 1000 * 60 * 60 * 24 * 30) {
    return 'monthAgo';
  } else if (now.getTime() - input.getTime() < 1000 * 60 * 60 * 24 * 30 * 3) {
    return 'threeMonthsAgo';
  }
};

export const getBeforeDateMap = (
  now = new Date()
): Record<string, () => Date> => ({
  dayAgo: () => new Date(new Date().setDate(now.getDate() - 1)),
  weekAgo: () => new Date(new Date().setDate(now.getDate() - 7)),
  monthAgo: () => new Date(new Date().setMonth(now.getMonth() - 1)),
  threeMonthsAgo: () => new Date(new Date().setMonth(now.getMonth() - 3)),
});

const ContactGroup = ({ showDetails }: ContactGroupProps) => {
  const router = useIonRouter();
  const user = useUser();
  const [searchValue, setSearchValue] = useState('');
  const organizationId = useOrganizationId();
  const [addingContact, setAddingContact] = useState(false);
  const [matchAllFilters, setMatchAllFilters] = useState(false);
  const page = useRef<HTMLElement | null>(null);
  const organizationSlug = useOrganizationSlug();
  const organization = useOrgainzation();
  const organizationOwnerId = organization?.ownerId;
  const organizationPermissions = useOrganizationPermissions();
  const ref = useRef<HTMLUListElement | null>(null);
  const [contactIndex, setContactIndex] = useState(-1);
  const [contentModalOpen, setContentModalOpen] = useState(false);

  const {
    ref: iconButtonRef,
    filterIsOpen,
    setFilterIsOpen,
    anchorPosition,
  } = useFilterPopover();

  const [promise, setPromise] = useState<Promise<number> | undefined>();
  const isDesktop = useMediaQuery('(min-width:747px)');
  const contentSearchInputRef = useRef<HTMLInputElement | null>(null);
  const [contentSearchValue, setContentSearchValue] = useState('');

  useEffect(() => {
    if (!isDesktop || contactIndex === -1) {
      return;
    }

    setPromise(
      new Promise((resolve) => setTimeout(() => resolve(contactIndex), 400))
    );
  }, [contactIndex, isDesktop]);

  const [contacts, contactLoadingBase] = useContactsQuery();

  const [contactsLoading, setContactsLoading] = useState(true);

  useEffect(() => {
    if (contactLoadingBase) {
      return;
    }

    if ((contacts?.length || 0) > 1) {
      setContactsLoading(false);
    }

    const timeoutId = setTimeout(() => setContactsLoading(false), 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [contactLoadingBase, contacts]);

  const [filterState = {}, setFilterState] = useLocalStorage<FilterState>(
    'filterState',
    {}
  );
  const filteredContacts = useMemo(() => {
    const contactNamesCount: Record<string, number> = {};

    contacts?.forEach((contact) => {
      const name = getContactName(contact, user);

      contactNamesCount[name] = (contactNamesCount[name] || 0) + 1;
    });

    return (contacts || [])
      .sort((a, b) => a.firstName.localeCompare(b.firstName))
      .filter((contact) => {
        const validSearch =
          !searchValue ||
          (`${contact.firstName} ${contact.lastName}`
            .toLowerCase()
            .includes(searchValue) &&
            !contact.tags.teacher);

        if (!validSearch) {
          return false;
        }

        const filters = Object.values(filterState);

        return filters[
          matchAllFilters || filters.length < 2 ? 'every' : 'some'
        ]((filterValue) => {
          const checkedSet = new Set(filterValue.checked);
          const shouldMatchEmpty = (resource: unknown[] | undefined) => {
            if (!checkedSet.has('meta-no-entry')) {
              return false;
            }

            return (resource?.length || 0) === 0;
          };

          const config = filterConfig?.[filterValue.filterKey];

          if (config?.dateFilter) {
            const compareDate = (input: ZodTimestamp) => {
              const filterBucket = filterValue.checked?.[0] || '';
              if (filterValue.dateOperator === 'after') {
                const actualBucket = bucketDate(input.toDate());

                return filterBucket === actualBucket;
              }

              const beforeDateMap = getBeforeDateMap();
              const beforeDate = beforeDateMap?.[filterBucket || '']?.();

              if (!beforeDate) {
                return true;
              }

              return input.toDate() < beforeDate;
            };

            if (filterValue.filterKey === 'createdDate') {
              return compareDate(contact.createdAt);
            }

            if (filterValue.filterKey === 'updatedDate') {
              return compareDate(contact.updatedAt);
            }
          }

          const hasItem = (() => {
            if (filterValue.filterKey === 'people') {
              return checkedSet.has(contact.id);
            }

            if (filterValue.filterKey === 'creator') {
              if (contact.createdBy === 'system' && organizationOwnerId) {
                return checkedSet.has(organizationOwnerId);
              }

              return checkedSet.has(contact.createdBy);
            }

            if (filterValue.filterKey === 'phones') {
              return (
                shouldMatchEmpty(contact.phones) ||
                contact.phones?.some((phone) => checkedSet.has(phone.number))
              );
            }

            if (filterValue.filterKey === 'emails') {
              return (
                shouldMatchEmpty(contact.emails) ||
                contact.emails?.some((email) => checkedSet.has(email.address))
              );
            }

            if (filterValue.filterKey === 'addresses') {
              return (
                shouldMatchEmpty(contact.addresses) ||
                contact.addresses?.some((address) =>
                  checkedSet.has(stringifyAddress(address))
                )
              );
            }

            if (filterValue.filterKey === 'instruments') {
              if (shouldMatchEmpty(contact.instruments)) {
                return true;
              }

              if (filterValue.all) {
                const contactInstruments = new Set(contact.instruments || []);

                return [...checkedSet].every((instrument) =>
                  contactInstruments?.has(instrument)
                );
              }

              return contact.instruments?.some((instrument) =>
                checkedSet.has(instrument)
              );
            }

            if (filterValue.filterKey === 'teachers') {
              return contact.tags.teacher;
            }

            if (filterValue.filterKey === 'students') {
              return contact.tags.student;
            }

            if (filterValue.filterKey === 'isPrimary') {
              return contact.tags.primaryContact;
            }

            if (filterValue.filterKey === 'hasLogin') {
              return Boolean(contact.userId);
            }

            if (filterValue.filterKey === 'duplicates') {
              return (
                (contactNamesCount[getContactName(contact, user)] || 0) > 1
              );
            }

            if (filterValue.filterKey === 'content') {
              const lowercasedSearch = [...checkedSet][0]?.toLowerCase();

              return (
                !lowercasedSearch ||
                contact.notes?.toLowerCase().includes(lowercasedSearch)
              );
            }

            return true;
          })();

          return filterValue.inclusive ? hasItem : !hasItem;
        });
      });
  }, [
    contacts,
    searchValue,
    filterState,
    matchAllFilters,
    user,
    organizationOwnerId,
  ]);

  const updateContactIndex = useCallback(
    (change: number) => {
      if (
        !router.routeInfo.pathname.includes('/contacts/') ||
        document.querySelector('ion-modal[is-open="true"]')
      ) {
        return;
      }

      const resolvedIndex = Math.min(
        Math.max(contactIndex + change, 0),
        filteredContacts.length - 1
      );

      if (resolvedIndex === contactIndex) {
        return;
      }

      setContactIndex(resolvedIndex);
    },
    [contactIndex, filteredContacts, router]
  );

  const pageIsActive = useMemo(
    () => router.routeInfo.pathname.includes('/contacts/'),
    [router.routeInfo.pathname]
  );

  useHotkeys(
    'ArrowUp',
    () => {
      if (!filterIsOpen) {
        updateContactIndex(-1);
      }
    },
    { enabled: pageIsActive }
  );
  useHotkeys(
    'ArrowDown',
    () => {
      if (!filterIsOpen) {
        updateContactIndex(1);
      }
    },
    { enabled: pageIsActive }
  );

  useHotkeys(
    'F',
    () => {
      if (showDetails) {
        setFilterIsOpen(true);
      }
    },
    { enabled: pageIsActive }
  );

  const searchRef = useRef<HTMLDivElement | null>(null);
  useHotkeys('Meta+f', (event) => {
    const input = searchRef.current?.querySelector('input');

    if (input) {
      event.preventDefault();

      input.focus();
    }
  });

  useEffect(() => {
    if (contactsLoading || filteredContacts.length === 0) {
      return;
    }

    if (contactIndex >= filteredContacts.length) {
      setContactIndex(filteredContacts.length - 1);
    } else if (contactIndex === -1) {
      setContactIndex(0);
    }
  }, [contactsLoading, filteredContacts, contactIndex]);

  const routeMatch = useRouteMatch<{ contactId: string }>(
    `/${organizationSlug}/contacts/:contactId`
  );
  const routeContactId = routeMatch?.params.contactId;

  useEffect(() => {
    if (!routeContactId || contactIndex !== -1 || contactsLoading) {
      return;
    }

    const index = filteredContacts.findIndex(
      (contact) => contact.id === routeContactId
    );

    setContactIndex(index !== -1 ? index : 0);
  }, [routeContactId, contactIndex, filteredContacts, contactsLoading]);

  useEffect(() => {
    if (
      !isDesktop ||
      !router.routeInfo.pathname.endsWith('/contacts') ||
      contactsLoading
    ) {
      return;
    }

    const nextId = filteredContacts[contactIndex]?.id;

    if (nextId) {
      router.push(`/${organizationSlug}/contacts/${nextId}`, 'none');
    }
  }, [
    contactIndex,
    contactsLoading,
    isDesktop,
    router,
    organizationSlug,
    filteredContacts,
  ]);

  useEffect(() => {
    if (!isDesktop) {
      return;
    }

    let mounted = true;

    (async () => {
      const nextIndex = await promise;

      if (
        nextIndex !== undefined &&
        mounted &&
        router.routeInfo.pathname.includes('/contacts')
      ) {
        router.push(
          `/${organizationSlug}/contacts/${filteredContacts[nextIndex]?.id}`,
          'none'
        );
        setPromise(undefined);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [promise, router, organizationSlug, isDesktop, filteredContacts]);

  const previousContactIndex = usePrevious(contactIndex);

  useEffect(() => {
    if (previousContactIndex === contactIndex) {
      return;
    }

    const nextId = filteredContacts[contactIndex]?.id;

    if (!nextId) {
      return;
    }

    const element = ref.current?.querySelector(`[data-contact-id="${nextId}"]`);

    element?.scrollIntoView({ block: 'nearest' });
  }, [contactIndex, previousContactIndex, filteredContacts]);

  const organizationCanCreate = Boolean(
    organizationPermissions?.contacts.canCreate
  );
  const accountCanCreate = Boolean(
    organizationPermissions?.accountPermissions?.contacts.canCreate
  );
  const canCreate = organizationCanCreate || accountCanCreate;
  const accountId = organizationId
    ? user?.organizationAccounts[organizationId]?.[0]
    : undefined;

  const [stared, setStared] = useState(false);

  const showContentModal = (filterId?: string) => {
    if (filterId === 'content') {
      const nextSearchValue = filterState[filterId]?.checked?.[0];
      setContentSearchValue(nextSearchValue ?? '');
    }
    setContentModalOpen(true);
    setTimeout(
      () => contentSearchInputRef.current?.querySelector('input')?.focus(),
      100
    );
  };

  return (
    <>
      <IonPage ref={page}>
        {isDesktop ? null : (
          <IonHeader>
            <IonToolbar class="no-background">
              <IonButtons slot="start">
                <Button
                  variant="plain"
                  color="neutral"
                  onClick={() => router.push(`/${organizationSlug}/contacts`)}
                >
                  <FilterList />
                </Button>
              </IonButtons>
              <IonTitle>Contacts</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  color="dark"
                  disabled={!canCreate}
                  onClick={() => setAddingContact(true)}
                  aria-label="create-new-contact-button"
                >
                  <IonIcon ios={addOutline} md={addSharp} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonToolbar class="no-background">
              <IonSearchbar
                style={{
                  borderRadius: '0px',
                  paddingBottom: 0,
                  height: '40px',
                }}
                value={searchValue}
                onIonInput={(event) => {
                  if (event.detail.value || event.detail.value === '') {
                    setSearchValue(event.detail.value.toLowerCase());
                  }
                }}
              />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent>
          <ContentContainer>
            {isDesktop ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 13px',
                  height: `${desktopNavHeightPixels}px`,
                  borderBottom: `1px solid ${theme.palette.divider}`,

                  '@media (min-width: 747px)': {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    zIndex: 9,
                    background: 'rgb(255 255 255 / 71%)',
                    [theme.getColorSchemeSelector('dark')]: {
                      backgroundColor: 'rgb(0 0 0 / 71%)',
                    },
                    backdropFilter: 'blur(30px)',
                  },
                })}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Breadcrumbs
                    separator={<KeyboardArrowRight />}
                    sx={{ padding: 0 }}
                  >
                    <Typography color="neutral">Contacts</Typography>
                  </Breadcrumbs>
                  <IconButton onClick={() => setStared((current) => !current)}>
                    {stared ? (
                      <Star
                        sx={{ color: 'rgb(242, 190, 0)', fontSize: '22px' }}
                      />
                    ) : (
                      <StarBorder sx={{ fontSize: '22px' }} />
                    )}
                  </IconButton>
                </Box>

                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Input
                    ref={searchRef}
                    variant="search"
                    size="sm"
                    onKeyDown={(event) => {
                      if (event.key === 'Escape') {
                        event.currentTarget.blur();
                      }
                    }}
                    placeholder="Find a contact…"
                    startDecorator={<Search />}
                    sx={{
                      height: '28px',
                      minHeight: 0,

                      width: '200px',
                      transition: `width 0.3s, opacity ${transition}`,
                      '&.Mui-focused': {
                        width: '350px',
                      },
                    }}
                    value={searchValue}
                    onChange={(event) =>
                      setSearchValue(event.target.value.toLowerCase())
                    }
                  />
                  <Button
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    sx={{
                      height: '28px',
                      minHeight: 0,
                      transition: '0.2s all',
                      '&:hover': {
                        opacity: 0.5,
                      },
                    }}
                    startDecorator={<Add sx={{ color: 'black' }} />}
                    onClick={() => setAddingContact(true)}
                    aria-label="create-new-contact-button"
                  >
                    New Contact
                  </Button>
                </Box>
              </Box>
            ) : null}
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <List
                ref={ref}
                sx={{
                  '--joy-focus-thickness': 0,
                  '@media (min-width: 747px)': {
                    maxWidth: '450px',
                    height: '100vh',
                    overflowY: 'auto',
                    flex: 1,
                    paddingTop: `${
                      desktopNavHeightPixels + desktopFilterBarHeight
                    }px`,
                    position: 'relative',
                    paddingLeft: '15px',

                    '@media (min-width: 747px)': {
                      paddingLeft: '10px',
                    },
                  },
                }}
                className="contact-list"
              >
                {isDesktop ? (
                  <Box
                    sx={(theme) => ({
                      position: 'fixed',
                      top: desktopNavHeightPixels,
                      left: sidebarWidth,
                      right: 0,
                      zIndex: 100,
                      minHeight: desktopFilterBarHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      padding: '6px',

                      background: 'rgb(255 255 255 / 71%)',
                      [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgb(0 0 0 / 71%)',
                      },
                      backdropFilter: 'blur(30px)',
                    })}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {Object.keys(filterState).map((filterId) => (
                        <FilterControl
                          contacts={contacts}
                          key={filterId}
                          filterId={filterId}
                          filterState={filterState}
                          onFilterSelected={(
                            nextFilterState,
                            shouldClose,
                            openContentModal
                          ) => {
                            if (nextFilterState) {
                              setFilterState(nextFilterState);
                            }

                            if (shouldClose) {
                              setFilterIsOpen(false);
                            }

                            if (openContentModal) {
                              showContentModal(filterId);
                            }
                          }}
                        />
                      ))}

                      <Tooltip
                        title={
                          <>
                            Filter <HotkeySpan>F</HotkeySpan>
                          </>
                        }
                        variant="plain"
                        placement="bottom"
                        color="neutral"
                        enterDelay={700}
                      >
                        <Button
                          onClick={() => setFilterIsOpen(true)}
                          ref={iconButtonRef}
                          startDecorator={<FilterList sx={{ fontSize: 18 }} />}
                          variant="plain"
                          color="neutral"
                          size="sm"
                          sx={{ paddingLeft: '7px' }}
                        >
                          {Object.keys(filterState).length ? null : 'Filter'}
                        </Button>
                      </Tooltip>
                      <Popover
                        open={filterIsOpen}
                        onClose={() => setFilterIsOpen(false)}
                        anchorReference="anchorPosition"
                        anchorPosition={anchorPosition}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <FilterOverview
                          contacts={contacts}
                          filterState={filterState}
                          onFilterSelected={(
                            nextFilterState,
                            shouldClose,
                            openContentModal
                          ) => {
                            if (nextFilterState) {
                              setFilterState(nextFilterState);
                            }

                            if (shouldClose) {
                              setFilterIsOpen(false);
                            }

                            if (openContentModal) {
                              showContentModal();
                            }
                          }}
                        />
                      </Popover>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      {Object.keys(filterState).length > 1 ? (
                        <>
                          <Button
                            startDecorator={
                              <Typography level="body-sm">Match</Typography>
                            }
                            variant="plain"
                            color="neutral"
                            size="sm"
                            onClick={() => setMatchAllFilters(!matchAllFilters)}
                          >
                            {matchAllFilters ? 'all filters' : 'any filter'}
                          </Button>
                        </>
                      ) : null}
                      <Button
                        startDecorator={<Tune sx={{ fontSize: 18 }} />}
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{ paddingRight: '10px' }}
                      >
                        Display
                      </Button>
                      {Object.keys(filterState).length ? (
                        <>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ margin: '6px 0' }}
                          />
                          <Button
                            onClick={() => setFilterState({})}
                            variant="plain"
                            color="neutral"
                          >
                            Clear
                          </Button>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                ) : null}

                {filteredContacts.length === 0 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: '20px',
                        padding: '50px',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                      level="title-lg"
                    >
                      No contacts
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        textAlign: 'center',
                        width: '280px',
                      }}
                    >
                      <Typography level="body-md" textColor="neutral.400">
                        <Box
                          component="span"
                          sx={(theme) => ({
                            display: 'inline',
                            color: theme.palette.text.primary,
                            fontWeight: 'bold',
                          })}
                        >
                          {contacts?.length} contacts
                        </Box>{' '}
                        hidden by filters
                      </Typography>{' '}
                      <Button
                        variant="outlined"
                        color="neutral"
                        endDecorator={<Close />}
                        onClick={() => {
                          setFilterState({});
                          setSearchValue('');
                        }}
                        sx={{
                          [`& .${buttonClasses.endDecorator} .${svgIconClasses.root}`]:
                            {
                              transform: 'translateX(0) rotate(0)',
                            },
                          [`&:hover .${buttonClasses.endDecorator} .${svgIconClasses.root}`]:
                            {
                              transform: 'translateX(0) rotate(180deg)',
                            },
                        }}
                      >
                        Clear Filters
                      </Button>
                    </Box>
                  </Box>
                ) : null}

                {filteredContacts.map((contact, index) => (
                  <IonRouterLink
                    key={contact.id}
                    onClick={
                      isDesktop ? () => setContactIndex(index) : undefined
                    }
                    routerLink={
                      isDesktop
                        ? undefined
                        : `/${organizationSlug}/contacts/${contact.id}`
                    }
                    routerDirection={isDesktop ? 'none' : 'forward'}
                    data-contact-id={isDesktop ? contact.id : undefined}
                    style={{ scrollMarginTop: desktopContactNavHeight }}
                  >
                    <ListItem>
                      <ListItemButton
                        sx={(theme) => ({
                          padding: '15px 10px 15px 4px',
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        })}
                        selected={contactIndex === index}
                      >
                        <ListItemDecorator sx={{ marginRight: '15px' }}>
                          <AvatarBadge
                            isPrimary={contact.tags.primaryContact}
                            size="sm"
                          >
                            <ContactAvatar contact={contact} />
                          </AvatarBadge>
                        </ListItemDecorator>

                        <ListItemContent>
                          <Typography>
                            {getContactName(contact, user)}
                          </Typography>
                          <ContactTags contact={contact} />
                        </ListItemContent>
                        <ArrowForwardIosRounded
                          sx={{
                            color: 'text.tertiary',
                            height: '0.7em',
                            width: '0.7em',
                            marginLeft: '0.5em',
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </IonRouterLink>
                ))}
              </List>
              {isDesktop ? (
                <Box
                  sx={(theme) => ({
                    flex: 1,
                    borderLeft: `1px solid ${theme.palette.divider}`,
                    position: 'relative',
                    overflowY: 'auto',
                    '@media (max-width: 900px)': {
                      flex: 1.5,
                    },
                  })}
                >
                  <ContactDetail
                    onContactSelection={(contactId) => {
                      const index = filteredContacts.findIndex(
                        (contact) => contact.id === contactId
                      );

                      setContactIndex(index);
                    }}
                    contactId={filteredContacts[contactIndex]?.id}
                  />
                </Box>
              ) : null}
            </Box>
          </ContentContainer>
        </IonContent>
      </IonPage>
      <AddContact
        addingContact={addingContact}
        setAddingContact={setAddingContact}
        preset={{ tags: { primaryContact: true } }}
        page={page}
        accountId={
          !organizationCanCreate && accountCanCreate ? accountId : undefined
        }
        onDone={(newContact) => {
          if (newContact) {
            setTimeout(
              () =>
                router.push(
                  `/${organizationSlug}/contacts/${newContact.id}`,
                  isDesktop ? 'none' : 'forward'
                ),
              500
            );
          }
        }}
      />
      <FilterContentModal
        page={page}
        contentModalOpen={contentModalOpen}
        setContentModalOpen={setContentModalOpen}
        contentSearchInputRef={contentSearchInputRef}
        contentSearchValue={contentSearchValue}
        setContentSearchValue={setContentSearchValue}
        onApplyFilter={(nextSearchValue) => {
          setFilterState({
            ...filterState,
            content: {
              ...filterState.content,
              filterKey: 'content',
              checked: [nextSearchValue],
              inclusive: filterState.content?.inclusive ?? true,
              all: undefined,
              dateOperator: 'after',
            },
          });

          setContentSearchValue('');
          setContentModalOpen(false);
        }}
      />
    </>
  );
};

export default ContactGroup;
