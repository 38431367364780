import { Box } from '@mui/joy';
import { useMediaQuery } from '@mui/material';

import Sidebar from '@components/Sidebar';

interface ContentContainerProps {
  children?: React.ReactNode;
}

export const ContentContainer = ({ children }: ContentContainerProps) => {
  const isDesktop = useMediaQuery('(min-width:747px)');

  if (!isDesktop) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100%',
      }}
    >
      <Sidebar />
      <Box sx={{ position: 'relative', flex: 1 }}>{children}</Box>
    </Box>
  );
};
