import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import { ListHelperText } from '@components/ListHelperText';
import {
  getBasePricing,
  roundCurrency,
  toCurrency,
  weeksInBillingCycle,
} from '@features/Admin/Products/AddProduct/pricingUtils';

import { useEditProductContext } from './ProductContext/useEditProductContext';

const CustomPricing = () => {
  const {
    cycleFormula,
    basePricing,
    flatFee,
    billingCycles,
    setCycleFormula,
    setFlatFee,
    setBasePricing,
    proratedDiscount,
    setProratedDiscount,
    proratedReduction,
    setProratedReduction,
    proratedPerLesson,
    setProratedPerLesson,
    customProratedAmount,
    setCustomProratedAmount,
    hasFirstMonthDiscount,
    setHasFirstMonthDiscount,
    firstMonthRate,
    setFirstMonthRate,
  } = useEditProductContext();

  const getPricing = (initialMarkup: number) => {
    const initialPrice = Math.round(basePricing * (1 + initialMarkup));
    const price = initialPrice % 2 === 0 ? initialPrice : initialPrice + 1;

    return [initialMarkup, `$${toCurrency(price)}`] as const;
  };

  const flatFeeEnabled = useFeatureFlag('flatFeeEnabled');

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" />
          </IonButtons>
          <IonTitle>Custom Pricing Options</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding" color="light">
        {billingCycles.some((billingCycle) => billingCycle === 'perMonth') ||
        billingCycles.some((billingCycle) => billingCycle === 'perQuarter') ? (
          <IonList inset>
            <IonItem>
              <IonToggle
                color="success"
                checked={cycleFormula === 'flatFee'}
                onIonChange={(event) => {
                  const next = event.detail.checked
                    ? 'flatFee'
                    : 'lessonsTimesPrice';

                  setCycleFormula(next);

                  if (next === 'flatFee') {
                    const newFlatFee = Math.round(flatFee);
                    setFlatFee(newFlatFee);
                    setBasePricing(
                      getBasePricing({
                        billingCycles,
                        flatFee: newFlatFee,
                      })
                    );
                  } else {
                    const newBasePricing = Math.round(basePricing);
                    setBasePricing(newBasePricing);
                    const defaultBillingCycle = billingCycles.find(
                      (billingCycle) =>
                        ['perMonth', 'perQuarter'].includes(billingCycle)
                    );
                    if (
                      defaultBillingCycle === 'perMonth' ||
                      defaultBillingCycle === 'perQuarter'
                    ) {
                      setFlatFee(
                        roundCurrency(
                          newBasePricing *
                            weeksInBillingCycle[defaultBillingCycle]
                        )
                      );
                    }
                  }
                }}
              >
                Flat Fee
              </IonToggle>
            </IonItem>
            {cycleFormula === 'flatFee' && flatFeeEnabled ? (
              <IonItem>
                <IonInput
                  style={{ textAlign: 'right', marginRight: 5 }}
                  label="Monthly Flat Fee"
                  type="number"
                  value={toCurrency(flatFee)}
                  onIonChange={(event) => {
                    const next = Number(event.detail.value);

                    setFlatFee(next);
                    setBasePricing(
                      getBasePricing({ billingCycles, flatFee: next })
                    );
                  }}
                />
                USD
              </IonItem>
            ) : null}
          </IonList>
        ) : null}

        {cycleFormula === 'lessonsTimesPrice' ? (
          <ListHelperText inset>
            Provide a discount to students who commit to buying lessons for the
            entire billing cycle. This will bill students for the same amount
            each month, even if there are 4 or 5 weeks in the month.
          </ListHelperText>
        ) : (
          <ListHelperText inset>
            With a flat monthly fee of {toCurrency(flatFee)} USD, your average
            earnings would be {basePricing} USD, slightly higher than your base
            hourly rate.This will bills students for the same amount each month,
            even if there are 4 or 5 weeks in the month.
          </ListHelperText>
        )}

        {cycleFormula === 'flatFee' ? (
          <>
            <IonList inset>
              <IonItem>
                <IonSelect
                  label="Partial Month Pricing"
                  interface="popover"
                  value={proratedDiscount}
                  onIonChange={(event) => {
                    setProratedDiscount(event.detail.value);
                    setProratedReduction('100Rounded');
                  }}
                >
                  {(
                    [
                      ['billedPerLesson', 'Bill Per Lesson'],
                      ['reduceFlatFee', 'Reduce Flat Fee'],
                    ] as const
                  ).map(([value, label]) => (
                    <IonSelectOption key={value} value={value}>
                      {label}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              {proratedDiscount === 'billedPerLesson' ? (
                <>
                  <IonItem>
                    <IonSelect
                      label="Per Lesson Pricing"
                      interface="popover"
                      value={proratedPerLesson}
                      onIonChange={(event) =>
                        setProratedPerLesson(event.detail.value)
                      }
                    >
                      {(
                        [
                          getPricing(0.2),
                          getPricing(0.15),
                          getPricing(0.1),
                          getPricing(0.05),
                          getPricing(0),
                          ['custom', 'Custom'],
                        ] as const
                      ).map(([value, label]) => (
                        <IonSelectOption key={value} value={value}>
                          {label}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </>
              ) : null}

              {proratedDiscount === 'reduceFlatFee' ? (
                <>
                  <IonItem>
                    <IonSelect
                      label="Reduction"
                      interface="popover"
                      value={proratedReduction}
                      onIonChange={(event) =>
                        setProratedReduction(event.detail.value)
                      }
                    >
                      {(
                        [
                          ...(Math.round(basePricing) !== basePricing
                            ? [
                                [
                                  '100Rounded',
                                  `$${toCurrency(
                                    Math.round(basePricing)
                                  )} (≈100%)`,
                                ],
                              ]
                            : []),
                          [
                            '100',
                            `$${toCurrency(basePricing)} (Average Hourly)`,
                          ],
                          [
                            '80',
                            `$${toCurrency(
                              Math.round(basePricing * 0.8)
                            )} (≈80%)`,
                          ],
                          [
                            '50',
                            `$${toCurrency(
                              Math.round(basePricing * 0.5)
                            )} (≈50%)`,
                          ],
                          ['custom', 'Custom'],
                        ] as const
                      ).map(([value, label]) => (
                        <IonSelectOption key={value} value={value}>
                          {label}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>

                  {proratedReduction === 'custom' ? (
                    <IonItem>
                      <IonInput
                        style={{ textAlign: 'right', marginRight: 5 }}
                        label="Custom Amount"
                        type="number"
                        value={toCurrency(customProratedAmount)}
                        onIonChange={(event) =>
                          setCustomProratedAmount(Number(event.detail.value))
                        }
                      ></IonInput>
                      USD
                    </IonItem>
                  ) : null}
                </>
              ) : null}
            </IonList>

            {proratedDiscount === 'billedPerLesson' ? (
              <ListHelperText inset>
                If a student doesn't qualify for the flat fee discount, they pay
                a higher price per lesson, typically when they begin mid-month
                or cancel according to your policy.
              </ListHelperText>
            ) : (
              <ListHelperText inset>
                For students not qualifying for the flat fee discount, the
                respective month's flat fee is modified according to authorized
                cancellations, generally for those beginning mid-month or in
                compliance with your cancellation policy.
              </ListHelperText>
            )}
          </>
        ) : null}

        {cycleFormula === 'flatFee' ? (
          <>
            <IonList inset>
              <IonItem>
                <IonToggle
                  color="success"
                  checked={hasFirstMonthDiscount}
                  onIonChange={(event) =>
                    setHasFirstMonthDiscount(event.detail.checked)
                  }
                >
                  First Month Discount
                </IonToggle>
              </IonItem>
              {hasFirstMonthDiscount ? (
                <IonItem>
                  <IonSelect
                    label="First Month Rate"
                    interface="popover"
                    value={firstMonthRate}
                    onIonChange={(event) =>
                      setFirstMonthRate(event.detail.value)
                    }
                  >
                    {(
                      [
                        ['baseRate', 'Base Rate ($60.00)'],
                        ['temp1', 'Base Rate ($64.00)'],
                        ['temp2', 'Base Rate ($68.00)'],
                        ['custom', 'Custom'],
                      ] as const
                    ).map(([value, label]) => (
                      <IonSelectOption key={value} value={value}>
                        {label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              ) : null}
              {firstMonthRate === 'custom' ? (
                <IonItem>
                  <IonInput
                    style={{ textAlign: 'right', marginRight: 5 }}
                    label="First Month Custom Rate"
                    type="number"
                    value={toCurrency(customProratedAmount)}
                    onIonChange={(event) =>
                      setCustomProratedAmount(Number(event.detail.value))
                    }
                  ></IonInput>
                  USD
                </IonItem>
              ) : null}
            </IonList>

            {hasFirstMonthDiscount ? (
              <ListHelperText inset>
                You are offering a offering a one-time reduced rate to students
                that start mid-month who have committed to flat fee billing.
              </ListHelperText>
            ) : (
              <ListHelperText inset>
                You can offer a one-time reduced rate, closer to your base rate,
                for flat fee pricing during partial months to accommodate
                customers starting lessons mid-month.
              </ListHelperText>
            )}
          </>
        ) : null}
      </IonContent>
    </>
  );
};

export default CustomPricing;
