import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Add, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, Checkbox, Typography } from '@mui/joy';
import { useState } from 'react';

import { ContentContainer } from '@features/Contacts/ContentContainer';

export const ThemeButtons = () => {
  const [checked, setChecked] = useState(false);

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar class="no-background">
          <IonTitle>Buttons</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <Box
            sx={{ p: 5, display: 'flex', flexDirection: 'column', gap: '30px' }}
          >
            {(
              ['primary', 'neutral', 'success', 'danger', 'warning'] as const
            ).map((color) => (
              <Box
                key={color}
                sx={{ display: 'flex', gap: '25px', alignItems: 'end' }}
              >
                <Box sx={{ width: '100px' }}>
                  <Typography level="title-md">
                    {color[0]?.toUpperCase()}
                    {color.slice(1)}
                  </Typography>
                </Box>

                <Button color={color} variant="solid">
                  Solid
                </Button>
                <Button
                  color={color}
                  variant="outlined"
                  endDecorator={<ArrowForwardIos />}
                >
                  Outlined
                </Button>
                <Button
                  color={color}
                  variant="outlined"
                  disabled
                  endDecorator={<ArrowForwardIos />}
                >
                  Outlined Disabled
                </Button>
                <Button color={color} variant="plain">
                  Plain
                </Button>
                <Button color={color} variant="soft">
                  Soft
                </Button>
                <Button
                  color={color}
                  variant="verticalPlain"
                  startDecorator={<Add />}
                >
                  Vertical Plain
                </Button>
                <Button
                  color={color}
                  variant="verticalOutline"
                  startDecorator={<Add />}
                >
                  Vertical Outline
                </Button>
                <Button
                  color={color}
                  variant="verticalSolid"
                  startDecorator={<Add />}
                >
                  Vertical Solid
                </Button>
                <Button
                  color={color}
                  variant="roundedButton"
                  // TODO: use decorator instead
                  // startDecorator={<Add />}
                >
                  <Add />
                  Rounded
                </Button>
              </Box>
            ))}
            <Box sx={{ display: 'flex', gap: '30px' }}>
              <Button
                size="sm"
                variant="instrument"
                startDecorator={
                  <img
                    alt="instrument icon"
                    src="assets/instruments/piano.svg"
                  />
                }
              >
                Piano
              </Button>
              <Button
                size="sm"
                variant="instrument"
                onClick={() => setChecked(!checked)}
                startDecorator={
                  <>
                    <Checkbox overlay checked={checked} />
                    <img
                      alt="instrument icon"
                      src="assets/instruments/piano.svg"
                    />
                  </>
                }
              >
                Piano
              </Button>
              <Checkbox sx={{ alignSelf: 'flex-end', marginBottom: '10px' }} />
            </Box>
          </Box>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};
