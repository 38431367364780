import dayjs from 'dayjs';
import { useMemo } from 'react';

export const dateFormat = 'YYYY-MM-DD';

export interface CalendarDayData {
  date: string;
  dayNumber: number;
  isCurrentMonth: boolean;
  hasEvent?: boolean;
}

function createMonthlyCalendar(input: Date, offset: number): CalendarDayData[] {
  const inputDate = dayjs(input).startOf('month').add(offset, 'months');

  const numberOfDaysInMonth = inputDate.daysInMonth();
  const currentMonthDays = [...Array(numberOfDaysInMonth)].map((_, index) => ({
    date: inputDate.add(index, 'days').format(dateFormat),
    dayNumber: index + 1,
    isCurrentMonth: true,
  }));

  const extraDaysFromPreviousMonth = Array(inputDate.day())
    .fill(0)
    .map((_, index) => ({
      date: inputDate.subtract(index + 1, 'days').format(dateFormat),
      dayNumber: inputDate.subtract(index + 1, 'days').date(),
      isCurrentMonth: false,
    }))
    .reverse();

  const startOfNextMonth = inputDate.add(1, 'month');
  const extraDaysInNextMonth = Array(7 - inputDate.add(1, 'month').day())
    .fill(0)
    .map((_, index) => ({
      date: startOfNextMonth.add(index, 'days').format(dateFormat),
      dayNumber: startOfNextMonth.add(index, 'days').date(),
      isCurrentMonth: false,
    }));

  return [
    ...extraDaysFromPreviousMonth,
    ...currentMonthDays,
    ...(extraDaysInNextMonth.length === 7 ? [] : extraDaysInNextMonth),
  ];
}

export const TODAY = dayjs().format(dateFormat);

function createWeeklyCalendar(input: Date, offset: number): CalendarDayData[] {
  const inputDate = dayjs(input).startOf('week').add(offset, 'weeks');

  return [...Array(7)].map((_, index) => {
    const day = inputDate.add(index, 'days');

    return {
      date: day.format(dateFormat),
      dayNumber: day.date(),
      isCurrentMonth: true,
    };
  });
}

interface UseCalendarProps {
  inputDate: Date;
  expanded: boolean;
  expanding: boolean;
  offset: number;
}

export const useCalendar = ({
  expanded,
  expanding,
  inputDate,
  offset,
}: UseCalendarProps) =>
  useMemo(
    () =>
      expanded && !expanding
        ? createWeeklyCalendar(inputDate, offset)
        : createMonthlyCalendar(inputDate, offset),
    [inputDate, offset, expanded, expanding]
  );
