import { useContext } from 'react';

import { EditProductContext } from '.';

export const useEditProductContext = () => {
  const currentUserContext = useContext(EditProductContext);

  if (!currentUserContext) {
    throw new Error(
      'useEditProductContext has to be used within <EditProductContext.Provider>'
    );
  }

  return currentUserContext;
};
