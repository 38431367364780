import { useEffect, useState } from 'react';
import { Swiper } from 'swiper/types';

export const useInfiniteSlider = () => {
  const [swiper, setSwiper] = useState<Swiper | null>(null);
  const [previousDate, setPreviousDate] = useState<Date | undefined>();
  const [transitioning, setTransitioning] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (transitioning === undefined || !swiper) {
      return;
    }

    setTransitioning(undefined);
    setPreviousDate(undefined);
    swiper.slideTo(1, 0, false);
  }, [transitioning, swiper]);

  return {
    setSwiper,
    previousDate,
    setPreviousDate,
    transitioning,
    setTransitioning,
  };
};
