import { Box } from '@mui/joy';
import Typography from '@mui/joy/Typography';

import { sidePadding } from '@features/Contacts/ContactDetail/ViewContact';

export const durationEventHeight = 60;
export const durationMarginLeft = 65;

interface DurationViewProps {
  children?: React.ReactNode;
  agendaView: boolean;
}

const EventViewContainer = ({ agendaView, children }: DurationViewProps) =>
  agendaView ? (
    <>{children}</>
  ) : (
    <Box
      style={{
        marginTop: 0.5 * durationEventHeight,
        position: 'relative',
      }}
    >
      {Array(25)
        .fill(0)
        .map((_, index) => (
          <Box
            key={index}
            style={{
              height: durationEventHeight,
              display: 'flex',
              position: 'relative',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                color: 'gray',
                left: sidePadding,
                top: -9,
              }}
            >
              <Typography fontSize={10}>
                {index === 0 ? 12 : index <= 12 ? index : index - 12}{' '}
                {index < 12 ? 'AM' : 'PM'}
              </Typography>
            </Box>
            <Box
              style={{
                ...(index === 0
                  ? { borderTop: '1px solid lightgray' }
                  : undefined),
                ...(index === 24
                  ? {}
                  : { borderBottom: '1px solid lightgray' }),
                flex: 1,
                marginLeft: durationMarginLeft,
              }}
            ></Box>
          </Box>
        ))}
      {children}
    </Box>
  );

export default EventViewContainer;
