import {
  AutoGraphOutlined,
  CloudUploadOutlined,
  DrawOutlined,
  InterestsOutlined,
  MusicNoteOutlined,
  TextFormatOutlined,
  VideoLibraryOutlined,
} from '@mui/icons-material';
import { Box, Button, buttonClasses } from '@mui/joy';

import { useMedia } from '../MediaProvider/useMedia';
import { Draw } from './Draw';
import { Elements } from './Elements';
import { GuidedPracticeConfiguration } from './GuidedPracticeConfiguration';
import { Text } from './Text';
import { Uploads } from './Uploads';

export const hasGuidedPractice = false;

export const MediaSidebar = () => {
  const { selectedSidebarItem, setSelectedSidebarItem } = useMedia();

  const getSidebarItemProps = (item: string) => ({
    className: selectedSidebarItem === item ? 'selected' : '',
    onClick: () => setSelectedSidebarItem(item),
  });

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        borderRight: `1px solid #E4E7EB`,
      }}
    >
      <Box
        sx={{
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          sx={(theme) => ({
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',

            [`.${buttonClasses.root}`]: {
              fontSize: '11px',
              padding: '10px',
              color: theme.palette.neutral[700],

              '&.selected': {
                color: theme.palette.common.black,
              },
            },

            [`.${buttonClasses.startDecorator}`]: {
              height: '22px',
              width: '22px',
              '& svg': {
                fontSize: '25px',
              },
            },
          })}
        >
          <Button
            variant="verticalPlain"
            startDecorator={<AutoGraphOutlined />}
            {...getSidebarItemProps('practice')}
          >
            Practice
          </Button>
          <Button
            variant="verticalPlain"
            startDecorator={<InterestsOutlined />}
            {...getSidebarItemProps('elements')}
          >
            Elements
          </Button>
          <Button
            variant="verticalPlain"
            startDecorator={<CloudUploadOutlined />}
            {...getSidebarItemProps('uploads')}
          >
            Uploads
          </Button>

          <Button
            variant="verticalPlain"
            startDecorator={<TextFormatOutlined />}
            {...getSidebarItemProps('text')}
          >
            Text
          </Button>
          <Button
            variant="verticalPlain"
            startDecorator={<DrawOutlined />}
            {...getSidebarItemProps('draw')}
          >
            Draw
          </Button>
          {hasGuidedPractice ? (
            <>
              <Button
                variant="verticalPlain"
                startDecorator={<MusicNoteOutlined />}
                {...getSidebarItemProps('audio')}
              >
                Audio
              </Button>
              <Button
                variant="verticalPlain"
                startDecorator={<VideoLibraryOutlined />}
                {...getSidebarItemProps('video')}
              >
                Video
              </Button>
            </>
          ) : null}
        </Box>
      </Box>
      {selectedSidebarItem ? (
        <Box sx={{ height: '100%', width: '350px', position: 'relative' }}>
          {selectedSidebarItem === 'practice' ? (
            <GuidedPracticeConfiguration />
          ) : null}

          {selectedSidebarItem === 'elements' ? <Elements /> : null}
          {selectedSidebarItem === 'uploads' ? <Uploads /> : null}
          {selectedSidebarItem === 'text' ? <Text /> : null}
          {selectedSidebarItem === 'draw' ? <Draw /> : null}
        </Box>
      ) : null}
    </Box>
  );
};
