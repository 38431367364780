import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { useEffect, useRef, useState } from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';

import { ShapeProps } from './CanvasPage';

interface MediaImageProps {
  shapeProps: ShapeProps;
  onSelect: (event: KonvaEventObject<MouseEvent>) => void;
  selectedItem: string;
  onChange?: (newAttrs: ShapeProps) => void;
}

const MediaImage = ({
  shapeProps,
  onSelect,
  selectedItem,
  onChange,
}: MediaImageProps) => {
  const shapeRef = useRef<Konva.Image | null>(null);
  const [isSelected, setIsSelected] = useState('');
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [sizeIncludeSpaceWidth, setSizeIncludeSpaceWidth] = useState(0);
  const [sizeIncludeSpaceHeight, setSizeIncludeSpaceHeight] = useState(0);
  const [image] = useImage(shapeProps.url, 'anonymous');
  const clearSpace = 28.79;
  const spaceRatio = clearSpace * 1.5;

  useEffect(() => {
    if (shapeRef.current) {
      setIsSelected(selectedItem);
      setSizeIncludeSpaceWidth(28.5 * 2 + shapeProps.width);
      setSizeIncludeSpaceHeight(28.5 * 2 + shapeProps.height);
      setPosX(spaceRatio - clearSpace);
      setPosY(spaceRatio - clearSpace);
    }
  }, [selectedItem, spaceRatio, shapeProps.width, shapeProps.height]);

  return (
    <>
      <Group
        height={sizeIncludeSpaceHeight}
        width={sizeIncludeSpaceWidth}
        x={posX}
        y={posY}
        draggable={Boolean(
          shapeRef.current && isSelected === shapeRef.current.attrs.name
        )}
        onDragEnd={(e) => {
          onChange?.({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
      >
        <Image
          image={image}
          onClick={onSelect}
          ref={shapeRef}
          draggable={false}
          name={shapeProps.name}
          width={shapeProps.width}
          height={shapeProps.height}
          x={clearSpace}
          y={clearSpace}
        />
      </Group>
    </>
  );
};

export default MediaImage;
