import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography } from '@mui/material';

export const AlmostDone = () => {
  return (
    <IonPage data-auth="signed-out">
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Almost Done</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Box sx={{ padding: 2 }}>
          <IonHeader collapse="condense">
            <IonToolbar className="inline-toolbar">
              <IonTitle size="large">Almost Done</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Box
            sx={{
              padding: '10px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Typography sx={{ lineHeight: 1.5 }}>
              We've created your account, but the invite link you used has
              expired. For your security, we just sent a fresh invite to your
              email.
            </Typography>

            <Typography sx={{ lineHeight: 1.5 }}>
              Please find the new email and click the link to complete
              registration.
            </Typography>
          </Box>
        </Box>
      </IonContent>
    </IonPage>
  );
};
