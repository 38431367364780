import dayjs from 'dayjs';
import { useMemo } from 'react';

import { ParsedEvent } from '@models/events/model';

import { AvailableEvent } from './AddEvent/SelectTime/AvailabilityEvent';
import { unfurlEventsBetween } from './useUnfurlEventsBetween/unfurlEventsBetween';

type OffsetKeys = -1 | 0 | 1;

const getAvailability = (
  events: ParsedEvent[],
  currentDate: Date
): AvailableEvent[] => {
  const availability = new Map<number, boolean>(
    Array(48)
      .fill(0)
      .map((_, index) => [index / 2, true] as const)
  );

  events.forEach((event) => {
    const start = dayjs(event.start);
    const startHour = start.hour() + start.minute() / 60;
    const slots = event.duration / 30;

    Array(slots)
      .fill(0)
      .forEach((_, index) => {
        const slot = startHour + index * 0.5;

        availability.set(slot, false);
      });
  });

  return Array.from(availability).flatMap(([slot, available]) => {
    if (!available) {
      return [];
    }

    const hour = Math.floor(slot);
    const minutes = (slot - hour) * 60;
    const day = dayjs(currentDate)
      .hour(hour)
      .minute(minutes)
      .second(0)
      .millisecond(0);

    if (day.isBefore(currentDate)) {
      return [];
    }

    const start = day.toDate();
    const end = day.add(30, 'minutes').toDate();

    return [{ start, end, duration: 30 }];
  });
};

const getTodaysEvents = (
  events: ParsedEvent[],
  currentDate: Date
): EventGroup => {
  const scheduledEvents = unfurlEventsBetween(events, currentDate, currentDate);

  return {
    events: scheduledEvents,
    availabileEvents: getAvailability(scheduledEvents, currentDate),
  };
};

export interface EventGroup {
  events: ParsedEvent[];
  availabileEvents: AvailableEvent[];
}

export const useEventGroups = (events: ParsedEvent[], currentDate: Date) =>
  useMemo(() => {
    return new Map<OffsetKeys, EventGroup>([
      [
        -1,
        getTodaysEvents(events, dayjs(currentDate).subtract(1, 'day').toDate()),
      ],
      [0, getTodaysEvents(events, currentDate)],
      [1, getTodaysEvents(events, dayjs(currentDate).add(1, 'day').toDate())],
    ]);
  }, [events, currentDate]);
