import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import { Typography } from '@mui/joy';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';

import { IonicLink } from '@components/IonicLink';

export interface AdminLinkProps {
  text: string;
  icon: JSX.Element;
  iconRight?: JSX.Element | null;
  subText?: string;
  to?: string;
  routerDirection?: 'forward' | 'back' | 'root' | 'none';
  onClick?: () => void;
  testId?: string;
}

export const AdminLinkBase = ({
  text,
  subText,
  icon,
  onClick,
  iconRight,
  testId,
}: AdminLinkProps) => (
  <>
    <ListItem
      onClick={onClick}
      sx={{ '--ListItem-minHeight': 0 }}
      data-testid={testId}
    >
      <ListItemButton
        sx={{ '&:active,&:hover': { backgroundColor: 'unset !important' } }}
      >
        <ListItemDecorator>{icon}</ListItemDecorator>
        <ListItemContent>{text}</ListItemContent>
        {subText && (
          <Typography textColor="text.tertiary">{subText}</Typography>
        )}
        {iconRight === undefined ? (
          <ArrowForwardIosRounded
            sx={{
              color: 'text.tertiary',
              height: '0.7em',
              width: '0.7em',
              marginLeft: '0.5em',

              '@media (min-width: 747px)': {
                display: 'none',
              },
            }}
          />
        ) : (
          iconRight
        )}
      </ListItemButton>
    </ListItem>
    <ListDivider />
  </>
);

export const AdminLink = (props: AdminLinkProps) => (
  <IonicLink to={props.to || '/'} unstyled>
    <AdminLinkBase {...props} />
  </IonicLink>
);
