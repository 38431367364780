import {
  AttachFileOutlined,
  ContentCopyOutlined,
  DeleteOutlined,
  FormatIndentIncreaseOutlined,
  MoreHoriz,
  MusicNote,
} from '@mui/icons-material';
import {
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  avatarClasses,
} from '@mui/joy';
import { Popover } from '@mui/material';
import { Fragment, useMemo } from 'react';
import uuid4 from 'uuid4';

import { useFilterPopover } from '@features/Contacts/useFilterPopover';

import { useLesson } from '../LessonProvider/useLesson';
import { initAssignment } from '../LessonProvider/useLessonProviderValue';

interface AssignmentMenuProps {
  assignmentId: string;
}

export const AssignmentMenu = ({ assignmentId }: AssignmentMenuProps) => {
  const {
    flattenedAssignmentItems,
    setFlattenedAssignmentItems,
    setAssignmentModalOpen,
    setNewAssignment,
  } = useLesson();
  const { ref, filterIsOpen, setFilterIsOpen, anchorPosition } =
    useFilterPopover<HTMLButtonElement>();

  const assignment = useMemo(() => {
    const match = flattenedAssignmentItems.find(
      ({ id }) => id === assignmentId
    );

    if (match?.type === 'assignment') {
      return match;
    }
  }, [assignmentId, flattenedAssignmentItems]);

  const canDent = useMemo(() => {
    if (!assignment) {
      return false;
    }

    const currentIndex = flattenedAssignmentItems.findIndex(
      ({ id }) => id === assignment.id
    );
    const previousItem = flattenedAssignmentItems[currentIndex - 1];
    const nextItem = flattenedAssignmentItems[currentIndex + 1];

    if (!previousItem) {
      return false;
    }

    if (
      assignment.children.length > 0 ||
      (previousItem.id === assignment.id && !assignment.isSubtask) ||
      previousItem.type === 'section'
    ) {
      return false;
    }

    if (nextItem?.id !== assignment.id && nextItem?.isSubtask) {
      return true;
    }

    return true;
  }, [assignment, flattenedAssignmentItems]);

  if (!assignment) {
    return null;
  }

  return (
    <>
      <IconButton
        disabled={assignment.isPlaceholder}
        ref={ref}
        onClick={() => setFilterIsOpen(true)}
      >
        <MoreHoriz />
      </IconButton>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <List
          size="sm"
          sx={{
            [`& .${avatarClasses.root}:not(.instruments)`]: {
              height: '20px',
              width: '20px',
            },
          }}
        >
          {[
            {
              id: '1',
              title: 'Add Sheet Music',
              startDecorator: <MusicNote />,
            },
            {
              id: '2',
              title: 'Add Attachments',
              startDecorator: (
                <AttachFileOutlined sx={{ transform: 'rotate(30deg)' }} />
              ),
              onClick: () => {
                setNewAssignment(initAssignment());
                setAssignmentModalOpen(true);
                setFilterIsOpen(false);
              },
            },
            {
              id: '3',
              title: 'Duplicate',
              startDecorator: <ContentCopyOutlined />,
              onClick: () => {
                const lastItem =
                  flattenedAssignmentItems[flattenedAssignmentItems.length - 1];

                if (!lastItem) {
                  return;
                }

                setFlattenedAssignmentItems([
                  ...flattenedAssignmentItems.slice(0, -1),
                  {
                    ...assignment,
                    id: uuid4(),
                    title: `${assignment.title} (copy)`,
                    children: [],
                    parentId: lastItem.sectionId,
                  },
                  ...flattenedAssignmentItems.slice(-1),
                ]);
                setFilterIsOpen(false);
              },
            },
            ...(canDent
              ? [
                  {
                    id: '4',
                    title: assignment.isSubtask
                      ? 'Outdent assignment'
                      : 'Indent assignment',
                    startDecorator: (
                      <FormatIndentIncreaseOutlined
                        sx={{
                          transform: assignment.isSubtask
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        }}
                      />
                    ),
                    divider: true,
                    onClick: () => {
                      setFilterIsOpen(false);

                      if (assignment.isSubtask) {
                        return setFlattenedAssignmentItems(
                          flattenedAssignmentItems.map((item) =>
                            assignment.id === item.id
                              ? { ...item, parentId: item.sectionId }
                              : item
                          )
                        );
                      }

                      setFlattenedAssignmentItems(
                        flattenedAssignmentItems.map((item, index) => {
                          if (assignment.id === item.id) {
                            const previousAssignment =
                              flattenedAssignmentItems[index - 1];

                            if (previousAssignment) {
                              return {
                                ...item,
                                parentId: previousAssignment.isSubtask
                                  ? previousAssignment.parentId
                                  : previousAssignment.id,
                              };
                            }
                          }

                          return item;
                        })
                      );
                      setFilterIsOpen(false);
                    },
                  },
                ]
              : []),
            {
              id: '99',
              title: 'Delete',
              startDecorator: <DeleteOutlined />,
              onClick: () =>
                setFlattenedAssignmentItems(
                  flattenedAssignmentItems.filter(
                    ({ id }) => id !== assignment.id
                  )
                ),
            },
          ].map((option) => (
            <Fragment key={option.id}>
              <ListItem>
                <ListItemButton
                  variant="plain"
                  color="neutral"
                  onClick={option.onClick}
                  sx={{
                    '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                      backgroundColor: 'unset',
                    },
                    '&.Mui-selected': {
                      fontWeight: 'unset',
                    },
                  }}
                >
                  {option.startDecorator ? (
                    <ListItemDecorator>
                      {option.startDecorator}
                    </ListItemDecorator>
                  ) : null}
                  <ListItemContent
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                  >
                    {option.title}
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              {option.divider ? <ListDivider /> : null}
            </Fragment>
          ))}
        </List>
      </Popover>
    </>
  );
};
