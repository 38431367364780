import {
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  tabClasses,
  tabListClasses,
  tabPanelClasses,
} from '@mui/joy';

import { AssignmentTab } from './AssignmentTab';
import { LessonNavbar } from './LessonNavbar';

export const LessonDetail = () => (
  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
    <LessonNavbar />
    <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            padding: '15px 30px',
            marginTop: '30px',
            maxWidth: '920px',
            margin: '0 auto',
          }}
        >
          <Tabs
            aria-label="Basic tabs"
            defaultValue={0}
            sx={{
              marginTop: '10px',
              backgroundColor: 'transparent',
              '--joy-palette-neutral-plainHoverBg': 'transparent',
              '--joy-palette-neutral-plainActiveBg': 'transparent',
              [`.${tabListClasses.root}`]: {
                gap: '25px',
                display: 'flex',
              },
              [`.${tabClasses.root}`]: {
                paddingLeft: '1px',
                paddingRight: '1px',
                fontSize: '18px',
              },
              [`.${tabPanelClasses.root}`]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <TabList disableUnderline>
              <Tab>Overview</Tab>
              <Tab>Details</Tab>
              <Tab>Analytics</Tab>
              <Tab>Settings</Tab>
            </TabList>
            <AssignmentTab />
            <TabPanel value={1}>
              <b>Second</b> tab panel
            </TabPanel>
            <TabPanel value={2}>
              <b>Third</b> tab panel
            </TabPanel>
          </Tabs>
        </Box>
      </Box>
    </Box>
  </Box>
);
