import { AvatarTypeMap } from '@mui/joy';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { useAuthState } from 'react-firebase-hooks/auth';

import Avatar from '@components/Avatar';
import { useUserData } from '@components/UserData/useUserData';
import { useContactAvatar } from '@components/useContactAvatar';
import { getAvatarInitials } from '@features/Settings/Workspace/General';
import { auth } from '@utils/firebase';

export default function ProfileAvatar({
  children,
  src,
  ...props
}: DefaultComponentProps<AvatarTypeMap<{}, 'div'>>) {
  const [user] = useAuthState(auth);
  const { userContact } = useUserData();
  const [avatarSrc] = useContactAvatar(userContact);

  return (
    <Avatar src={src ?? avatarSrc ?? undefined} {...props}>
      {children || (user ? getAvatarInitials(user.displayName) : null)}
    </Avatar>
  );
}
