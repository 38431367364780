import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { Search } from '@mui/icons-material';
import { Box, Input, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';

import { ModalListContainer } from '@components/Modal/ModalListContainer';

import { InstrumentsSelection } from './InstrumentsSelection';

interface InstrumentsModalProps {
  page: React.MutableRefObject<HTMLElement | null>;
  isOpen: boolean;
  onDone?: () => void;
  selectedInstruments: string[];
  setSelectedInstruments: (next: string[]) => void;
}

export const InstrumentsModal = ({
  page,
  isOpen,
  onDone,
  selectedInstruments,
  setSelectedInstruments,
}: InstrumentsModalProps) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setSearchValue('');
  }, [isOpen]);

  if (!page.current) {
    return null;
  }

  return (
    <IonModal
      presentingElement={page.current}
      isOpen={isOpen}
      onDidDismiss={() => onDone?.()}
    >
      <IonHeader>
        <IonToolbar class="no-background">
          <IonButtons slot="end">
            <IonButton
              color="dark"
              onClick={onDone}
              data-testid="close-instruments-modal"
            >
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ModalListContainer sx={{ gap: '10px' }}>
          <Box>
            <Typography level="h4">Select Instruments</Typography>
            <Typography>
              Select the instruments that this student is learning.
            </Typography>
          </Box>

          <Box sx={{ paddingTop: '30px' }}>
            <Input
              variant="search"
              placeholder="Find an instrument…"
              startDecorator={<Search />}
              value={searchValue}
              onChange={(event) =>
                setSearchValue(event.target.value.toLowerCase())
              }
            />
          </Box>

          <Box
            className="scrollable"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <InstrumentsSelection
              selectedInstruments={selectedInstruments}
              setSelectedInstruments={setSelectedInstruments}
              searchValue={searchValue}
            />
          </Box>
        </ModalListContainer>
      </IonContent>
    </IonModal>
  );
};
