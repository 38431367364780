import { z } from 'zod';

import { zodTimestamp } from '../zodTimestamp';

export const billingCycleSchema = z.union([
  // @v1.2
  z.literal('perLesson'),
  // @v1.3
  z.literal('perWeek'),
  // @v1.1
  z.literal('perMonth'),
  // @v1.3
  z.literal('perQuarter'),
]);

export type BillingCycle = z.infer<typeof billingCycleSchema>;

export const billingCycleLabelMap: Record<BillingCycle, string> = {
  perLesson: 'Per Lesson',
  perWeek: 'Per Week',
  perMonth: 'Per Month',
  perQuarter: 'Per Quarter',
};

export const invoiceIssuedSchema = z.union([
  z.literal('firstLessonOfCycle'),
  z.literal('lastLessonOfCycle'),
  z.literal('beforeCycle'),
  z.literal('afterCycle'),
]);

export type InvoiceIssued = z.infer<typeof invoiceIssuedSchema>;

export const invoiceIssuedLabelMap: Record<InvoiceIssued, string> = {
  firstLessonOfCycle: 'First lesson of cycle',
  lastLessonOfCycle: 'Last lesson of cycle',
  beforeCycle: 'Before billing cycle',
  afterCycle: 'After billing cycle',
};

// @v1.1
export const invoiceDueSchema = z.union([
  z.literal('firstLessonOfCycle'),
  z.literal('lastLessonOfCycle'),
  z.literal('beforeCycle'),
  z.literal('afterCycle'),
]);

export type InvoiceDue = z.infer<typeof invoiceDueSchema>;

export const invoiceDueLabelMap: Record<InvoiceDue, string> = {
  firstLessonOfCycle: 'First lesson of cycle',
  lastLessonOfCycle: 'Last lesson of cycle',
  beforeCycle: 'Before billing cycle',
  afterCycle: 'After billing cycle',
};

export const cycleFormulaSchema = z.union([
  // @v1.1
  z.literal('lessonsTimesPrice'),
  // @vE.1
  z.literal('flatFee'),
]);

export type CycleFormula = z.infer<typeof cycleFormulaSchema>;

export const lessonPricingItemSchema = z.object({
  duration: z.number(),
  price: z.number(),
  isDefault: z.boolean().optional(),
});

export type LessonPricingItem = z.infer<typeof lessonPricingItemSchema>;

// @v1.1
export const refundAmountSchema = z.union([
  z.literal('100'),
  z.literal('75'),
  z.literal('50'),
  z.literal('25'),
  z.literal('custom'),
]);

export type RefundAmount = z.infer<typeof refundAmountSchema>;

// @v1.2
export const lastMinuteRefundAmountSchema = z.union([
  z.literal('100'),
  z.literal('75'),
  z.literal('50'),
  z.literal('25'),
  z.literal('custom'),
]);

export type LastMinuteRefundAmount = z.infer<
  typeof lastMinuteRefundAmountSchema
>;

// @v1.4
const maximumReschedulesSchema = z.union([
  z.literal('1'),
  z.literal('2'),
  z.literal('3'),
  z.literal('custom'),
]);

export type MaximumReschedules = z.infer<typeof maximumReschedulesSchema>;

// @v1.4
export const maxMakeupOfMakeupsSchema = z.union([
  z.literal('1'),
  z.literal('2'),
  z.literal('3'),
  z.literal('custom'),
]);

export type MaxMakeupOfMakeups = z.infer<typeof maxMakeupOfMakeupsSchema>;

// @v1.2
export const maximumSavedLessonsSchema = z.union([
  z.literal('1Credits'),
  z.literal('2Credits'),
  z.literal('3Credits'),
  z.literal('5Credits'),
  z.literal('custom'),
]);

export type MaximumSavedLessons = z.infer<typeof maximumSavedLessonsSchema>;

// @v1.3
export const savedLessonExpirationSchema = z.union([
  z.literal('3Week'),
  z.literal('6Weeks'),
  z.literal('custom'),
]);

export type SavedLessonExpiration = z.infer<typeof savedLessonExpirationSchema>;

// @v1.1
export const productNameTypeSchema = z.union([
  z.literal('auto'),
  z.literal('custom'),
]);

export type ProductNameType = z.infer<typeof productNameTypeSchema>;

// @v3.0
export const registrationPolicySchema = z.union([
  z.literal('public'),
  z.literal('private'),
]);

export type RegistrationPolicy = z.infer<typeof registrationPolicySchema>;

export const productType = z.union([
  // @v1.1
  z.literal('global'),
  // @v1.2
  z.literal('standard'), // mixes with global settings
  // @v1.3
  z.literal('advanced'), // does not mix with global
]);

export type ProductType = z.infer<typeof productType>;

export const rawProductSchema = z.object({
  createdAt: zodTimestamp,
  updatedAt: zodTimestamp,
  createdBy: z.string(),
  updatedBy: z.string(),
  type: productType,

  // @v1.1
  billingCycles: z.array(billingCycleSchema),
  // @v1.1
  cycleFormula: cycleFormulaSchema,
  // @v1.1
  invoiceIssued: invoiceIssuedSchema,
  // @v1.1
  invoiceIssuedOffset: z.number().optional(),
  // @v1.1
  invoiceDue: invoiceDueSchema,
  // @v1.1
  invoiceDueOffset: z.number().optional(),
  /**
   * Only used if cycleFormula is `flatFee`
   */
  // @vE.1
  flatFee: z.number().optional(),
  // @vE.1
  basePricing: z.number().optional(),
  // @v1.1
  organizationId: z.string(),
  // @v1.1
  lessonPricing: z.array(lessonPricingItemSchema),

  // Refunds
  // @v1.1
  allowRefunds: z.boolean(),
  /**
   * Only used if allowRefunds is true
   */
  // @v1.1
  refundAmount: refundAmountSchema.optional(),
  // @v1.1
  customRefundAmount: z.number().optional(),

  // Rescheduling
  // @v1.4
  unlimitedRescheduling: z.boolean(),
  // @v1.2
  allowSavingCancelledLessons: z.boolean(),

  // Makeups
  // @v1.2
  allowMakupLessons: z.boolean(),
  // @v1.2
  allowMakeupCredits: z.boolean(),
  // @v1.4
  allowReschedulingMakeups: z.boolean(),
  // @v1.4
  allowMakingUpMakeups: z.boolean(),
  // @v1.1
  allowLastMinuteRefunds: z.boolean(),

  /**
   * Only used if allowLastMinuteRefunds is true
   */
  // @v1.1
  lastMinuteRefundAmount: lastMinuteRefundAmountSchema.optional(),
  // @v1.1
  customLastMinuteRefundAmount: z.number().optional(),

  /**
   * Only used if allowReschedulingMakeups is true
   */
  // @v1.4
  maximumReschedules: maximumReschedulesSchema.optional(),
  // @v1.4
  customMaximumReschedules: z.number().optional(),

  /**
   * Only used if allowMakingUpMakeups is true
   */
  // @v1.4
  maxMakeupOfMakeups: maxMakeupOfMakeupsSchema.optional(),
  // @v1.4
  customMaxMakeupOfMakeups: z.number().optional(),

  /**
   * Only used if allowMakeupCredits is true
   */
  // @v1.2
  maximumSavedLessons: maximumSavedLessonsSchema.optional(),
  // @v1.2
  customMaximumSavedLessons: z.number().optional(),

  /**
   * Only used if allowMakeupCredits is true
   */
  // @v1.2
  savedLessonExpiration: savedLessonExpirationSchema.optional(),
  // @v1.2
  customSavedLessonExpiration: z.number().optional(),

  // @v1.1
  instrument: z.string(),
  // @v1.1
  productNameType: productNameTypeSchema,
  // @v1.1
  customProductName: z.string().optional(),
  // @v1.1
  productName: z.string(),
  // @v1.4
  registrationPolicies: z.array(registrationPolicySchema),

  /**
   * TODO: add remaining fields
   */
  // @v1.3 - just issues at the start of the month for @v1.1
  // issueDate
  // @v1.1
  // dueDate
  // @v1.3
  // cateogry: discounts
  // @v1.4
  // flat fee
  // @v1.1
  // allowReschedulingLastMinute: z.boolean(),
  // @v1.4
  // lockAfterOriginalLessonDate: z.boolean(),
  // @v1.1
  // lastMinuteDeadline: tbd,
  // @v1.4
  // treatNoShowsTheSameAsLastMinute: z.boolean(),
  // @v1.3
  // productActive: z.boolean(),
});

export const sharedProductSchema = rawProductSchema.omit({
  billingCycles: true,
  registrationPolicies: true,
  lessonPricing: true,
});

export const refineSharedProductFields = <T extends typeof sharedProductSchema>(
  inputSchema: T
) =>
  inputSchema
    // TODO: only allow invoice due relative to the lesson date when billed per lesson
    .refine(
      (data) => !data.allowRefunds || data.refundAmount,
      'You cannot have refunds without defining a refund amount'
    )
    .refine(
      (data) => data.refundAmount !== 'custom' || data.customRefundAmount,
      'You cannot have a custom refund amount without defining a custom refund amount'
    )
    // Last-minute refunds
    .refine(
      (data) => !data.allowLastMinuteRefunds || data.lastMinuteRefundAmount,
      'You cannot have last-minute refunds without defining a last-minute refund amount'
    )
    .refine(
      (data) =>
        data.lastMinuteRefundAmount !== 'custom' ||
        data.customLastMinuteRefundAmount,
      'You cannot have a custom last-minute refund amount without defining a custom last-minute refund amount'
    )
    .refine(
      (data) =>
        data.invoiceIssued === 'firstLessonOfCycle' ||
        data.invoiceIssued === 'lastLessonOfCycle' ||
        data.invoiceIssuedOffset !== undefined,
      "Your invoice issued dates require an offset if it's not at the start of the cycle"
    )
    .refine(
      (data) =>
        data.invoiceDue === 'firstLessonOfCycle' ||
        data.invoiceDue === 'lastLessonOfCycle' ||
        data.invoiceDueOffset !== undefined,
      "Your invoice due dates require an offset if it's not at the start of the cycle"
    );

export const refinedProductSchema = refineSharedProductFields(rawProductSchema);

export type RawProduct = z.infer<typeof rawProductSchema>;

export interface ParsedProduct extends RawProduct {
  id: string;
}
