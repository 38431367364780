import { BillingCycle } from '@shared/models/products';

export const weeksInMonth = 365.2425 / 7 / 12;
export const weeksInQuarter = weeksInMonth * 3;

export const toCurrency = (input: number) =>
  input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const toSimpleCurrancy = (input: number) => {
  const base = toCurrency(input);

  if (base.endsWith('.00')) {
    return String(input);
  }

  return base;
};

export const weeksInBillingCycle = {
  perMonth: weeksInMonth,
  perQuarter: weeksInQuarter,
};

interface GetBasePricingProps {
  billingCycles: BillingCycle[];
  flatFee: number;
}

export const getBasePricing = ({
  billingCycles,
  flatFee,
}: GetBasePricingProps) => {
  const defaultBillingCycle = billingCycles.find((billingCycle) =>
    ['perMonth', 'perQuarter'].includes(billingCycle)
  );
  if (
    defaultBillingCycle === 'perMonth' ||
    defaultBillingCycle === 'perQuarter'
  ) {
    return roundCurrency(flatFee / weeksInBillingCycle[defaultBillingCycle]);
  }

  return 0;
};

export const roundCurrency = (input: number) => Math.round(input * 100) / 100;

export interface AddBillingPlanProps {
  billingCycle: string;
  flatFee: number;
  setFlatFee: (input: number) => void;
  basePricing: number;
  setBasePricing: (input: number) => void;
  cycleFormula: string;
  setCycleFormula: (input: string) => void;
}
