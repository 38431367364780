import { IonItem, IonList } from '@ionic/react';

import { ListHelperText } from '@components/ListHelperText';
import { ToggleWrapper } from '@components/ionic-wrappers/ToggleWrapper';

interface NoShowsContentProps {
  readOnly?: boolean;
}

const NoShowsContent = ({ readOnly }: NoShowsContentProps) => (
  <>
    <IonList inset>
      <IonItem>
        <ToggleWrapper readOnly={readOnly} color="success" checked>
          Use Last-Minute Policy
        </ToggleWrapper>
      </IonItem>
    </IonList>
    <ListHelperText inset>
      Streamlines your cancellation policies by treating last-minute
      cancellations and no-shows with the same set of rules.
    </ListHelperText>
  </>
);

export default NoShowsContent;
