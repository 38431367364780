import { Box } from '@mui/material';

import { IonicLink } from '@components/IonicLink';

export interface HomePageBlockProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
  color?: string;
  to?: string;
}

export const HomePageBlock = ({
  title,
  color,
  to,
  children,
}: HomePageBlockProps) => (
  <IonicLink to={to} unstyled>
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        background: color,
        padding: '15px',
      }}
    >
      {children}
    </Box>
  </IonicLink>
);
