import {
  Add,
  CloseOutlined,
  DoNotDisturbAltOutlined,
} from '@mui/icons-material';
import {
  AvatarGroup,
  Box,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
  menuButtonClasses,
} from '@mui/joy';
import {
  ButtonGroup,
  IconButton,
  Popover,
  avatarClasses,
  buttonClasses,
  iconButtonClasses,
  svgIconClasses,
  typographyClasses,
} from '@mui/material';
import { ParsedContact } from '@shared/models/contacts';
import { useMemo } from 'react';

import { useUser } from '@features/Organization/organizationSlice';

import { ContactAvatar } from './ContactAvatar';
import { getContactName } from './ContactDetail/ViewContact';
import { FilterOverview, FilterState, filterConfig } from './FilterOverview';
import { InstrumentAvatar } from './InstrumentAvatar';
import { useFilterPopover } from './useFilterPopover';

interface UpdateFilterProps {
  inclusive: boolean;
  all: boolean | undefined;
  dateOperator?: 'before' | 'after' | 'in' | undefined;
}

interface FilterControlProps {
  onFilterSelected?: (
    filter: FilterState | undefined,
    shouldClose: boolean,
    openContentModal?: boolean
  ) => void;
  filterState?: FilterState;
  filterId: string;
  contacts: ParsedContact[] | undefined;
}

export const FilterControl = ({
  filterId,
  filterState,
  onFilterSelected,
  contacts,
}: FilterControlProps) => {
  const user = useUser();
  const contactsById: Record<string, ParsedContact> = useMemo(
    () =>
      Object.fromEntries(
        (contacts || []).map((contact) => [contact.id, contact])
      ),
    [contacts]
  );
  const contactsByUserId: Record<string, ParsedContact> = useMemo(
    () =>
      Object.fromEntries(
        (contacts || []).flatMap((contact) =>
          contact.userId ? [[contact.userId, contact]] : []
        )
      ),
    [contacts]
  );
  const filterValue = filterState?.[filterId];
  const { ref, filterIsOpen, setFilterIsOpen, anchorPosition } =
    useFilterPopover();

  const count = filterValue?.checked?.length || 0;
  const hasMany = count > 1;
  const config =
    filterValue?.filterKey && filterValue.filterKey in filterConfig
      ? filterConfig[filterValue.filterKey as keyof typeof filterConfig]
      : undefined;

  const updateFilter = ({
    inclusive,
    all,
    dateOperator,
  }: UpdateFilterProps) => {
    if (!filterValue) {
      throw new Error('Filter value is missing');
    }

    onFilterSelected?.(
      {
        ...filterState,
        [filterId]: {
          ...filterValue,
          inclusive,
          all,
          dateOperator: dateOperator || 'after',
        },
      },
      false
    );
  };

  if (!filterValue) {
    return null;
  }

  const firstValue = filterValue.checked?.[0];

  const label = (() => {
    if (count === 1) {
      if (firstValue === 'meta-no-entry') {
        return `No ${config?.title.toLowerCase()}`;
      }

      if (config?.hasAvatar) {
        if (!firstValue) {
          return 'Unknown';
        }

        const contactMap =
          filterValue.filterKey === 'people' ? contactsById : contactsByUserId;

        return getContactName(contactMap[firstValue], user);
      }

      const displayMap: Record<string, string> = {
        dayAgo: '1 day ago',
        weekAgo: '1 week ago',
        monthAgo: '1 month ago',
        threeMonthsAgo: '3 months ago',
      };

      return displayMap[firstValue || ''] ?? firstValue;
    }

    return `${count} ${(config?.pluralTitle || config?.title)?.toLowerCase()}`;
  })();

  const operatorValue = (() => {
    if (config?.dateFilter) {
      return filterValue.dateOperator;
    }

    if (filterValue.filterKey === 'content') {
      return filterValue.inclusive ? 'contains' : 'does not contain';
    }

    if (config?.manyToMany) {
      if (hasMany) {
        if (filterValue.all) {
          return filterValue.inclusive ? 'include all of' : 'exclude if all';
        }

        return filterValue.inclusive ? 'include any of' : 'exclude if any of';
      }

      return filterValue.inclusive ? 'include' : 'do not include';
    }

    if (count > 1) {
      return filterValue.inclusive ? 'is any of' : 'is not';
    }

    return filterValue.inclusive ? 'is' : 'is not';
  })();

  const startDecorator = (() => {
    if (config?.hasAvatar) {
      if (filterValue?.filterKey === 'instruments') {
        return (
          <AvatarGroup
            sx={{
              '--AvatarGroup-gap': '-0.6rem',
            }}
          >
            {filterValue.checked?.slice(0, 3).map((instrument) => (
              <InstrumentAvatar
                key={instrument}
                instrument={instrument}
                sx={(theme) => ({
                  background: theme.palette.background.level1,
                  '--Avatar-ringSize': '0.6px',
                  '--Avatar-ring': `0 0 0 var(--Avatar-ringSize) ${theme.palette.common.black}`,
                })}
              />
            ))}
          </AvatarGroup>
        );
      }

      const contactMap =
        filterValue?.filterKey === 'creator' ? contactsByUserId : contactsById;

      return (
        <AvatarGroup sx={{ '--AvatarGroup-gap': '-0.2rem' }}>
          {filterValue.checked?.slice(0, 3).map((contactId) => (
            <ContactAvatar key={contactId} contact={contactMap[contactId]} />
          ))}
        </AvatarGroup>
      );
    }

    return config?.subIcon;
  })();

  const operatorOptions = (() => {
    if (config?.dateFilter) {
      return (
        <>
          <MenuItem
            onClick={() =>
              updateFilter({
                inclusive: true,
                all: undefined,
                dateOperator: 'after',
              })
            }
          >
            after
          </MenuItem>
          <MenuItem
            onClick={() =>
              updateFilter({
                inclusive: true,
                all: true,
                dateOperator: 'before',
              })
            }
          >
            before
          </MenuItem>
        </>
      );
    }

    return config?.manyToMany ? (
      <>
        {hasMany ? (
          <>
            <MenuItem
              onClick={() => updateFilter({ inclusive: true, all: true })}
            >
              include all of
            </MenuItem>
            <MenuItem
              onClick={() => updateFilter({ inclusive: true, all: false })}
            >
              include any of
            </MenuItem>
            <MenuItem
              onClick={() => updateFilter({ inclusive: false, all: false })}
            >
              exclude if any of
            </MenuItem>
            <MenuItem
              onClick={() => updateFilter({ inclusive: false, all: true })}
            >
              exclude if all
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => updateFilter({ inclusive: true, all: undefined })}
            >
              <Add /> include
            </MenuItem>
            <MenuItem
              onClick={() => updateFilter({ inclusive: false, all: undefined })}
            >
              <DoNotDisturbAltOutlined />
              does not include
            </MenuItem>
          </>
        )}
      </>
    ) : (
      <>
        <MenuItem
          onClick={() => updateFilter({ inclusive: true, all: undefined })}
        >
          <Add /> {filterValue.filterKey === 'content' ? 'contains' : 'is'}
        </MenuItem>
        <MenuItem
          onClick={() => updateFilter({ inclusive: false, all: undefined })}
        >
          <DoNotDisturbAltOutlined />
          {filterValue.filterKey === 'content' ? 'does not contain' : 'is not'}
        </MenuItem>
      </>
    );
  })();

  return (
    <>
      <ButtonGroup
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          border: '1px solid gray',
          padding: '0 6px',
          height: '26px',
          fontSize: '12px',
          [`& .${svgIconClasses.root}`]: {
            fontSize: '16px',
          },
          [`& .${buttonClasses.root}, & .${menuButtonClasses.root}`]: {
            padding: 0,
            fontSize: '12px',

            minHeight: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          [`& .${iconButtonClasses.root}`]: {
            padding: '3px',
          },
          [`& .${typographyClasses.root}`]: {
            fontSize: '12px',
          },
          [`& .${avatarClasses.root}`]: {
            width: '16px',
            height: '16px',
          },
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          {config?.icon}
          <Typography>
            {config?.noSubOptionTitle ? 'Contact' : config?.title}
          </Typography>
        </Box>

        <Dropdown>
          <MenuButton variant="plain" color="neutral" size="sm">
            {operatorValue}
          </MenuButton>
          <Menu size="sm">{operatorOptions}</Menu>
        </Dropdown>

        {config?.noSubOptionTitle ? (
          <>{config.noSubOptionTitle}</>
        ) : (
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            startDecorator={startDecorator}
            ref={ref}
            onClick={() => {
              if (filterValue.filterKey === 'content') {
                onFilterSelected?.(undefined, false, true);
              } else {
                setFilterIsOpen(true);
              }
            }}
          >
            {label}
          </Button>
        )}
        <IconButton
          size="small"
          onClick={() => {
            onFilterSelected?.(
              Object.fromEntries(
                Object.entries(filterState).filter(([key]) => key !== filterId)
              ),
              true
            );
          }}
        >
          <CloseOutlined />
        </IconButton>
      </ButtonGroup>
      <Popover
        open={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <FilterOverview
          contacts={contacts}
          filterState={filterState}
          onFilterSelected={(nextFilterState, shouldClose) => {
            if (nextFilterState) {
              onFilterSelected?.(nextFilterState, shouldClose);
            }

            if (shouldClose) {
              setFilterIsOpen(false);
            }
          }}
          currentFilterId={filterId}
          currentFilterOptionKey={filterValue.filterKey}
        />
      </Popover>
    </>
  );
};
