import Box, { BoxProps } from '@mui/joy/Box';

export const ModalTypographyContainer = ({ sx, ...props }: BoxProps) => (
  <Box
    sx={[
      {
        maxWidth: '530px',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);
