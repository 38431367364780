import { IonModal, IonNav } from '@ionic/react';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { useUserData } from '@components/UserData/useUserData';

import { AppIntroProvider } from './AppIntroProvider';
import { Page1 } from './Page1';

interface AppIntroProps {
  children: React.ReactNode;
}

export const AppIntro = ({ children }: AppIntroProps) => {
  const { userContact } = useUserData();
  const [animated, setAnimated] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const [showChildren, setShowChildren] = useState(false);
  const isDesktop = useMediaQuery('(min-width:747px)');

  useEffect(() => void setTimeout(() => setAnimated(true), 1000), []);

  useEffect(() => {
    if (!userContact || isOpen !== undefined) {
      return;
    }
    const needsModal = userContact.updatedBy === 'system';

    setIsOpen(needsModal);
    if (!showChildren) {
      setTimeout(() => setShowChildren(true), needsModal ? 300 : 0);
    }
  }, [userContact, showChildren, isOpen]);

  if (isOpen === undefined) {
    return <div>loading user contact…</div>;
  }

  return (
    <>
      {showChildren ? children : null}
      <AppIntroProvider onDone={() => setIsOpen(false)}>
        <IonModal
          isOpen={isOpen}
          animated={animated}
          canDismiss={false}
          style={isDesktop ? { '--width': '650px' } : undefined}
        >
          <div data-auth="app-intro-modal">
            <IonNav root={() => <Page1 />} />
          </div>
        </IonModal>
      </AppIntroProvider>
    </>
  );
};
